// import { Component, OnInit } from '@angular/core';
import { Component,OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
declare var $:any;
declare var call: any;
declare var init_page: any;
declare var getinStatus: any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent implements OnInit {
  @ViewChild('videoPlayer') videoplayer: ElementRef;
title = 'app';
  elementType = 'url';
  videourl;
  value = 'https://play.google.com/store/apps/details?id=com.communityinstructor';
iosvalue ='https://apps.apple.com/us/app/id1530028946';
admin_type;
  constructor() { }

  ngOnInit(): void {
    init_page('000','3YAEcao70l','htfsfXhi8n','5060','cal4care.3cx.sg','');
    this.admin_type=localStorage.getItem('admin_type');

  }

getandroid(){

 $('#androidapp').modal({
   show:true,
   backdrop:false
});
$('#androidapp').modal('show');

}

getios(){
  
   $('#iosapp').modal({
   show:true,
   backdrop:false
});
$('#iosapp').modal('show');

}

gethelpvideo(){
    $('#help').modal({
   show:true,
   backdrop:false
});
$('#help').modal('show');
}


helpvideoplay(val){
  console.log(val)
if(val == 'inst')
  this.videourl ='inst'
else if(val == 'stud')
  this.videourl ='stud'
else if(val == 'event')
  this.videourl ='event'

  console.log(this.videourl)
    $('#showvideo').modal({
   show:true,
   backdrop:false
});
$('#showvideo').modal('show');


}

toggleVideo() {
    this.videoplayer.nativeElement.play();
}
clickToCall(){
  let status: any = getinStatus();
    console.log(status);
    if(status === 'REGISTERED'){
      this.webMakeCall();
      return status;
    }
    return status;
}

webMakeCall() {
  $('#make_call_number').val('864');
  call();
}

incomingCallEnd() {
  console.log('Incoming call end');
}
outgoingCallEnd(){
  console.log('Outgoing call end');
}
incoming_call_trigger(){
  console.log('Incomming call');
}
}
