import { Component, OnInit } from '@angular/core';
import { NgxEditorModule } from 'ngx-editor';
import { ServerService } from '../services/server.service';
import { Router,ActivatedRoute } from '@angular/router';
declare var $:any;
declare var iziToast:any;
import * as moment from 'moment';
@Component({
  selector: 'app-assignnewevent',
  templateUrl: './assignnewevent.component.html',
  styleUrls: ['./assignnewevent.component.css']
})
export class AssignneweventComponent implements OnInit {
eventData:any={};
value;
eventslist:any=[];
eventbind;
instructorlist:any=[]
 
 studentslist:any=[];
 participantlist:any=[];
recordNotFound = false;
recordNotFound2 = false;
recordNotFound3 = false;

 pageLimit = 5;
 pageLimit2 = 10;
 pageLimit3 = 10;

 paginationData:any ={"info":"hide"};
 paginationData2:any ={"info":"hide"};
 paginationData3:any ={"info":"hide"};

 offset_count = 0;
  offset_count2 = 0;
  offset_count3 = 0;

 sendstudent;
 sendparticipant;

 sendstudentarr=[];
 participantarr=[];

 instructorselect;
  instructorselectco;

 instructselectname;
instructselectnameco;


 instructorfullname;
 instructorfullnameco;

 instructorshowfullname;
 instructorshowfullnameco;

instructselectshow;
instructselectshowco;

instructselectshowname;
instructselectshownameco;

dayspick;
 days:any=[];
 
 typeinstructor;
 locations:any=[];
 studentnames:any=[];
 sendstudentname;
 showsendnames:any=[];

 participantnames:any=[];
 sendparticipantname;
 showsendnamesparticipant:any=[];
 pagetype;
 addtitleinstructorco;
 addtitleinstructor;
  constructor(private route:ActivatedRoute,private router:Router,private serverService: ServerService) {
 // this.eventData.types = 'events';
  this.addtitleinstructor = 'Add Incharge To Event'
  this.addtitleinstructorco = 'Add Co-Incharge To Event'
this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {

  this.pagetype=this.router.getCurrentNavigation().extras.state.pagetype;
  this.assignpage()

      

      }
    });
console.log(this.pagetype)
  if(this.pagetype == undefined ){
    this.pagetype=localStorage.getItem('assignpagetype');
    this.assignpage()
   }

   }

assignpage(){
  if(this.pagetype == 'meeting'){
this.eventData.types = 'meeting';
this.addtitleinstructor = 'Add Incharge To Meeting';
this.addtitleinstructorco = 'Add Co-Incharge To Meeting';
 localStorage.setItem('assignpagetype', 'meeting'); 


}else if(this.pagetype == 'event'){
  this.eventData.types = 'events';
  this.addtitleinstructor = 'Add Incharge To Event'
  this.addtitleinstructorco = 'Add Co-Incharge To Event'
localStorage.setItem('assignpagetype', 'event'); 


}else{
  this.eventData.types = 'course';
  this.addtitleinstructor = 'Add Instructor To Course'
  this.addtitleinstructorco = 'Add Co-Instructor To Course'
 localStorage.setItem('assignpagetype', 'course'); 

}
}


  ngOnInit(): void {


    this.typeinstructor = 'instructor'
  	this.viewevents()
    this.viewlocation()
  	// this.get_instructor_list({})
    this.get_student_list({})
    this.get_participant_list({})
    // this.eventData.types = 'events'
    this.dayslist()
    this.eventData.mobile_display = true;
  }

golocationpage(name){
this.router.navigate(['location'], { state: { page: name} });
}

eventpage(name){
this.router.navigate(['addnewevent'], { state: { type: name} });

}



dayslist(){
  this.days=[{

    "id": 1,
    "name": "Mon",
    "checked": false
  },
  {
    "id": 2,
    "name": "Tue",
    "checked": false
  },
  {
    "id": 3,
    "name": "Wed",
    "checked": false
  },
  {
    "id": 4,
    "name": "Thurs",
    "checked": false
  },
  {
    "id": 5,
    "name": "Fri",
    "checked": false
  },
  {
    "id": 6,
    "name": "Sat",
    "checked": false
  },
    {
    "id": 7,
    "name": "Sun",
    "checked": false
  }
]
 
}

 addstud(){

    // $('#add_queue_form').modal('show');

     $('#addNewPopup').modal({
  show:true,
   backdrop:false

});
$('#addNewPopup').modal('show');
  }

 addparticipant(){

    // $('#add_queue_form').modal('show');

     $('#addNewPopupparticipant').modal({
  show:true,
   backdrop:false

});
$('#addNewPopupparticipant').modal('show');
  }





addinstruct(name){

  this.typeinstructor = name;

    this.get_instructor_list({})
  $('#addNewPopups').modal({
  show:true,
   backdrop:false

});
$('#addNewPopups').modal('show');
}


listDataInfo(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
}  

listDataInfo2(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit2 : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
} 

listDataInfo3(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit3 : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
} 

getsearch(searchValue: string): void {  
  console.log(searchValue);
  this.get_student_list({'search_text':searchValue})
}

getsearchpaticipant(searchValue: string): void {  
  console.log(searchValue);
  this.get_participant_list({'search_text':searchValue})
}

getsearchinstructor(searchValue: string): void {  
  console.log(searchValue);
  this.get_instructor_list({'search_text':searchValue})
}

get_student_list(data){
  var list_data= this.listDataInfo(data);
  console.log(list_data)

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

 
    let api_req:any = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"view_student_rec","limit":"'+list_data.limit+'","offset":'+list_data.offset+',"search_text":"'+list_data.search_text+'","admin_id":"'+admin_id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){
      this.studentslist=response.result.data.list_data;
//          for (var i = 0; i < this.studentslist.length; i++) {
 
//    this.studentslist[i].add = false
  
// }
console.log(this.sendstudentarr)
console.log(this.studentslist)


if(this.studentslist.length != 0){

if(this.sendstudentarr.length != 0){
for (var i = 0; i < this.studentslist.length; i++) {
   var ismatch = false;
  for (var j = 0; j < this.sendstudentarr.length; j++) {
   if(this.studentslist[i].username ==  this.sendstudentarr[j]){
     ismatch = true;
     this.studentslist[i].add = true;
     break;  

   }
 }
 if (!ismatch){
         this.studentslist[i].add = false//  checkbox status false
      
      } 

}
}
else{
  
  for (var i = 0; i < this.studentslist.length; i++) {
     this.studentslist[i].add = false  
  }
}

}

console.log(this.studentslist)




          this.offset_count = list_data.offset;
        this.paginationData = this.serverService.pagination({'offset':response.result.data.list_info.offset, 'total':response.result.data.list_info.total, 'page_limit' :this.pageLimit });
         console.log(this.paginationData);
                this.recordNotFound = this.studentslist.length == 0 ? true : false;


      }
     

    }, 
    (error)=>{
        console.log(error);
    });
  }



get_participant_list(data){
  var list_data= this.listDataInfo3(data);
  console.log(list_data)

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

 
      let api_req:any = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"view_participant","limit":"'+list_data.limit+'","offset":'+list_data.offset+',"search_text":"'+list_data.search_text+'","admin_id":"'+admin_id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){
      this.participantlist=response.result.data.list_data;
//          for (var i = 0; i < this.studentslist.length; i++) {
 
//    this.studentslist[i].add = false
  
// }
console.log(this.participantarr)
console.log(this.participantlist)


if(this.participantlist.length != 0){

if(this.participantarr.length != 0){
for (var i = 0; i < this.participantlist.length; i++) {
   var ismatch = false;
  for (var j = 0; j < this.participantarr.length; j++) {
   if(this.participantlist[i].id ==  this.participantarr[j]){
     ismatch = true;
     this.participantlist[i].add = true;
     break;  

   }
 }
 if (!ismatch){
         this.participantlist[i].add = false//  checkbox status false
      
      } 

}
}
else{
  
  for (var i = 0; i < this.participantlist.length; i++) {
     this.participantlist[i].add = false  
  }
}

}

console.log(this.participantlist)




          this.offset_count3 = list_data.offset;
        this.paginationData3 = this.serverService.pagination({'offset':response.result.data.list_info.offset, 'total':response.result.data.list_info.total, 'page_limit' :this.pageLimit3 });
         console.log(this.paginationData);
                this.recordNotFound3 = this.participantlist.length == 0 ? true : false;


      }
     

    }, 
    (error)=>{
        console.log(error);
    });
  }

// addstudents(){

//    let registerstudentdata:any = new Object();
//  let registeruser:any = new Object();
//     let access_token: any=localStorage.getItem('access_token');

//     let admin_id: any=localStorage.getItem('userId');

// registerstudentdata.action="assign_students";
// registerstudentdata.event_name=this.eventData.event_name;

// registerstudentdata.start_time='';
// registerstudentdata.end_time='';

// registerstudentdata.student_name='';

// registerstudentdata.admin_id=admin_id;

// registerstudentdata.inst_id='';

//  registeruser.access_token=access_token;

//   registeruser.operation="lecture";
//   registeruser.moduleType="lecture";
//   registeruser.api_type="web";
//   registeruser.element_data = registerstudentdata;
//       this.serverService.sendServer(registeruser).subscribe((response: any) => {

//         console.log(response)
       
 

//         if(response.result.status==true){

//         }
     
      
//       }, 
//       (error)=>{
//           console.log(error);
//       });
// }



assigneventscourse(){


if(this.instructselectname == undefined){
if(this.eventData.types == 'course'){
   iziToast.error({

          message: "Please Select Instructor Name",
          position: 'topRight'
      });
      return false
}
     else{
        iziToast.error({

          message: "Please Select Incharge Name",
          position: 'topRight'
      });
      return false
     }
}

// if(this.instructselectnameco == undefined){
// if(this.eventData.types != 'meeting'){
//    iziToast.error({

//           message: "Please Select Co-Instructor Name",
//           position: 'topRight'
//       });
//       return false
// }
//      else{
//         iziToast.error({

//           message: "Please Select Co-Incharge Name",
//           position: 'topRight'
//       });
//       return false
//      }
// }

if(this.eventData.types == 'course'){
if(this.sendstudent== undefined){

      iziToast.error({

          message: "Please Add Students",
          position: 'topRight'
      });
      return false
}
}

if(this.eventData.types !== 'course'){
if(this.eventData.start_time== undefined){

      iziToast.error({

          message: "Please Select Start Time",
          position: 'topRight'
      });
      return false
}

if(this.eventData.end_time== undefined){

      iziToast.error({

          message: "Please Select End Time",
          position: 'topRight'
      });
      return false
}

}


if(this.eventData.location== undefined){

      iziToast.error({

          message: "Please Enter Location",
          position: 'topRight'
      });
      return false
}



   if(this.eventData.types == 'events'){
if(this.eventData.event_name == undefined){

      iziToast.error({

          message: "Please Select Event Name",
          position: 'topRight'
      });
      return false
}

     this.assignevent()
   }
   else if(this.eventData.types == 'course'){



if(this.eventData.event_name == undefined){

      iziToast.error({

          message: "Please Select Course Name",
          position: 'topRight'
      });
      return false
}


if(this.eventData.coursestartdate == undefined){

      iziToast.error({

          message: "Please Select Course Start Date",
          position: 'topRight'
      });
      return false
}


if(this.eventData.coursestarttime == undefined){

      iziToast.error({

          message: "Please Select Course Start Date",
          position: 'topRight'
      });
      return false
}

if(this.eventData.cousreendtime == undefined){

      iziToast.error({

          message: "Please Select Course Start Date",
          position: 'topRight'
      });
      return false
}
if(this.eventData.no_of_class == undefined){

      iziToast.error({

          message: "Please Select Course Start Date",
          position: 'topRight'
      });
      return false
}
     this.add_course()
   }
    else if(this.eventData.types == 'meeting'){
      if(this.eventData.event_name == undefined){

      iziToast.error({

          message: "Please Select Meeting Name",
          position: 'topRight'
      });
      return false
}

      if(this.eventData.reference == undefined){

      iziToast.error({

          message: "Please Enter Reference",
          position: 'topRight'
      });
      return false
}
 if(this.sendparticipant == undefined){

      iziToast.error({

          message: "Please Add Participant",
          position: 'topRight'
      });
      return false
}



this.assignmeeting()
    }

}

assignevent(){


var sendtemplate;
var senddisplay;
if(this.eventData.template == true){
  sendtemplate = 1;
}else{
sendtemplate = 0;
}
if(this.eventData.mobile_display == true){
  senddisplay = 1;
}else{
senddisplay = 0;
}
  



  


console.log(this.days)
  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');

    let admin_id: any=localStorage.getItem('userId');
    let superadmin: any=localStorage.getItem('superadmin');

registerstudentdata.action="add_event_details";
registerstudentdata.admin_id=admin_id;
registerstudentdata.location=this.eventData.location;
registerstudentdata.mobile_display=senddisplay;


registerstudentdata.super_admin=superadmin;

registerstudentdata.event_name=this.eventData.event_name;
registerstudentdata.instructors=this.instructorselect;
registerstudentdata.co_inst=this.instructorselectco;

registerstudentdata.notes=this.eventData.note;
registerstudentdata.start_time=this.eventData.start_time;
registerstudentdata.end_time=this.eventData.end_time;
registerstudentdata.status='';
registerstudentdata.template=sendtemplate;
registerstudentdata.template_name=this.eventData.template_name;


 registeruser.access_token=access_token;
  registeruser.operation="user";
  registeruser.moduleType="user";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

      	console.log(response)
       
 

        if(response.result.status==true){
this.assignstudentstoevents(response.result.data)

        }
        else{
iziToast.error({
    // title: 'OK',
    position: 'topRight',
    message: 'Error Occured!',
});
        }
      
      }, 
      (error)=>{
          console.log(error);
      });
}

viewevents(){

  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');


registerstudentdata.action="view_events";
registerstudentdata.admin_id=admin_id;

 registeruser.access_token=access_token;
  registeruser.operation="user";
  registeruser.moduleType="user";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){
     this.eventslist = response.result.data
   }else{

   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}





get_instructor_list(data){
  var list_data= this.listDataInfo(data);

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');
var api_req:any;
var perms;
if(this.eventData.types == 'meeting'){
perms ='meeting'
}else if(this.eventData.types == 'events'){

perms ='event'
}else if(this.eventData.types == 'course'){
perms ='course'
}

  // if(this.eventData.types == 'meeting'){
    api_req = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"view_instruct_rec","limit":"'+list_data.limit+'","offset":'+list_data.offset+',"search_text":"'+list_data.search_text+'","admin_id":"'+admin_id+'","type":"'+perms+'"}}';
  // }
  // else{
  // api_req = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"view_instruct_rec","limit":"'+list_data.limit+'","offset":"'+list_data.offset+'","search_text":"'+list_data.search_text+'","admin_id":"'+admin_id+'",}}';
  // }
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){
      this.instructorlist = response.result.data.list_data

    for (var i = 0; i < this.instructorlist.length; i++) {

      if(this.typeinstructor == 'instructor'){
        if(this.instructorlist[i].id == this.instructorselect){
this.instructorlist[i].add = true 

}
else{
this.instructorlist[i].add = false 

}
      }
      else{
                if(this.instructorlist[i].id == this.instructorselectco){
this.instructorlist[i].add = true 

}
else{
this.instructorlist[i].add = false 

}
      }


}


 console.log(this.instructorlist)
      this.offset_count2= list_data.offset;
        this.paginationData2 = this.serverService.pagination({'offset':response.result.data.list_info.offset, 'total':response.result.data.list_info.total, 'page_limit' :this.pageLimit });
                this.recordNotFound2 = this.instructorlist.length == 0 ? true : false;


      }
     
    }, 
    (error)=>{
        console.log(error);
    });
  }



savedata(val){

 var adding = false
if(val.add == false){

if(this.sendstudentarr.length != 0){

 for (var i = 0; i < this.sendstudentarr.length; i++) {


if(val.username !== this.sendstudentarr[i]){

 adding  = true
}

}
  if(adding){
      this.sendstudentarr.push(val.username)
      this.studentnames.push(val.fname+val.lname)

  }
}
else{
   this.sendstudentarr.push(val.username)

    this.studentnames.push(val.fname+val.lname)
}

}
else{

   for (var j = 0; j < this.sendstudentarr.length; j++) {

if(val.username == this.sendstudentarr[j]){

    this.sendstudentarr.splice(this.sendstudentarr.indexOf(this.sendstudentarr[j]), 1 );

}
}
   for (var k = 0; k < this.studentnames.length; k++) {

if(val.fname+val.lname == this.studentnames[k]){

    this.studentnames.splice(this.studentnames.indexOf(this.studentnames[k]), 1 );

}
}

}
 
 console.log(this.sendstudentarr)
}

savedataparticipant(val){

 var adding = false
if(val.add == false){

if(this.participantarr.length != 0){

 for (var i = 0; i < this.participantarr.length; i++) {


if(val.id !== this.participantarr[i]){

 adding  = true
}

}
  if(adding){
      this.participantarr.push(val.id)
      this.participantnames.push(val.name)

  }
}
else{
   this.participantarr.push(val.id)

    this.participantnames.push(val.name)
}

}
else{

   for (var j = 0; j < this.participantarr.length; j++) {

if(val.id == this.participantarr[j]){

    this.participantarr.splice(this.participantarr.indexOf(this.participantarr[j]), 1 );

}
}
   for (var k = 0; k < this.participantnames.length; k++) {

if(val.name == this.participantnames[k]){

    this.participantnames.splice(this.participantnames.indexOf(this.participantnames[k]), 1 );

}
}

}
 
 console.log(this.sendstudentarr)
}
savedatainstructor(val){
 console.log(val)
if(val.add == false){
  if(this.typeinstructor == "instructor"){


this.instructselectshow = val.id;
this.instructselectshowname = val.username;
this.instructorshowfullname = val.fname + val.lname

  }else{
this.instructselectshowco = val.id;
this.instructselectshownameco = val.username;
this.instructorshowfullnameco = val.fname + val.lname



  }


}

}

savealldata2(){
  if(this.typeinstructor == "instructor"){
this.instructorselect= this.instructselectshow;
this.instructselectname = this.instructselectshowname;
this.instructorfullname = this.instructorshowfullname;

  }else{
 
    this.instructorselectco= this.instructselectshowco
this.instructselectnameco =this.instructselectshownameco
this.instructorfullnameco = this.instructorshowfullnameco;

  }



}
savealldata(){
  if(this.eventData.types == "course"){
  this.showsendnames =[];
   this.sendstudent = ''
   this.sendstudentname  =''
  this.eventData.count = this.sendstudentarr.length
   this.sendstudent=this.sendstudentarr.join(',');
   this.showsendnames =this.studentnames
   this.sendstudentname =this.studentnames.join(',');  
  }
else{
 this.showsendnamesparticipant =[];
   this.sendparticipant = ''

   this.sendparticipantname  =''
  this.eventData.count2 = this.participantarr.length
   this.sendparticipant=this.participantarr.join(',');
   this.showsendnamesparticipant =this.participantnames
   this.sendparticipantname =this.participantnames.join(',');  
}
 
}






assignstudentstoevents(res){
console.log(res)
  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');


registerstudentdata.action="assign_students";
registerstudentdata.event_name=res[0].event_name;
registerstudentdata.start_time=res[0].start_time;
registerstudentdata.end_time=res[0].end_time;
// registerstudentdata.student_name=this.sendstudent;
registerstudentdata.student_name=this.sendparticipant;

registerstudentdata.inst_id=this.instructorselect;
registerstudentdata.co_inst=this.instructorselectco;



registerstudentdata.admin_id=admin_id;
registerstudentdata.event_id=res[0].id;



 registeruser.access_token=access_token;
  registeruser.operation="lecture";
  registeruser.moduleType="lecture";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){
     iziToast.success({
    title: 'OK',
    position: 'topRight',
    message: 'Successfully event'+' '+res[0].id+' '+'assigned!',
});
   
this.reseting()
      this.eventData.types = 'events'

   }else{
   iziToast.error({
    title: 'OK',
    position: 'topRight',
    message: 'Illegal operation!',
});
   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}


add_course(){
  console.log(this.eventData)

  console.log(this.eventData.coursestarttime)
  console.log(this.eventData.cousreendtime)

  console.log(this.days)
this.dayspick =''
var daysarray=[];
for (var i = 0; i<this.days.length; i++) {
  if(this.days[i].checked == true)
  {
   daysarray.push(this.days[i].id)
  }
}
this.dayspick = daysarray.toString();
  console.log(this.dayspick)
var sendtemplate;
if(this.eventData.template == true){
  sendtemplate = 1;
}else{
sendtemplate = 0;
}

  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

registerstudentdata.location=this.eventData.location;

registerstudentdata.action="add_new_course";
registerstudentdata.event_name=this.eventData.event_name;
registerstudentdata.instructors=this.instructselectname;
registerstudentdata.co_inst=this.instructselectnameco;


registerstudentdata.template=sendtemplate;
registerstudentdata.template_name=this.eventData.template_name;


registerstudentdata.start_time= moment(this.eventData.coursestartdate).format('YYYY-MM-DD');
// registerstudentdata.end_time=moment(this.eventData.end_time).format('YYYY-MM-DD');
registerstudentdata.no_of_class=this.eventData.no_of_class;

registerstudentdata.notes=this.eventData.note;
registerstudentdata.from_time=this.eventData.coursestarttime+':'+'00'
registerstudentdata.to_time=this.eventData.cousreendtime+':'+'00'
registerstudentdata.admin_id=admin_id;
registerstudentdata.days=this.dayspick;
 registeruser.access_token=access_token;
  registeruser.operation="lecture";
  registeruser.moduleType="lecture";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.data.status== "true"){
    this.assignstudentstocourse(response.result.data.course_id)
   }else{
   iziToast.error({
    title: 'OK',
    position: 'topRight',
    message: 'Illegal operation!',
});
   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}





assignstudentstocourse(val){

  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');


registerstudentdata.action="add_course_students";
registerstudentdata.event_name=this.eventData.event_name;
registerstudentdata.start_time= moment(this.eventData.coursestartdate).format('YYYY-MM-DD');

registerstudentdata.from_time=this.eventData.coursestarttime+':'+'00'
registerstudentdata.to_time=this.eventData.cousreendtime+':'+'00'




registerstudentdata.course_id=val;
registerstudentdata.no_of_class=this.eventData.no_of_class;

registerstudentdata.days=this.dayspick;

registerstudentdata.student_name=this.sendstudent;
registerstudentdata.inst_id=this.instructorselect;
registerstudentdata.co_inst=this.instructorselectco;

registerstudentdata.admin_id=admin_id;



 registeruser.access_token=access_token;
  registeruser.operation="lecture";
  registeruser.moduleType="lecture";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.data.status=="true" ){
     iziToast.success({
    title: 'OK',
    position: 'topRight',
    message: 'Successfully Course Id'+' '+val+' '+'assigned!',
});

this.reseting()


 this.eventData.types = 'events'
   }else{
   iziToast.error({
    title: 'OK',
    position: 'topRight',
    message: 'Illegal operation!',
});
   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}

viewlocation(){

  let updateevents:any =new Object();
 let updateeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
   
updateevents.action="view_location";

updateevents.admin_id =localStorage.getItem('userId');


 updateeventsuser.access_token=access_token;
  updateeventsuser.operation="lecture";
  updateeventsuser.moduleType="lecture";
  updateeventsuser.api_type="web";
  updateeventsuser.element_data = updateevents;
      this.serverService.sendServer(updateeventsuser).subscribe((response: any) => {
console.log(response)
        if(response.result.status==true){
     this.locations = response.result.data
   

          }
        else{

 
        }
      
      }, 
      (error)=>{
          console.log(error);
      });

}


assignmeeting(){
console.log(this.eventData)
  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');

    let admin_id: any=localStorage.getItem('userId');
    let superadmin: any=localStorage.getItem('superadmin');
    var templatesend;
    var sends;
if(this.eventData.template == true){
  templatesend = 1;
}else{
templatesend = 0;
}

if(this.eventData.mobile_display == true){
  sends = 1;
}else{
sends = 0;
}

registerstudentdata.action="create_meeting";
registerstudentdata.admin_id=admin_id;
registerstudentdata.location=this.eventData.location;
registerstudentdata.reference=this.eventData.reference;


registerstudentdata.super_admin=superadmin;

registerstudentdata.meeting_name=this.eventData.event_name;
registerstudentdata.incharge_id=this.instructorselect;
registerstudentdata.co_incharge_id=this.instructorselectco;

registerstudentdata.template=templatesend;
registerstudentdata.template_name=this.eventData.template_name;
registerstudentdata.mobile_display=sends;

registerstudentdata.notes=this.eventData.note;
registerstudentdata.meeting_dt=this.eventData.start_time;
registerstudentdata.meeting_end=this.eventData.end_time;

// registerstudentdata.meeting_dt=moment(this.eventData.start_time).format('YYYY-MM-DD hh:mm:ss');
// registerstudentdata.meeting_end=moment(this.eventData.end_time).format('YYYY-MM-DD hh:mm:ss');
// registerstudentdata.status=''
// ;

 registeruser.access_token=access_token;
  registeruser.operation="meeting";
  registeruser.moduleType="meeting";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
       
 

        if(response.result.status==true){
this.assignstudentstomeetings(response.result.data)

        }
        else{
iziToast.error({
    // title: 'OK',
    position: 'topRight',
    message: 'Illegal operation!',
});
        }
      
      }, 
      (error)=>{
          console.log(error);
      });
}

assignstudentstomeetings(res){
console.log(res)
  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');


registerstudentdata.action="assign_participant";
registerstudentdata.meeting_name=res[0].meeting_name;
registerstudentdata.start_time=res[0].meeting_dt;
registerstudentdata.end_time=res[0].meeting_end;
registerstudentdata.participant_name=this.sendparticipant;
registerstudentdata.inst_id=this.instructorselect;
registerstudentdata.co_inst=this.instructorselectco;

registerstudentdata.admin_id=admin_id;
registerstudentdata.meeting_id=res[0].id;



 registeruser.access_token=access_token;
  registeruser.operation="meeting";
  registeruser.moduleType="meeting";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){
     iziToast.success({
    title: 'OK',
    position: 'topRight',
    message: 'Successfully Meeting'+' '+res[0].id+' '+'assigned!',
});
   
this.reseting()
      this.eventData.types = 'events'

   }else{
   iziToast.error({
    title: 'OK',
    position: 'topRight',
    message: 'Error Occured!',
});
   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}





reseting(){
  this.eventData={};
   this.sendstudentarr=[];
    this.participantarr=[];
 this.instructorselect='';
  this.instructorselectco='';

 this.instructselectname ='';
 this.instructselectnameco='';


 this.instructorfullname = '';
 this.instructorfullnameco = '';

 this.instructorshowfullname='';
 this.instructorshowfullnameco= '';

this.instructselectshow= '';
this.instructselectshowco='';

this.instructselectshowname ='';
this.instructselectshownameco='';

this.dayspick='';

 
 this.typeinstructor='';

 this.studentnames=[];
 this.sendstudentname='';
 this.showsendnames=[];

 this.participantnames=[];
 this.sendparticipantname='';
 this.showsendnamesparticipant=[];

 this.eventData.types = 'events'
 this.eventData.mobile_display = true;
 this.dayslist()
}




}
