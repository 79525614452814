
          <div class="section-body">
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    <div class="card-header-title">
                      <h4>Participant Record</h4>
                    </div>
                    <div class="card-header-btn-group"  >
                      <a style="margin: 0px 10px 0px 10px !important;" [routerLink]="['/addneewparticipant']" class="btn btn-info"><i class="fa fa-plus-circle" aria-hidden="true"></i> Add New</a>
                      <a (click)="showmodalcsv()" style="margin: 0px 10px 0px 10px !important;" class="btn btn-warning"><i class="fa fa-plus-upload" aria-hidden="true"></i>Upload CSV</a>
                      <a href="{{this.dummyCsv}}"  style="margin: 0px 10px 0px 10px !important;"  class="btn btn-success"><i class="fa fa-plus-download" aria-hidden="true"></i>Download Sample CSV</a>
                      <a (click)="removealert('deletestudentrecord','')" style="margin: 0px 10px 0px 10px !important;"  class="btn btn-danger"><i class="fa fa-plus-download" aria-hidden="true" ></i>Delete</a>
                    </div>
                
                  </div>
                  <div class="card-body" >
                    <div class="table-responsive">
                      <table  class="table  table-bordered table-striped table-md " id="table-2">




                        <thead>
                          <tr>
                            <td colspan="20" class="search-text-tbl">
                              <div class="input-group"><input type="text" class="form-control" placeholder="Search Participant" (input)="getsearch($event.target.value)"  (keyup.enter)="get_participant_list({'search_text':agent_search.value})" #agent_search>
                                <div class="input-group-append">
                                  <button class="btn btn-primary" (click)="get_participant_list({'search_text':agent_search.value})"><i class="fas fa-search"></i></button>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr class="l-bg-light-blue">
                            <th>
                             <!--   <div class="custom-checkbox custom-checkbox-table custom-control">
                                 
                                <input type="checkbox" data-checkboxes="mygroup" data-checkbox-role="dad" [(ngModel)]="allcheck"
                                class="custom-control-input" id="checkbox-all" (click)="allchk()">
                                <label for="checkbox-all" class="custom-control-label">&nbsp;</label>
                              </div> -->
                            </th>
                            <th>S.no</th>
                            <th>Participant Name</th>
                            <th>Photo</th>
                            <th>Email</th>
                            <th>Contact no.</th>
                            <!-- <th>NRIC</th> -->
                            <th>Action</th>
                          </tr>
                        </thead>
                        
                        <tbody >


                          <tr *ngFor="let l of participantlist;let i=index">
                       <td class="text-center pt-2">
                             
                                <input class="" type="checkbox"  [(ngModel)]="l.add" name="add" (click)="multislt(l)">
                                
                            </td>
                            <td>{{offset_count+i+1}}</td>
                            <td>{{l.name}}</td>
                            <td class="student-img">
                           <!--    <img alt="image" *ngIf="l.image == ''" src="assets/images/avatar.jpg" width="35">
                              <img alt="image" [src]="l.image"  *ngIf="l.image != ''" width="35"> -->

<img src="{{l.image}}"  onerror="this.src='assets/images/avatar.jpg'" alt="image"  width="35">
                            </td>

                            <td>{{l.email}}</td>
                            <td>{{l.phone_no}}</td>
                            <!-- <td>{{l.nirp}}</td> -->

     
                              <td>
                                <a href="javascript:void(0)" (click)="goedit(l)" class="btn btn-action bg-purple mr-1" data-toggle="tooltip" title="Edit" >
                                  <i class="fas fa-pencil-alt"></i>
                                </a> 
                        <a href="javascript:void(0)" (click)="removealert('deletestudentrecord',l)"  class="btn btn-danger btn-action" data-toggle="tooltip" title="Delete">
                                  <i class="fas fa-trash"></i>
                                </a>
                              </td>
                            </tr>

                      </tbody>
                      <tr *ngIf="recordNotFound == true">
                       <td colspan="12">Data not found</td>
                     </tr>
                     
                   </table>
                 </div>


<div class="card-footer text-right" *ngIf="recordNotFound == false">
  <nav class="d-inline-block">

    <!-- <nav class="d-inline-block"> -->
      <ul class="pagination mb-0" *ngIf="paginationData.info == 'show'">

        <li class="page-item">
          <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? get_participant_list({'search_text':agent_search.value,'offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
        </li>
        <li class="page-item" *ngIf="paginationData.backtab =='show'">
          <a class="page-link" href="javascript:void(0)" (click)="get_participant_list({'search_text':agent_search.value,'offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
        </li>
        <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
          <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? get_participant_list({'search_text':agent_search.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
        </li>

        <li class="page-item" *ngIf="paginationData.nexttab =='show'">
          <a class="page-link" href="javascript:void(0)" (click)="get_participant_list({'search_text':agent_search.value,'offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
        </li>

        <li class="page-item">
          <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? get_participant_list({'search_text':agent_search.value,'offset':paginationData.end}) : false"><i class="fas fa-angle-right"></i></a>
        </li>
      </ul>
    </nav>
  </div>























</div>
</div>
</div>
</div>
</div>


      <div id="addNewPopup" class="modal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="card-header">
              <h4>Upload CSV</h4>
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <!-- <div class="modal-header"> -->

             
              <!--    <h4 class="modal-title">Confirmation</h4> -->
              <!-- </div> -->
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="contact_owner">CSV file</label>
                      <input type="file" class="form-control" name="file" id="file" size="150">
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <a  href="{{this.dummyCsv}}"  target="_blank">Click Here To Download Sample CSV Format</a>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                  <button type="button" (click)="uploadcsv()" data-dismiss="modal" class="btn btn-primary">Upload</button>
                </div>
              </div>
            </div>
          </div>    
        </div>