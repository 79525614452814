import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';
declare var $:any;
declare var iziToast:any;
@Component({
  selector: 'app-coursereport',
  templateUrl: './coursereport.component.html',
  styleUrls: ['./coursereport.component.css']
})
export class CoursereportComponent implements OnInit {
	 getRep: FormGroup;

	 instructorlist:any=[]

recordNotFound2 = false;

 pageLimit2 = 10;

 paginationData2:any ={"info":"hide"};

  offset_count2 = 0;


courses:any=[];
allreport:any=[];

 instructorselect;
 instructselectname;
  constructor(private serverService: ServerService) { 


  }

  ngOnInit(): void {
this.courselist()
this.viewreportlist()
	this.get_instructor_list({})
		  this.getRep = new FormGroup({
			'course' :new FormControl(null,Validators.required),
			'report_type':new FormControl(null,Validators.required)

		  });



  }
addinstructor(){
    $('#addNewPopups').modal({
  show:true,
   backdrop:false

});
$('#addNewPopups').modal('show');

}

savealldata2(){

}



listDataInfo(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit2 : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
}  



getsearchinstructor(searchValue: string): void {  
  console.log(searchValue);
  this.get_instructor_list({'search_text':searchValue})
}


getReports(){
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');
  
    let api_req:any = '{"operation":"course_report","moduleType":"course_report","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"report","course_id": "'+this.getRep.value.course+'","report_type":"'+this.getRep.value.report_type+'","instructor":"'+this.instructselectname+'","admin_id":"'+admin_id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){
    

     if(response.result.data == 1){
     this.viewreportlist()	
   	   iziToast.success({
    title: 'OK',
    position: 'topRight',
    message: 'Report Generated Successfully!',
});

     }
    else{
    	   iziToast.error({
    title: 'OK',
    position: 'topRight',
    message: 'Illegal operation!',
});
    }
    
      }
   else{
  	   iziToast.error({
    title: 'OK',
    position: 'topRight',
    message: 'Illegal operation!',
});
   }

    }, 
    (error)=>{
        console.log(error);
    });
  }

courselist(){
  
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');
  
    let api_req:any = '{"operation":"lecture","moduleType":"lecture","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"course_list","admin_id":"'+admin_id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.data){
    


this.courses = response.result.data
     
    
      // console.log(this.courselist);
      }
   

    }, 
    (error)=>{
        console.log(error);
    });
  }



get_instructor_list(data){
  var list_data= this.listDataInfo(data);

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

  
    let api_req:any = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"view_instruct_rec","limit":"'+list_data.limit+'","offset":'+list_data.offset+',"search_text":"'+list_data.search_text+'","admin_id":"'+admin_id+'"}}';
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){
      this.instructorlist = response.result.data.list_data

    for (var i = 0; i < this.instructorlist.length; i++) {
if(this.instructorlist[i].id == this.instructorselect){
this.instructorlist[i].add = true 

}
else{
this.instructorlist[i].add = false 

}

}

 console.log(this.instructorlist)
      this.offset_count2= list_data.offset;
     this.paginationData2 = this.serverService.pagination({'offset':response.result.data.list_info.offset, 'total':response.result.data.list_info.total, 'page_limit' :this.pageLimit2 });
     this.recordNotFound2 = this.instructorlist.length == 0 ? true : false;


      }
     
    }, 
    (error)=>{
        console.log(error);
    });
  }


savedatainstructor(val){
 console.log(val)
if(val.add == false){
this.instructorselect= val.id
this.instructselectname = val.username

}

}







viewreportlist(){
  
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');
  
    let api_req:any = '{"operation":"course_report","moduleType":"course_report","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"view_report_list","admin_id":"'+admin_id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.data){
    


this.allreport = response.result.data

      }
   

    }, 
    (error)=>{
        console.log(error);
    });
  }
















}
