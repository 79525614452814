import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Router,ActivatedRoute } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
declare var $:any;
declare var iziToast:any;
import * as moment from 'moment';
const TODAY_STR = new Date(); 
@Component({
  selector: 'app-adminattendance',
  templateUrl: './adminattendance.component.html',
  styleUrls: ['./adminattendance.component.css']
})
export class AdminattendanceComponent implements OnInit {

attendanceheader:any=[];
attendanceheadershow:any=[];
attendancecontent:any=[];
showing = false;
nameshowinstructor;
nameshowevent
instructorlist:any=[]
 studentslist:any=[];
recordNotFound = false;
recordNotFound2 = false;

 pageLimit = 10;
 pageLimit2 = 10;

 paginationData:any ={"info":"hide"};
 paginationData2:any ={"info":"hide"};

 offset_count = 0;
  offset_count2 = 0;
 // sendstudent;

 // sendstudentarr=[];
studentselect;
studentselectname ='';


 instructorselect;
 instructselectname = '';
 eventss:any=[];
dateattendance:any=[];

 getRep:FormGroup;
 markedvalue;
 markeddate;
 markedstudentname;
 markeddetail;

eventslist:any=[];

courselist:any=[];

eventData:any={};
cur_date;
page ;
  constructor(private route:ActivatedRoute,private router:Router,private serverService: ServerService) {
      this.eventData.types = 'events'
 this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {


     this.page=this.router.getCurrentNavigation().extras.state.page; 

 console.log(this.page)
     // if(this.page == 'event'){
   // this.eventData.types = 'events'
   // }else{
      // this.eventData.types = 'course'
   // }
      }
    });
 
   }

  ngOnInit(): void {
// this.getevents()
// var cur_date:string =  moment(TODAY_STR).format('YYYY-MM-DD'); 
this.cur_date = moment(TODAY_STR).format('YYYY-MM-DD'); 
console.log(this.cur_date);
 this.event_id_list()
      this.course_list()
	  		this.get_instructor_list({})
    this.get_student_list({})
	  this.getRep = new FormGroup({
			'event_name' :new FormControl(null,Validators.required),
      'course_name':new FormControl(null)
		  });

    this.eventData.types = 'events'
  }




getevents(){
  
 

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');
  
    let api_req:any = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"dashboard_det","admin_id":"'+admin_id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response){
      this.eventss= response

     
    
      console.log(this.eventss);
      }
   

    }, 
    (error)=>{
        console.log(error);
    });
  }



deskattendance(){
  if(this.eventData.types == 'events'){
this.event_attendee_view()
  }else{
this.course_attendee_view()
  }
// console.log(this.getRep.value.event_name)
// console.log(this.instructselectname)
// console.log(this.instructselectname)
// 
// if(this.getRep.value.event_name == null){

//       iziToast.error({

//           message: "Please Select Event Name",
//           position: 'topRight'
//       });
//       return false
// }



// if(this.instructselectname == ''){

//       iziToast.error({

//           message: "Please Select Instructor Name",
//           position: 'topRight'
//       });
//       return false
// }


//   let updateevents:any = new Object();
//  let updateeventsuser:any = new Object();
//     let access_token: any=localStorage.getItem('access_token');


// updateevents.action="desk_attendance";
// updateevents.event_name=this.getRep.value.event_name;
// updateevents.name='';
// updateevents.inst_id=this.instructorselect;
// updateevents.admin_id =localStorage.getItem('userId');

//  updateeventsuser.access_token=access_token;
//   updateeventsuser.operation="student_report";
//   updateeventsuser.moduleType="student_report";
//   updateeventsuser.api_type="web";
//   updateeventsuser.element_data = updateevents;
//       this.serverService.sendServer(updateeventsuser).subscribe((response: any) => {

//         if(response.result.status == true && response.result.data.header != null && response.result.data.values != null){
      
//       this.showing = true;
      


//        this.attendanceheadershow = response.result.data.header

 

// this.dateattendance =[];
// for (var i = 0; i < this.attendanceheadershow.length; i++) {
// var parsedDate = Date.parse(this.attendanceheadershow[i]);

// if (isNaN(this.attendanceheadershow[i]) && !isNaN(parsedDate)) {
//   console.log(this.attendanceheadershow[i])
//   this.dateattendance.push(this.attendanceheadershow[i])

// }

// }
// console.log(this.dateattendance)

//        this.attendanceheader = response.result.data.header

//  this.attendancecontent =  response.result.data.values

 
    
//           }
//         else{
//           console.log("data")
//  iziToast.error({

//           message: "No Data Found",
//           position: 'topRight'
//       });

//         }
      
//       }, 
//       (error)=>{
//           console.log(error);
//       });

}

addstudent(){
   $('#addNewPopup').modal({
  show:true,
   backdrop:false

});
$('#addNewPopup').modal('show');
}

addinstructor(){
    $('#addNewPopups').modal({
  show:true,
   backdrop:false

});
$('#addNewPopups').modal('show');

}

getsearch(searchValue: string): void {  
  console.log(searchValue);
  this.get_student_list({'search_text':searchValue})
}

getsearchinstructor(searchValue: string): void {  
  console.log(searchValue);
  this.get_instructor_list({'search_text':searchValue})
}

get_student_list(data){
  var list_data= this.listDataInfo(data);
  console.log(list_data)

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

 
    let api_req:any = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"view_student_rec","limit":"'+list_data.limit+'","offset":'+list_data.offset+',"search_text":"'+list_data.search_text+'","admin_id":"'+admin_id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){
      this.studentslist=response.result.data.list_data;

// console.log(this.sendstudentarr)
console.log(this.studentslist)




   for (var i = 0; i < this.studentslist.length; i++) {
if(this.studentslist[i].username == this.studentselectname){
this.studentslist[i].add = true 

}
else{
this.studentslist[i].add = false 

}

}



console.log(this.studentslist)




          this.offset_count = list_data.offset;
        this.paginationData = this.serverService.pagination({'offset':response.result.data.list_info.offset, 'total':response.result.data.list_info.total, 'page_limit' :this.pageLimit });
         console.log(this.paginationData);
                this.recordNotFound = this.studentslist.length == 0 ? true : false;


      }
     

    }, 
    (error)=>{
        console.log(error);
    });
  }


listDataInfo(list_data){
// console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
}  

listDataInfo2(list_data){
// console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
} 



get_instructor_list(data){
  var list_data= this.listDataInfo(data);

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

  
    let api_req:any = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"view_instruct_rec","limit":"'+list_data.limit+'","offset":'+list_data.offset+',"search_text":"'+list_data.search_text+'","admin_id":"'+admin_id+'"}}';
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      // console.log(response);
      if(response.result.status == true){
      this.instructorlist = response.result.data.list_data

    for (var i = 0; i < this.instructorlist.length; i++) {
if(this.instructorlist[i].id == this.instructorselect){
this.instructorlist[i].add = true 

}
else{
this.instructorlist[i].add = false 

}

}

 // console.log(this.instructorlist)
      this.offset_count2= list_data.offset;
     this.paginationData2 = this.serverService.pagination({'offset':response.result.data.list_info.offset, 'total':response.result.data.list_info.total, 'page_limit' :this.pageLimit });
     this.recordNotFound2 = this.instructorlist.length == 0 ? true : false;


      }
     
    }, 
    (error)=>{
        console.log(error);
    });
  }

savedata(val){
 console.log(val)
 // var adding = false
if(val.add == false){
this.studentselect= val.id
this.studentselectname = val.username

}

 

}

savedatainstructor(val){
 console.log(val)
if(val.add == false){
this.instructorselect= val.id
this.instructselectname = val.username

}

}

savealldata(){

}

savealldata2(){

}


// getvaluesss(gg){
//   console.log(gg)
// }



getvalue(valdate,detail,value){
console.log(valdate)
console.log(detail)
console.log(value)

if(value == true){
this.markedvalue = 1;
}else{
this.markedvalue = 0;
}
// this.markedstudentname = detail.fname;
this.markeddate = valdate;
this.markeddetail = detail;


this.addattendance()
 
}





addattendance(){
var courseid;
var eventid;
console.log(this.markeddetail);
if(this.markeddetail.course_id == "0"){
  courseid = ""
}
else if(this.markeddetail.course_id == "undefined" || this.markeddetail.course_id == undefined ){
  courseid = ""
} else {
  courseid = this.markeddetail.course_id 
}


if(this.markeddetail.event_id == "0"){
  eventid = ""
}
else if(this.markeddetail.event_id == "undefined" || this.markeddetail.event_id == undefined ){
  eventid = ""
}
else{
  eventid = this.markeddetail.event_id 
}



   var statussend;
   console.log(this.markedvalue)
   

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');
  
   let userData:any = '{"access_token":"'+access_token+'","operation": "lecture","moduleType":"lecture","api_type":"web","element_data":{"action": "add_desk_attendance","event_id":"'+eventid+'","course_id":"'+courseid+'","date":"'+this.markeddate+'","student_name":"'+this.markeddetail.student_id+'","status":"'+this.markedvalue+'","inst_id":"'+this.markeddetail.inst_id+'"}}';
  
    this.serverService.sendServer(userData).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){
     

  if(this.eventData.types == 'events'){
this.event_attendee_view()
  }else{
this.course_attendee_view()
  }
    
      console.log(this.eventss);
      }else{

      }
   

    }, 
    (error)=>{
        console.log(error);
    });
  }




event_id_list(){

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');
  
    let api_req:any = '{"operation":"lecture","moduleType":"lecture","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"event_id_list","admin_id":"'+admin_id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response){
  

           this.eventslist= response.result.data
    
      console.log(this.eventslist);
      }
   

    }, 
    (error)=>{
        console.log(error);
    });
  }


   
course_list(){

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');
  
    let api_req:any = '{"operation":"lecture","moduleType":"lecture","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"course_list","admin_id":"'+admin_id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response){
      this.courselist= response.result.data

     
    
      console.log(this.courselist);
      }
   

    }, 
    (error)=>{
        console.log(error);
    });
  } 





course_attendee_view(){
console.log(this.getRep.value.event_name)
console.log(this.instructselectname)
console.log(this.instructselectname)

if(this.getRep.value.course_name == null){

      iziToast.error({

          message: "Please Select Course Name",
          position: 'topRight'
      });
      return false
}



// if(this.instructselectname == ''){

//       iziToast.error({

//           message: "Please Select Instructor Name",
//           position: 'topRight'
//       });
//       return false
// }

 this.attendanceheader =[];

 this.attendancecontent =[];

this.attendanceheadershow=[];

  let updateevents:any = new Object();
 let updateeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');


updateevents.action="course_attendee_view";
updateevents.course_id=this.getRep.value.course_name;
updateevents.name='';
updateevents.inst_id='';
updateevents.admin_id =localStorage.getItem('userId');
updateevents.report_type ='';


 updateeventsuser.access_token=access_token;
  updateeventsuser.operation="student_report";
  updateeventsuser.moduleType="student_report";
  updateeventsuser.api_type="web";
  updateeventsuser.element_data = updateevents;
      this.serverService.sendServer(updateeventsuser).subscribe((response: any) => {

        if(response.result.status == true && response.result.data.header != null && response.result.data.values != null){
      
      this.showing = true;
      


       this.attendanceheadershow = response.result.data.header

  if(response.result.data.values.length != 0){
        if(response.result.data.values[0].course_id == "0"){
this.nameshowevent = "Event : "+response.result.data.values[0].event_name+"Ev"+response.result.data.values[0].event_id;
        }else{
this.nameshowevent = "Course : "+response.result.data.values[0].event_name+"C"+response.result.data.values[0].course_id;

        }
        this.nameshowinstructor = response.result.data.values[0].ins_fname+" "+response.result.data.values[0].ins_lname
      }

this.dateattendance =[];
for (var i = 0; i < this.attendanceheadershow.length; i++) {
var parsedDate = Date.parse(this.attendanceheadershow[i]);

if (isNaN(this.attendanceheadershow[i]) && !isNaN(parsedDate)) {
  console.log(this.attendanceheadershow[i])
  this.dateattendance.push(this.attendanceheadershow[i])
    /* do your work */
}

}
console.log(this.dateattendance)

       this.attendanceheader = response.result.data.header

 this.attendancecontent =  response.result.data.values

 
   
          }
        else{
          console.log("data")
 iziToast.error({

          message: "No Data Found",
          position: 'topRight'
      });

        }
      
      }, 
      (error)=>{
          console.log(error);
      });

}



event_attendee_view(){
console.log(this.getRep.value.event_name)
console.log(this.instructselectname)
console.log(this.instructselectname)

if(this.getRep.value.event_name == null){

      iziToast.error({

          message: "Please Select Event Name",
          position: 'topRight'
      });
      return false
}



// if(this.instructselectname == ''){

//       iziToast.error({

//           message: "Please Select Instructor Name",
//           position: 'topRight'
//       });
//       return false
// }

 this.attendanceheader =[];

 this.attendancecontent =[];

this.attendanceheadershow=[];

  let updateevents:any = new Object();
 let updateeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');


updateevents.action="student_attendee_view";
updateevents.event_id=this.getRep.value.event_name;
updateevents.name='';
updateevents.inst_id='';
updateevents.admin_id =localStorage.getItem('userId');
updateevents.report_type ='';


 updateeventsuser.access_token=access_token;
  updateeventsuser.operation="student_report";
  updateeventsuser.moduleType="student_report";
  updateeventsuser.api_type="web";
  updateeventsuser.element_data = updateevents;
      this.serverService.sendServer(updateeventsuser).subscribe((response: any) => {

        if(response.result.status == true && response.result.data.header != null && response.result.data.values != null){
      
      this.showing = true;
      


       this.attendanceheadershow = response.result.data.header
 if(response.result.data.values.length != 0){
        // if(response.result.data.values[0].course_id == "0"){
this.nameshowevent = "Event : "+"Ev"+response.result.data.values[0].event_id+" "+response.result.data.values[0].event_name;

//         }else{
// this.nameshowevent = "Course : "+response.result.data.values[0].event_name+"C"+response.result.data.values[0].course_id;

//         }
        this.nameshowinstructor = response.result.data.values[0].fname
      }
 

this.dateattendance =[];
for (var i = 0; i < this.attendanceheadershow.length; i++) {
var parsedDate = Date.parse(this.attendanceheadershow[i]);

if (isNaN(this.attendanceheadershow[i]) && !isNaN(parsedDate)) {
  console.log(this.attendanceheadershow[i])
  this.dateattendance.push(this.attendanceheadershow[i])

}

}
console.log(this.dateattendance)

       this.attendanceheader = response.result.data.header

 this.attendancecontent =  response.result.data.values

 
   
          }
        else{
          console.log("data")
 iziToast.error({

          message: "No Data Found",
          position: 'topRight'
      });

        }
      
      }, 
      (error)=>{
          console.log(error);
      });

}






}
