import { Component, OnInit } from '@angular/core';
import * as feather from 'feather-icons';
import { ServerService } from '../services/server.service';
import { Router,ActivatedRoute } from '@angular/router';

declare function chart1(data,data2,datascolor):any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
loginUser;
dashboardevents:any=[];
countdata:any=[];
timedata:any=[];
dataschart:any=[];
dataall:any={};
admin_type;
  constructor(private serverService: ServerService,private router:Router) { }

  ngOnInit(): void {

  	this.loginUser = localStorage.getItem('user_name');
  	this.admin_type = localStorage.getItem('admin_type');
if(this.admin_type=='ParkingSlot')
      this.router.navigate(['/Carslot-dashboard']); 
      
 	feather.replace();
     if(localStorage.getItem('enable') == '1'){
     localStorage.setItem('enable','0'); 
      location.reload()
    }
  	this.get_dashboard_details()
  	this.get_chart_details()
   

  }
 

get_dashboard_details(){
  
 

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');
  
    let api_req:any = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"dashboard_det","admin_id":"'+admin_id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response){
      this.dashboardevents= response

      for (var i = 0; i < this.dashboardevents.length; i++) {
        // this.dashboardevents[i].events = this.dashboardevents[i]
      if(this.dashboardevents[i].colour == ""){
        this.dashboardevents[i].colour = 'hotpink'
      }
    }

      console.log(this.dashboardevents);

      }
   

    }, 
    (error)=>{
        console.log(error);
    });
  }



get_chart_details(){
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');
  
    let api_req:any = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"chart","admin_id":"'+admin_id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){

//       	var counts=[];
this.dataschart = response.result.data.date 
// for (var i = 0; i < this.dataschart.length; i++) {
this.dataall = response.result.data.value 
// console.log(this.dataall.Football)
  // for (var i = 0; i < this.dataschart.length; i++) {

// console.log(this.dataall)
// var vals = this.dataall+'.'+this.dataschart[i] 
// console.log(vals)
  // this.countdata.push({
      // name:this.dataschart[i],
//       data: this.dataall+'.'+this.dataschart[i] 
  // })
//   this.timedata.push(response.result.data[i].start_time)


// }
for (var i = 0; i < this.dataall.length; i++) {

  // if(this.dataall[i]+'.'+this.dataschart[j] == this.dataschart[j]){
    this.countdata.push({
     
      data: this.dataall[i].count[0],
       name:this.dataall[i].event_name, 
  })
  // }

// }
  
}


// console.log(this.timedata)

console.log(this.countdata)
      	chart1(this.countdata,this.dataschart,response.result.data.colour)

// 
      }
   

    }, 
    (error)=>{
        console.log(error);
    });
  }



}
