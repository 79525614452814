<div class="section-body">
    <input type="hidden" id="reconnect_socket"  (click)="initSocket()">
    <input type="hidden" id="reloadlogs"  (click)="getLogs()">

    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div class="card-header-title">
                        <h4>Current Active Parking Lot</h4>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table  table-bordered table-striped table-md " id="table-2">

                            <thead>
                                <tr class="l-bg-light-blue">
                                   
                                    <th>S.no</th>
                                    <th>Visitor Name</th>
                                    <th>Mobile Number</th>
                                    <th>Vehicle No</th>
                                    <th>Lot Name</th>
                                    <!-- <th>Lot Number</th> -->
                                    <th>Check In Time</th>                                    
                                    <th>Status</th>
                                    <th>Action</th>


                                </tr>
                            </thead>

                            <tbody>


                                <tr *ngFor="let l of carlots_log;let i=index">
                                    
                                    <td>{{i+1}}</td>
                                    <td>{{l.visitor_name}}</td>
                                    <td>{{l.visitor_num}}</td>
                                    <td>{{l.visitor_carnum}}</td>
                                    <td>{{l.lot_name}}</td>
                                    <!-- <td>{{l.lot_no}}</td> -->
                                    <td>{{l.checkin_time | date:'MM/dd/yy h:mm a'}}</td>                                 
                                    <td style="border-radius: 23px;" class="btn btn-success mb-2 ml-4 mt-2">Entry</td>
                                    <td >
                                        <a (click)="makeExit(l.lot_name,l.lot_no)" href="javascript:void(0)" class="btn btn-danger btn-action mr-1" data-toggle="tooltip" title="Make Exit">
                                        <i class="fas fa-sign-out-alt"></i>
                                    </a> </td>
                                </tr>

                            </tbody>
                            <!-- <tr *ngIf="recordNotFound == true">
                                <td colspan="12">Data not found</td>
                            </tr> -->

                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
