  <div class="section-body">
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    <div class="card-header-title">
                      <h4>CC List </h4>
                    </div>
                    <div class="card-header-btn-group"  >
                      <a style="margin: 0px 10px 0px 10px !important;" [routerLink]="['/addadmincc']" class="btn btn-info"><i class="fa fa-plus-circle" aria-hidden="true"></i> Add New</a>
                        <a style="margin: 0px 10px 0px 10px !important;"  class="btn btn-success" target="_blank"   href="{{this.helpurl}}">Help Content</a>
                    </div>
                   
                  </div>
                  <div class="card-body" >
                    <div class="table-responsive">
                      <table  class="table table-striped" id="table-2">




                        <thead>
                      <!--     <tr>
                <td colspan="20" class="search-text-tbl">
                            <div class="input-group"><input type="text" class="form-control" placeholder="Search Student" (input)="getsearch($event.target.value)"  (keyup.enter)="get_student_list({'search_text':agent_search.value})" #agent_search>
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" (click)="get_student_list({'search_text':agent_search.value})"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </td>
                            </tr -->
                          <tr>
                           
                            <th>S.no</th>
                            <th>Admin Name</th>
                            <th>User Name</th>
                            <th>Email</th>
                            <th>Contact no.</th>
                           

                            <th>Action</th>
                             <th>Welcome Email</th>
                          </tr>
                        </thead>
                      
                        <tbody >


                          <tr *ngFor="let l of adminlist;let i=index">
                          
                            <td>{{i+1}}</td>
                            <td>{{l.fname}} {{l.lname}}</td>
                            <td>{{l.username}}</td>
                           

                            <td>{{l.email}}</td>
                            <td>{{l.phone_no}}</td>
                            <!-- <td>{{l.nirp}}</td> -->

               
                            <td>
                          <a href="javascript:void(0)" (click)="goedit(l)" class="btn btn-action bg-purple mr-1" data-toggle="tooltip" title="Edit" >
                                <i class="fas fa-pencil-alt"></i>
                              </a> 
                            <!--   <a href="javascript:void(0)" (click)="deletestudentrecord(l)"  class="btn btn-danger btn-action" data-toggle="tooltip" title="Delete">
                                <i class="fas fa-trash"></i>
                              </a> -->
                            </td>

<td>
  <button type="button" class="btn btn-success"  (click)="welcome_email(l)">Send Welcome Email</button>
</td>

                          </tr>


                        </tbody>
                        <!--  <tr *ngIf="recordNotFound == true">
                               <td colspan="12">Data not found</td>
                            </tr> -->
                      
                      </table>
                    </div>




   <!-- <div class="card-footer text-right">
                <nav class="d-inline-block">
                    <ul class="pagination mb-0" *ngIf="paginationData.info == 'show'">

                        <li class="page-item">
                            <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? get_student_list({'search_text':agent_search.value,'offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
                        </li>
                        <li class="page-item" *ngIf="paginationData.backtab =='show'">
                            <a class="page-link" href="javascript:void(0)" (click)="get_student_list({'search_text':agent_search.value,'offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
                        </li>
                        <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
                            <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? get_student_list({'search_text':agent_search.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                        </li>

                        <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                            <a class="page-link" href="javascript:void(0)" (click)="get_student_list({'search_text':agent_search.value,'offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
                        </li>

                        <li class="page-item">
                            <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? get_student_list({'search_text':agent_search.value,'offset':paginationData.end}) : false"><i class="fas fa-angle-right"></i></a>
                        </li>
                    </ul>
                </nav>
            </div> -->





                  </div>
                </div>
              </div>
            </div>
          </div>