import { Component, OnInit, Input } from '@angular/core';
declare var $:any;
import * as moment from 'moment';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/angular';
import { INITIAL_EVENTS, createEventId } from './event-utils';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { ServerService } from '../services/server.service';
declare var iziToast:any;
import '../../assets/js/scripts.js';
const TODAY_STR = new Date(); 

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {
id;
 instructorlist:any=[];
IsmodelShow = false;
  events:any=[];
  eventss:any=[];
  meetings:any=[];
handleDateClick;
  calendarVisible = true;
 currentEvents: EventApi[] = [];
  calendarOptions: CalendarOptions;

addEvent : FormGroup;
addCourse: FormGroup;

instructorss:any={}

studentslist:any=[];

recordNotFound = false;
pageLimit = 10;
paginationData:any ={"info":"hide"};
offset_count = 0;


recordNotFound2 = false;
pageLimit2 = 10;
paginationData2:any ={"info":"hide"};
offset_count2 = 0;


recordNotFound3 = false;
pageLimit3 = 10;
paginationData3:any ={"info":"hide"};
offset_count3 = 0;



sendstudent;
sendstudentarr=[];

course_send_id;

get_attendees:any=[];
instid;




instructorselect;
instructorselectco;

instructselectname;
instructselectnameco;

instructselectshow;
instructselectshowco;



instructselectshowname;
instructselectshownameco;

instructorfullnameco;
instructorshowfullnameco;

 instructorfullname;
 instructorshowfullname;

typeinstructor;
 editshow = false;
titleshow = "Event"
event_id;
courses:any=[];
typecalled;


courseditdetials:any=[];
eventeditdetials:any=[];


locations:any=[];
senddeletenames;
deletearr:any=[];
meeting_id;

participantlist:any=[];
sendparticipant;
participantarr=[];
participantnames:any=[];

instructorselectview;
instructorselectviewco;
constructor(private serverService: ServerService,private router:Router) {

  this.addevents()
  this.viewlocation()
  // this.get_instructor_list({})
    // this.get_student_list({})

   }

 ngOnInit(): void {
this.addEvent = new FormGroup({
      'event_name' : new FormControl(null,Validators.required),
      'instructors' : new FormControl(null,Validators.required),
      'start_time' : new FormControl(null,Validators.required),
      'end_time' : new FormControl(null,Validators.required),
      'count' : new FormControl(null,Validators.required),
      'location' : new FormControl(null,Validators.required),
      'editshowing' : new FormControl(null),
      'reference' : new FormControl(null)
     });

this.addCourse = new FormGroup({
      'event_name' : new FormControl(null,Validators.required),
      'instructors' : new FormControl(null,Validators.required),
      'start_time' : new FormControl(null,Validators.required),
      'end_time' : new FormControl(null,Validators.required),
      'start_date':new FormControl(null,Validators.required),
      // 'count' : new FormControl(null,Validators.required),
      'location' : new FormControl(null,Validators.required),
      'editshowing' : new FormControl(null)
     });
}


goevent(name){
this.router.navigate(['addnewevent'], { state: { type: name} });

}

// editeventdetails(l,type,val){

// this.typecalled = type;
// this.eventeditdetials =[];
//   let editevents:any = new Object();
//  let editeventsuser:any = new Object();
//     let access_token: any=localStorage.getItem('access_token');

// editevents.action="edit_event_details";
// editevents.id=l;
// editevents.admin_id =localStorage.getItem('userId');
// editevents.event_name=val;

//  editeventsuser.access_token=access_token;
//   editeventsuser.operation="user";
//   editeventsuser.moduleType="user";
//   editeventsuser.api_type="web";
//   editeventsuser.element_data = editevents;
//       this.serverService.sendServer(editeventsuser).subscribe((response: any) => {

//         if(response.result.status==true && response.result.data.length != 0){
// this.eventeditdetials = response.result.data[0]
// this.id =response.result.data[0].event_id
         
// this.instructorselect = response.result.data[0].inst_id
//  this.instructorselectview = response.result.data[0].inst_id;
//          this.instructorselectviewco = response.result.data[0].co_inst_id;

//          this.instructorselectco = response.result.data[0].co_inst_id
//          this.instructselectnameco =response.result.data[0].co_inst;

//          this.instructselectname =response.result.data[0].instructors;

// this.instructorfullname = response.result.data[0].instruct_name;
//    this.instructorfullnameco= response.result.data[0].co_instruct_name;
// this.event_id =response.result.data[0].event_id;
// // console.log(moment(TODAY_STR).format('YYYY-MM-DDTHH:mm'))
// // console.log(moment(response.result.data[0].end_time).format('YYYY-MM-DDTHH:mm'))
// if(moment(TODAY_STR).format('YYYY-MM-DDTHH:mm') >= moment(response.result.data[0].start_time).format('YYYY-MM-DDTHH:mm')){
//   this.editshow = true;
// }else{
//   this.editshow = false;
// }

// // if(response.result.data[0].course_id != "0"){
// // this.titleshow = "Course"
// // }else{
// this.titleshow = "Event"
// // }


//           this.addEvent.patchValue({
// event_name: response.result.data[0].event_name,
//  instructors :response.result.data[0].instructors,
//  start_time:moment(response.result.data[0].start_time).format('YYYY-MM-DDTHH:mm'),
//  end_time:moment(response.result.data[0].end_time).format('YYYY-MM-DDTHH:mm'),
//  count:response.result.data[0].count,
//  location:response.result.data[0].location,
// editshowing :this.editshow

//   });
//        console.log(this.events);
//   $('#addNewPopup').modal({
//    show:true,
//    backdrop:false
// });
// $('#addNewPopup').modal('show');
 
 
//           }
//         else{

//         }
      
//       }, 
//       (error)=>{
//           console.log(error);
//       });
// }

editeventdetails(l,type,val){
console.log(l,type)
this.typecalled = type;
this.eventeditdetials =[];
  let editevents:any = new Object();
 let editeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');

editevents.event_name=val.event_name;
editevents.admin_id =localStorage.getItem('userId');

editevents.action="edit_event_details";
editevents.id=l;

 editeventsuser.access_token=access_token;
  editeventsuser.operation="user";
  editeventsuser.moduleType="user";
  editeventsuser.api_type="web";
  editeventsuser.element_data = editevents;
      this.serverService.sendServer(editeventsuser).subscribe((response: any) => {

        if(response.result.status==true && response.result.data.length != 0){
this.eventeditdetials = response.result.data[0]
console.log(this.eventeditdetials)
this.id =response.result.data[0].event_id
         this.instructorselect = response.result.data[0].inst_id

         this.instructorselectview = response.result.data[0].inst_id;
         this.instructorselectviewco = response.result.data[0].co_inst_id;

         this.instructorselectco = response.result.data[0].co_inst_id
       

         this.instructselectname =response.result.data[0].instructors;
           this.instructselectnameco =response.result.data[0].co_inst;

         this.instructorfullname = response.result.data[0].instruct_name;
         this.instructorfullnameco= response.result.data[0].co_instruct_name;

      

         this.instructselectname =response.result.data[0].instructors;
         // this.instructselectname =response.result.data[0].instructors;

this.event_id =response.result.data[0].event_id;
console.log(moment(TODAY_STR).format('YYYY-MM-DDTHH:mm'))
console.log(moment(response.result.data[0].end_time).format('YYYY-MM-DDTHH:mm'))
if(moment(TODAY_STR).format('YYYY-MM-DDTHH:mm') >= moment(response.result.data[0].start_time).format('YYYY-MM-DDTHH:mm')){

  this.editshow = true;
}else{
  this.editshow = false;
}

// if(response.result.data[0].course_id != "0"){
// this.titleshow = "Course"
// }else{
this.titleshow = "Event"
// }


          this.addEvent.patchValue({
event_name: response.result.data[0].event_name,
 instructors :response.result.data[0].instructors,
 start_time:moment(response.result.data[0].start_time).format('YYYY-MM-DDTHH:mm'),
 end_time:moment(response.result.data[0].end_time).format('YYYY-MM-DDTHH:mm'),
 count:response.result.data[0].count,
 location:response.result.data[0].location,
editshowing :this.editshow

  });
// if(this.details == "duplicate"){
  this.viewstud('')
// }



       console.log(this.events);
  $('#addNewPopup').modal({
   show:true,
   backdrop:false
});
$('#addNewPopup').modal('show');
 
 
          }
        else{

        }
      
      }, 
      (error)=>{
          console.log(error);
      });
}










edit_course_details(l,type){
this.typecalled = type;

this.courseditdetials =[];
  let editevents:any = new Object();
 let editeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');


editevents.action="edit_course_details";
editevents.id=l;

 editeventsuser.access_token=access_token;
  editeventsuser.operation="user";
  editeventsuser.moduleType="user";
  editeventsuser.api_type="web";
  editeventsuser.element_data = editevents;
      this.serverService.sendServer(editeventsuser).subscribe((response: any) => {

        if(response.result.status==true && response.result.data.length != 0){
this.courseditdetials = response.result.data[0]
this.id =response.result.data[0].id


  this.instructorselect = response.result.data[0].inst_id
  this.instructorselectview = response.result.data[0].inst_id;
         this.instructorselectviewco = response.result.data[0].co_inst_id;
         this.instructorselectco = response.result.data[0].co_inst_id
         this.instructselectnameco =response.result.data[0].co_inst;

         this.instructselectname =response.result.data[0].instructors;

this.instructorfullname = response.result.data[0].instruct_name;
   this.instructorfullnameco= response.result.data[0].co_instruct_name;

this.event_id =response.result.data[0].id;
this.course_send_id =response.result.data[0].course_id;
console.log(moment(TODAY_STR).format('YYYY-MM-DDTHH:mm'))
console.log(moment(response.result.data[0].end_time).format('YYYY-MM-DDTHH:mm'))
if(moment(TODAY_STR).format('YYYY-MM-DDTHH:mm') >= moment(response.result.data[0].start_time).format('YYYY-MM-DDTHH:mm')){

  this.editshow = true;
}else{
  this.editshow = false;
}

          this.addCourse.patchValue({
event_name: response.result.data[0].event_name,
 instructors :response.result.data[0].instructors,
 start_time:moment(response.result.data[0].start_time).format('HH:mm:ss'),
 end_time:moment(response.result.data[0].end_time).format('HH:mm:ss'),
 start_date:moment(response.result.data[0].start_time).format('YYYY-MM-DD'),
 // count:response.result.data[0].count,
 location:response.result.data[0].location,
editshowing :this.editshow

  });
this.viewstud('')
          
       console.log(this.events);
  $('#addNewPopupcourse').modal({
   show:true,
   backdrop:false
});
$('#addNewPopupcourse').modal('show');
 
 
          }
        else{

        }
      
      }, 
      (error)=>{
          console.log(error);
      });
}


editmeetingdetails(l,type){

this.typecalled = type;
this.eventeditdetials =[];
  let editevents:any = new Object();
 let editeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');


editevents.action="edit_meeting";
editevents.id=l;
editevents.admin_id=admin_id;

 editeventsuser.access_token=access_token;
  editeventsuser.operation="meeting";
  editeventsuser.moduleType="meeting";
  editeventsuser.api_type="web";
  editeventsuser.element_data = editevents;
      this.serverService.sendServer(editeventsuser).subscribe((response: any) => {

        if(response.result.status==true && response.result.data.length != 0){
this.eventeditdetials = response.result.data[0]
console.log(this.eventeditdetials)
this.id =response.result.data[0].id
         this.instructorselect = response.result.data[0].inst_id
         this.instructorselectco = response.result.data[0].co_inst_id

           this.instructorselectview = response.result.data[0].inst_id;
         this.instructorselectviewco = response.result.data[0].co_inst_id;
         // this.instructselectnameco =response.result.data[0].co_inst;

         // this.instructselectname =response.result.data[0].instructors;
         this.instructorfullname = response.result.data[0].instruct_name;
         this.instructorfullnameco= response.result.data[0].co_instruct_name;

      

         // this.instructselectname =response.result.data[0].instructors;
         // this.instructselectname =response.result.data[0].instructors;

this.meeting_id =response.result.data[0].meeting_id;
console.log(moment(TODAY_STR).format('YYYY-MM-DDTHH:mm'))
console.log(moment(response.result.data[0].meeting_end).format('YYYY-MM-DDTHH:mm'))
if(moment(TODAY_STR).format('YYYY-MM-DDTHH:mm') >= moment(response.result.data[0].meeting_dt).format('YYYY-MM-DDTHH:mm')){

  this.editshow = true;
}else{
  this.editshow = false;
}

// if(response.result.data[0].course_id != "0"){
// this.titleshow = "Course"
// }else{
this.titleshow = "Meeting"
// }


          this.addEvent.patchValue({
event_name: response.result.data[0].meeting_name,
 instructors :response.result.data[0].instructors,
 start_time:moment(response.result.data[0].meeting_dt).format('YYYY-MM-DDTHH:mm'),
 end_time:moment(response.result.data[0].meeting_end).format('YYYY-MM-DDTHH:mm'),
 // count:response.result.data[0].count,
 reference:response.result.data[0].reference,

 location:response.result.data[0].location,
editshowing :this.editshow

  });
// if(this.details == "duplicate"){
  this.viewstud('')
// }



       // console.log(this.events);
  $('#addNewPopup').modal({
   show:true,
   backdrop:false
});
$('#addNewPopup').modal('show');
 
 
          }
        else{

        }
      
      }, 
      (error)=>{
          console.log(error);
      });
}


update(){
  if(this.typecalled == 'Event'){
this.updateEventData()
}
  else if(this.typecalled == 'Meeting'){
this.updateDatasmeeting()
  }
}


updateEventData(){

  let updateevents:any =this.addEvent.value;
 let updateeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
   let superadmin: any=localStorage.getItem('superadmin');

updateevents.action="update_event_details";
updateevents.super_admin=superadmin;

updateevents.event_id=this.id;
updateevents.admin_id =localStorage.getItem('userId');
updateevents.instructors =this.instructorselect;
updateevents.co_inst =this.instructorselectco;

 updateeventsuser.access_token=access_token;
  updateeventsuser.operation="user";
  updateeventsuser.moduleType="user";
  updateeventsuser.api_type="web";
  updateeventsuser.element_data = updateevents;
      this.serverService.sendServer(updateeventsuser).subscribe((response: any) => {

        if(response.result.status==true){
       // this.addevents()
      iziToast.success({
        position: 'topRight',
          message: "Event Updated successfully",
          
      });
 window.location.reload();
 
          }
        else{
 iziToast.error({
    position: 'topRight',
    message: 'Some Error Occured',
});

        }
      
      }, 
      (error)=>{
          console.log(error);
      });

}

updateCourseData(){
  
}


updateDatasmeeting(){
// this.IsmodelShow=true
  let updateevents:any =this.addEvent.value;
 let updateeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let superadmin: any=localStorage.getItem('superadmin');

// console.log(this.addEvent.value.instructors)

updateevents.action="update_meeting";
updateevents.meeting_name=this.addEvent.value.event_name;

updateevents.incharge_id=this.instructorselect;
updateevents.co_incharge_id=this.instructorselectco;


updateevents.meeting_dt=this.addEvent.value.start_time;
updateevents.meeting_end=this.addEvent.value.end_time;
updateevents.location=this.addEvent.value.location;
updateevents.reference=this.addEvent.value.reference;
updateevents.template=0;
updateevents.template_name='';
updateevents.meeting_id=this.meeting_id;
updateevents.notes='';


updateevents.super_admin=superadmin;
// updateevents.id=this.id;
updateevents.admin_id =localStorage.getItem('userId');
// updateevents.instructors =this.instructselectname;
// updateevents.co_inst =this.instructselectnameco;

 updateeventsuser.access_token=access_token;
  updateeventsuser.operation="meeting";
  updateeventsuser.moduleType="meeting";
  updateeventsuser.api_type="web";
  updateeventsuser.element_data = updateevents;
      this.serverService.sendServer(updateeventsuser).subscribe((response: any) => {

        if(response.result.status==true){
       // this.addevents()
      iziToast.success({

          message: "Updated successfully",
          position: 'topRight'
      });
      // $('#addNewPopup').modal('hide');
 window.location.reload();

          }
        else{
 iziToast.error({
    position: 'topRight',
    message: 'Error Occured.Please try again later',
});

        }
      
      }, 
      (error)=>{
          console.log(error);
      });

}





close() {
    this.IsmodelShow=true;// set false while you need open your model popup
   // do your more code
}






addevents(){
this.events =[];
  let editevents:any = new Object();
 let editeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');

    let admin_id: any=localStorage.getItem('userId');

editevents.action="calender_view";
editevents.admin_id=admin_id;

 editeventsuser.access_token=access_token;
  editeventsuser.operation="user";
  editeventsuser.moduleType="user";
  editeventsuser.api_type="web";
  editeventsuser.element_data = editevents;
      this.serverService.sendServer(editeventsuser).subscribe((response: any) => {

        console.log(response)
       
 

        if(response.result.status == true){
          this.eventss = response.result.data
          for (var i = 0; i < this.eventss.length; i++) {
           this.events.push({
              id: createEventId(),
                title:"Ev"+this.eventss[i].id+" "+this.eventss[i].event_name,
                start: this.eventss[i].start_time.replace(/T.*$/, ''),
                end: this.eventss[i].end_time.replace(/T.*$/, ''),
                color  : this.eventss[i].colour,
                 extendedProps: {
        eventrecordid: this.eventss[i].id,
        eventrecordname: this.eventss[i].event_name,        
                type:"Event"
      }

           })
          }
this.addcourses(this.events)
       console.log(this.events);

 
          }
        else{

        }
      
      }, 
      (error)=>{
          console.log(error);
      });
}

addcourses(events){
console.log(this.events)
  let editevents:any = new Object();
 let editeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

editevents.action="calender_view_course";
editevents.admin_id=admin_id;

 editeventsuser.access_token=access_token;
  editeventsuser.operation="user";
  editeventsuser.moduleType="user";
  editeventsuser.api_type="web";
  editeventsuser.element_data = editevents;
      this.serverService.sendServer(editeventsuser).subscribe((response: any) => {

        console.log(response)
       
 

        if(response.result.status == true){
          this.courses = response.result.data
          for (var i = 0; i < this.courses.length; i++) {

           this.events.push({
              id: createEventId(),
                title:"C"+this.courses[i].course_id+" "+this.courses[i].event_name,
                start: this.courses[i].start_time.replace(/T.*$/, ''),
                end: this.courses[i].end_time.replace(/T.*$/, ''),
                color  : this.courses[i].colour,
                 extendedProps: {
        eventrecordid: this.courses[i].id,
        eventrecordname: this.courses[i].event_name,
        type:"Course"
      }

           })
          }
this.addmeetings(this.events)
          }
        else{

        }
      
      }, 
      (error)=>{
          console.log(error);
      });
}



addmeetings(events){
console.log(this.events)
  let editevents:any = new Object();
 let editeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');

    let admin_id: any=localStorage.getItem('userId');

editevents.action="calender_view_meeting",
editevents.admin_id=admin_id;

 editeventsuser.access_token=access_token;
  editeventsuser.operation="user";
  editeventsuser.moduleType="user";
  editeventsuser.api_type="web";
  editeventsuser.element_data = editevents;

      this.serverService.sendServer(editeventsuser).subscribe((response: any) => {

        console.log(response)
        if(response.result.status == true){
          this.meetings = response.result.data
          for (var i = 0; i < this.meetings.length; i++) {
           this.events.push({
              id: createEventId(),
                title:"M"+this.meetings[i].meeting_id+" "+this.meetings[i].meeting_name,
                start: this.meetings[i].meeting_dt.replace(/T.*$/, ''),
                end: this.meetings[i].meeting_end.replace(/T.*$/, ''),
                color  : this.meetings[i].colour,
                 extendedProps: {
        eventrecordid: this.meetings[i].meeting_id,
        eventrecordname: this.meetings[i].meeting_name,
        type:"Meeting"
      }

           })
          }

   this.calendarOptions = {
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
    },
    initialView: 'dayGridMonth',
    initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    allDaySlot:true,
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
    events:this.events
 
    
  };
 
          }
        else{

        }
      
      }, 
      (error)=>{
          console.log(error);
      });
}

 handleCalendarToggle() {
    this.calendarVisible = !this.calendarVisible;
  }

 handleEventClick(clickInfo: EventClickArg) {
// console.log(clickInfo)
// console.log(clickInfo.event.extendedProps);
this.showedit(clickInfo.event.extendedProps.eventrecordid,clickInfo.event.extendedProps.type,clickInfo.event.extendedProps.eventrecordname)

  }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
  }

handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }


handleDateSelect(selectInfo: DateSelectArg) {

  }




showedit(id,type,val){
  console.log(type)
  console.log(id)

  if(type == "Event"){

this.editeventdetails(id,type,val)
  }
  else if(type == "Meeting"){
this.editmeetingdetails(id,type)
  }
  else if(type == "Course"){
this.edit_course_details(id,type)
  }

}



  

  deleteevents(){

  let deleteuser:any = new Object();
 let deleteuserdata:any = new Object();
    let access_token: any=localStorage.getItem('access_token');


deleteuserdata.action="delete_event_details";
deleteuserdata.id=this.id;

 deleteuser.access_token=access_token;
  deleteuser.operation="user";
  deleteuser.moduleType="user";
  deleteuser.api_type="web";
  deleteuser.element_data = deleteuserdata;
      this.serverService.sendServer(deleteuser).subscribe((response: any) => {

        console.log(response)
       
 

        if(response.result.status==true){
       iziToast.success({
    title: 'OK',
    position: 'topRight',
    message: 'Event deleted Successfully!',
});
       // $('#addNewPopup').modal('hide');
 window.location.reload();
        }
        else{
  iziToast.error({
    position: 'topRight',
    message: 'Some Error Occured',
});
        }
      
      }, 
      (error)=>{
          console.log(error);
      });
}





addstudent(){

this.get_student_list({})

   $('#addNewPopups').modal({
  show:true,
   backdrop:false

});
$('#addNewPopups').modal('show');
}



addstudents(){
  this.get_participant_list({})
   $('#addNewPopupparticipant').modal({
  show:true,
   backdrop:false

});
$('#addNewPopupparticipant').modal('show');
}

getsearch(searchValue: string): void {  
  console.log(searchValue);
  this.get_student_list({'search_text':searchValue})
}


getsearchpaticipant(searchValue: string): void {  
  console.log(searchValue);
  this.get_participant_list({'search_text':searchValue})
}



savedataparticipant(val){

 var adding = false
if(val.add == false){

if(this.participantarr.length != 0){

 for (var i = 0; i < this.participantarr.length; i++) {


if(val.id !== this.participantarr[i]){

 adding  = true
}

}
  if(adding){
      this.participantarr.push(val.id)
      this.participantnames.push(val.name)

  }
}
else{
   this.participantarr.push(val.id)

    this.participantnames.push(val.name)
}

}
else{

   for (var j = 0; j < this.participantarr.length; j++) {

if(val.id == this.participantarr[j]){

    this.participantarr.splice(this.participantarr.indexOf(this.participantarr[j]), 1 );

}
}
   for (var k = 0; k < this.participantnames.length; k++) {

if(val.name == this.participantnames[k]){

    this.participantnames.splice(this.participantnames.indexOf(this.participantnames[k]), 1 );

}
}

}

console.log(this.participantnames)

console.log(this.participantarr)
}


get_participant_list(data){
    // if(this.details == 'edit'){
  // this.viewstud('') 
  // }
  var list_data= this.listDataInfo2(data);
  // console.log(list_data)

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

 
      let api_req:any = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"view_participant","limit":"'+list_data.limit+'","offset":'+list_data.offset+',"search_text":"'+list_data.search_text+'","admin_id":"'+admin_id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){
      this.participantlist=response.result.data.list_data;
if(this.participantlist.length != 0){

if(this.participantarr.length != 0){
for (var i = 0; i < this.participantlist.length; i++) {
   var ismatch = false;
  for (var j = 0; j < this.participantarr.length; j++) {
   if(this.participantlist[i].id ==  this.participantarr[j]){
     ismatch = true;
     this.participantlist[i].add = true;
     break;  

   }
 }
 if (!ismatch){
         this.participantlist[i].add = false//  checkbox status false
      
      } 

}
}
else{
  
  for (var i = 0; i < this.participantlist.length; i++) {
     this.participantlist[i].add = false  
  }
}

}

console.log(this.participantlist)


 this.offset_count2 = list_data.offset;
 this.paginationData2 = this.serverService.pagination({'offset':response.result.data.list_info.offset, 'total':response.result.data.list_info.total, 'page_limit' :this.pageLimit3 });
 this.recordNotFound2 = this.participantlist.length == 0 ? true : false;


      }
     

    }, 
    (error)=>{
        console.log(error);
    });
  }



get_student_list(data){

// this.viewstud('')

  var list_data= this.listDataInfo(data);
  console.log(list_data)

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

 
    let api_req:any = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"view_student_rec","limit":"'+list_data.limit+'","offset":'+list_data.offset+',"search_text":"'+list_data.search_text+'","admin_id":"'+admin_id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){
      this.studentslist=response.result.data.list_data;

console.log(this.sendstudentarr)
console.log(this.studentslist)


if(this.studentslist.length != 0){

if(this.sendstudentarr.length != 0){
for (var i = 0; i < this.studentslist.length; i++) {
   var ismatch = false;
  for (var j = 0; j < this.sendstudentarr.length; j++) {
   if(this.studentslist[i].username ==  this.sendstudentarr[j]){
     ismatch = true;
     this.studentslist[i].add = true;
     break;  

   }
 }
 if (!ismatch){
         this.studentslist[i].add = false//  checkbox status false
      
      } 

}
}
else{
  
  for (var i = 0; i < this.studentslist.length; i++) {
     this.studentslist[i].add = false  
  }
}

}

console.log(this.studentslist)

 this.offset_count = list_data.offset;
 this.paginationData = this.serverService.pagination({'offset':response.result.data.list_info.offset, 'total':response.result.data.list_info.total, 'page_limit' :this.pageLimit });
      // console.log(this.paginationData);
 this.recordNotFound = this.studentslist.length == 0 ? true : false;
      }
     

    }, 
    (error)=>{
        console.log(error);
    });
  }








deleteassignedstudents(){
 
  let deleteuser:any = new Object();
 let deleteuserdata:any = new Object();
    let access_token: any=localStorage.getItem('access_token');


deleteuserdata.action="remove_assign_students";
deleteuserdata.id='';

 deleteuser.access_token=access_token;
  deleteuser.operation="lecture";
  deleteuser.moduleType="lecture";
  deleteuser.api_type="web";
  deleteuser.element_data = deleteuserdata;
      this.serverService.sendServer(deleteuser).subscribe((response: any) => {

        console.log(response)
       
 

        if(response.result.status==true){
       iziToast.success({
    title: 'OK',
    position: 'topRight',
    message: 'Event deleted Successfully!',
});
 
        }
        else{
  iziToast.error({
    position: 'topRight',
    message: 'Some Error Occured',
});
        }
      
      }, 
      (error)=>{
          console.log(error);
      });



}

savealldata(){
  console.log(this.typecalled)
  

if(this.typecalled == "Event"){
   
   this.sendparticipant = ''
   this.sendparticipant=this.participantarr.join(',');

this.assignstudentstoevents()
}else if(this.typecalled == "Course"){

   this.sendstudent = ''
   this.sendstudent=this.sendstudentarr.join(',');
this.assignstudentstocourse()
}
else if(this.typecalled =="Meeting"){

 this.sendparticipant = ''
   this.sendparticipant=this.participantarr.join(',');
   this.assignstudentstomeeting()
}

}




listDataInfo(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
} 

listDataInfo2(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
} 



viewstud(val){
 let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

if(this.typecalled == "Event"){
var starts =moment(this.eventeditdetials.start_time).format('YYYY-MM-DD HH:mm:ss');
 var ends =moment(this.eventeditdetials.end_time).format('YYYY-MM-DD HH:mm:ss');
registerstudentdata.action="view_assign_students";
registerstudentdata.admin_id=admin_id;
registerstudentdata.event_name=this.eventeditdetials.event_name;
registerstudentdata.start_time=starts;
registerstudentdata.end_time=ends;
registerstudentdata.inst_id=this.instructorselectview;
registerstudentdata.event_id =this.event_id;
registerstudentdata.course_id =0;
registerstudentdata.meeting_id =0;

}

else if(this.typecalled == "Course"){

  var starts =moment(this.courseditdetials.start_time).format('YYYY-MM-DD HH:mm:ss');
 var ends =moment(this.courseditdetials.end_time).format('YYYY-MM-DD HH:mm:ss');
registerstudentdata.action="view_assign_students";
registerstudentdata.admin_id=admin_id;
registerstudentdata.event_name=this.addCourse.value.event_name;
registerstudentdata.start_time=starts;
registerstudentdata.end_time=ends;
registerstudentdata.inst_id=this.instructorselectview;

registerstudentdata.event_id =0;
registerstudentdata.course_id =this.course_send_id;
registerstudentdata.meeting_id =0;



}
else{
// var starts =moment(this.courseditdetials.start_time).format('YYYY-MM-DD HH:mm:ss');
 // var ends =moment(this.courseditdetials.end_time).format('YYYY-MM-DD HH:mm:ss');

 var starts =moment(this.eventeditdetials.start_time).format('YYYY-MM-DD HH:mm:ss');
 var ends =moment(this.eventeditdetials.end_time).format('YYYY-MM-DD HH:mm:ss');

registerstudentdata.action="view_assign_students";
registerstudentdata.admin_id=admin_id;
registerstudentdata.event_name=this.eventeditdetials.event_name;
registerstudentdata.start_time=starts;
registerstudentdata.end_time=ends;
registerstudentdata.inst_id=this.instructorselectview;

registerstudentdata.event_id =0;
registerstudentdata.course_id =0;
registerstudentdata.meeting_id =this.meeting_id;

}

 registeruser.access_token=access_token;
  registeruser.operation="lecture";
  registeruser.moduleType="lecture";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){
     this.get_attendees = response.result.data


if(this.typecalled == "Course"){
  this.sendstudentarr=[];
for (var i = 0; i < this.get_attendees.length; i++) {
    this.get_attendees[i].add = false;
  this.sendstudentarr.push(this.get_attendees[i].student_name)    
}

}


if(this.typecalled != "Course"){
this.participantarr=[];
for (var i = 0; i < this.get_attendees.length; i++) {
   this.get_attendees[i].add = false;
  this.participantarr.push(this.get_attendees[i].student_name)
} 
}

console.log(this.participantarr)

if(val == 'call'){

if(this.typecalled == 'Course'){
   this.deletearr =[];
   $('#addNewPopupee').modal({
   show:true,
   backdrop:false
});
$('#addNewPopupee').modal('show');
}
else{
 this.deletearr =[];
   $('#addNewPopupeeparti').modal({
   show:true,
   backdrop:false
});
$('#addNewPopupeeparti').modal('show');
}
    


 
 
}
     

   }else{

   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}


// viewstudspartic(val){
// console.log(this.eventeditdetials)
//  let registerstudentdata:any = new Object();
//  let registeruser:any = new Object();
//     let access_token: any=localStorage.getItem('access_token');
//     let admin_id: any=localStorage.getItem('userId');

// var starts =moment(this.eventeditdetials.meeting_dt).format('YYYY-MM-DD HH:mm:ss');
//  var ends =moment(this.eventeditdetials.meeting_end).format('YYYY-MM-DD HH:mm:ss');

//  console.log(starts)
//  console.log(ends)

// registerstudentdata.action="view_assign_students";
// registerstudentdata.admin_id=admin_id;
// registerstudentdata.event_name=this.addEvent.value.event_name;
// registerstudentdata.start_time=starts;
// registerstudentdata.end_time=ends;
// registerstudentdata.inst_id=this.instructorselect;
// // registerstudentdata.co_inst=this.instructorselectco;
// registerstudentdata.course_id =0;
// registerstudentdata.event_id  =0;

// registerstudentdata.meeting_id  =this.meeting_id;

//  registeruser.access_token=access_token;
//   registeruser.operation="lecture";
//   registeruser.moduleType="lecture";
//   registeruser.api_type="web";
//   registeruser.element_data = registerstudentdata;
//       this.serverService.sendServer(registeruser).subscribe((response: any) => {

//         console.log(response)
//    if(response.result.status==true){
//      this.get_attendees = response.result.data
// this.participantarr=[];
// for (var i = 0; i < this.get_attendees.length; i++) {
//   this.get_attendees[i].add = false;
//   this.participantarr.push(this.get_attendees[i].student_name)
  
// }
// if(val == 'call'){
//   this.deletearr =[];
//    $('#addNewPopupeeparti').modal({
//    show:true,
//    backdrop:false
// });
// $('#addNewPopupeeparti').modal('show');
 
// }
     

//    }else{

//    }
      
//       }, 
//       (error)=>{
//           console.log(error);
//       });
// }



adddeletestudent(val){
 var adding = false
if(val.add == false){

if(this.deletearr.length != 0){

 for (var i = 0; i < this.deletearr.length; i++) {


if(val.id !== this.deletearr[i]){

 adding  = true
}

}
  if(adding){
      this.deletearr.push(val.id)


  }
}
else{
  this.deletearr.push(val.id)

}

}
else{

   for (var j = 0; j < this.deletearr.length; j++) {

if(val.id == this.deletearr[j]){

    this.deletearr.splice(this.deletearr.indexOf(this.deletearr[j]), 1 );

}
}
 

}
 
 console.log(this.deletearr)
}



deleteassignstudent(){
  // console.log(val)
this.senddeletenames = '';
this.senddeletenames = this.deletearr.join(',');
 let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');

registerstudentdata.action="remove_assign_students";
registerstudentdata.id=this.senddeletenames;
       
 registeruser.access_token=access_token;
  registeruser.operation="lecture";
  registeruser.moduleType="lecture";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){

   iziToast.success({
    title: 'OK',
    position: 'topRight',
    message: 'Deleted Successfully!',
});
$('#addNewPopupee').modal('hide');

   }else{

   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}



savedata(val){
console.log(val)
console.log(this.sendstudentarr)
console.log(val.add)
 var adding = false
if(val.add == false){

if(this.sendstudentarr.length != 0){

 for (var i = 0; i < this.sendstudentarr.length; i++) {


if(val.username !== this.sendstudentarr[i]){

 adding  = true
}

}
  if(adding)
      this.sendstudentarr.push(val.username)


}
else{
   this.sendstudentarr.push(val.username)
}

}
else{
 
   for (var j = 0; j < this.sendstudentarr.length; j++) {

if(val.username == this.sendstudentarr[j]){
 
    this.sendstudentarr.splice(this.sendstudentarr.indexOf(this.sendstudentarr[j]), 1 );

}
}


}
 
 console.log(this.sendstudentarr)
}


assignstudentstomeeting(){
// console.log(res)
  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

var starts =moment(this.eventeditdetials.start_time).format('YYYY-MM-DD HH:mm:ss');
 var ends =moment(this.eventeditdetials.end_time).format('YYYY-MM-DD HH:mm:ss');

registerstudentdata.action="assign_participant";
registerstudentdata.participant_name=this.sendparticipant;

// registerstudentdata.inst_id=this.instructorselect;
// registerstudentdata.co_inst=this.instructorselectco;

registerstudentdata.inst_id=this.instructorselectview;
registerstudentdata.co_inst=this.instructorselectviewco;

registerstudentdata.admin_id=admin_id;

  registerstudentdata.meeting_id=this.meeting_id ;
  registerstudentdata.meeting_name=this.addEvent.value.event_name;
registerstudentdata.start_time=starts;
registerstudentdata.end_time=ends;
registerstudentdata.type="update" ;



 registeruser.access_token=access_token;
  registeruser.operation="meeting";
  registeruser.moduleType="meeting";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){

// if(this.details == 'duplicate'){
//     iziToast.success({
//     title: 'OK',
//     position: 'topRight',
//     message: 'Successfully Meeting'+' '+res[0].id+' '+'assigned!',
// }); 
//      this.vieweventsdetail({}) 
// }

     iziToast.success({
    title: 'OK',
    position: 'topRight',
    message: 'Updated Successfully!',
});
   }else{
   iziToast.error({
    title: 'OK',
    position: 'topRight',
    message: 'Error Occured!',
});
   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}













assignstudentstoevents(){
// console.log(res)
  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

var starts =moment(this.eventeditdetials.start_time).format('YYYY-MM-DD HH:mm:ss');
 var ends =moment(this.eventeditdetials.end_time).format('YYYY-MM-DD HH:mm:ss');

registerstudentdata.action="assign_students";
registerstudentdata.event_name=this.addEvent.value.event_name;
registerstudentdata.start_time=starts;
registerstudentdata.end_time=ends;
registerstudentdata.student_name=this.sendparticipant;
registerstudentdata.inst_id=this.instructorselectview;
registerstudentdata.co_inst=this.instructorselectviewco;

registerstudentdata.admin_id=admin_id;
registerstudentdata.event_id=this.event_id ;
registerstudentdata.type="update";



 registeruser.access_token=access_token;
  registeruser.operation="lecture";
  registeruser.moduleType="lecture";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){
     iziToast.success({
    title: 'OK',
    position: 'topRight',
    message: 'Successfully student assigned!',
});
   }else{
   iziToast.error({
    title: 'OK',
    position: 'topRight',
    message: 'Illegal operation!',
});
   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}









getsearchinstructor(searchValue: string): void {  
  console.log(searchValue);
  this.get_instructor_list({'search_text':searchValue})
}

get_instructor_list(data){

  var list_data= this.listDataInfo3(data);

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');
    var type;
if(this.typecalled == 'Event'){
type = 'event';
}else if(this.typecalled == 'Course'){
type = 'course';
}
else if(this.typecalled == 'Meeting'){
 type = 'Meeting';
  }
  
    let api_req:any = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"view_instruct_rec","limit":"'+list_data.limit+'","offset":'+list_data.offset+',"search_text":"'+list_data.search_text+'","admin_id":"'+admin_id+'","type":"'+type+'"}}';
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      console.log(this.instructorselect);

      if(response.result.status == true){
      this.instructorlist = response.result.data.list_data

   for (var i = 0; i < this.instructorlist.length; i++) {
        if(this.typeinstructor == 'instructor'){
if(this.instructorlist[i].id == this.instructorselect){
this.instructorlist[i].add = true 

}
else{
this.instructorlist[i].add = false 

}

}
else{
   if(this.instructorlist[i].id == this.instructorselectco){
this.instructorlist[i].add = true 

}
else{
this.instructorlist[i].add = false 

}
}
}

 console.log(this.instructorlist)
 this.offset_count3= list_data.offset;
 this.paginationData3 = this.serverService.pagination({'offset':response.result.data.list_info.offset, 'total':response.result.data.list_info.total, 'page_limit' :this.pageLimit });
   this.recordNotFound3 = this.instructorlist.length == 0 ? true : false;

      }
     
    }, 
    (error)=>{
        console.log(error);
    });
  }



listDataInfo3(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
} 



savedatainstructor(val){
 console.log(val)
if(val.add == false){
  if(this.typeinstructor == "instructor"){
this.instructselectshow = val.id;
this.instructselectshowname = val.username;
this.instructorshowfullname = val.fname + val.lname

  }else{
this.instructselectshowco = val.id;
this.instructselectshownameco = val.username;
this.instructorshowfullnameco = val.fname + val.lname
  }


}

}


savealldata2(){
  if(this.typeinstructor == "instructor"){
this.instructorselect= this.instructselectshow;
this.instructselectname = this.instructselectshowname;
this.instructorfullname = this.instructorshowfullname;


  }else{
 
    this.instructorselectco= this.instructselectshowco
this.instructselectnameco =this.instructselectshownameco
this.instructorfullnameco = this.instructorshowfullnameco;

  }

}








chnageinst(name){
  console.log(name)
this.typeinstructor = name;
      this.get_instructor_list({})

  $('#addNewPopupinst').modal({
  show:true,
   backdrop:false

});
$('#addNewPopupinst').modal('show');

}






assignstudentstocourse(){

  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');


  let updateevents:any =this.addCourse.value;


registerstudentdata.action="add_course_students";
registerstudentdata.event_name=this.courseditdetials.event_name;
registerstudentdata.start_time= moment(this.courseditdetials.start_time).format('YYYY-MM-DD');

registerstudentdata.from_time=this.courseditdetials.start_time+':'+'00'
registerstudentdata.to_time=this.courseditdetials.end_time+':'+'00'




registerstudentdata.course_id=this.courseditdetials.course_id;
registerstudentdata.no_of_class=this.courseditdetials.no_of_class;

registerstudentdata.days=this.courseditdetials.days;
registerstudentdata.type="update";

registerstudentdata.student_name=this.sendstudent;
registerstudentdata.inst_id=this.instructorselectview;
registerstudentdata.co_inst=this.instructorselectviewco;

 


registerstudentdata.admin_id=admin_id;



 registeruser.access_token=access_token;
  registeruser.operation="lecture";
  registeruser.moduleType="lecture";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.data.status=="true" ){
     iziToast.success({
    title: 'OK',
    position: 'topRight',
    message: 'Successfully Course Updated!',
});


   }else{
   iziToast.error({
    title: 'OK',
    position: 'topRight',
    message: 'Error Occured!',
});
   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}




viewlocation(){

  let updateevents:any =new Object();
 let updateeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
   
updateevents.action="view_location";

updateevents.admin_id =localStorage.getItem('userId');


 updateeventsuser.access_token=access_token;
  updateeventsuser.operation="lecture";
  updateeventsuser.moduleType="lecture";
  updateeventsuser.api_type="web";
  updateeventsuser.element_data = updateevents;
      this.serverService.sendServer(updateeventsuser).subscribe((response: any) => {
console.log(response)
        if(response.result.status==true){
     this.locations = response.result.data
   

          }
        else{

 
        }
      
      }, 
      (error)=>{
          console.log(error);
      });

}


  
removealert(methodname,val){
var s = this;
  iziToast.question({
    timeout: 20000,
    close: false,
    overlay: true,
    displayMode: 'once',
    id: 'question',
    zindex: 999,
    title: 'Confirm',
    message: 'Are you sure you want to delete?',
    position: 'center',
    buttons: [
        ['<button><b>YES</b></button>', function (instance, toast) {
 
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
 console.log('yes')
 if(methodname == 'delete_meeting'){
   // s.delete_meeting(val)
 }
else if(methodname == 'deleteassignstudent'){
  s.deleteassignstudent()
}
        }, true],
        ['<button>NO</button>', function (instance, toast) {
 
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
  console.log('no')

        }],
    ]

});

}





}
