import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
declare var $:any;
declare var iziToast:any;
import Swal from 'sweetalert2'
@Component({
  selector: 'app-car-park-logs',
  templateUrl: './car-park-logs.component.html',
  styleUrls: ['./car-park-logs.component.css']
})
export class CarParkLogsComponent implements OnInit {

  constructor(private serverService:ServerService) { }
  carlots_log;
  ngOnInit(): void {
    this.getLogs();
  }
  getLogs(){
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('supersuperadmin');
     let api_req:any = '{"operation":"carparking","moduleType":"carparking","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"slot_reports","admin_id":"'+admin_id+'"}}'
     Swal.fire({
			html:
				'<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
		showCloseButton: false,
			showCancelButton: false,
			showConfirmButton: false,
			focusConfirm: false,
			background: 'transparent'
		});
       this.serverService.sendServer(api_req).subscribe((response:any) => {
         Swal.close()
         console.log(response);
         if(response.result.status == true){
       this.carlots_log=response.result.data;      
    
         }
   
   
       }, 
       (error)=>{
           console.log(error);
       });
  }
}
