import { Component, OnInit } from '@angular/core';
import { NgxEditorModule } from 'ngx-editor';
import { ServerService } from '../services/server.service';
import { Router,ActivatedRoute } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
declare var $:any;
declare var iziToast:any;

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) {}
 
 public transform(value: any, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case 'html': return this.sanitizer.bypassSecurityTrustHtml(value);
      case 'style': return this.sanitizer.bypassSecurityTrustStyle(value);
      case 'script': return this.sanitizer.bypassSecurityTrustScript(value);
      case 'url': return this.sanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl': return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default: throw new Error(`Invalid safe type specified: ${type}`);
    }
  }
}

@Component({
  selector: 'app-addnewevent',
  templateUrl: './addnewevent.component.html',
  styleUrls: ['./addnewevent.component.css']
})
export class AddneweventComponent implements OnInit {
 colors;
 public previewFileUrl;
 public previewFileUrl2;
	    value;
eventData:any={};
instructorlist:any=[]
eventDatas : FormGroup;
eventslist:any=[];
 type;
editdata:any={};
  constructor(private route:ActivatedRoute,private router:Router,private serverService: ServerService,private sanitizer: DomSanitizer) {

this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {

  this.type=this.router.getCurrentNavigation().extras.state.type; 
  this.assignpage()
      }
    });
if(this.type == undefined ){
    this.type=localStorage.getItem('addneweventpagetype');
    this.assignpage()
   }

this.viewevents()
   }

assignpage(){


  console.log(this.type)
  if(this.type == 'add'){

 localStorage.setItem('addneweventpagetype', 'add'); 


}else if(this.type == 'view'){

localStorage.setItem('addneweventpagetype', 'view'); 


}
}


  ngOnInit(): void {
   
    this.get_instructor_list()
    
     this.eventDatas = new FormGroup({
      'event' : new FormControl(null,Validators.required),
      'color' : new FormControl(null),
    
      // 'instructor' : new FormControl(null,Validators.required),
      // 'status' : new FormControl(null,Validators.required),

        // 'colordata':new FormControl(null,Validators.required)
        
        
     
     
     });
  	   // $('#summernote').summernote();
  }
  file: File;
 selectedImageChanged(event) {
    this.file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = async e => {
      this.previewFileUrl = fileReader.result as string;
    }
    fileReader.readAsDataURL(event.target.files[0]);
  }

  file2: File;
 selectedImageChanged2(event) {
    this.file2 = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = async e => {
      this.previewFileUrl2 = fileReader.result as string;
    }
    fileReader.readAsDataURL(event.target.files[0]);
  }

goevent(name){
this.type = name
 localStorage.setItem('addneweventpagetype',name); 
 this.viewevents()

}

finishFunction(){
  console.log(this.colors)
   if(this.eventDatas.valid){
     
     this.createevent()
   }
   else {
            this.validateAllFields(this.eventDatas); 
        }
  
}


validateAllFields(formGroup: FormGroup) {         
        Object.keys(formGroup.controls).forEach(field => {  
            const control = formGroup.get(field);            
            if (control instanceof FormControl) {             
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {        
                this.validateAllFields(control);  
            }
        });
    }
       







createevent(){

  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let superadmin: any=localStorage.getItem('superadmin');

var formData = new FormData();
    // var json_arr = JSON.stringify(registerstudentdata);
    formData.append('operation', 'user');
    formData.append('moduleType', 'user');
    formData.append('api_type', 'web');
    // formData.append('image', this.file);
    formData.append('access_token', access_token);
  formData.append('action', "add_events");


  formData.append('event_name', this.eventDatas.value.event);

  formData.append('super_admin',superadmin);
  // formData.append('instructors', this.eventData.instructor[0]);
  formData.append('notes','');
  formData.append('small_image','');
  formData.append('dash_image',this.file);
  formData.append('colour',this.colors);
  formData.append('status',"1");
  formData.append('admin_id',localStorage.getItem('userId'));



 $.ajax({  
    url:"https://booking.mconnectapps.com/api/v1.0/index_new.php",  
    type : 'POST',
    data : formData,
    processData: false,  // tell jQuery not to process the data
    contentType: false, 
    success:function(data){ 
      var parsed_data = JSON.parse(data);
      console.log(this.parsed_data );

      if(parsed_data.status == 'true'){  
      
  iziToast.success({
          message: "New Event Created successfully",
          position: 'topRight'
   
// this.router.navigate(['/'])
  
      })
      
      
      

      } else {
        iziToast.error({
          message: "Sorry, Some Error Occured",
          position: 'topRight'
      });
      
      }
    }  
});  














}

get_instructor_list(){
  

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');
  
    let api_req:any = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"view_instruct_rec","limit":"10","offset":"0","search_text":"","admin_id":"'+admin_id+'"}}';
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){
      this.instructorlist = response.result.data.list_data
      

      }
     
    }, 
    (error)=>{
        console.log(error);
    });
  }




colorpick(s){
console.log(s)
this.eventData.colordata =s
}

viewevents(){

  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');


registerstudentdata.action="view_events";
registerstudentdata.admin_id=admin_id;

 registeruser.access_token=access_token;
  registeruser.operation="user";
  registeruser.moduleType="user";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){
     this.eventslist = response.result.data
   }else{

   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}

editevent(val){
this.previewFileUrl ='';
  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');


registerstudentdata.action="edit_events";
registerstudentdata.id=val.event_id;
// registerstudentdata.admin_id=admin_id;

 registeruser.access_token=access_token;
  registeruser.operation="user";
  registeruser.moduleType="user";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){
     // this.eventslist = response.result.data
this.previewFileUrl =response.result.data[0].dash_image
this.editdata.colour =response.result.data[0].colour
this.editdata.event_name =response.result.data[0].event_name
this.editdata.id =response.result.data[0].event_id

  $('#addNewPopup').modal({
   show:true,
   backdrop:false
});
$('#addNewPopup').modal('show');

   }else{

   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}


deleteevent(val){

  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');


registerstudentdata.action="delete_event";
registerstudentdata.id=val.event_id;
// registerstudentdata.admin_id=admin_id;

 registeruser.access_token=access_token;
  registeruser.operation="user";
  registeruser.moduleType="user";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){
      iziToast.success({
          message: "Event Deleted successfully",
          position: 'topRight'
   

  
      })
     this.viewevents()
     // this.eventslist = response.result.data
   }else{

   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}

updateevents(){
  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let superadmin: any=localStorage.getItem('superadmin');

var formData = new FormData();

    if(this.file != undefined){
    formData.append('dash_image', this.file);

  }else{
    formData.append('dash_image','');

  }

    // var json_arr = JSON.stringify(registerstudentdata);
    formData.append('operation', 'user');
    formData.append('moduleType', 'user');
    formData.append('api_type', 'web');
    // formData.append('image', this.file);
    formData.append('access_token', access_token);
  formData.append('action', "update_events");
 formData.append('id',this.editdata.id);
  formData.append('event_name', this.editdata.event_name);

 
  formData.append('instructors', '');
  formData.append('notes', '');
 
  formData.append('small_image','');
  // formData.append('dash_image',this.file);
  formData.append('colour',this.editdata.colour);
  formData.append('status',"1");
  formData.append('admin_id',localStorage.getItem('userId'));
 formData.append('super_admin',superadmin);

var s = this;
 $.ajax({  
    url:"https://booking.mconnectapps.com/api/v1.0/index_new.php",  
    type : 'POST',
    data : formData,
    processData: false,  // tell jQuery not to process the data
    contentType: false, 
    success:function(data){ 
      var parsed_data = JSON.parse(data);
      console.log(this.parsed_data );

      if(parsed_data.status == 'true'){  
      
  iziToast.success({
          message: " Event Updated successfully",
          position: 'topRight'
   // ss.viewevents()
// this.router.navigate(['/'])
  
      })
      
      s.viewevents()
      

      } else {
        iziToast.error({
          message: "Sorry, Some Error Occured",
          position: 'topRight'
      });
      
      }
    }  
});  



}


}
