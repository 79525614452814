import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ServerService } from '../services/server.service';
import * as feather from 'feather-icons';
import { Router,ActivatedRoute } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
declare var $:any;
declare function forming():any;
declare var iziToast:any;
import '../../assets/js/scripts.js';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-addnewstudent',
  templateUrl: './addnewstudent.component.html',
  styleUrls: ['./addnewstudent.component.css']
})

export class AddnewstudentComponent implements OnInit {
  @ViewChild(WizardComponent)
    public wizard: WizardComponent;
information: FormGroup;
step2 : FormGroup;
step3 : FormGroup;
nextForm = false;
id;
page ='';
settingshow = false;
studentseditdetails:any=[];
// layoutM;
layoutM = 'light'
themeM;
layout = '1';
theme = 'white';
eSave = true;
imagePath;
formData;
Logo;
 eventslist:any=[];
 eventbind;
  constructor(private route:ActivatedRoute,private router:Router,private serverService: ServerService) {
     
     this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {

     this.id=this.router.getCurrentNavigation().extras.state.id; 
     this.page=this.router.getCurrentNavigation().extras.state.page; 

     console.log(this.id)
     console.log(this.page)

     this.get_edit_student()
    
      }
    });

   }


   setsidebar(){
     if(this.settingshow == false){
$(".settingSidebar").toggleClass("showSettingPanel");
this.settingshow = true;
   }
   else{
      $(".settingSidebar").removeClass("showSettingPanel");
     this.settingshow = false;
   }

}

 myLayout(){
   console.log('asda')
    this.layout = $('[name="value"]:checked').val();
  }
mytheme(theme){

}



  ngOnInit(): void {
   // formcalling()
   this.viewevents()
    feather.replace();
    this.information = new FormGroup({
      'email' : new FormControl(null),

      // 'email' : new FormControl(null,[Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
      'fname' : new FormControl(null,Validators.required),
      'lname' : new FormControl(null),
    
      'phone_no' : new FormControl(null,Validators.required),
      'nirp' : new FormControl(null),

      'country_code' : new FormControl(null,Validators.required),
     
        // 'parent_name':new FormControl(null),
        // 'parent_phone':new FormControl(null),
        'address':new FormControl(null),
        // 'city':new FormControl(null),
        // 'state':new FormControl(null),
      
        'country':new FormControl(null,Validators.required),
        'pincode':new FormControl(null),
      
        'age':new FormControl(null),
        'gender':new FormControl(null,Validators.required)


     });

  this.step2 = new FormGroup({
      'username' : new FormControl(null),
    
      'password' : new FormControl(null),
        'confirm':new FormControl(null)
        
        
     
     
     });

    this.step3 = new FormGroup({
      // 'acceptTerms' : new FormControl(0),
    'agree': new FormControl(false,Validators.requiredTrue)
     
     
     });
   // this.information.statusChanges.subscribe(s => this.eSave = true);

// this.information.setValue({
//   'gender': "", 
// });

//  this.information.patchValue({

//   gender: "", 
// });
 // this.information.value.gender ="";
     this.information.patchValue({

  country_code: "65",
  country: "Singapore",
  gender: ""
});



  }



  get password() {
  return this.step2.controls['password'];
}

get confirm_password(){
  return this.step2.controls['confirm'];
}

onPasswordChange() {
  if (this.confirm_password.value == this.password.value) {
    this.confirm_password.setErrors(null);
  } else {
    this.confirm_password.setErrors({ mismatch: true });
  }
}

  moveToNext() {
    // this.registerdatasss()
    console.log(this.information.value)
        if (this.information.valid) {
     if(this.page != 'Edit') {
 this.get_username()

}   else{
 this.wizard.goToNextStep();
  
} 

             
            
   
        } else {

            this.validateAllFields(this.information); 
        }
    }

moveToNext2(){
// 
   if(this.step2.valid){
      this.registedatass()
   }
     // this.wizard.goToNextStep();
   
   else {
            this.validateAllFields(this.step2); 
        }
  }

finishFunction(){
   if(this.step3.valid){
     
     this.registedatass()
   }
   else {
            this.validateAllFields(this.step3); 
        }
  
}


validateAllFields(formGroup: FormGroup) {         
        Object.keys(formGroup.controls).forEach(field => {  
            const control = formGroup.get(field);            
            if (control instanceof FormControl) {             
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {        
                this.validateAllFields(control);  
            }
        });
    }
        


// registerdatass(){
//  // console.log(this.step2.username)
//  // console.log(this.step2.value)
//   let registerstudentdata:any =  this.information.value;
//     let superadmin: any=localStorage.getItem('superadmin');
  
//   let registeruser:any = new Object();

//     let access_token: any=localStorage.getItem('access_token');

// if(this.page == 'Edit'){
// registerstudentdata.id=this.id;
// registerstudentdata.status="1";
// registerstudentdata.action="update_student_rec";
// }else{
// registerstudentdata.action="student_rec";

// }




//  // formData.append('profile_image', $('#profile_image')[0].files[0]);
// registerstudentdata.username =  this.step2.value.username
// registerstudentdata.password = this.step2.value.password
// // registerstudentdata.image = this.file

//     registerstudentdata.super_admin =superadmin,

//     registerstudentdata.phone_code="+91",
//      registeruser.access_token=access_token;
//   registeruser.operation="user";
//   registeruser.moduleType="user";
//   registeruser.api_type="web";
//   registeruser.element_data = registerstudentdata;
//       this.serverService.sendServer(registeruser).subscribe((response: any) => {

//         console.log(response)
       


//        //  if(response.result.status==true){
      
//       }, 
//       (error)=>{
//           console.log(error);
//       });
// }



 localUrl;
 file: File;
  showPreviewImage(event: any) {
    this.file = event.target.files[0];
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();
            reader.onload = (event: any) => {
                this.localUrl = event.target.result;
            }
            reader.readAsDataURL(event.target.files[0]);
        }
    }

  
registedatass(){

  

    let access_token: any=localStorage.getItem('access_token');
    let superadmin: any=localStorage.getItem('superadmin');




    var formData = new FormData();
    // var json_arr = JSON.stringify(registerstudentdata);
    formData.append('operation', 'user');
    formData.append('moduleType', 'user');
    formData.append('api_type', 'web');



    if(this.file != undefined){
    formData.append('image', this.file);

  }else{
    formData.append('image','');

  }
    if(this.information.value.state != undefined){
  formData.append('state', this.information.value.state);
  

  }else{
    formData.append('state','');


  }


    formData.append('access_token', access_token);
  formData.append('email', this.information.value.email);
  formData.append('fname', this.information.value.fname);
    formData.append('gender',this.information.value.gender);
  formData.append('country_code', this.information.value.country_code);
  formData.append('phone_no',this.information.value.phone_no);
  formData.append('home', this.information.value.home);
  formData.append('nirp',this.information.value.nirp);
 formData.append('age', this.information.value.age);
 formData.append('country', this.information.value.country);
  formData.append('address', this.information.value.address);
  formData.append('pincode', this.information.value.pincode);

  formData.append('username', this.step2.value.username);
  formData.append('password',this.step2.value.password);
  formData.append('admin_id',localStorage.getItem('userId'));
  formData.append('super_admin',superadmin);
  
  // formData.append('lname', this.information.value.lname);
  // formData.append('parent_name', this.information.value.parent_name);
  // formData.append('parent_phone',this.information.value.parent_phone);
  // formData.append('city', this.information.value.city);
  
  

  // formData.append('phone_code', this.information.value.country_code);


 
if(this.page == 'Edit'){
  formData.append('id', this.id);
  formData.append('status', "1");
formData.append('action', 'update_student_rec');

// registerstudentdata.id=this.id;
// registerstudentdata.status="1";
// registerstudentdata.action="update_student_rec";
}else{
// registerstudentdata.action="student_rec";
formData.append('action', 'student_rec');

}

    console.log(formData);
  var s = this;
  $.ajax({  
    url:"https://booking.mconnectapps.com/api/v1.0/index_new.php",  
    type : 'POST',
    data : formData,
    processData: false,  // tell jQuery not to process the data
    contentType: false, 
    success:function(data){ 
      var parsed_data = JSON.parse(data);
      console.log(this.parsed_data );

      if(parsed_data.status == 'true'){  
      if(s.page == 'Edit'){
          iziToast.success({
          message: "Student Data Updated successfully",
          position: 'topRight'
      });
s.router.navigate(['/studentrecordcourse'])

      }else{
  iziToast.success({
          message: "New Student added successfully",
          position: 'topRight'
      });
s.router.navigate(['/studentrecordcourse'])
  
      }  
      
      // $("#refresh_page").click();
      

      } else {
        if(parsed_data.status){
          
        iziToast.error({
          message: parsed_data.status,
          position: 'topRight'
      });
      
}
else{
   iziToast.error({
          message: "Sorry, Some Error Occured",
          position: 'topRight'
      });
}

      }
    }  
});  

  }







get_edit_student(){
    let access_token: any=localStorage.getItem('access_token');
  
    let api_req:any = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"edit_student_rec","id":"'+this.id+'"}}';
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){
      this.studentseditdetails = response.result.data
  this.localUrl= response.result.data[0].image
  var array = this.studentseditdetails[0].events.split(','); 
  
this.eventbind = array
  console.log(array)
  this.information.patchValue({

      email:this.studentseditdetails[0].email,
      fname:this.studentseditdetails[0].fname,
      lname:this.studentseditdetails[0].lname,
      phone_no:this.studentseditdetails[0].phone_no,
      country_code:this.studentseditdetails[0].country_code,
        parent_name:this.studentseditdetails[0].parent_name,
        parent_phone:this.studentseditdetails[0].parent_phone,
        address:this.studentseditdetails[0].address,
        city:this.studentseditdetails[0].city,
        state:this.studentseditdetails[0].state,
        country:this.studentseditdetails[0].country,
        pincode:this.studentseditdetails[0].pincode,
        nirp:this.studentseditdetails[0].nirp,
        // events:array,
        age:this.studentseditdetails[0].age,
        gender:this.studentseditdetails[0].gender

  });

 this.step2.patchValue({
username: this.studentseditdetails[0].username,
 password :this.studentseditdetails[0].psw,
 confirm:this.studentseditdetails[0].psw
  });

      }

    }, 
    (error)=>{
        console.log(error);
    });
  }

viewevents(){

  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');


registerstudentdata.action="view_events";
registerstudentdata.admin_id=admin_id;

 registeruser.access_token=access_token;
  registeruser.operation="user";
  registeruser.moduleType="user";
  registeruser.api_type="web";

  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){
     this.eventslist = response.result.data
   }else{

   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}

get_username(){
  Swal.fire('Please wait')
Swal.showLoading()
  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');


registerstudentdata.action="get_username";
registerstudentdata.admin_id=admin_id;

 registeruser.access_token=access_token;
  registeruser.operation="user";
  registeruser.moduleType="user";
  registeruser.api_type="web";

  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {
   Swal.close();
        console.log(response)
   if(response.result.status==true){
 this.step2.patchValue({
username: response.result.data,
 
  });
 this.wizard.goToNextStep();
   }else{

   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}









}



