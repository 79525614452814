import { Component, OnInit } from '@angular/core';
import { ServerService } from '../../services/server.service';
import Swal from 'sweetalert2'
declare var $:any;
declare var iziToast:any;
@Component({
  selector: 'app-car-park-dashboard',
  templateUrl: './car-park-dashboard.component.html',
  styleUrls: ['./car-park-dashboard.component.css']
})
export class CarParkDashboardComponent implements OnInit {
current_date;
scanned;
not_scanned;
carlots_list;
total_slots;

e_lotname;
e_lotno;
websocket;
  constructor(private serverService:ServerService) { }

  ngOnInit(): void {

    var today = new Date();

   this.current_date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
   this.getcounts();
   this.getLots();
   this.initSocket();

  }
  initSocket(){
   
    
    this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4035"); 
 


    this.websocket.onopen = function(event) { 
      console.log('Manage Slots socket connected');
    }

    this.websocket.onmessage = function(event) {  
      var result_message= JSON.parse(event.data);
      console.log(result_message)
        if(result_message.message_type=="parking"){
        $('#reloaddashboard').click();
        $('#reloadcounts').click();        
        }
      
    }

    this.websocket.onerror = function(event){
      console.log('error');
    }
    this.websocket.onclose = function(event){
      $('#reconnect_socket').click();

}




}
  getcounts(){
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('supersuperadmin');
     let api_req:any = '{"operation":"carparking","moduleType":"carparking","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"get_dashboard_count","admin_id":"'+admin_id+'"}}'
     
       this.serverService.sendServer(api_req).subscribe((response:any) => {
         console.log(response);
         if(response.status == 'true'){
   
         this.scanned=response.scanned;
         this.not_scanned=response.not_scanned;
         this.total_slots=+this.scanned + + this.not_scanned;
   
         }
   
   
       }, 
       (error)=>{
           console.log(error);
       });
  }
  getLots(){
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('supersuperadmin');
     let api_req:any = '{"operation":"carparking","moduleType":"carparking","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"parking_list","admin_id":"'+admin_id+'"}}'
     Swal.fire({
			html:
				'<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
		showCloseButton: false,
			showCancelButton: false,
			showConfirmButton: false,
			focusConfirm: false,
			background: 'transparent'
		});
       this.serverService.sendServer(api_req).subscribe((response:any) => {
         Swal.close()
         console.log(response);
         if(response.result.status == true){
       this.carlots_list=response.result.data;
         }
   
   
       }, 
       (error)=>{
           console.log(error);
       });
  }
  makeEntry(name,no){
    
    this.e_lotname=name;
    this.e_lotno=no;
      $('#makeEntryPopup').modal({
      show:true,
       backdrop:false  
    });
    $('#makeEntryPopup').modal('show');
   
  }
  AddEntry(){
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('supersuperadmin');

    let api_data:any =  new Object();
   let  Vname=$('#visitor_name').val();
   let  Vmob=$('#visitor_mobile').val();
   let  Vno=$('#visitor_vehicle').val();
if(Vname==''){
iziToast.warning({
  message: "Please enter Visitor Name",
  position: 'topRight'
});
return false;
}
if(Vmob==''){
  iziToast.warning({
    message: "Please enter Mobile Number",
    position: 'topRight'
  });
  return false;
 }
if(Vno==''){
    iziToast.warning({
      message: "Please enter Vehicle Number",
      position: 'topRight'
    });
  return false;
}
    let api_req:any = new Object();
    api_data.action="parking_register";
    api_data.name=Vname;   
    api_data.mobile_no=Vmob;   
    api_data.number_plate=Vno;   
    api_data.admin_id=admin_id;
    api_req.operation="carparking";
    api_req.moduleType="carparking";
    api_req.api_type="web";
    api_req.element_data=api_data;
    //  let api_req:any = '{"operation":"carparking","moduleType":"carparking","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"parking_slot","id":""}}'
    Swal.fire({
			html:
				'<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
		showCloseButton: false,
			showCancelButton: false,
			showConfirmButton: false,
			focusConfirm: false,
			background: 'transparent'
		}); 
    this.serverService.sendServer(api_req).subscribe((response:any) => {
         Swal.close();
         if(response.result.data.id!='')
           this.makescan(response.result.data.id);
   else{
    iziToast.warning({
      message: "Sorry, can't able to register.Please contact admin",
      position: 'topRight'
    });
   }
       }, 
       (error)=>{
           console.log(error);
       });
  }
  makescan(id){
    let admin_id: any=localStorage.getItem('supersuperadmin');
    let api_data:any =  new Object();
    let api_req:any = new Object();
    api_data.action="parking_scan";
    api_data.lot_name=this.e_lotname;   
    api_data.lot_no=this.e_lotno;   
    api_data.id=id;
    api_data.status=1;  
    api_data.admin_id=admin_id;
    api_req.operation="carparking";
    api_req.moduleType="carparking";
    api_req.api_type="web";
    api_req.element_data=api_data;
    //  let api_req:any = '{"operation":"carparking","moduleType":"carparking","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"parking_slot","id":""}}'
       this.serverService.sendServer(api_req).subscribe((response:any) => {
         
         console.log(response);   
         if(response.result.data==true){
          iziToast.success({
            message: "Registered Successfully",
            position: 'topRight'
          });
          this.getLots();
          $('#makeEntryPopup').modal('hide');
          this.getcounts();
          var socket_message  =  '{"message_type":"parking_web","message_status":"qrscan_reg","message_info" : {"status":"1"}}';
          this.websocket.send(socket_message);
         }
         else{
          iziToast.warning({
            message: "Sorry, can't able to register.Please contact admin",
            position: 'topRight'
          });  
         }
       }, 
       (error)=>{
           console.log(error);
       });
  }
   makeExit(name,id){
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to Exit this Slot manually",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
    let admin_id: any=localStorage.getItem('supersuperadmin');
    let api_data:any =  new Object();
    let api_req:any = new Object();
    api_data.action="parking_exit_web";
    api_data.status=0;   
    api_data.lot_no=id;
    api_data.exit_by='Web';
    api_data.id=id;
    api_data.admin_id=admin_id;
    api_req.operation="carparking";
    api_req.moduleType="carparking";
    api_req.api_type="web";
    api_req.element_data=api_data;
    //  let api_req:any = '{"operation":"carparking","moduleType":"carparking","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"parking_slot","id":""}}'
       this.serverService.sendServer(api_req).subscribe((response:any) => {
         
         console.log(response);   
         if(response.result.status==true){
          iziToast.success({
            message: "Slot "+name+" exited Successfully",
            position: 'topRight'
          });
          this.getLots();
          this.getcounts();
          var socket_message  =  '{"message_type":"parking_web","message_status":"qrscan_exit","message_info" : {"status":"0"}}';
          this.websocket.send(socket_message);
         }
         else{
          iziToast.warning({
            message: "Sorry, we having trouble to exit the slot "+name+".Please contact admin",
            position: 'topRight'
          });  
         }
       }, 
       (error)=>{
           console.log(error);
       });
      }
    });
  }

}
