
<div class="section-body">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title" style="width: 22% !important;">
            <h4>Meeting Record</h4>
          </div>
          <div class="card-header-btn-group"  style="width: 100%;">
            <a  (click)="assignnew()" class="btn btn-info"><i class="fa fa-edit" aria-hidden="true"></i> Assign New Meeting</a> &nbsp;
            <a  (click)="eventpage('add')" class="btn btn-warning"><i class="fa fa-plus-circle" aria-hidden="true"></i> Add New Meeting</a>&nbsp;
            <a (click)="templatepage()"  class="btn btn-danger"><i class="fa fa-eye" aria-hidden="true"></i>Meeting Template View</a>&nbsp;
            <a  (click)="eventpage('view')" class="btn btn-success"><i class="fa fa-eye" aria-hidden="true"></i> View Meetings</a>


          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-striped" id="table-2">
              <thead>
                <tr>
                  <td colspan="20" class="search-text-tbl">
                    <div class="input-group"><input type="text" class="form-control" placeholder="Search Meetings" (input)="getsearch($event.target.value)"  (keyup.enter)="vieweventsdetail({'search_text':agent_search.value})" #agent_search>
                      <div class="input-group-append">
                        <button class="btn btn-primary" (click)="vieweventsdetail({'search_text':agent_search.value})"><i class="fas fa-search"></i></button>
                      </div>
                    </div>
                  </td>
                </tr>
              </thead>

              <thead>
                 <tr class="l-bg-light-blue">
                  <th>S.no</th>
                  <th>Meeting Name</th>
                  <th>ID</th>
                  <th>Incharge</th>
                  <th>Attendees</th>
                  <!-- <th>Type</th> -->
                  <th>Start Date  & Time</th>
                  <th>End Date  & Time</th>
                  <th colspan="3">Action</th>
                </tr>
              </thead>

              <tbody>
                <tr  *ngFor="let l of meetings;let i=index">

                  <td>{{offset_count+i+1}}</td>
                  <td>
                    <span  [style.background-color]="l.colour" class="badge">{{l.meeting_name}}</span>

                  </td>
                  <td>M{{l.id}} {{l.meeting_name}}</td>

                  <td>
               
<!-- <img src="{{l.image}}"  onerror="this.src='assets/images/avatar.jpg'" alt="image" width="35">&nbsp; -->
<!-- <img src="{{l.image}}"  onerror="this.src='assets/images/avatar.jpg'" alt="image"  width="35">&nbsp; -->
                    <!-- {{l.instructors}} -->

                    {{l.inst_name}}
                  </td>
                    <!--  <td>
               
<img src="{{l.image}}"  onerror="this.src='assets/images/avatar.jpg'" alt="avatar" width="35">&nbsp;&nbsp;
                    {{l.inst_name}}
                  </td> -->
                  <td style="text-align: center;">

                    {{l.present}}/{{l.total_students}}

                  </td>

                  <!-- <td >{{l.type}}</td> -->

                  <td>{{l.start}}</td>

                  <td>{{l.end}}</td>
                  <td>
                    <button type="button" class="btn btn-danger btn-icon icon-left" (click)="removealert('delete_meeting',l)">
                    <i class="far fa-trash-alt"></i> Delete</button>
                  </td>
                  <td>
                    <button type="button" class="btn btn-success btn-icon icon-left" (click)="showedit(l,'edit')">
                      <i class="fas fa-pencil-alt" ></i> Manage</button>
                    </td>
                    <td>
                      <button *ngIf="type == '1' && enableduplicate == '1'" type="button" class="btn btn-info btn-icon icon-left" (click)="showedit(l,'duplicate')">
                        <i class="fas fa-pencil-alt" ></i> Duplicate</button>
                      <button  *ngIf="type == '2'" type="button" class="btn btn-info btn-icon icon-left" (click)="showedit(l,'duplicate')">
                        <i class="fas fa-pencil-alt" ></i> Duplicate</button>

                      </td>
                    </tr>

                  </tbody>
                </table>

                <tr *ngIf="recordNotFound == true">
                 <td colspan="12">Data not found</td>
               </tr>
             </div>
             <div class="card-footer text-right" *ngIf="recordNotFound == false">
              <nav class="d-inline-block">
                <ul class="pagination mb-0" *ngIf="paginationData.info == 'show'">

                  <li class="page-item">
                    <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? vieweventsdetail({'search_text':agent_search.value,'offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
                  </li>
                  <li class="page-item" *ngIf="paginationData.backtab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="vieweventsdetail({'search_text':agent_search.value,'offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
                  </li>


                  <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? vieweventsdetail({'search_text':agent_search.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                  </li>

                  <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="vieweventsdetail({'search_text':agent_search.value,'offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
                  </li>

                  <li class="page-item">
                    <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? vieweventsdetail({'search_text':agent_search.value,'offset':paginationData.end}) : false"><i class="fas fa-angle-right"></i></a>
                  </li>
                </ul>
              </nav>
            </div>








          </div>
        </div>
      </div>
    </div>
  </div>


<div class="modal fade"   id="addNewPopup">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <form [formGroup]="addEvent" >

          <div class="modal-header">
          <!--  <div class="event-box l-bg-purple-dark">
            <img data-toggle="tooltip" title="" src="assets/img/dashboard/Basketball.svg" data-original-title="Swimming">
          </div> -->
          <h5 class="modal-title" id="exampleModalLabel" *ngIf="details == 'edit'">{{titleshow}} Details </h5>
          <h5 class="modal-title" id="exampleModalLabel" *ngIf="details == 'duplicate'">Duplicate {{titleshow}} Details </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          <!-- Event edit start -->

          <div class="row" *ngIf="details == 'edit'">
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label>{{titleshow}} Name</label>
                <input type="text" readonly class="form-control" formControlName="event_name" >

              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
               <label>Instructor</label>  :  <strong>{{instructorfullname}}</strong>
               <button *ngIf="!addEvent.value.editshowing"  type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="chnageinst('instructor')">Change Incharge</button>

             </div>
           </div>
           <div class="col-md-6 col-12">
            <div class="form-group">
             <label>Co-Instructor</label>  :  <strong>{{instructorfullnameco}}</strong>
             <button *ngIf="!addEvent.value.editshowing"  type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="chnageinst('coinstructor')">Change Co-Incharge</button>

           </div>
         </div>


         <div class="col-md-6 col-12">
           <div class="form-group">
            <label>Start Time</label>

            <input *ngIf="addEvent.value.editshowing" type="datetime-local" formControlName="start_time"  class="form-control datetimepicker" readonly>

            <input *ngIf="!addEvent.value.editshowing" type="datetime-local" formControlName="start_time"  class="form-control datetimepicker">



          </div>
        </div>

   <div class="col-md-6 col-12">
           <div class="form-group">
            <label>Reference</label>

            <input *ngIf="addEvent.value.editshowing" type="text" formControlName="reference"  class="form-control datetimepicker" readonly>

            <input *ngIf="!addEvent.value.editshowing" type="text" formControlName="reference"  class="form-control datetimepicker">



          </div>
        </div>



        <div class="col-md-6 col-12">
         <div class="form-group">
          <label>End Time</label>
          <input *ngIf="addEvent.value.editshowing" type="datetime-local" formControlName="end_time" class="form-control datetimepicker" readonly>

          <input *ngIf="!addEvent.value.editshowing" type="datetime-local" formControlName="end_time" class="form-control datetimepicker">

        </div>
      </div>

      <div class="col-md-6 col-12">
       <div class="form-group">
        <label>Location</label>

        <input *ngIf="addEvent.value.editshowing" type="text" formControlName="location" class="form-control " readonly>

        <select *ngIf="!addEvent.value.editshowing" class="form-control"  formControlName="location" >
         <option value="{{l.location}}" *ngFor ="let l of locations">{{l.location}}</option>
       </select>

     </div>
   </div>

<div class="col-md-6 col-12">
                            <div class="form-group form-float">
                              <div class="form-line">
                                <label class="form-label">Display Mobile</label>
    
    <div class="form-control">
    
      <input *ngIf="addEvent.value.editshowing" type="checkbox" disabled  class="mrg-right" formControlName="mobile_display" >

 <input type="checkbox" *ngIf="!addEvent.value.editshowing" class="mrg-right" formControlName="mobile_display" >
 



     <label class="form-label" style="padding-right: 10px !important">Display</label>
 
         
                          
                      </div>
                              </div>
                            </div>
                          </div>






   <div class="col-md-6 col-12">
     <div class="form-group">
      <label>View Participants</label>
      <button type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="viewstud('call')">View Participant</button>
    </div>
  </div>

  <div class="col-md-6 col-12" *ngIf="!addEvent.value.editshowing">
   <div class="form-group">
    <label>Add Participant</label>
    <button type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="addstudent()">Add Participant</button>
  </div>
</div>

</div>
<!-- event edit end -->

<!-- event duplicate start -->
<div class="row" *ngIf="details == 'duplicate'">
  <div class="col-md-6 col-12">
    <div class="form-group">
      <label>{{titleshow}} Name</label>
      <select required class="form-control"  formControlName="event_name">
       <!-- <input type="text"  class="form-control" formControlName="event_name" > -->
       <option value="{{l.event_name}}" *ngFor ="let l of eventslist">{{l.event_name}}</option>
     </select>

   </div>
 </div>


 



 <div class="col-md-6 col-12">
  <div class="form-group">
   <label>Instructor</label>  :  <strong>{{instructorfullname}}</strong>
   <button   type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="chnageinst('instructor')">Change Incharge</button>

 </div>
</div>
<div class="col-md-6 col-12">
  <div class="form-group">
   <label>Co-Instructor</label>  :  <strong>{{instructorfullnameco}}</strong>
   <button   type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="chnageinst('coinstructor')">Change Co-Incharge</button>

 </div>
</div>


<div class="col-md-6 col-12">
 <div class="form-group">
  <label>Start Time</label>

  <input  type="datetime-local" formControlName="start_time"  class="form-control datetimepicker" >





</div>
</div>

<div class="col-md-6 col-12">
 <div class="form-group">
  <label>End Time</label>
  <input  type="datetime-local" formControlName="end_time" class="form-control datetimepicker" >


</div>
</div>

<div class="col-md-6 col-12">
 <div class="form-group">
  <label>Location</label>

  <select  class="form-control"  formControlName="location">
   <option value="{{l.location}}" *ngFor ="let l of locations">{{l.location}}</option>
 </select>

</div>
</div>

<div class="col-md-6 col-12">
                            <div class="form-group form-float">
                              <div class="form-line">
                                <label class="form-label">Display Mobile</label>
    
    <div class="form-control">
      <input type="checkbox"  class="mrg-right" formControlName="mobile_display" >
     <label class="form-label" style="padding-right: 10px !important">Display</label>
                       </div>
                              </div>
                            </div>
                          </div>


      <div class="col-md-6 col-12">
       <div class="form-group">
        <label>Add Participant</label>
        <button type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="addstudent()">Add Participant</button>
      </div>
    </div>

  </div>


  <!-- event duplicate end -->

</div>

<div class="modal-footer bg-whitesmoke br">
  <!-- event edit start -->

  <div *ngIf="details == 'edit'">
    <button style="margin-right: 5px;" *ngIf="!addEvent.value.editshowing" type="button" class="btn btn-success" data-dismiss="modal" (click)="updateEventData()">Save changes</button>
    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
  </div>

  <!-- event edit end -->

  <!-- event duplicate start -->

  <div  *ngIf="details == 'duplicate'">
    <button  style="margin-right: 5px;" type="button" class="btn btn-success" data-dismiss="modal" (click)="assignmeeting()">Duplicate</button>
    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
  </div>

  <!-- event duplicate end -->
</div>


</form>
</div>
</div>
</div>

  <div class="modal fade bd-example-modal-lg" id="addNewPopupinst" aria-modal="true" >
    <div class="modal-dialog modal-lg">

      <div class="modal-content">


        <div class="modal-header">
          <h5 class="modal-title" id="myLargeModalLabel">Incharge</h5>

        </div>

        <div class="modal-body">

          <div class="row">
            <div class="col-sm-12">

              <div class="card-body" >
                <div class="table-responsive">
                  <table  class="table table-striped" id="table-2">




                    <thead>
                      <tr>
                        <td colspan="20" class="search-text-tbl">
                          <div class="input-group"><input type="text" class="form-control" placeholder="Search Incharge" (input)="getsearchinstructor($event.target.value)"  (keyup.enter)="get_instructor_list({'search_text':agent_search3.value})" #agent_search3>
                            <div class="input-group-append">
                              <button class="btn btn-primary" (click)="get_instructor_list({'search_text':agent_search3.value})"><i class="fas fa-search"></i></button>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>

                        <th>S.no</th>
                        <th>Instructor Name</th>
                        <th>Photo</th>
                        <th>Email</th>
                        <th>Contact no.</th>
                        <th>NRIC</th>
                        <th>Add</th>
                      </tr>
                    </thead>

                    <tbody >


                      <tr *ngFor="let l of instructorlist;let i=index">

                        <td>{{offset_count3+i+1}}</td>
                        <td>{{l.fname}} {{l.lname}}</td>
                        <td class="student-img">
                          <img alt="image" *ngIf="l.image == ''" src="assets/images/avatar.jpg" width="35">
                          <img alt="image" [src]="l.image"  *ngIf="l.image != ''" width="35">


                        </td>

                        <td>{{l.email}}</td>
                        <td>{{l.phone_no}}</td>
                        <td>{{l.nirp}}</td>

                        <td><input type="radio"    [checked]="l.add" name="add"  (click)="savedatainstructor(l)"></td>
                        <td>

                        </td>
                      </tr>


                    </tbody>
                    <tr *ngIf="recordNotFound3 == true">
                     <td colspan="12">Data not found</td>
                   </tr>

                 </table>
               </div>

               <div class="card-footer text-right" *ngIf="recordNotFound3 == false">
                <nav class="d-inline-block">
                  <ul class="pagination mb-0" *ngIf="paginationData3.info == 'show'">

                    <li class="page-item">
                      <a [class]="paginationData3.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData3.backtab=='show' ? get_instructor_list({'search_text':agent_search3.value,'offset':paginationData3.start}) : false"><i class="fas fa-angle-left"></i></a>
                    </li>
                    <li class="page-item" *ngIf="paginationData3.backtab =='show'">
                      <a class="page-link" href="javascript:void(0)" (click)="get_instructor_list({'search_text':agent_search3.value,'offset':paginationData3.back})"><i class="fas fa-angle-double-left"></i></a>
                    </li>


                    <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData3.data;let i=index">
                      <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? get_instructor_list({'search_text':agent_search3.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                    </li>

                    <li class="page-item" *ngIf="paginationData3.nexttab =='show'">
                      <a class="page-link" href="javascript:void(0)" (click)="get_instructor_list({'search_text':agent_search3.value,'offset':paginationData3.next})"><i class="fas fa-angle-double-right"></i></a>
                    </li>

                    <li class="page-item">
                      <a [class]="paginationData3.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData3.nexttab =='show' ? get_instructor_list({'search_text':agent_search3.value,'offset':paginationData3.end}) : false"><i class="fas fa-angle-right"></i></a>
                    </li>
                  </ul>
                </nav>
              </div>










            </div>
            <!-- </div> -->


          </div>



        </div>
      </div>
      <div class="modal-footer bg-whitesmoke br">

        <button  type="button" class="btn btn-success" (click)="savealldata2()" data-dismiss="modal" >Save changes</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
      </div>



    </div>
  </div>
</div>

  <div class="modal fade bd-example-modal-lg" id="addNewPopupee" aria-modal="true" >
  <div class="modal-dialog modal-lg">

    <div class="modal-content">


      <div class="modal-header">
        <h5 class="modal-title" id="myLargeModalLabel">Enrolled Participants</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">

        <div class="row">
          <div class="col-sm-12">

            <div class="card-body" >
              <div class="table-responsive">
                <table  class="table table-striped" id="table-2">

                  <thead>

                    <tr>

                      <th>S.no</th>
                      <th>Participant Name</th>
                      <th>Email</th>
                      <th>Contact no.</th>


                      <!-- <th>NRIC</th> -->

                      <th  *ngIf="!addEvent.value.editshowing">Action</th>
                    </tr>
                  </thead>

                  <tbody >


                    <tr *ngFor="let l of get_attendees;let i=index">

                      <td>{{i+1}}</td>
                      <td>{{l.name}}</td>
                      <td>{{l.email}}</td>
                      <td>{{l.phone_no}}</td>

                      <!-- <td>{{l.nric}}</td> -->

                      <!-- <td class="student-img"> -->
                         <!--      <img alt="image" *ngIf="l.image == ''" src="assets/images/avatar.jpg" width="35">
                           <img alt="image" [src]="l.image"  *ngIf="l.image != ''" width="35"> -->
                          <!--  <td *ngIf="!addEvent.value.editshowing">
                            <a href="javascript:void(0)" (click)="deleteassignstudent(l)"  class="btn btn-danger btn-action" data-toggle="tooltip" title="Delete">
                              <i class="fas fa-trash"></i>
                            </a>
                          </td> -->

                          <!-- <td>{{l.email}}</td> -->
                          <!-- <td>{{l.phone_no}}</td> -->
                          <!-- <td>{{l.nirp}}</td> -->

                          <td  *ngIf="!addEvent.value.editshowing" ><input type="checkbox"    [(ngModel)]="l.add"  name="add"  (click)="adddeletestudent(l)"></td>

                          <td>

                          </td>
                        </tr>

                        
                      </tbody>

                    </table>
                  </div>


                </div>



              </div>



            </div>
          </div>
          <div class="modal-footer bg-whitesmoke br">
           <button style="margin-right: 5px;" *ngIf="!addEvent.value.editshowing" type="button" class="btn btn-danger" data-dismiss="modal" (click)="removealert('deleteassignstudent','')">Delete</button>
            <!-- <button  type="button" class="btn btn-success" (click)="savealldata() data-dismiss="modal" >Save changes</button> -->
            <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
          </div>



        </div>
      </div>
    </div>



<div class="modal fade bd-example-modal-lg" id="addNewPopupparticipant" aria-modal="true" >
    <div class="modal-dialog modal-lg">

        <div class="modal-content">


            <div class="modal-header">
                <h5 class="modal-title" id="myLargeModalLabel">Add Paticipant</h5>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>

            <div class="modal-body">

                <div class="row">
                    <div class="col-sm-12">

                  <div class="card-body" >
                    <div class="table-responsive">
                      <table  class="table table-striped" id="table-2">




                        <thead>
                          <tr>
                <td colspan="20" class="search-text-tbl">
                            <div class="input-group"><input type="text" class="form-control" placeholder="Search Participant" (input)="getsearchpaticipant($event.target.value)"  (keyup.enter)="get_participant_list({'search_text':participant_search.value})" #participant_search>
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" (click)="get_participant_list({'search_text':participant_search.value})"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                          <tr>
                          
                            <th>S.no</th>
                            <th>Participant Name</th>
                            <!-- <th>Photo</th> -->
                            <th>Email</th>
                            <th>Contact no.</th>
                            <!-- <th>NIRP</th> -->
                            <th>Add</th>
                          </tr>
                        </thead>
                      
                        <tbody >


                          <tr *ngFor="let l of participantlist;let i=index">
                          
                            <td>{{offset_count2+i+1}}</td>
                            <td>{{l.name}}</td>
                            <!-- <td class="student-img">
                              <img alt="image" *ngIf="l.image == ''" src="assets/images/avatar.jpg" width="35">
                               <img alt="image" [src]="l.image"  *ngIf="l.image != ''" width="35">


                            </td> -->

                            <td>{{l.email}}</td>
                            <td>{{l.phone_no}}</td>
                            <td>{{l.nirp}}</td>

    <td><input type="checkbox"    [(ngModel)]="l.add"  name="add"  (click)="savedataparticipant(l)"></td>
        <!-- checked="l.add" -->
                            <td>
                     
                            </td>
                          </tr>

                        
                        </tbody>
                         <tr *ngIf="recordNotFound2 == true">
                               <td colspan="12">Data not found</td>
                            </tr>
                      
                      </table>
                    </div>

 <div class="card-footer text-right" *ngIf="recordNotFound2 == false">
                        <nav class="d-inline-block">
                        <ul class="pagination mb-0" *ngIf="paginationData2.info == 'show'">

                                <li class="page-item">
                                    <a [class]="paginationData2.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData2.backtab=='show' ? get_participant_list({'search_text':participant_search.value,'offset':paginationData2.start}) : false"><i class="fas fa-angle-left"></i></a>
                                </li>
                                <li class="page-item" *ngIf="paginationData2.backtab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="get_participant_list({'search_text':participant_search.value,'offset':paginationData2.back})"><i class="fas fa-angle-double-left"></i></a>
                                </li>


                                <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData2.data;let i=index">
                                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? get_participant_list({'search_text':participant_search.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                                </li>

                                <li class="page-item" *ngIf="paginationData2.nexttab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="get_participant_list({'search_text':participant_search.value,'offset':paginationData2.next})"><i class="fas fa-angle-double-right"></i></a>
                                </li>

                              <li class="page-item">
                                    <a [class]="paginationData2.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData2.nexttab =='show' ? get_participant_list({'search_text':participant_search.value,'offset':paginationData2
                                    .end}) : false"><i class="fas fa-angle-right"></i></a>
                                </li>
                        </ul>
                    </nav>
                </div>










                  </div>
                <!-- </div> -->


            </div>

            

        </div>
    </div>
<div class="modal-footer bg-whitesmoke br">
 
    <button  type="button" class="btn btn-success" (click)="savealldata()" data-dismiss="modal" >Save changes</button>
    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
  </div>


    
</div>
</div>
</div>