
    <div class="section-body">

      <div class="row clearfix">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="card">
           <div class="card-header">
            <div class="card-header-title" *ngIf="page == 'Edit'">
              <h4>Edit Student Profile</h4>
            </div>
            <div class="card-header-title" *ngIf="page != 'Edit'">
              <h4>Add New Student</h4>
            </div>
            <div class="card-header-btn-group">
              <a  [routerLink]="['/studentrecord']"  class="btn btn-success"><i class="fa fa-eye" aria-hidden="true"></i> Students List</a>
            </div>
          </div>
          <div class="card-body">


<aw-wizard >
    <aw-wizard-step   stepTitle="Profile Information">
    <!-- <aw-wizard-step  *ngIf="!information.valid" style="color: red" stepTitle="Profile Information"  > -->

<!-- <div *ngIf="!nextForm"> -->
            <form class="pad-25" id="wizard_with_validation" method="POST"  [formGroup]="information">
              <h4>Profile Information</h4>
              <!-- <fieldset> -->




                <div class="row">
                  <div class="col-md-6 col-12">
                    <div class="form-group form-float">
                      <div class="form-line">
                        <label class="form-label">Name*</label>
                        <input type="text" formControlName="fname" class="form-control" required>
                         <div class="error-message message-small" *ngIf="!information.get('fname').valid && information.get('fname').touched">
                      Please enter your  Name
                    </div>
                      </div>
                    </div>
                  </div>
                 <!--  <div class="col-md-6 col-12">
                    <div class="form-group form-float">
                      <div class="form-line">
                        <label class="form-label">Last Name*</label>
                        <input type="text" formControlName="lname" class="form-control" required>
          <div class="error-message message-small" *ngIf="!information.get('lname').valid && information.get('lname').touched">
                      Please enter your Last Name
                    </div>

                      </div>
                    </div>
                  </div> -->
                  <div class="col-md-6 col-12">
                    <div class="form-group form-float">
                      <div class="form-line">
                        <label class="form-label">Email</label>
                        <input type="email" formControlName="email" class="form-control" >
                 <!--        <div class="error-message message-small" *ngIf="!information.get('email').valid && information.get('email').touched" >
                       
                      Please enter valid email
</div>
 -->





                      </div>
                    </div>
                  </div>

    <div class="col-md-6 col-12">
                    <div class="form-group">
                      <div class="form-line">
                        <label class="form-label">Gender*</label>
                        <select required class="form-control"  formControlName="gender">
                          <option value="">Select</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>

                        </select>
                        <div class="error-message message-small" *ngIf="!information.get('gender').valid && information.get('gender').touched">
                      Please select gender
                    </div>
                      </div>
                    </div>
                  </div>












 <div class="col-md-6 col-12">
                    <div class="form-group form-float">
                      <div class="form-line">
                        <label class="form-label">Country Code*</label>
                        <input type="number" formControlName="country_code" class="form-control" required>
                        <div class="error-message message-small" *ngIf="!information.get('country_code').valid && information.get('country_code').touched">
                      Please enter Country Code 
                    </div>
                      </div>
                    </div>
                  </div>

<div class="col-md-6 col-12">
                    <div class="form-group form-float">
                      <div class="form-line">
                        <label class="form-label">Mobile*</label>
                        <input type="number" formControlName="phone_no" class="form-control" required>
                        <div class="error-message message-small" *ngIf="!information.get('phone_no').valid && information.get('phone_no').touched">
                      Please enter Mobile number
                    </div>
                      </div>
                    </div>
                  </div>
  <div class="col-md-6 col-12">
                    <div class="form-group form-float">
                      <div class="form-line">
                        <label class="form-label">Country*</label>
                         <input type="text" formControlName="country" class="form-control" required>
                          <div class="error-message message-small" *ngIf="!information.get('country').valid && information.get('country').touched">
                      Please enter country
                    </div>
                      </div>
                    </div>
                  </div>  


 <div class="col-md-6 col-12">
                    <div class="form-group form-float">
                      <div class="form-line">
                        <label class="form-label">NRIC</label>
                        <input type="text" formControlName="nirp" class="form-control" >
                        <!-- <div class="error-message message-small" *ngIf="!information.get('nirp').valid && information.get('nirp').touched">
                      Please enter NRIC
                    </div> -->
                      </div>
                    </div>
                  </div>


                  
                <!--   <div class="col-md-6 col-12">
                    <div class="form-group form-float">
                      <div class="form-line">
                        <label class="form-label">Parent Name</label>
                        <input type="text" formControlName="parent_name" class="form-control">
                      
                      </div>
                    </div>
                  </div> -->
                  <!-- <div class="col-md-6 col-12">
                    <div class="form-group form-float">
                      <div class="form-line">
                        <label class="form-label">Parent Phone</label>
                        <input type="number" formControlName="parent_phone" class="form-control" >
                      
                      </div>
                    </div>
                  </div> -->
                  <div class="col-md-6 col-12">
                    <div class="form-group form-float">
                      <div class="form-line">
                        <label class="form-label">Address</label>
                        <textarea  formControlName="address" cols="30" rows="3" class="form-control  no-resize"
                        ></textarea>
                        <!--  <div class="error-message message-small" *ngIf="!information.get('address').valid && information.get('address').touched">
                      Please enter address
                    </div> -->
                      </div>
                    </div>
                  </div>
<!-- <div class="col-md-6 col-12">
                    <div class="form-group form-float">
                      <div class="form-line">
                        <label class="form-label">City</label>
                       <input type="text" formControlName="city" class="form-control" >
                      
                      </div>
                    </div>
                  </div> -->

<!-- 
 <div class="col-md-6 col-12">
                    <div class="form-group form-float">
                      <div class="form-line">
                        <label class="form-label">State</label>
                          <input type="text" formControlName="state" class="form-control" >
                      
                      </div>
                    </div>
                  </div> -->
               



                   <!-- <div class="col-md-6 col-12">
                    <div class="form-group form-float">
                      <div class="form-line">
                        <label class="form-label">Postal Code*</label>
                          <input type="number" formControlName="pincode" class="form-control" required>
                           <div class="error-message message-small" *ngIf="!information.get('pincode').valid && information.get('pincode').touched">
                      Please enter postal code
                    </div>
                      </div>
                    </div>
                  </div> -->
               <!--    <div class="col-md-6 col-12">
                    <div class="form-group">
                      <div class="form-line">
                        <label class="form-label">Interested Events*</label>
                        <select required class="form-control select2" multiple="" formControlName="events"  [(ngModel)]="eventbind">
                          <option *ngFor ="let l of eventslist" [ngValue]="l.event_name">{{l.event_name}}</option>
                        </select>
                        <div class="error-message message-small" *ngIf="!information.get('events').valid && information.get('events').touched">
                      Please select events
                    </div>
                      </div>
                    </div>
                  </div> -->


                  <div class="col-md-6 col-12">
                    <div class="form-group form-float">
                      <div class="form-line">
                        <label class="form-label">Date Of Birth</label>
                        <input min="18" type="date" formControlName="age" class="form-control" >
      <!--   <div class="error-message message-small" *ngIf="!information.get('age').valid && information.get('age').touched">
                      Please enter Date Of Birth
                    </div> -->

                      </div>
                      <!-- <div class="help-info">The warning step will show up if age is less than 18</div> -->
                    </div>
                  </div>

                  <div class="col-md-6 col-12">
                    <div class="registerdata form-group">
                      <div class="form-line">
                        <label class="form-label">Upload Photo</label>
                        <div class="row">
                         <div class="col-md-6">
                           <!-- <input required type="file" name="uploaded-img" id="uploaded-img" (change)="changeListener($event)" > -->

<input required type="file" name="uploaded-img" id="uploaded-img" (change)="showPreviewImage($event)">


                         </div>
                         <div class="col-md-6">
                          <!-- <img  [src]="localUrl" *ngIf="localUrl" class="img-thumbnail imguploadthumb"> -->
 <img src="{{localUrl}}"  onerror="this.src='assets/img/placeholder-img.png'"  class="img-thumbnail imguploadthumb">
                         
                          <!-- <img id="uploaded-img" *ngIf="!localUrl" src="assets/img/placeholder-img.png" class="img-thumbnail imguploadthumb"> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>










              </div>
<button type="button" class="btn btn-primary  btn-lg" style="float: right !important" (click)="moveToNext() ">Next</button>
            </form>



   </aw-wizard-step>
   <aw-wizard-step stepTitle="Account Information" >

   <form [formGroup]="step2">
            <h3>Account Information</h3>
            <!-- <fieldset> -->



              <div class="row" >

               <div class="col-md-6 col-12">
                <div class="form-group form-float">
                  <div class="form-line">
                    <label class="form-label">Username*</label>

   <!-- <input type="text" *ngIf="page != 'Edit'" class="form-control" formControlName="username"> -->
                    <input type="text" class="form-control" formControlName="username" readonly>
                     <!-- <div class="error-message message-small" *ngIf="!step2.get('username').valid && step2.get('username').touched">
                      Please enter username
                    </div> -->
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-12">
                <div class="form-group form-float">
                  <div class="form-line">
                    <label class="form-label">Password</label>
                    <input type="password" class="form-control" formControlName="password" id="password"  >
                    <!--  <div class="error-message message-small" *ngIf="!step2.get('password').valid && step2.get('password').touched">
                      Please enter password
                    </div> -->
                  </div>
                </div>
              </div>
         <!--      <div class="col-md-6 col-12">
                <div class="form-group form-float">
                  <div class="form-line">
                    <label class="form-label">Confirm Password*</label>
                    <input type="password" class="form-control" formControlName="confirm"  (change)="onPasswordChange()" required>
                    <div *ngIf="confirm_password.hasError('mismatch') && step2.get('confirm').touched" class="error-message message-small">Password do not match.</div> 
                    <div class="error-message message-small" *ngIf="!confirm_password.hasError('mismatch') && !step2.get('confirm').valid && step2.get('confirm').touched">
                      Please enter confirm password
                    </div>
                  </div>
                </div>
              </div> -->

            
            </div>
<button type="button" class="btn btn-primary  btn-lg" style="float: right !important;margin-left: 10px !important;" (click)="moveToNext2()">Submit</button>
<button type="button" class="btn btn-primary  btn-lg" style="float: right !important;margin-left: 10px !important;"  awPreviousStep>Previous</button>


</form>



  </aw-wizard-step>
   <!-- <aw-wizard-step stepTitle="Terms &amp; Conditions - Finish">
    <form [formGroup]="step3">
            <h3>Terms &amp; Conditions - Finish</h3>
  
               <input type="checkbox"  id="acceptTerms-2" formControlName="agree"  required>
            <label for="agree" style="margin: 1% !important">I agree with the Terms and Conditions.</label>


 <div class="error-message message-small" *ngIf="!step3.get('agree').valid && step3.get('agree').touched">
  Please Agree the with the Terms and Conditions  
                
                    </div>
            
<button type="button" class="btn btn-primary  btn-lg" style="float: right !important;margin-left: 10px !important;margin-top: 5% !important" (click)="finishFunction()">Submit</button>
<button type="button" class="btn btn-primary  btn-lg" style="float: right !important;margin-left: 10px !important;margin-top: 5% !important" awPreviousStep>Go to previous</button>

            </form>
           

    
   </aw-wizard-step> -->
</aw-wizard>
            <!-- </fieldset> -->






          <!-- </fieldset> -->
          <!-- <h3>Terms &amp; Conditions - Finish</h3> -->
          <!-- <fieldset> -->
           <!--  <input id="acceptTerms-2" formControlName="acceptTerms" type="checkbox" required>
            <label for="acceptTerms-2">I agree with the Terms and Conditions.</label> -->
<!-- 
            <div class="row">
              <div class="col-md-3">
              </div>

        <div class="col-md-6">
            <div class="form-group">
          
                <button type="submit" class="btn btn-primary  btn-lg btn-block">Submit</button>
            </div>
        </div> 
<div class="col-md-3">
</div>
         </div> -->





          <!-- </fieldset> -->
        <!-- </form> -->
      </div>
    </div>
  </div>
</div>
</div>
<!-- </section> -->






