import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule,FormsModule }  from '@angular/forms';
import { AppComponent } from './app.component';

import { LoginComponent } from './login/login.component';
import { Routes, RouterModule } from '@angular/router';
import { RegisterComponent } from './register/register.component';
import { ServerService } from './services/server.service';
import { HttpClientModule } from '@angular/common/http';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StudentrecordComponent } from './studentrecord/studentrecord.component';
import { AddnewstudentComponent } from './addnewstudent/addnewstudent.component';
import { RecordinstructorComponent } from './recordinstructor/recordinstructor.component';
import { RecordeventsComponent } from './recordevents/recordevents.component';
import { CalendarComponent } from './calendar/calendar.component';
import { AddneweventComponent,SafePipe } from './addnewevent/addnewevent.component';
// import { SafePipe } from './addnewevent/SafePipe.com';
import { AgmCoreModule } from '@agm/core';
import { ColorPickerModule } from 'ngx-color-picker';
import { AssignneweventComponent } from './assignnewevent/assignnewevent.component';
import { StudentaddformComponent } from './assignnewevent/studentaddform.component';
import { LogoutComponent } from './login/logout.component';
import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
// import { ArchwizardModule } from 'ng2-archwizard';
import { ArchwizardModule } from 'angular-archwizard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EditorModule } from '@progress/kendo-angular-editor';
import { AddnewinstructorComponent } from './addnewinstructor/addnewinstructor.component';
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; 
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';// a plugin
import interactionPlugin from '@fullcalendar/interaction';
import { ForgotpwdComponent } from './forgotpwd/forgotpwd.component';
import { ReportsComponent } from './reports/reports.component';
import { AdminattendanceComponent } from './adminattendance/adminattendance.component';
import { AdminlistComponent } from './adminlist/adminlist.component';
import { AddadminComponent } from './addadmin/addadmin.component'
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { CoursereportComponent } from './coursereport/coursereport.component';
import { LocationComponent } from './location/location.component';
import { AddlessonComponent } from './addlesson/addlesson.component';
import { MappageComponent } from './mappage/mappage.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { MeetingrecordComponent } from './meetingrecord/meetingrecord.component';
import { ParticipantrecordComponent } from './participantrecord/participantrecord.component';
import { AddneewparticipantComponent } from './addneewparticipant/addneewparticipant.component';
import { InchargeComponent } from './incharge/incharge.component';
import { MeetingreportComponent } from './meetingreport/meetingreport.component';

import { RecordcourseComponent } from './recordcourse/recordcourse.component';
import { StudentrecordcourseComponent } from './studentrecordcourse/studentrecordcourse.component';
import { RecordinstructorcourseComponent } from './recordinstructorcourse/recordinstructorcourse.component';
import { ReportscourseComponent } from './reportscourse/reportscourse.component';
import { AdminattendancecourseComponent } from './adminattendancecourse/adminattendancecourse.component';
import { AddnewparticipanteventComponent } from './addnewparticipantevent/addnewparticipantevent.component';
import { ParticipantrecordeventComponent } from './participantrecordevent/participantrecordevent.component';
import { ExportsmsComponent } from './exportsms/exportsms.component';
import { EventtemplateComponent } from './eventtemplate/eventtemplate.component';
import { MeetingattendanceComponent } from './meetingattendance/meetingattendance.component';
import { AddadminccComponent } from './addadmincc/addadmincc.component';
import { CclistComponent } from './cclist/cclist.component';
import { CarParkDashboardComponent } from './car-park/car-park-dashboard/car-park-dashboard.component';
import { CpLogin } from './car-park/login/cp-login.component';
import { ManageCarLotsComponent } from './manage-car-lots/manage-car-lots.component';
import { QRCodeModule } from 'angular2-qrcode';
import { CarParkLogsComponent } from './car-park-logs/car-park-logs.component';
import { ActiveLogsComponent } from './active-logs/active-logs.component';
import { AddusersComponent } from './addusers/addusers.component';

const appRoutes: Routes = [
    {
        path :'login', component:LoginComponent
    },
 {
        path :'register', component:RegisterComponent
    },
    {
        path :'', component:DashboardComponent
    },
    {
        path :'dashboard', component:DashboardComponent
    },
    {
        path :'studentrecord', component:StudentrecordComponent
    }, {
        path :'studentrecordcourse', component:StudentrecordcourseComponent
    },
     {
        path :'addnewstudent', component: AddnewstudentComponent
    },
     {
        path :'recordevents', component: RecordeventsComponent
    }, 
    {
        path :'recordcourses', component: RecordcourseComponent
    },
    
     {
        path :'recordinchargeevent', component: RecordinstructorComponent
    },
 {
        path :'recordinstructorcourse', component: RecordinstructorcourseComponent
    },
    {
        path :'calendar', component: CalendarComponent
    },
    {
        path :'addnewevent', component: AddneweventComponent
    },
    {
        path :'assignnewevent', component: AssignneweventComponent
    },
    {
        path :'logout', component: LogoutComponent
    },
     {
        path :'addnew', component: AddnewinstructorComponent
    },
     {
        path :'forgotpwd', component: ForgotpwdComponent
    },
   {
        path :'reports', component: ReportsComponent
    },
 {
        path :'reportcourse', component: ReportscourseComponent
    },
     {
        path :'rclist', component: AdminlistComponent
    },
    {
        path :'adminattendance', component: AdminattendanceComponent,
 
    },
   {
        path :'adminattendancecourse', component: AdminattendancecourseComponent,
 
    },
       {
        path :'addadmin', component: AddadminComponent
    },
    {
        path :'coursereport', component: CoursereportComponent
    },
      {
        path :'location', component: LocationComponent
    },
         {
        path :'addlesson', component: AddlessonComponent
    },          
  
    {
        path :'mappage', component: MappageComponent
    },
     {
        path :'feedback', component: FeedbackComponent
    },       
     {
        path :'meetingrecord', component: MeetingrecordComponent
    },
    {
        path :'participant', component: ParticipantrecordComponent
    },
    {
        path :'addneewparticipant', component: AddneewparticipantComponent
    },
     {
        path :'incharge', component: InchargeComponent
    },
    {
        path :'addnewparticipantevent', component: AddnewparticipanteventComponent
    },       
     {
        path :'participantrecordevent', component: ParticipantrecordeventComponent
    },
    {
        path :'meetingreport', component: MeetingreportComponent
    },
      {
        path :'exporte-sms', component: ExportsmsComponent
    },
       {
        path :'eventtemplate', component: EventtemplateComponent
    }, 
         {
        path :'meetingattendance', component: MeetingattendanceComponent
    },   {
        path :'addadmincc', component: AddadminccComponent
    }, 
 {
        path :'cclist', component: CclistComponent
    },
     {
        path :'Carslot-dashboard', component: CarParkDashboardComponent
    },  {
        path :'Cp-Login', component: CpLogin
    },
    {
        path :'manage-car-lots', component: ManageCarLotsComponent
    },
 {
        path :'parking-logs', component: CarParkLogsComponent
    },
    {
        path :'active-logs', component: ActiveLogsComponent
    },
    {
        path :'addusers',component: AddusersComponent
    }
]
// FullCalendarModule.registerPlugins([ // register FullCalendar plugins
//   dayGridPlugin,
//   timeGridPlugin,
//   interactionPlugin
// ]);

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin
])
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    DashboardComponent,
    StudentrecordComponent,
    AddnewstudentComponent,
    RecordinstructorComponent,
    RecordeventsComponent,
    CalendarComponent,
    AddneweventComponent,
    AssignneweventComponent,
    LogoutComponent,
    MenuComponent,
    FooterComponent,
    AddnewinstructorComponent,
    ForgotpwdComponent,
    StudentaddformComponent,
        SafePipe,
        ReportsComponent,
        AdminattendanceComponent,
        AdminlistComponent,
        AddadminComponent,
        CoursereportComponent,
        LocationComponent,
        AddlessonComponent,
        MappageComponent,
        FeedbackComponent,
        MeetingrecordComponent,
        ParticipantrecordComponent,
        AddneewparticipantComponent,
        InchargeComponent,
        MeetingreportComponent,
        RecordcourseComponent,
        StudentrecordcourseComponent,
        RecordinstructorcourseComponent,
        ReportscourseComponent,
        AdminattendancecourseComponent,
        AddnewparticipanteventComponent,
        ParticipantrecordeventComponent,
        ExportsmsComponent,
        EventtemplateComponent,
        MeetingattendanceComponent,
        AddadminccComponent,
        CclistComponent,
        CarParkDashboardComponent,
        CpLogin,
        ManageCarLotsComponent,
        CarParkLogsComponent,
        ActiveLogsComponent,
        AddusersComponent

  ],
  imports: [
    BrowserModule,
     AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCDSuxG-slLmOw9jhTDyaEdDoaW1TfoSOQ'
    }),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    ArchwizardModule,
EditorModule,
 FullCalendarModule ,
   NgxQRCodeModule,
   ColorPickerModule,
    // NgApexchartsModule,
    RouterModule.forRoot(appRoutes,{ useHash: true }),
    QRCodeModule,

    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
