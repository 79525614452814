<nav class="navbar navbar-expand-lg main-navbar">
	<div class="form-inline mr-auto">
		<ul class="navbar-nav mr-3">
			<li><a href="#" (click)="toggleSidebarMenu()" class="nav-link nav-link-lg collapse-btn"> 
				<i data-feather="align-justify"></i></a></li>
				<li><a href="javascript:void(0)" class="nav-link nav-link-lg fullscreen-btn">
				
					<i data-feather="maximize"></i>
				</a>
			</li>

<li *ngIf='type == "2" && this.admin_type!="ParkingSlot"'>
 <div class="form-group alignrgt">
 	 <h6 class="white-clr alignrgt">Select CC</h6>
                      <div class="form-line" style="background-color: #f2f2f2;border-radius: 5px" (click)="chnageadmincc()">
  
                    <input type="text"  [(ngModel)]="adminusercc" class="form-control" >             <i data-feather="chevron-down"  ></i>
                      </div>
                    </div>
</li>


<li *ngIf="(type == '1' || type == '2')  && this.admin_type!='ParkingSlot'">
 <div class="form-group alignrgt">
 	<h6 class="white-clr alignrgt">Select RC</h6>
                      <div class="form-line" style="background-color: #f2f2f2;border-radius: 5px" (click)="chnageadmin()">
  
                    <input type="text"  [(ngModel)]="adminuser" class="form-control" >             <i data-feather="chevron-down"  ></i>
                      </div>
                    </div>
</li>


		</ul>
	</div>
	<div class="user-role pt-2"><h6 class="white-clr">Welcome {{logincompany}} !</h6></div>
	<ul class="navbar-nav navbar-right">
		<li class="dropdown"><a href="javascript:void(0)" data-toggle="dropdown"
			class="nav-link dropdown-toggle nav-link-lg nav-link-user"> 
			 <img  *ngIf='this.admin_type !="ParkingSlot"' alt="image" src="assets/img/user.png"	class="user-img-radious-style">
			 <img   *ngIf='this.admin_type =="ParkingSlot"' alt="image" src="assets/img/logo4.png"	>
			 <span class="d-sm-none d-lg-inline-block"></span></a>
			<div class="dropdown-menu dropdown-menu-right pullDown">
				<div class="dropdown-title">Hello <b>{{logincompany}}!</b></div>
				<div class="dropdown-divider"></div>
				<a [routerLink]="['/logout']" class="dropdown-item has-icon text-danger"> <i class="fas fa-sign-out-alt"></i>
					Logout
				</a>
			</div>
		</li>
	</ul>
</nav>


<!-- rc start -->
<div class="main-sidebar sidebar-style-2" style="overflow: auto !important;" *ngIf='type == "0"'>
	<aside id="sidebar-wrapper">
		<div class="sidebar-brand">
			 <a href="index.html"> <img alt="image" src="assets/img/logo.png" class="header-logo" />
			</a> 
			
			<a href="index.html"> <img alt="image" src="assets/img/favicon.png" class="header-mini" />
			</a>
		</div>
		<div class="sidebar-user">
			<div class="sidebar-user-picture">
				<img alt="image" src="assets/img/userbig.png">
			</div>
			<div class="sidebar-user-details">
				<div class="user-name">{{loginUser}}</div>
				<div class="user-role" >RC</div>



			</div>
		</div>
		<ul class="sidebar-menu" >
			<li class="menu-header">Main</li>
			<li routerLinkActive="active">
				<a href="javascript:void(0)"  [routerLink]="['/dashboard']" class="nav-link "><i data-feather="monitor" ></i><span>Dashboard</span></a>
			</li>


<li class="menu-header" >Event Management</li>
  <li class="dropdown" >
         <a href="javascript:void(0)" class="nav-link has-dropdown"><i data-feather="life-buoy"></i><span>Events</span></a>
         <ul class="dropdown-menu">

	<li routerLinkActive="active"><a class="nav-link " [routerLink]="['/participantrecordevent']"><i data-feather="users"></i><span>Participant Record</span></a></li>
	<li routerLinkActive="active"><a class="nav-link " [routerLink]="['/recordinchargeevent']"><i data-feather="user-check"></i><span>Incharge Record</span></a></li>
	<li routerLinkActive="active"><a class="nav-link " [routerLink]="['/recordevents']"><i data-feather="activity"></i><span>Event Record</span></a></li>

	<li routerLinkActive="active"><a class="nav-link " [routerLink]="['/reports']"><i data-feather="trending-up"></i><span>Event Report</span></a></li>
  <li routerLinkActive="active"><a class="nav-link "  [routerLink]="['/adminattendance']"><i data-feather="book-open"></i><span>Event Attendance</span></a></li>
</ul>
</li>

<li class="menu-header" >Course Management</li>
  <li class="dropdown" >
         <a href="javascript:void(0)" class="nav-link has-dropdown"><i data-feather="life-buoy"></i><span>Courses</span></a>
         <ul class="dropdown-menu">

	<li routerLinkActive="active"><a class="nav-link" [routerLink]="['/studentrecordcourse']"><i data-feather="users" ></i><span>Student Record</span></a></li>
	<li routerLinkActive="active"><a class="nav-link " [routerLink]="['/recordinstructorcourse']"><i data-feather="user-check"></i><span>Instructor Record</span></a></li>
	<li routerLinkActive="active" ><a class="nav-link" [routerLink]="['/recordcourses']"><i data-feather="activity"></i><span>Course Record</span></a></li>

	<li routerLinkActive="active"><a class="nav-link " [routerLink]="['/reportcourse']" (click)="goreports('course')"><i data-feather="trending-up"></i><span>Course Report</span></a></li>
  <li routerLinkActive="active"><a class="nav-link "  [routerLink]="['/adminattendancecourse']"><i data-feather="book-open"></i><span>Course Attendance</span></a></li>
</ul>
</li>

<li class="menu-header" >Meeting Management</li>
<li class="dropdown" >
<a href="javascript:void(0)" class="nav-link has-dropdown"><i data-feather="crosshair"></i><span>Meetings</span></a>

 <ul class="dropdown-menu">
 	<li routerLinkActive="active"><a class="nav-link" [routerLink]="['/participant']"><i data-feather="users"></i><span>Participant Record</span></a></li>
			<li routerLinkActive="active"><a class="nav-link" [routerLink]="['/incharge']"><i data-feather="user-check"></i><span>Incharge Record</span></a></li>
<li routerLinkActive="active"  class="dropdown"><a class="nav-link" [routerLink]="['/meetingrecord']"><i data-feather="airplay"></i><span>Meeting Record</span></a>
</li>
	<li routerLinkActive="active"><a class="nav-link" [routerLink]="['/meetingreport']"><i data-feather="trending-up"></i><span>Meeting Report</span></a></li>
	<li routerLinkActive="active"><a class="nav-link" [routerLink]="['/exporte-sms']"><i data-feather="trending-up"></i><span>Export e-SMS</span></a></li>
	<li routerLinkActive="active"><a class="nav-link" [routerLink]="['/meetingattendance']"><i data-feather="book-open"></i><span>Meeting Attendance</span></a></li>

 </ul>
</li>


				<li class="menu-header">View</li>

				<li routerLinkActive="active"><a class="nav-link" [routerLink]="['/calendar']" ><i data-feather="calendar"></i><span>Calendar</span></a></li>


			<li class="menu-header">Contact</li>


<li routerLinkActive="active"><a class="nav-link"  [routerLink]="['/mappage']"><i data-feather="at-sign"></i><span>Contact Us</span></a></li>

<li routerLinkActive="active"><a class="nav-link"  [routerLink]="['/feedback']"><i data-feather="help-circle"></i><span>Feedback Us</span></a></li>


				<li class="menu-header">Manage</li>

			
				<li><a class="nav-link" [routerLink]="['/logout']"><i data-feather="lock"></i><span>logout</span></a></li>

<div class="sidebar-brand pointer footer-bottom-logo">
      <a href="http://itfs.org.sg/" target="_blank"> <img alt="image" src="assets/images/itfs-logo.svg" class="header-logo" />
      </a>
      <a href="http://itfs.org.sg/" target="_blank">
       <img alt="image" src="assets/images/itfs-favicon.png" class="header-mini" />
      </a>
    </div>




			</ul>
</aside>
</div>

<!-- rc end -->


<!-- cc start -->

<div class="main-sidebar sidebar-style-2" style="overflow: auto !important;"  *ngIf='type == "1"'>
	<aside id="sidebar-wrapper">
		<div class="sidebar-brand">
			<a href="index.html"> <img alt="image" src="assets/img/logo.png" class="header-logo" />
			</a>
			<a href="index.html"> <img alt="image" src="assets/img/favicon.png" class="header-mini" />
			</a>
		</div>
		<div class="sidebar-user">
			<div class="sidebar-user-picture">
				<img alt="image" src="assets/img/userbig.png">
			</div>
			<div class="sidebar-user-details">
				<div class="user-name">{{loginUser}}</div>
			
				<div class="user-role" >CC</div>

			</div>
		</div>


		<ul class="sidebar-menu">

<li class="menu-header">Main</li>
			<li routerLinkActive="active">
				<a href="javascript:void(0)"  [routerLink]="['/dashboard']" class="nav-link "><i data-feather="monitor" ></i><span>Dashboard</span></a>
			</li>


<li class="menu-header">Admin Management</li>



			<li routerLinkActive="active"><a class="nav-link" [routerLink]="['/rclist']" ><i data-feather="user-check"></i><span>RC List</span></a></li>
		



<li class="menu-header" >Event Management</li>
  <li class="dropdown" >
         <a href="javascript:void(0)" class="nav-link has-dropdown"><i data-feather="life-buoy"></i><span>Events</span></a>
         <ul class="dropdown-menu">

	<li routerLinkActive="active"><a class="nav-link " [routerLink]="['/participantrecordevent']"><i data-feather="users"></i><span>Participant Record</span></a></li>
	<li routerLinkActive="active"><a class="nav-link " [routerLink]="['/recordinchargeevent']"><i data-feather="user-check"></i><span>Incharge Record</span></a></li>
	<li routerLinkActive="active"><a class="nav-link " [routerLink]="['/recordevents']"><i data-feather="activity"></i><span>Event Record</span></a></li>

	<li routerLinkActive="active"><a class="nav-link " [routerLink]="['/reports']"><i data-feather="trending-up"></i><span>Event Report</span></a></li>
  <li routerLinkActive="active"><a class="nav-link "  [routerLink]="['/adminattendance']"><i data-feather="book-open"></i><span>Event Attendance</span></a></li>
</ul>
</li>

<li class="menu-header" >Course Management</li>
  <li class="dropdown" >
         <a href="javascript:void(0)" class="nav-link has-dropdown"><i data-feather="life-buoy"></i><span>Courses</span></a>
         <ul class="dropdown-menu">

	<li routerLinkActive="active"><a class="nav-link" [routerLink]="['/studentrecordcourse']"><i data-feather="users" ></i><span>Student Record</span></a></li>
	<li routerLinkActive="active"><a class="nav-link " [routerLink]="['/recordinstructorcourse']"><i data-feather="user-check"></i><span>Instructor Record</span></a></li>
	<li routerLinkActive="active" ><a class="nav-link" [routerLink]="['/recordcourses']"><i data-feather="activity"></i><span>Course Record</span></a></li>

	<li routerLinkActive="active"><a class="nav-link " [routerLink]="['/reportcourse']" (click)="goreports('course')"><i data-feather="trending-up"></i><span>Course Report</span></a></li>
  <li routerLinkActive="active"><a class="nav-link "  [routerLink]="['/adminattendancecourse']"><i data-feather="book-open"></i><span>Course Attendance</span></a></li>
</ul>
</li>




<li class="menu-header" >Meeting Management</li>
<li class="dropdown" >
<a href="javascript:void(0)" class="nav-link has-dropdown"><i data-feather="crosshair"></i><span>Meetings</span></a>
 <ul class="dropdown-menu">
 	<li routerLinkActive="active"><a class="nav-link" [routerLink]="['/participant']"><i data-feather="users"></i><span>Participant Record</span></a></li>
			<li routerLinkActive="active"><a class="nav-link" [routerLink]="['/incharge']"><i data-feather="user-check"></i><span>Incharge Record</span></a></li>
<li routerLinkActive="active"  class="dropdown"><a class="nav-link" [routerLink]="['/meetingrecord']"><i data-feather="airplay"></i><span>Meeting Record</span></a>
</li>
	<li routerLinkActive="active"><a class="nav-link" [routerLink]="['/meetingreport']"><i data-feather="trending-up"></i><span>Meeting Report</span></a></li>
<li routerLinkActive="active"><a class="nav-link" [routerLink]="['/exporte-sms']"><i data-feather="trending-up"></i><span>Export e-SMS</span></a></li>
<li routerLinkActive="active"><a class="nav-link" [routerLink]="['/meetingattendance']"><i data-feather="book-open"></i><span>Meeting Attendance</span></a></li>

 </ul>
</li>


	<li class="menu-header">View</li>
	<li routerLinkActive="active"><a class="nav-link" [routerLink]="['/calendar']" ><i data-feather="calendar"></i><span>Calendar</span></a></li>


		<li class="menu-header">Contact</li>
		<li routerLinkActive="active"><a class="nav-link"  [routerLink]="['/mappage']"><i data-feather="at-sign"></i><span>Contact Us</span></a></li>
		<li routerLinkActive="active"><a class="nav-link"  [routerLink]="['/feedback']"><i data-feather="help-circle"></i><span>Feedback Us</span></a></li>


<li class="menu-header">Manage</li>
<li><a class="nav-link" [routerLink]="['/logout']"><i data-feather="lock"></i><span>logout</span></a></li>

  <div class="sidebar-brand pointer footer-bottom-logo">
      <a href="http://itfs.org.sg/" target="_blank"> <img alt="image" src="assets/images/itfs-logo.svg" class="header-logo" />
      </a>
      <a href="http://itfs.org.sg/" target="_blank">
       <img alt="image" src="assets/images/itfs-favicon.png" class="header-mini" />
      </a>
    </div>




		</ul>

	</aside>
</div>

<!-- cc end -->


<!-- super super admin start-->
<div class="main-sidebar sidebar-style-2" style="overflow: auto !important;"  *ngIf='type == "2" && this.admin_type !="ParkingSlot"'>
	<aside id="sidebar-wrapper">
		<div class="sidebar-brand">
			<a href="index.html"> <img alt="image" src="assets/img/logo.png" class="header-logo" />
			</a>
			<a href="index.html"> <img alt="image" src="assets/img/favicon.png" class="header-mini" />
			</a>
		</div>
		<div class="sidebar-user">
			<div class="sidebar-user-picture">
				<img alt="image" src="assets/img/userbig.png">
			</div>
			<div class="sidebar-user-details">
				<div class="user-name">{{loginUser}}</div>
			
				<div class="user-role" >Super Admin</div>

			</div>
		</div>


		<ul class="sidebar-menu">

<li class="menu-header">Main</li>
			<li routerLinkActive="active">
				<a href="javascript:void(0)"  [routerLink]="['/dashboard']" class="nav-link "><i data-feather="monitor" ></i><span>Dashboard</span></a>
			</li>


<li class="menu-header">Admin Management</li>

			<li routerLinkActive="active"><a class="nav-link" [routerLink]="['/cclist']" ><i data-feather="user-check"></i><span>CC List</span></a></li>

			<li routerLinkActive="active"><a class="nav-link" [routerLink]="['/rclist']" ><i data-feather="user-check"></i><span>RC List</span></a></li>
		



<li class="menu-header" >Event Management</li>
  <li class="dropdown" >
         <a href="javascript:void(0)" class="nav-link has-dropdown"><i data-feather="life-buoy"></i><span>Events</span></a>
         <ul class="dropdown-menu">

	<li routerLinkActive="active"><a class="nav-link " [routerLink]="['/participantrecordevent']"><i data-feather="users"></i><span>Participant Record</span></a></li>
	<li routerLinkActive="active"><a class="nav-link " [routerLink]="['/recordinchargeevent']"><i data-feather="user-check"></i><span>Incharge Record</span></a></li>
	<li routerLinkActive="active"><a class="nav-link " [routerLink]="['/recordevents']"><i data-feather="activity"></i><span>Event Record</span></a></li>

	<li routerLinkActive="active"><a class="nav-link " [routerLink]="['/reports']"><i data-feather="trending-up"></i><span>Event Report</span></a></li>
  <li routerLinkActive="active"><a class="nav-link "  [routerLink]="['/adminattendance']"><i data-feather="book-open"></i><span>Event Attendance</span></a></li>
</ul>
</li>

<li class="menu-header" >Course Management</li>
  <li class="dropdown" >
         <a href="javascript:void(0)" class="nav-link has-dropdown"><i data-feather="life-buoy"></i><span>Courses</span></a>
         <ul class="dropdown-menu">

	<li routerLinkActive="active"><a class="nav-link" [routerLink]="['/studentrecordcourse']"><i data-feather="users" ></i><span>Student Record</span></a></li>
	<li routerLinkActive="active"><a class="nav-link " [routerLink]="['/recordinstructorcourse']"><i data-feather="user-check"></i><span>Instructor Record</span></a></li>
	<li routerLinkActive="active" ><a class="nav-link" [routerLink]="['/recordcourses']"><i data-feather="activity"></i><span>Course Record</span></a></li>

	<li routerLinkActive="active"><a class="nav-link " [routerLink]="['/reportcourse']" (click)="goreports('course')"><i data-feather="trending-up"></i><span>Course Report</span></a></li>
  <li routerLinkActive="active"><a class="nav-link "  [routerLink]="['/adminattendancecourse']"><i data-feather="book-open"></i><span>Course Attendance</span></a></li>
</ul>
</li>




<li class="menu-header" >Meeting Management</li>
<li class="dropdown" >
<a href="javascript:void(0)" class="nav-link has-dropdown"><i data-feather="crosshair"></i><span>Meetings</span></a>
 <ul class="dropdown-menu">
 	<li routerLinkActive="active"><a class="nav-link" [routerLink]="['/participant']"><i data-feather="users"></i><span>Participant Record</span></a></li>
			<li routerLinkActive="active"><a class="nav-link" [routerLink]="['/incharge']"><i data-feather="user-check"></i><span>Incharge Record</span></a></li>
<li routerLinkActive="active"  class="dropdown"><a class="nav-link" [routerLink]="['/meetingrecord']"><i data-feather="airplay"></i><span>Meeting Record</span></a>
</li>
	<li routerLinkActive="active"><a class="nav-link" [routerLink]="['/meetingreport']"><i data-feather="trending-up"></i><span>Meeting Report</span></a></li>
<li routerLinkActive="active"><a class="nav-link" [routerLink]="['/exporte-sms']"><i data-feather="trending-up"></i><span>Export e-SMS</span></a></li>
<li routerLinkActive="active"><a class="nav-link" [routerLink]="['/meetingattendance']"><i data-feather="book-open"></i><span>Meeting Attendance</span></a></li>

 </ul>
</li>


	<li class="menu-header">View</li>
	<li routerLinkActive="active"><a class="nav-link" [routerLink]="['/calendar']" ><i data-feather="calendar"></i><span>Calendar</span></a></li>


		<li class="menu-header">Contact</li>
		<li routerLinkActive="active"><a class="nav-link"  [routerLink]="['/mappage']"><i data-feather="at-sign"></i><span>Contact Us</span></a></li>
		<li routerLinkActive="active"><a class="nav-link"  [routerLink]="['/feedback']"><i data-feather="help-circle"></i><span>Feedback Us</span></a></li>


<li class="menu-header">Manage</li>
<li><a class="nav-link" [routerLink]="['/logout']"><i data-feather="lock"></i><span>logout</span></a></li>

  <div class="sidebar-brand pointer footer-bottom-logo">
      <a href="http://itfs.org.sg/" target="_blank"> <img alt="image" src="assets/images/itfs-logo.svg" class="header-logo" />
      </a>
      <a href="http://itfs.org.sg/" target="_blank">
       <img alt="image" src="assets/images/itfs-favicon.png" class="header-mini" />
      </a>
    </div>




		</ul>

	</aside>
</div>


<!-- super super admin end-->


<!-- super super admin start-->
<div class="main-sidebar sidebar-style-2" style="overflow: auto !important;"  *ngIf='this.admin_type =="ParkingSlot"'>
	<aside id="sidebar-wrapper">
		<div class="sidebar-brand">
			<a href="index.html"> <img alt="image" src="assets/img/logo1.png" class="header-logo" />
			</a> 
			<a href="index.html"> <img alt="image" src="assets/img/logo4.png" class="header-mini" />
			</a> 
		</div>
		<div class="sidebar-user">
			<div class="sidebar-user-picture">
				<img alt="image" src="assets/img/logo4.png"> 
			</div>
			<div class="sidebar-user-details">
				<div class="user-name">{{loginUser}}</div>
			
				<div class="user-role" >Admin</div>
        
			</div>
		</div>


		<ul class="sidebar-menu">

<li class="menu-header">Main</li>
			<li routerLinkActive="active">
				<a href="javascript:void(0)"  [routerLink]="['/Carslot-dashboard']" class="nav-link "><i data-feather="monitor" ></i><span>Dashboard</span></a>
			</li>



			<li routerLinkActive="active"><a class="nav-link" [routerLink]="['/manage-car-lots']" ><i data-feather="user-check"></i><span>Manage Parking Lot</span></a></li>
			

			<li routerLinkActive="active"><a class="nav-link" [routerLink]="['/parking-logs']" ><i data-feather="user-check"></i><span>Parking Logs</span></a></li>
				<li routerLinkActive="active"><a class="nav-link" [routerLink]="['/active-logs']" ><i data-feather="user-check"></i><span>Active Parking Lot</span></a></li>
				<li routerLinkActive="active"><a class="nav-link" [routerLink]="['/addusers']" ><i data-feather="user-check"></i><span>App Users</span></a></li>

	<li class="menu-header">Contact</li>

	<li routerLinkActive="active"><a class="nav-link"  [routerLink]="['/mappage']"><i data-feather="at-sign"></i><span>Contact Us</span></a></li>
	
			
			
<li class="menu-header">Manage</li>
<li><a class="nav-link" [routerLink]="['/logout']"><i data-feather="lock"></i><span>Logout</span></a></li>

  <div class="sidebar-brand pointer footer-bottom-logo">
      <a href="http://itfs.org.sg/" target="_blank"> <img alt="image" src="assets/images/itfs-logo.svg" class="header-logo" />
      </a>
      <a href="http://itfs.org.sg/" target="_blank">
       <img alt="image" src="assets/images/itfs-favicon.png" class="header-mini" />
      </a>
    </div>
	


		</ul>

	</aside>
</div>


<!-- super super admin end-->








  <div class="modal fade bd-example-modal-lg" id="addNewPopupadmin" aria-modal="true" >
    <div class="modal-dialog modal-lg">

      <div class="modal-content">


        <div class="modal-header">
          <h5 class="modal-title" id="myLargeModalLabel">Select RC</h5>

        </div>

        <div class="modal-body">

          <div class="row">
            <div class="col-sm-12">

              <div class="card-body" >
                <div class="table-responsive">
                  <table  class="table table-striped" id="table-2">




                    <thead>

                      <tr>

                        <th>S.no</th>
                        <th>Name</th>
                        <th>Company Name</th>
                        <th>Email</th>
                        <th>Contact no.</th>
                        <th>Select</th>
                      </tr>
                    </thead>

                    <tbody >


                      <tr *ngFor="let l of adminlist;let i=index">

                        <td>{{i+1}}</td>
                        <td>{{l.fname}} {{l.lname}}</td>
  
                        <td>{{l.company_name}}</td>

                        <td>{{l.email}}</td>
                        <td>{{l.phone_no}}</td>

                        <td><input type="radio"    [checked]="l.add"  name="adds" (click)="savedata(l)"></td>
                        <td>

                        </td>
                      </tr>


                    </tbody>
                 
                 </table>
               </div>

            </div>


          </div>



        </div>
      </div>
      <div class="modal-footer bg-whitesmoke br">

        <button  type="button" class="btn btn-success" (click)="selectadmin()" data-dismiss="modal" >OK</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
      </div>



    </div>
  </div>
</div>




<div class="modal fade bd-example-modal-lg" id="addNewPopupadmincc" aria-modal="true" >
    <div class="modal-dialog modal-lg">

      <div class="modal-content">


        <div class="modal-header">
          <h5 class="modal-title" id="myLargeModalLabel">Select CC</h5>

        </div>

        <div class="modal-body">

          <div class="row">
            <div class="col-sm-12">

              <div class="card-body" >
                <div class="table-responsive">
                  <table  class="table table-striped" id="table-2">




                    <thead>

                      <tr>

                        <th>S.no</th>
                        <th>Name</th>
                        <th>Company Name</th>
                        <th>Email</th>
                        <th>Contact no.</th>
                        <th>Select</th>
                      </tr>
                    </thead>

                    <tbody >


                      <tr *ngFor="let l of cclist;let i=index">

                        <td>{{i+1}}</td>
                        <td>{{l.fname}} {{l.lname}}</td>
  
                        <td>{{l.company_name}}</td>

                        <td>{{l.email}}</td>
                        <td>{{l.phone_no}}</td>

                        <td><input type="radio"    [checked]="l.add" name="add" (click)="savedatacc(l)"></td>
                        <td>

                        </td>
                      </tr>


                    </tbody>
                 
                 </table>
               </div>

            </div>


          </div>



        </div>
      </div>
      <div class="modal-footer bg-whitesmoke br">

        <button  type="button" class="btn btn-success" (click)="select_cc_admin()" data-dismiss="modal" >OK</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
      </div>



    </div>
  </div>
</div>

<!-- About us for Car Parking -->
<!-- <div class="modal fade bd-example-modal-xl" id="aboutus" aria-modal="true" >
    <div class="modal-dialog modal-xl">

      <div class="modal-content">


        <div class="modal-header">
          <h5 class="modal-title" id="myLargeModalLabel">About US</h5>

        </div>

        <div class="modal-body">

          <div class="row">
            <div class="col-sm-12">

              <div class="card-body" >
                <div class="form-group form-float">
					<div class="code-to-copy">
						<img src="assets/images/itfs-logo.svg" alt="ITFS">
						<div class="widget-copy">
							ITFS strives to set a path out of poverty to the deserving youth belonging to the economically downtrodden community. The Foundation provides education to eligible youth in the field of Information Technology and aids them to find new jobs. ITFS believes in working together in reshaping the lives of the less fortunate and so we join forces with Grassroots, various Religious Groups and the local NGOs.
					
		
						</div>
					</div>
								  </div>

            </div>


          </div>



        </div>
      </div>
      <div class="modal-footer bg-whitesmoke br">

        <button  type="button" class="btn btn-success" (click)="select_cc_admin()" data-dismiss="modal" >OK</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
      </div>



    </div>
  </div>
</div> -->


