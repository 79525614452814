
<div class="section-body">
   <div class="row">
      <div class="col-12">
         <div class="card">
            <div class="card-header">
               <div class="card-header-title">
                  <h4 *ngIf="type =='add'">Create New </h4>
                  <h4 *ngIf="type =='view'">List</h4>

               </div>
               <div class="card-header-btn-group">

 <!-- <a [routerLink]="['/assignnewevent']" class="btn btn-info"><i class="fa fa-edit" aria-hidden="true"></i> Assign New Event / Course /  Meeting</a> &nbsp; -->
                
                 <a (click)="goevent('view')" *ngIf="type =='add'" class="btn btn-success"><i class="fa fa-eye" aria-hidden="true"></i> View All</a>



 <a (click)="goevent('add')" class="btn btn-success" *ngIf="type =='view'"><i class="fa fa-eye" aria-hidden="true"></i> Add New</a>


               </div>
            </div>
            <div class="card-body">

<!-- add event start -->

<div *ngIf="type == 'add'">
               <form  [formGroup]="eventDatas" method="POST"  >
               <div class="form-group row mb-4">
                  <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Name*</label>
                  <div class="col-sm-12 col-md-7">
                         
                     <input type="text" class="form-control"  placeholder="Enter Name" formControlName="event"  required>
 <div class="error-message message-small" *ngIf="!eventDatas.get('event').valid && eventDatas.get('event').touched">
                      Please enter Name
                    </div>


                  </div>
               </div>
           

              
          <!--      <div class="form-group row mb-4">
                  <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Event Logo</label>
                  <div class="col-sm-12 col-md-7">
                     <div class="row">
                        <div class="col-md-6">
                           
                           <input required type="file"     name="uploaded-img" id="uploaded-img" (change)="selectedImageChanged2($event)">
                        </div>
                        <div class="col-md-6">
                           <img  [src]="previewFileUrl2  | safe: 'url' " *ngIf="previewFileUrl2" class="img-thumbnail imguploadthumb" alt=alt/>
                           <img id="uploaded-img" *ngIf="!previewFileUrl2" src="assets/img/placeholder-img.png" class="img-thumbnail imguploadthumb">
                        </div>
                     </div>
                  </div>
               </div> -->

               <div class="form-group row mb-4">
                  <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Dashboard Image</label>
                  <div class="col-sm-12 col-md-7">
                     <div class="row">
                        <div class="col-md-6">
                           <input required type="file"     name="uploaded-img" id="uploaded-img" (change)="selectedImageChanged($event)">
                        </div>
                        <div class="col-md-6">
                           <img  [src]="previewFileUrl  | safe: 'url' " *ngIf="previewFileUrl" class="img-thumbnail imguploadthumb" alt=alt/>
                           <img id="uploaded-img" *ngIf="!previewFileUrl" src="assets/img/placeholder-img.png" class="img-thumbnail imguploadthumb">
                        </div>
                     </div>
                  </div>
               </div>



               <div class="form-group row mb-4">
                  <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Color</label>
                  <div class="col-sm-12 col-md-7">
                   

<input [(colorPicker)]="colors" [style.background]="colors"/>
                  </div>
               </div>
  


               <div class="form-group row mb-4">
                  <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3"></label>
                  <div class="col-sm-12 col-md-7">
                     <button class="btn btn-success" (click)="finishFunction()">Create</button>
                  </div>
               </div>
</form>
</div>
<!-- add event end -->



<!-- view events list start-->

   <div *ngIf="type == 'view'">
                    <div class="table-responsive">
                      <table  class="table table-striped" id="table-2">

                        <thead>
         
                          <tr>
                          
                            <th>S.no</th>
                            <th>Name</th>
                            <th>Dashboard Image</th>
                            <th>Colour</th>

                           
                            <!-- <th>NRIC</th> -->

                            <th>Action</th>
                          </tr>
                        </thead>
                      
                        <tbody >
 

                          <tr *ngFor="let l of eventslist;let i=index"  >
                          
                            <td>{{i+1}}</td>
                            <td>{{l.event_name}}</td>
                           

                          

                            <!-- <td class="student-img"> -->
                           <!--   <img alt="image" *ngIf="l.image == ''" src="assets/images/avatar.jpg" width="35">
                               <img alt="image" [src]="l.dash_image"  *ngIf="l.image != ''" width="35"> -->
<td>
<img src="{{l.dash_image}}"  onerror="this.src='assets/images/noimg.png'" alt="avatar" width="35">
</td>
<!-- </td> -->

  <td [style.background]="l.colour" style="margin-top: 10px !important;
    display: inline-block;border-radius: 20%;width: 40px;height: 40px;"></td>

<!-- 
<td>

  <a href="javascript:void(0)" (click)="deleteevent(l)"  class="btn btn-danger btn-action" data-toggle="tooltip" title="Delete">
                                <i class="fas fa-trash"></i>
                              </a>
                            </td> -->
   <td>
                          <a href="javascript:void(0)" (click)="editevent(l)" class="btn btn-action bg-purple mr-1" data-toggle="tooltip" title="Edit" >
                                <i class="fas fa-pencil-alt"></i>
                              </a> 
                              <a href="javascript:void(0)" (click)="deleteevent(l)"  class="btn btn-danger btn-action" data-toggle="tooltip" title="Delete">
                                <i class="fas fa-trash"></i>
                              </a>
                            </td>








                        
                          </tr>

                        
                        </tbody>
                
                      </table>
                    </div>


                  </div> 
</div>
<!-- view events list end -->





            </div>
         </div>
      </div>
   </div>
<!-- </div> -->


   <div class="modal fade"   id="addNewPopup">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
           <!--  <form [formGroup]="addEvent" > -->

        <div class="modal-header">
         <!-- <div class="event-box l-bg-purple-dark"> -->
          <!-- <img data-toggle="tooltip" title="" src="assets/img/dashboard/Basketball.svg" data-original-title="Swimming"> -->
        <!-- </div> -->
        <h5 class="modal-title" id="exampleModalLabel">Edit Event</h5>
         
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

<!-- location edit start -->
  <div class="row">
     <div class="col-md-12 col-12">
         <div class="form-group">
          <label>Event Name</label>

          <input  type="text" [(ngModel)]="editdata.event_name" class="form-control" readonly>
       

        </div>
      </div>

     <!-- <div class="col-md-6 col-12">
         <div class="form-group">
          <label>Event Name</label>

          <input  type="text" [(ngModel)]="editdata.event_name" class="form-control" readonly>
       

        </div>
      </div> -->

 <div class="col-md-6 col-12">
         <div class="form-group">
          <label>Colour</label>

       <input [(colorPicker)]="editdata.colour" [style.background]="editdata.colour" class="form-control"/>
       

        </div>
      </div>

 
 <div class="col-md-12 col-12">
         <div class="form-group">
          <label>Dashboard Image</label>
  <div class="row">
                        <div class="col-md-6">
                           <input required type="file"     name="uploaded-img" id="uploaded-img" (change)="selectedImageChanged($event)">
                        </div>
                        <div class="col-md-6">
                           <img  [src]="previewFileUrl  | safe: 'url' " *ngIf="previewFileUrl" class="img-thumbnail imguploadthumb" alt=alt/>
                           <img id="uploaded-img" *ngIf="!previewFileUrl" src="assets/img/placeholder-img.png" class="img-thumbnail imguploadthumb">
                        </div>
                     </div>
      
       

        </div>
      </div>
    </div>
<!-- location edit end -->

  </div>
  
 <div class="modal-footer bg-whitesmoke br">
  <!-- event edit start -->

    <button style="margin-right: 5px;"  type="button" class="btn btn-success" data-dismiss="modal" (click)="updateevents()">Save changes</button>
    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>

<!-- event edit end -->

  </div>


<!-- </form> -->
</div>
</div>
</div>
