<footer class="main-footer">
  
  <input type="hidden" id="call_history_id" value="0">
  <input type="hidden" id="call_customer_key" value="0">
  <input type="hidden" id="call_incoming_number" value="">
  <input type="hidden" id="incoming_call_trigger" (click)="incoming_call_trigger()">
  <input type="hidden" id="outgoing_call_end_trigger" (click)="outgoingCallEnd()">
  <input type="hidden" id="incoming_call_end_trigger" (click)="incomingCallEnd()">
  <div class="webrtc_dailpad" style="visibility: hidden;width: 0px; height: 0px;position: fixed;left: 0;">
    <input type="hidden" value='0' id='make_call_number'>
    <!-- <input type="hidden" value='0' id='makecallHanupBtn'> -->
    <div id='makecallHanupBtn'></div>
    <div id="incomingCallAlert" style="display: none;">
    </div>
    <div id="incomingCall" style="display: none;">
      <button id="incomingCallAnswerBtn" type="button"></button>
      <button id="incomingCallHangupBtn" type="button"></button>
    </div>
    <div id="remoteDisplay"></div>
    <div class="fp-localDisplay">
      <div id="localDisplay"></div>
    </div>
  </div>
  <div class="footer-left">
    Copyright © 2020 Designed by <a class="blue-clr" href="http://cal4care.com/" target="_blank"><strong>Cal4care</strong></a> and License to  <a class="blue-clr" href="https://itfs.org.sg/" target="_blank"><strong>Information Technology Foundation Singapore.</strong></a>
     
        <!--  <a href="javascript:;" (click)="gethelpvideo()"><span class="footer-icon">
          <img alt="image" class="fitimagefooter" src="assets/img/footer/video-icon.svg">
         
        </span>
      </a>
      <a href="javascript:;" (click)="getandroid()"><span class="footer-icon">
        <img alt="image" class="fitimagefooter" src="assets/img/play-store.png">
       
      </span>
    </a>
     <a href="javascript:;" (click)="getios()"><span class="footer-icon">
     <img alt="image"   class="fitimagefooter" src="assets/img/app-store.png">
     
   </span>
 </a> -->
  </div>
  <div *ngIf="this.admin_type!='ParkingSlot'" class="">
    <ul class="">
       <li class="alignfooter mr-3">
       <a href="javascript:;" style="text-decoration: none;padding-left: 10px" (click)="clickToCall()"><span class="footer-icon">
            <img alt="image" class="fitimagefooter" src="assets/images/call.png">
      
          </span>
          
        </a>
      </li>
      <li class="alignfooter mr-3">
        <a href="javascript:;" (click)="gethelpvideo()"><span class="footer-icon">
          <img alt="image" class="fitimagefooter" src="assets/img/footer/video-icon.svg">
          
        </span>
      </a>
    </li>
    <li class="alignfooter mr-2">
      <a href="javascript:;" (click)="getandroid()"><span class="footer-icon">
        <img alt="image" class="fitimagefooter" src="assets/img/play-store.png">
       
      </span>
    </a>
  </li>
  <li class="alignfooter">
    <a href="javascript:;" (click)="getios()"><span class="footer-icon">
     <img alt="image"   class="fitimagefooter" src="assets/img/app-store.png">
 
   </span>
 </a>
</li>
</ul>
</div>
</footer>
<div class="modal fade bd-example-modal-lg" id="androidapp" aria-modal="true" >
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="myLargeModalLabel">Scan To Get Andriod App</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="text-align: center;">
        <div class="row">
          <div class="col-sm-12">
            <div class="card-body" >
              <ngx-qrcode
              [elementType]="elementType" 
              [value] = "value"
              cssClass = "aclass"
              errorCorrectionLevel = "L">
            </ngx-qrcode>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<div class="modal fade bd-example-modal-lg" id="iosapp" aria-modal="true" >
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="myLargeModalLabel">Scan To Get IOS App</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="text-align: center;">
        <div class="row">
          <div class="col-sm-12">
            <div class="card-body" >
              <ngx-qrcode
              [elementType]="elementType" 
              [value] = "iosvalue"
              cssClass = "aclass"
              errorCorrectionLevel = "L">
            </ngx-qrcode>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<div class="modal fade bd-example-modal-lg" id="help" aria-modal="true" >
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="myLargeModalLabel">Help Videos</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-12">
            <div class="card-body" >
              <div class="table-responsive">
                <table  class="table table-striped" id="table-2">
                  <thead>
                    <tr>
                      <th>S.no</th>
                      <th>Help Video Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr>
                      <td>1</td>
                      <td>Add Student</td>
                      <td>
                        <button type="button" class="btn btn-primary  btn-lg"  (click)="helpvideoplay('stud')">Play</button>
                      </td>
                    </tr>
                    <tr>
                     <td>2</td>
                     <td>Add Instructor</td>
                     <td><button type="button" class="btn btn-primary  btn-lg"  (click)="helpvideoplay('inst')">Play</button>
                     </td>
                   </tr>
                   <tr>
                    <td>3</td>
                    <td>Add and Assign Event</td>
                    <td>
                      <button type="button" class="btn btn-primary  btn-lg"  (click)="helpvideoplay('event')">Play</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer bg-whitesmoke br">
      <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
    </div>  
  </div>
</div>
</div>
<div class="modal fade bd-example-modal-lg" id="showvideo" aria-modal="true" >
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="myLargeModalLabel">Scan To Get IOS App</h5> -->
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="text-align: center;">
        <div class="row">
          <div class="col-sm-12">
            <div class="card-body" >
              <!-- <iframe src="videourl" width="540" height="310"></iframe> -->
              <!-- <video width="320" height="240" controls> -->
                <div  *ngIf="videourl =='stud'">
                 <video controls width="auto" height="400" (click)="toggleVideo()" #videoPlayer>
                  <source   src="https://booking.mconnectapps.com/api/storage/help/add%20student.mp4" type="video/mp4">
                    <!-- < <source src="movie.ogg" type="video/ogg"> -->
                    </video> 
                  </div>
                  <div  *ngIf="videourl =='inst'">
                    <video controls  width="auto" height="400" (click)="toggleVideo()" #videoPlayer>
                     <source  src="https://booking.mconnectapps.com/api/storage/help/add-instructor.mp4" type="video/mp4">
                     </video>
                   </div>
                   <div *ngIf="this.videourl =='event'" >
                    <video controls width="auto" height="400" (click)="toggleVideo()" #videoPlayer>
                      <source  src="https://booking.mconnectapps.com/api/storage/help/add-and-assign-Event.mp4" type="video/mp4">
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>