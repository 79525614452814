import { Component, OnInit } from '@angular/core';
import * as Feather from 'feather-icons';
declare var $:any;
declare const window: any;
import { Router,ActivatedRoute } from '@angular/router';
import { ServerService } from '../services/server.service';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
loginUser;
opens = false;
type;
logincompany;

adminlist:any=[];
cclist:any=[];

adminuser;
adminusercc;

selected;
selectedcc;
admin_type;
  constructor(private serverService: ServerService,private route:ActivatedRoute,private router:Router) {
   }
ngAfterViewInit() {
    Feather.replace();
  }
  ngOnInit(): void {
  	
    this.loginUser = localStorage.getItem('user_name');
    this.logincompany = localStorage.getItem('company_name');
    this.admin_type = localStorage.getItem('admin_type');

    this.type = localStorage.getItem('type');
if(this.type == '1' || this.type == '2'){
// this.get_admin_list('')
this.get_cc_list('')
}


console.log(this.type)
      let color_class = 'light'+' '+'light'+'-sidebar theme-'+'black';
  	 $("body").removeClass();
      $("body").addClass(color_class);
  }
  


toggleSidebarMenu(){
  if(this.opens == false)
 {
$("body").toggleClass("sidebar-mini");
$("body").style.width = "0%";
this.opens = true
}
else{
$("body").removeClass("sidebar-mini");
$("body").style.width = "100%";
this.opens = true
}
}




chnageadmincc(){



  $('#addNewPopupadmincc').modal({
  show:true,
   backdrop:false

});
$('#addNewPopupadmincc').modal('show');



}

chnageadmin(){
  
  $('#addNewPopupadmin').modal({
  show:true,
   backdrop:false

});
$('#addNewPopupadmin').modal('show');
}


get_admin_list(val){
  


    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('superadmin');

  let api_req:any = '{"operation":"lecture","moduleType":"lecture","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"view_admin","admin_id":"'+admin_id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){

      this.adminlist=response.result.data;

      if(val == ''){
if(localStorage.userId == undefined || localStorage.userId == ''){
        for (var i = 0; i < this.adminlist.length; i++) {
  if(i == 0){
 this.adminlist[0].add = true;

 localStorage.setItem('userId',this.adminlist[0].user_id); 
 localStorage.setItem('rc_companyname',this.adminlist[0].company_name); 
   
this.adminuser = this.adminlist[0].company_name;
  }
  else{
 this.adminlist[i].add = false;

  }
}

}else{
   for (var i = 0; i < this.adminlist.length; i++) {
     if(localStorage.getItem('userId') == this.adminlist[i].user_id){
       this.adminlist[i].add = true;

     }
     else{
        this.adminlist[i].add = false;
     }
   }
  this.adminuser = localStorage.getItem('rc_companyname');

}

if(this.adminlist.length == 0){
   this.adminuser= '';
}


      }

 console.log(this.adminlist);
      }


    }, 
    (error)=>{
        console.log(error);
    });
  }



get_cc_list(val){
  


    let access_token: any=localStorage.getItem('access_token');
    // let admin_id: any=localStorage.getItem('superadmin');
 let admin_id: any=localStorage.getItem('supersuperadmin');
  let api_req:any = '{"operation":"lecture","moduleType":"lecture","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"get_cc","admin_id":"'+admin_id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){

      this.cclist=response.result.data;
      if(val == ''){
if(localStorage.superadmin == undefined || localStorage.superadmin == ''){
        for (var j = 0; j < this.cclist.length; j++) {
  if(j == 0){
 this.cclist[0].add = true;

 localStorage.setItem('superadmin',this.cclist[0].user_id); 
 localStorage.setItem('cc_companyname',this.cclist[0].company_name); 
   
this.adminusercc = this.cclist[0].company_name;
  }
  else{
 this.cclist[j].add = false;

  }
}

}else{
   for (var j = 0; j < this.cclist.length; j++) {
     if(localStorage.getItem('superadmin') == this.cclist[j].user_id){
       this.cclist[j].add = true;

     }
     else{
        this.cclist[j].add = false;
     }
   }
  this.adminusercc = localStorage.getItem('cc_companyname');

}

if(this.cclist.length == 0){
   this.adminusercc= '';
}


      }
this.get_admin_list('')

      }


    }, 
    (error)=>{
        console.log(error);
    });



  }










selectadmin(){
this.adminuser =  this.selected.company_name;
 localStorage.setItem('userId', this.selected.user_id); 
 localStorage.setItem('rc_companyname', this.selected.company_name); 
 location.reload()
}

select_cc_admin(){
this.adminusercc =  this.selectedcc.company_name;
 localStorage.setItem('superadmin', this.selectedcc.user_id); 
 localStorage.setItem('cc_companyname', this.selectedcc.company_name); 
 location.reload()
}


savedata(val){
  console.log(val)
  this.selected = val;
}

savedatacc(val){
  console.log(val)
  this.selectedcc = val;

}
showaboutus(){
  $('#aboutus').modal({
    show:true,
     backdrop:false
  
  });
  $('#aboutus').modal('show');
  
}

}
