  <div class="section-body">
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-header">
                      <div class="card-header-title">
                        <h4>Locations</h4>
                      </div>
                      <div class="card-header-btn-group">
                        <!--   <a [routerLink]="['/assignnewevent']" class="btn btn-info"><i class="fa fa-edit" aria-hidden="true"></i> Assign New Event (or) Course</a> &nbsp; -->
                        
                        <a  *ngIf="page == 'view'" (click)="pageshow('add')" class="btn btn-success"><i class="fa fa-plus-circle" aria-hidden="true"></i> Add New Location</a>&nbsp;

                        <a  *ngIf="page == 'add'" (click)="pageshow('view')" class="btn btn-success"><i class="fa fa-eye" aria-hidden="true"></i>View Location</a>
                      </div>
                    </div>
                    <div class="card-body">

<!-- add start -->
<div *ngIf="page == 'add'">

 <div class="form-group row mb-4">
                  <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Location</label>
                  <div class="col-sm-12 col-md-7">
                     <input  type="text" [(ngModel)]="eventData.location" name="location" class="form-control ">
                  </div>
               </div>



    <div class="form-group row mb-4">
                  <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3"></label>
                  <div class="col-sm-12 col-md-7">
                     <button type="submit" class="btn btn-success" (click)="addlocation()">Add Location</button>
                  </div>
               </div>
</div>
<!-- add end -->




<!-- view start -->
<div *ngIf="page == 'view'">


                      <div class="table-responsive">
                        <table class="table table-striped" id="table-2">
                          <thead>
                            
                            <tr>
                         
                              <th>S.no</th>
                              <th>Location Name</th>
                             
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr  *ngFor="let l of locations;let i=index">
                          
                              <td>{{i+1}}</td>
                              
                              <td>{{l.location}}</td>

                              <td>
                                <button type="button" class="btn btn-info btn-icon icon-left" (click)="editlocation(l)">
                                <i class="fas fa-pencil-alt" ></i> Edit</button>
                              </td>
                              
                            </tr>
                        
                          </tbody>
                        </table>

                      
                      </div>
                    </div>
<!-- view end -->









                    </div>
                  </div>
                </div>
              </div>
            </div>
       

    
    <div class="modal fade"   id="addNewPopup">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
           <!--  <form [formGroup]="addEvent" > -->

        <div class="modal-header">
         <!-- <div class="event-box l-bg-purple-dark"> -->
          <!-- <img data-toggle="tooltip" title="" src="assets/img/dashboard/Basketball.svg" data-original-title="Swimming">
        </div> -->
        <h5 class="modal-title" id="exampleModalLabel">Edit Location</h5>
         
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

<!-- location edit start -->

        

 <div class="col-md-6 col-12">
         <div class="form-group">
          <label>Location</label>

          <input  type="text" [(ngModel)]="eventData.location" class="form-control ">
       

        </div>
      </div>

 
 
<!-- location edit end -->

  </div>
  
 <div class="modal-footer bg-whitesmoke br">
  <!-- event edit start -->

    <button style="margin-right: 5px;" type="button" (click)="deletelocation()" data-dismiss="modal" class="btn btn-danger">Delete Event</button>
    <button style="margin-right: 5px;"  type="button" class="btn btn-success" data-dismiss="modal" (click)="updatelocation()">Save changes</button>
    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>

<!-- event edit end -->

  </div>


<!-- </form> -->
</div>
</div>
</div>




