
<div class="section-body">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title" style="width: 22% !important;">
            <h4>Course Record</h4>
          </div>
          <div class="card-header-btn-group" style="width: 100%;">



            <a  (click)="assignnew()" class="btn btn-info"><i class="fa fa-edit" aria-hidden="true"></i> Assign New Course</a> &nbsp;

            <a  (click)="eventpage('add')" class="btn btn-warning"><i class="fa fa-plus-circle" aria-hidden="true"></i> Add New Course</a>&nbsp;

           

            <a (click)="templatepage()"  class="btn btn-danger"><i class="fa fa-eye" aria-hidden="true"></i>Course Template View</a>&nbsp;

            <a (click)="eventpage('view')" class="btn btn-success"><i class="fa fa-eye" aria-hidden="true"></i> View Courses</a>

 

          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-striped" id="table-2">
              <thead>
                <tr>
                  <td colspan="20" class="search-text-tbl">
                    <div class="input-group"><input type="text" class="form-control" placeholder="Search Course" (input)="getsearch($event.target.value)"  (keyup.enter)="vieweventsdetail({'search_text':agent_search.value})" #agent_search>
                      <div class="input-group-append">
                        <button class="btn btn-primary" (click)="vieweventsdetail({'search_text':agent_search.value})"><i class="fas fa-search"></i></button>
                      </div>
                    </div>
                  </td>
                </tr>
              </thead>

              <thead>
                 <tr class="l-bg-light-blue">
                  <th>S.no</th>
                  <th>Name</th>
                  <th>ID</th>
                  <th>Instructor</th>
                  <th>Attendees</th>
                  <th>Type</th>
                  <th>Start Date  & Time</th>
                  <th>End Date  & Time</th>
                  <th colspan="3">Action</th>
                </tr>
              </thead>

              <tbody>
                <tr  *ngFor="let l of events;let i=index">

                  <td>{{offset_count+i+1}}</td>
                  <td>
                    <span  [style.background-color]="l.colour" class="badge">{{l.event_name}}</span>

                  </td>
                  <td>{{l.number}}</td>

                  <td class="student-img">
                    <!-- <img  alt="image" *ngIf="l.image == ''" src="assets/images/avatar.jpg" width="35">&nbsp; -->
                    <!-- <img alt="image" *ngIf="l.image !=''" [src]="l.image" width="35">&nbsp;{{l.instructors}} -->
 <img src="{{l.image}}"  onerror="this.src='assets/images/avatar.jpg'" alt="image"  width="35">&nbsp;{{l.instructors}}

                    
                  </td>
                  <td style="text-align: center;">

                    {{l.present_students}}/{{l.total_students}}

                  </td>

                  <td >{{l.type}}</td>

                  <td>{{l.start}}</td>

                  <td>{{l.end}}</td>
                  <td>
                    <button type="button" class="btn btn-danger btn-icon icon-left" (click)="removealert('deleteeventorcourse',l)">
                    <i class="far fa-trash-alt"></i> Delete</button>
                  </td>
                  <td>
                    <button type="button" class="btn btn-success btn-icon icon-left" (click)="showedit(l,'edit')">
                      <i class="fas fa-pencil-alt" ></i> Manage</button>
                    </td>
                    <td>
                      <button *ngIf="type == '1' && enableduplicate == '1'" type="button" class="btn btn-info btn-icon icon-left" (click)="showedit(l,'duplicate')">
                        <i class="fas fa-pencil-alt" ></i> Duplicate</button>

                      <button *ngIf="type == '2'" type="button" class="btn btn-info btn-icon icon-left" (click)="showedit(l,'duplicate')">
                        <i class="fas fa-pencil-alt" ></i> Duplicate</button>
                      </td>
                    </tr>

                  </tbody>
                </table>

                <tr *ngIf="recordNotFound == true">
                 <td colspan="12">Data not found</td>
               </tr>
             </div>
             <div class="card-footer text-right" *ngIf="recordNotFound == false">
              <nav class="d-inline-block">
                <ul class="pagination mb-0" *ngIf="paginationData.info == 'show'">

                  <li class="page-item">
                    <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? vieweventsdetail({'search_text':agent_search.value,'offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
                  </li>
                  <li class="page-item" *ngIf="paginationData.backtab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="vieweventsdetail({'search_text':agent_search.value,'offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
                  </li>


                  <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? vieweventsdetail({'search_text':agent_search.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                  </li>

                  <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="vieweventsdetail({'search_text':agent_search.value,'offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
                  </li>

                  <li class="page-item">
                    <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? vieweventsdetail({'search_text':agent_search.value,'offset':paginationData.end}) : false"><i class="fas fa-angle-right"></i></a>
                  </li>
                </ul>
              </nav>
            </div>








          </div>
        </div>
      </div>
    </div>
  </div>





  <div class="modal fade"   id="addNewPopup">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <form [formGroup]="addEvent" >

          <div class="modal-header">
         <!--   <div class="event-box l-bg-purple-dark">
            <img data-toggle="tooltip" title="" src="assets/img/dashboard/Basketball.svg" data-original-title="Swimming">
          </div> -->
          <h5 class="modal-title" id="exampleModalLabel" *ngIf="details == 'edit'">{{titleshow}} Details </h5>
          <h5 class="modal-title" id="exampleModalLabel" *ngIf="details == 'duplicate'">Duplicate {{titleshow}} Details </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          <!-- Event edit start -->

          <div class="row" *ngIf="details == 'edit'">
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label>{{titleshow}} Name</label>
                <input type="text" readonly class="form-control" formControlName="event_name" >

              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
               <label>Instructor</label>  :  <strong>{{instructorfullname}}</strong>
               <button *ngIf="!addEvent.value.editshowing"  type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="chnageinst('instructor')">Change Instructor</button>

             </div>
           </div>
           <div class="col-md-6 col-12">
            <div class="form-group">
             <label>Co-Instructor</label>  :  <strong>{{instructorfullnameco}}</strong>
             <button *ngIf="!addEvent.value.editshowing"  type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="chnageinst('coinstructor')">Change Co-Instructor</button>

           </div>
         </div>


         <div class="col-md-6 col-12">
           <div class="form-group">
            <label>Start Time</label>

            <input *ngIf="addEvent.value.editshowing" type="datetime-local" formControlName="start_time"  class="form-control datetimepicker" readonly>

            <input *ngIf="!addEvent.value.editshowing" type="datetime-local" formControlName="start_time"  class="form-control datetimepicker">



          </div>
        </div>

        <div class="col-md-6 col-12">
         <div class="form-group">
          <label>End Time</label>
          <input *ngIf="addEvent.value.editshowing" type="datetime-local" formControlName="end_time" class="form-control datetimepicker" readonly>

          <input *ngIf="!addEvent.value.editshowing" type="datetime-local" formControlName="end_time" class="form-control datetimepicker">

        </div>
      </div>

      <div class="col-md-6 col-12">
       <div class="form-group">
        <label>Location</label>

        <input *ngIf="addEvent.value.editshowing" type="text" formControlName="location" class="form-control " readonly>

        <select *ngIf="!addEvent.value.editshowing" class="form-control"  formControlName="location" >
         <option value="{{l.location}}" *ngFor ="let l of locations">{{l.location}}</option>
       </select>

     </div>
   </div>

   <div class="col-md-6 col-12">
     <div class="form-group">
      <label>View Students</label>
      <button type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="viewstud('call')">View Student</button>
    </div>
  </div>

  <div class="col-md-6 col-12" *ngIf="!addEvent.value.editshowing">
   <div class="form-group">
    <label>Add Students</label>
    <button type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="addstudent()">Add Student</button>
  </div>
</div>

</div>
<!-- event edit end -->

<!-- event duplicate start -->
<div class="row" *ngIf="details == 'duplicate'">
  <div class="col-md-6 col-12">
    <div class="form-group">
      <label>{{titleshow}} Name</label>
      <select required class="form-control"  formControlName="event_name">
       <!-- <input type="text"  class="form-control" formControlName="event_name" > -->
       <option value="{{l.event_name}}" *ngFor ="let l of eventslist">{{l.event_name}}</option>
     </select>

   </div>
 </div>


 



 <div class="col-md-6 col-12">
  <div class="form-group">
   <label>Instructor</label>  :  <strong>{{instructorfullname}}</strong>
   <button   type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="chnageinst('instructor')">Change Instructor</button>

 </div>
</div>
<div class="col-md-6 col-12">
  <div class="form-group">
   <label>Co-Instructor</label>  :  <strong>{{instructorfullnameco}}</strong>
   <button   type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="chnageinst('coinstructor')">Change Co-Instructor</button>

 </div>
</div>


<div class="col-md-6 col-12">
 <div class="form-group">
  <label>Start Time</label>

  <input  type="datetime-local" formControlName="start_time"  class="form-control datetimepicker" >





</div>
</div>

<div class="col-md-6 col-12">
 <div class="form-group">
  <label>End Time</label>
  <input  type="datetime-local" formControlName="end_time" class="form-control datetimepicker" >


</div>
</div>

<div class="col-md-6 col-12">
 <div class="form-group">
  <label>Location</label>


  <!-- <input  type="text" formControlName="location" class="form-control "> -->
  <select  class="form-control"  formControlName="location">
   <option value="{{l.location}}" *ngFor ="let l of locations">{{l.location}}</option>
 </select>

</div>
</div>

<!--  <div class="col-md-6 col-12">
         <div class="form-group">
          <label>View Students</label>
           <button type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="viewstud('call')">View Student</button>
        </div>
      </div> -->

      <div class="col-md-6 col-12">
       <div class="form-group">
        <label>Add Students</label>
        <button type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="addstudent()">Add Student</button>
      </div>
    </div>

  </div>


  <!-- event duplicate end -->

</div>

<div class="modal-footer bg-whitesmoke br">
  <!-- event edit start -->
  <div *ngIf="details == 'edit'">


    <button style="margin-right: 5px;" *ngIf="!addEvent.value.editshowing" type="button" class="btn btn-success" data-dismiss="modal" (click)="updateEventData()">Save changes</button>
    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
  </div>
  <!-- event edit end -->

  <!-- event duplicate start -->
  <div  *ngIf="details == 'duplicate'">

    <button  style="margin-right: 5px;" type="button" class="btn btn-success" data-dismiss="modal" (click)="assignevent()">Duplicate</button>
    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>

  </div>
  <!-- event duplicate end -->
</div>


</form>
</div>
</div>
</div>






<div class="modal fade"   id="addNewPopupcourse">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <form [formGroup]="addCourse" >

        <div class="modal-header">
       <!--   <div class="event-box l-bg-purple-dark">
          <img data-toggle="tooltip" title="" src="assets/img/dashboard/Basketball.svg" data-original-title="Swimming">
        </div> -->
        <h5 class="modal-title" id="exampleModalLabel" *ngIf="details == 'edit'">Course Details </h5>
        <h5 class="modal-title" id="exampleModalLabel" *ngIf="details == 'duplicate'">Duplicate Course Details </h5>


        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- course edit -->
        <div class="row" *ngIf="details == 'edit'">


          <div class="col-md-6 col-12">
            <div class="form-group">
              <label>Course Name</label>
              <input type="text" readonly class="form-control" formControlName="event_name" >
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="form-group">
              <label>Instructor</label>  :  <strong>{{instructorfullname}}</strong>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="form-group">
              <label>Co-Instructor</label>  :  <strong>{{instructorfullnameco}}</strong>

            </div>
          </div>
          <div class="col-md-6 col-12">
           <div class="form-group">
            <label>Start Date</label>        
            <input  type="date" formControlName="start_date"  class="form-control datetimepicker" >
          </div>
        </div>
        <div class="col-md-6 col-12">
         <div class="form-group">
          <label>Start Time</label>
          <input  type="time" formControlName="start_time"  class="form-control datetimepicker" >
        </div>
      </div>

      <div class="col-md-6 col-12">
       <div class="form-group">
        <label>End Time</label>
        <input  type="time" formControlName="end_time" class="form-control datetimepicker" >
      </div>
    </div>

<!--     <div class="col-md-6 col-12">
     <div class="form-group">
      <label>Location</label>
      <input type="text" formControlName="location" class="form-control ">
    </div>
  </div> -->

<div class="col-md-6 col-12">
 <div class="form-group">
  <label>Location</label>


  <!-- <input  type="text" formControlName="location" class="form-control "> -->
  <select  class="form-control"  formControlName="location">
   <option value="{{l.location}}" *ngFor ="let l of locations">{{l.location}}</option>
 </select>

</div>
</div>

  

  <div class="col-md-6 col-12">
   <div class="form-group">
    <label>View Students</label>
    <button type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="viewstud('call')">View Student</button>
  </div>
</div>

<div class="col-md-6 col-12" *ngIf="!addCourse.value.editshowing">
 <div class="form-group">
  <label>Add Students</label>
  <button type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="addstudent()">Add Student</button>
</div>
</div> 


</div>
<!--  course edit end -->

<!--  Duplicate Course Details -->
<div class="row" *ngIf="details == 'duplicate'">

 <div class="col-md-6 col-12">
  <div class="form-group">
    <label>{{titleshow}} Name</label>
    <select required class="form-control"  formControlName="event_name">
     <!-- <input type="text"  class="form-control" formControlName="event_name" > -->
     <option value="{{l.event_name}}" *ngFor ="let l of eventslist">{{l.event_name}}</option>
   </select>

 </div>
</div>








<div class="col-md-6 col-12">
  <div class="form-group">
   <label>Instructor</label>  :  <strong>{{instructorfullname}}</strong>
   <button   type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="chnageinst('instructor')">Change Instructor</button>

 </div>
</div>
<div class="col-md-6 col-12">
  <div class="form-group">
   <label>Co-Instructor</label>  :  <strong>{{instructorfullnameco}}</strong>
   <button   type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="chnageinst('coinstructor')">Change Co-Instructor</button>

 </div>
</div>

<!-- <div class="form-group row mb-4" *ngIf="eventData.types == 'course'"> -->
  <!-- <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Choose Days</label> -->
  <div class="col-md-12 col-12">
    <div class="form-group" style="display: flex">
     <label>Choose Days</label>

     <!-- <div class="col-sm-12 col-md-7"  style="display: flex;">    -->
      <div *ngFor="let c of days; let i = index;">

       <input type="checkbox" [(ngModel)]="c.checked" style="margin-right: 8px !important;"  class="" name="daysselect"  [ngModelOptions]="{standalone: true}">
       <label for="phone number"  class="mrg-right">{{c.name}}</label>

     </div>






   </div>
 </div>



 <div class="col-md-6 col-12">
   <div class="form-group">
    <label>Start Date</label>        
    <input  type="date" formControlName="start_date"  class="form-control datetimepicker" >
  </div>
</div>
<div class="col-md-6 col-12">
 <div class="form-group">
  <label>Start Time</label>
  <input  type="time" formControlName="start_time"  class="form-control datetimepicker" >
</div>
</div>

<div class="col-md-6 col-12">
 <div class="form-group">
  <label>End Time</label>
  <input  type="time" formControlName="end_time" class="form-control datetimepicker" >
</div>
</div>




<div class="col-md-6 col-12">
 <div class="form-group">
  <label>Location</label>
  <!-- <input type="text" formControlName="location" class="form-control " > -->

  <select  class="form-control"  formControlName="location">
   <option value="{{l.location}}" *ngFor ="let l of locations">{{l.location}}</option>
 </select>


</div>
</div>
<div class="col-md-6 col-12">
  <div class="form-group">
    <label>No Of Class</label>
    <input type="text"  class="form-control" formControlName="no_of_class" >
  </div>
</div>





<!--  <div class="col-md-6 col-12">
         <div class="form-group">
          <label>View Students</label>
           <button type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="viewstud('call')">View Student</button>
        </div>
      </div> -->

      <div class="col-md-6 col-12">
       <div class="form-group">
        <label>Add Students</label>
        <button type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="addstudent()">Add Student</button>
      </div>
    </div>


  </div>
  <!-- course duplicate end -->

</div>

<div class="modal-footer bg-whitesmoke br">
  <div *ngIf="details == 'edit'">
      <button style="margin-right: 5px;" type="button" class="btn btn-success" data-dismiss="modal" (click)="updatecourseData()">Save changes</button>
   <!--  <button style="margin-right: 5px;" *ngIf="!addCourse.value.editshowing" type="button" class="btn btn-success" data-dismiss="modal" (click)="updatecourseData()">Save changes</button> -->
    <!-- <button style="margin-right: 5px;" *ngIf="!addCourse.value.editshowing" type="button" class="btn btn-success" data-dismiss="modal" (click)="deletecourseevent()">Delete</button> -->
    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>

  </div>

  <div *ngIf="details == 'duplicate'">
    <button  style="margin-right: 5px;" type="button" class="btn btn-success" data-dismiss="modal" (click)="add_course()">Duplicate</button>
    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>

  </div>


</div>


</form>
</div>
</div>
</div>











<div class="modal fade bd-example-modal-lg" id="addNewPopupee" aria-modal="true" >
  <div class="modal-dialog modal-lg">

    <div class="modal-content">


      <div class="modal-header">
        <h5 class="modal-title" id="myLargeModalLabel">Enrolled Students</h5>

      </div>

      <div class="modal-body">

        <div class="row">
          <div class="col-sm-12">

            <div class="card-body" >
              <div class="table-responsive">
                <table  class="table table-striped" id="table-2">

                  <thead>

                    <tr>

                      <th>S.no</th>
                      <th>Student Name</th>
                      <th>Email</th>
                      <th>Contact no.</th>


                      <th>NRIC</th>

                      <th  *ngIf="!addEvent.value.editshowing">Action</th>
                    </tr>
                  </thead>

                  <tbody >


                    <tr *ngFor="let l of get_attendees;let i=index">

                      <td>{{i+1}}</td>
                      <td>{{l.name}}</td>
                      <td>{{l.email}}</td>
                      <td>{{l.phone_no}}</td>

                      <td>{{l.nric}}</td>
     <td  *ngIf="!addEvent.value.editshowing" ><input type="checkbox"    [(ngModel)]="l.add"  name="add"  (click)="adddeletestudent(l)"></td>
                      <!-- <td class="student-img"> -->
                         <!--      <img alt="image" *ngIf="l.image == ''" src="assets/images/avatar.jpg" width="35">
                           <img alt="image" [src]="l.image"  *ngIf="l.image != ''" width="35"> -->
                           <!-- <td *ngIf="!addEvent.value.editshowing"> -->
                            <!-- <a href="javascript:void(0)" (click)="deleteassignstudent(l)"  class="btn btn-danger btn-action" data-toggle="tooltip" title="Delete"> -->
                              <!-- <i class="fas fa-trash"></i> -->
                            <!-- </a> -->
                          <!-- </td> -->

                          <!-- <td>{{l.email}}</td> -->
                          <!-- <td>{{l.phone_no}}</td> -->
                          <!-- <td>{{l.nirp}}</td> -->

                          <!-- <td><input type="checkbox"    [(ngModel)]="l.add"  name="add"  (click)="savedata(l)"></td> -->

                          <td>

                          </td>
                        </tr>

                        
                      </tbody>

                    </table>
                  </div>


                </div>



              </div>



            </div>
          </div>
          <div class="modal-footer bg-whitesmoke br">
  <button style="margin-right: 5px;" *ngIf="!addEvent.value.editshowing" type="button" class="btn btn-danger" data-dismiss="modal" (click)="removealert('deleteassignstudent')">Delete</button>

            <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
          </div>



        </div>
      </div>
    </div>




    <div class="modal fade bd-example-modal-lg" id="addNewPopupstud" aria-modal="true" >
      <div class="modal-dialog modal-lg">

        <div class="modal-content">


          <div class="modal-header">
            <h5 class="modal-title" id="myLargeModalLabel">Add Student To Event</h5>

          </div>

          <div class="modal-body">

            <div class="row">
              <div class="col-sm-12">

                <div class="card-body" >
                  <div class="table-responsive">
                    <table  class="table table-striped" id="table-2">




                      <thead>
                        <tr>
                          <td colspan="20" class="search-text-tbl">
                            <div class="input-group"><input type="text" class="form-control" placeholder="Search Student" (input)="getsearch2($event.target.value)"  (keyup.enter)="get_student_list({'search_text':agent_search2.value})" #agent_search2>
                              <div class="input-group-append">
                                <button class="btn btn-primary" (click)="get_student_list({'search_text':agent_search2.value})"><i class="fas fa-search"></i></button>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>

                          <th>S.no</th>
                          <th>Student Name</th>
                          <th>Photo</th>
                          <th>Email</th>
                          <th>Contact no.</th>
                          <th>NRIC</th>
                          <th>Add</th>
                        </tr>
                      </thead>
                      
                      <tbody >


                        <tr *ngFor="let l of studentslist;let i=index">

                          <td>{{offset_count2+i+1}}</td>
                          <td>{{l.fname}} {{l.lname}}</td>
                          <td class="student-img">
                            <img alt="image" *ngIf="l.image == ''" src="assets/images/avatar.jpg" width="35">
                            <img alt="image" [src]="l.image"  *ngIf="l.image != ''" width="35">


                          </td>

                          <td>{{l.email}}</td>
                          <td>{{l.phone_no}}</td>
                          <td>{{l.nirp}}</td>

                          <td><input type="checkbox"    [(ngModel)]="l.add"  name="add"  (click)="savedata(l)"></td>
                          <!-- checked="l.add" -->
                          <td>

                          </td>
                        </tr>

                        
                      </tbody>
                      <tr *ngIf="recordNotFound2 == true">
                       <td colspan="12">Data not found</td>
                     </tr>

                   </table>
                 </div>

                 <div class="card-footer text-right" *ngIf="recordNotFound2 == false">
                  <nav class="d-inline-block">
                    <ul class="pagination mb-0" *ngIf="paginationData2.info == 'show'">

                      <li class="page-item">
                        <a [class]="paginationData2.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData2.backtab=='show' ? get_student_list({'search_text':agent_search2.value,'offset':paginationData2.start}) : false"><i class="fas fa-angle-left"></i></a>
                      </li>
                      <li class="page-item" *ngIf="paginationData2.backtab =='show'">
                        <a class="page-link" href="javascript:void(0)" (click)="get_student_list({'search_text':agent_search2.value,'offset':paginationData2.back})"><i class="fas fa-angle-double-left"></i></a>
                      </li>


                      <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData2.data;let i=index">
                        <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? get_student_list({'search_text':agent_search2.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                      </li>

                      <li class="page-item" *ngIf="paginationData2.nexttab =='show'">
                        <a class="page-link" href="javascript:void(0)" (click)="get_student_list({'search_text':agent_search2.value,'offset':paginationData2.next})"><i class="fas fa-angle-double-right"></i></a>
                      </li>

                      <li class="page-item">
                        <a [class]="paginationData2.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData2.nexttab =='show' ? get_student_list({'search_text':agent_search2.value,'offset':paginationData2.end}) : false"><i class="fas fa-angle-right"></i></a>
                      </li>
                    </ul>
                  </nav>
                </div>










              </div>
              <!-- </div> -->


            </div>

            

          </div>
        </div>
        <div class="modal-footer bg-whitesmoke br">

          <button  type="button" class="btn btn-success" (click)="savealldata()" data-dismiss="modal" >Save changes</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
        </div>



      </div>
    </div>
  </div>



  <div class="modal fade bd-example-modal-lg" id="addNewPopupinst" aria-modal="true" >
    <div class="modal-dialog modal-lg">

      <div class="modal-content">


        <div class="modal-header">
          <h5 class="modal-title" id="myLargeModalLabel">Add Instructor To Event</h5>

        </div>

        <div class="modal-body">

          <div class="row">
            <div class="col-sm-12">

              <div class="card-body" >
                <div class="table-responsive">
                  <table  class="table table-striped" id="table-2">




                    <thead>
                      <tr>
                        <td colspan="20" class="search-text-tbl">
                          <div class="input-group"><input type="text" class="form-control" placeholder="Search Student" (input)="getsearchinstructor($event.target.value)"  (keyup.enter)="get_instructor_list({'search_text':agent_search3.value})" #agent_search3>
                            <div class="input-group-append">
                              <button class="btn btn-primary" (click)="get_instructor_list({'search_text':agent_search3.value})"><i class="fas fa-search"></i></button>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>

                        <th>S.no</th>
                        <th>Instructor Name</th>
                        <th>Photo</th>
                        <th>Email</th>
                        <th>Contact no.</th>
                        <th>NRIC</th>
                        <th>Add</th>
                      </tr>
                    </thead>

                    <tbody >


                      <tr *ngFor="let l of instructorlist;let i=index">

                        <td>{{offset_count3+i+1}}</td>
                        <td>{{l.fname}} {{l.lname}}</td>
                        <td class="student-img">
                          <img alt="image" *ngIf="l.image == ''" src="assets/images/avatar.jpg" width="35">
                          <img alt="image" [src]="l.image"  *ngIf="l.image != ''" width="35">


                        </td>

                        <td>{{l.email}}</td>
                        <td>{{l.phone_no}}</td>
                        <td>{{l.nirp}}</td>

                        <td><input type="radio"    [checked]="l.add" name="add"  (click)="savedatainstructor(l)"></td>
                        <td>

                        </td>
                      </tr>


                    </tbody>
                    <tr *ngIf="recordNotFound == true">
                     <td colspan="12">Data not found</td>
                   </tr>

                 </table>
               </div>

               <div class="card-footer text-right" *ngIf="recordNotFound3 == false">
                <nav class="d-inline-block">
                  <ul class="pagination mb-0" *ngIf="paginationData3.info == 'show'">

                    <li class="page-item">
                      <a [class]="paginationData3.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData3.backtab=='show' ? get_instructor_list({'search_text':agent_search3.value,'offset':paginationData3.start}) : false"><i class="fas fa-angle-left"></i></a>
                    </li>
                    <li class="page-item" *ngIf="paginationData3.backtab =='show'">
                      <a class="page-link" href="javascript:void(0)" (click)="get_instructor_list({'search_text':agent_search3.value,'offset':paginationData3.back})"><i class="fas fa-angle-double-left"></i></a>
                    </li>


                    <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData3.data;let i=index">
                      <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? get_instructor_list({'search_text':agent_search3.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                    </li>

                    <li class="page-item" *ngIf="paginationData3.nexttab =='show'">
                      <a class="page-link" href="javascript:void(0)" (click)="get_instructor_list({'search_text':agent_search3.value,'offset':paginationData3.next})"><i class="fas fa-angle-double-right"></i></a>
                    </li>

                    <li class="page-item">
                      <a [class]="paginationData3.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData3.nexttab =='show' ? get_instructor_list({'search_text':agent_search3.value,'offset':paginationData3.end}) : false"><i class="fas fa-angle-right"></i></a>
                    </li>
                  </ul>
                </nav>
              </div>










            </div>
            <!-- </div> -->


          </div>



        </div>
      </div>
      <div class="modal-footer bg-whitesmoke br">

        <button  type="button" class="btn btn-success" (click)="savealldata2()" data-dismiss="modal" >Save changes</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
      </div>



    </div>
  </div>
</div>








