<div class="section-body">
  <div class="row">
    <div class="col-7 col-md-7 col-lg-7">
      <div class="card">
        <div class="card-header">
          <h4>Feedback Us</h4>
        </div>
        <div class="card-body p-30">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">        
               <!-- <div class="form-group row mb-4" *ngIf="eventData.template"> -->
                <!-- <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Template Name</label> -->
                <div class="col-sm-12 col-md-12">
                  <textarea style="width: 100%; height: 260px !important;" type="text" [(ngModel)]="eventData.feed" name="feed" class="form-control" placeholder="Type your valuable feedback here"></textarea>
               </div>
               <!-- </div> -->
             </div>
           </div>
         </div>
         <div class="row">
           <div class="col-md-4"></div>
           <div class="col-md-4">
           </div>
           <div class="col-md-4">
            <div class="form-group" style="float: right;">
              <button type="button" class="btn btn-success" (click)="sendfeedback()" ><i class="fa fa-paper-plane" aria-hidden="true" style="padding-right: 10px"></i>Submit</button>
            </div>
          </div>
        </div>      
      </div>
    </div>
  </div>
</div> 
</div>