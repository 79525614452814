import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import jsPDF from 'jspdf';

declare var $:any;
declare var iziToast:any;
import Swal from 'sweetalert2'
@Component({
  selector: 'app-manage-car-lots',
  templateUrl: './manage-car-lots.component.html',
  styleUrls: ['./manage-car-lots.component.css']
})

export class ManageCarLotsComponent implements OnInit {

  constructor(private serverService:ServerService) { }
  carlots_list;
  QR_code_data;
  slot_name;
  last_id:number;
  slot_id;
  websocket;
  ngOnInit(): void {
    this.getLots();

    document.getElementById("Print").onclick = function () {
      printElement(document.getElementById("printThis"));
  }
  
  function printElement(elem) {
      var domClone = elem.cloneNode(true);
      
      var $printSection = document.getElementById("printSection");
      
      if (!$printSection) {
          $printSection = document.createElement("div");
          $printSection.id = "printSection";
          document.body.appendChild($printSection);
      }
      
      $printSection.innerHTML = "";
      $printSection.appendChild(domClone);
      window.print();
      $('#printThis').addClass('modal-close')
//       // $('#ShowQrCode').modal('hide');
//       $('#ShowQrCode').addClass('modal-close')
//       setTimeout(() => {
//         // $('#ShowQrCode').removeStyle('display');
//         // $('#ShowQrCode').css('display','none');    
//         const elem = $("#ShowQrCode");
// elem[0].style.removeAttribute('display');
// elem[0].style.setProperty('display', 'none', 'important');          
//       }, 2000);
setTimeout(() => {
  window.location.reload();
}, 2000);
  }
this.initSocket();
  }


  initSocket(){
   
    
        this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4035"); 
     


        this.websocket.onopen = function(event) { 
          console.log('Manage Slots socket connected');
        }
    
        this.websocket.onmessage = function(event) {      
          var result_message= JSON.parse(event.data);
          console.log(result_message)
          if(result_message.message_type=="parking"){
          $('#reloadslots').click();
          }
        }
    
        this.websocket.onerror = function(event){
          console.log('error');
        }
        this.websocket.onclose = function(event){
          $('#reconnect_socket').click();

    }




  }
  getLots(){
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('supersuperadmin');
     let api_req:any = '{"operation":"carparking","moduleType":"carparking","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"parking_list","admin_id":"'+admin_id+'"}}'
     Swal.fire({
			html:
				'<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
		showCloseButton: false,
			showCancelButton: false,
			showConfirmButton: false,
			focusConfirm: false,
			background: 'transparent'
		});
       this.serverService.sendServer(api_req).subscribe((response:any) => {
         Swal.close()
         console.log(response);
         if(response.result.status == true){
       this.carlots_list=response.result.data;
       this.last_id=this.carlots_list[this.carlots_list.length-1].id;
       let c:number =1
       let last = +this.last_id + +c;      
       this.last_id=last;
    
         }
   
   
       }, 
       (error)=>{
           console.log(error);
       });
  }
  getLotclose(){
    $('#ShowQrCode').modal('hide');
    this.getLots();
  }
  popupadd(){
    $('#AddNewSlot').modal({
    show:true,
     backdrop:false  
  });
  $('#AddNewSlot').modal('show');
    }
    GenerateQR(id){

      let access_token: any=localStorage.getItem('access_token');
      let admin_id: any=localStorage.getItem('supersuperadmin');
       let api_req:any = '{"operation":"carparking","moduleType":"carparking","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"parking_edit","id":"'+id+'"}}'
       
         this.serverService.sendServer(api_req).subscribe((response:any) => {
           console.log(response);
           if(response.result.status == true){     
            var QR_data=response.result.data;
            // this.QR_code_data=response.result.data;
            console.log(QR_data)
            this.QR_code_data = JSON.stringify({
              en_id: QR_data.id,
              slotname: QR_data.lot_name,
              lot_no: QR_data.lot_no,
            });
            this.QR_code_data=btoa(btoa(btoa(this.QR_code_data)))
            this.slot_name=QR_data.lot_name;
           }
     
     
         }, 
         (error)=>{
             console.log(error);
         });        
    $('#ShowQrCode').modal({
    show:true,
     backdrop:false
  });
  $('#ShowQrCode').modal('show');

    }
  addSlot(){

      let access_token: any=localStorage.getItem('access_token');
      let admin_id: any=localStorage.getItem('supersuperadmin');

      let api_data:any =  new Object();
     let  Lname=$('#add_slot_name').val();
 if(Lname==''){
  iziToast.warning({
    message: "Please enter Slot Name",
    position: 'topLeft'
});
return false;
 }
      let api_req:any = new Object();
      api_data.action="parking_slot";
      api_data.lot_name=Lname;
      api_data.lot_no=this.last_id;
      api_data.admin_id=admin_id;
      api_req.operation="carparking";
      api_req.moduleType="carparking";
      api_req.api_type="web";
      api_req.element_data=api_data;
      //  let api_req:any = '{"operation":"carparking","moduleType":"carparking","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"parking_slot","id":""}}'
         this.serverService.sendServer(api_req).subscribe((response:any) => {
           console.log(response);
           $('#AddNewSlot').modal('hide');
           if(response.result.data == 1){   
            iziToast.success({
              message: "Slot added Successfully",
              position: 'topRight'
          });
          $('#add_slot_name').val('');
          
          this.getLots();
           }
          else if(response.result.data == 2){   
            iziToast.warning({
              message: "Slot name already exist",
              position: 'topRight'
          });
          $('#add_slot_name').val('');
         
           }
           else{
            iziToast.warning({
              message: "Sorry Some error occurred",
              position: 'topLeft'
          });  
           }
     
     
         }, 
         (error)=>{
             console.log(error);
         });

    }
    editSlot(id){

      let access_token: any=localStorage.getItem('access_token');
      let admin_id: any=localStorage.getItem('supersuperadmin');

      let api_data:any =  new Object();
 
      let api_req:any = new Object();
      api_data.action="parking_edit";
      api_data.id=id;     
      api_req.operation="carparking";
      api_req.moduleType="carparking";
      api_req.api_type="web";
      api_req.element_data=api_data;
      //  let api_req:any = '{"operation":"carparking","moduleType":"carparking","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"parking_slot","id":""}}'
      Swal.fire({
        html:
          '<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
      showCloseButton: false,
        showCancelButton: false,
        showConfirmButton: false,
        focusConfirm: false,
        background: 'transparent'
      });
      this.serverService.sendServer(api_req).subscribe((response:any) => {
        Swal.close();
           console.log(response);
           if(response.result.status == true){   
           let data=response.result.data;
           this.slot_id=data.id;
          // this.getLots();
            $('#edit_slot_name').val(data.lot_name);
            $('#EditSlotpop').modal({
    show:true,
     backdrop:false  
  });
  $('#EditSlotpop').modal('show');
           }
           else{
            iziToast.warning({
              message: "Sorry Some error occurred",
              position: 'topLeft'
          });  
           }
     
     
         }, 
         (error)=>{
             console.log(error);
         });

    } 
    updateSlot(){

      let access_token: any=localStorage.getItem('access_token');
      let admin_id: any=localStorage.getItem('supersuperadmin');

      let api_data:any =  new Object();
 let lotName=$('#edit_slot_name').val();
 if(lotName==''){
  iziToast.warning({
    message: "Please enter Slot Name",
    position: 'topLeft'
});
return false;
 }
      let api_req:any = new Object();
      api_data.action="parking_update";
      api_data.lot_name=lotName;     
      api_data.id=this.slot_id;       
      api_data.lot_no=this.slot_id;       
      api_req.operation="carparking";
      api_req.moduleType="carparking";
      api_req.api_type="web";
      api_req.element_data=api_data;
      //  let api_req:any = '{"operation":"carparking","moduleType":"carparking","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"parking_slot","id":""}}'
         this.serverService.sendServer(api_req).subscribe((response:any) => {
           console.log(response);
           $('#EditSlotpop').modal('hide');
           if(response.result.data == 1){               
              iziToast.success({
                message: "Slot Updated Successfully",
                position: 'topLeft'
            });
            this.getLots();                
          }
           else if(response.result.data == 2){   
                iziToast.warning({
                  message: "Slot name already exist",
                  position: 'topRight'
              });           
             
            }
           else{
            iziToast.warning({
              message: "Sorry Some error occurred",
              position: 'topLeft'
          });  
           }
     
     
         }, 
         (error)=>{
             console.log(error);
         });

    }

    deleteSlot(id){
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't able to revert this",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then((result) => {
        if (result.value) {
              let api_data:any =  new Object();
               let api_req:any = new Object();
               api_data.action="parking_delete";
               api_data.id=id;       
               api_req.operation="carparking";
               api_req.moduleType="carparking";
               api_req.api_type="web";
               api_req.element_data=api_data;
               //  let api_req:any = '{"operation":"carparking","moduleType":"carparking","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"parking_slot","id":""}}'
                  this.serverService.sendServer(api_req).subscribe((response:any) => {
                    console.log(response);
                    if(response.result.status == true){               
                       iziToast.success({
                         message: "Slot Delete Successfully",
                         position: 'topLeft'
                     });
                     this.getLots();                
                       }
                    else{
                     iziToast.warning({
                       message: "Sorry Some error occurred",
                       position: 'topLeft'
                   });  
                    }
              
              
                  }, 
                  (error)=>{
                      console.log(error);
                  });

        }
      })
    }
    closemanual(){
      this.getLots();
      // $('#ShowQrCode').removeAtt("style"); 
    }     
  
    download2() {
      // const qrcode = document.getElementById('printThis');
      // let doc = new jsPDF();  
      // let imageData= this.getBase64Image(qrcode.firstChild.firstChild);
      // doc.addImage(imageData,"JPG",10,10);  
      // doc.save('FirstPdf.pdf');
      
     
      for( let  item of this.carlots_list)
    {
      var pdf = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: [84, 41]
    });
    pdf.setFontSize(15);
    pdf.text(item.lot_name, 43, 20);
    this.QR_code_data = JSON.stringify({
      en_id: item.id,
      slotname: item.lot_name,
      lot_no: item.lot_no,
    });

    this.QR_code_data=btoa(btoa(btoa(this.QR_code_data)))
       const elem=document.getElementById("printThis");
    var imgData = this.QR_code_data;
 
  var domClone = elem.cloneNode(true);
      
  var $printSection = document.getElementById("printSection");
  
  if (!$printSection) {
      $printSection = document.createElement("div");
      $printSection.id = "printSection";
      document.body.appendChild($printSection);
  }
  
  $printSection.innerHTML = this.QR_code_data;
  $printSection.appendChild(domClone);
    // pdf.setFontSize(10);
    // pdf.text('Scan For Menu', 43, 25);
    
    let base64Image = $('#printSection qr-code').attr('src');
       pdf.addImage(base64Image, 'png', 0, 0, 40, 40);    

      }
      pdf.save('FirstPdf.pdf');
    }
    download() {
      // const qrcode = document.getElementById('printThis');
      // let doc = new jsPDF();  
      // let imageData= this.getBase64Image(qrcode.firstChild.firstChild);
      // doc.addImage(imageData,"JPG",10,10);  
      // doc.save('FirstPdf.pdf');
      
     
      for( let  item of this.carlots_list)
    {
      this.QR_code_data = JSON.stringify({
        en_id: item.id,
        slotname: item.lot_name,
        lot_no: item.lot_no,
      });
      this.QR_code_data=btoa(btoa(btoa(this.QR_code_data)));
      var doc = new jsPDF()
      const contentHtml = this.QR_code_data;
      const image = contentHtml.toDataURL('image/jpeg', 0.8)
      doc.addImage(image, 'png', 0, 0, 40, 40);         

      }
      doc.save('sample.pdf')   ;
    }
}
