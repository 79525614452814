    
<div class="section-body">

    <div class="row">
        <div class="col-7 col-md-7 col-lg-7">
            <div class="card">
                <div class="card-header">
                    <h4>Admin Attendance Meeting</h4>
                    
                </div>
                <div class="card-body p-30">
                    <form [formGroup]="getRep" >
                        <div class="row">
                            


          <div class="col-md-12">
            <div class="form-group">
                <label for="auxcode_name">Select Meeting Name</label>
                <select   required id="auxcode_name" class="form-control " formControlName="event_name">
                    <option value="" selected>Select</option>
                    <option *ngFor="let l of meetinglist" value="{{l.id}}">{{l.meeting}}</option>
                </select>
            <div class="error-message message-small" *ngIf="!getRep.get('event_name').valid && getRep.get('event_name').touched">
              Please Select Meeting 
                            </div>      


              </div>
          </div>



      </div>

                
                <div class="row">
                   <div class="col-md-4"></div>
                   <div class="col-md-4">
                    <div class="form-group">
                        <button type="button" class="btn btn-success" (click)="deskattendance()">Take Attendance</button>
                    </div>
                </div>
                <div class="col-md-4"></div>
            </div>
            
        </form>
    </div>
</div>

</div>
</div> 


<div class="row" *ngIf="showing">
    <div class="col-12 col-md-12 col-lg-12">
        <div class="card">
            <div class="card-header">
                <h4>Attendance List</h4>

                
            </div>
            <div class="card-body p-0">
                <h2 style="padding-left: 25px">Attendance : {{nameshowevent}} <span style="padding-left: 25px">     Incharge Name :{{nameshowinstructor}}</span></h2>
                <div class="table-responsive">
                    <table class="table table-striped table-md">
                 
                                <tr >

                                    

                                    <th>S.No</th>
                                    
                                    <th>Name</th>
                                    <!-- <th>Last Name</th> -->

                                    <!-- <th>Event Name</th> -->
                                    <!-- <th>NRIC</th> -->
                                    <th>Phone Number</th>
                                    <th>Email</th>
                                    <!-- <th>Address</th> -->
                                    <!-- <th>City</th> -->
                                    <!-- <th>State</th> -->
                                    <!-- <th>Pincode</th> -->
                                    <!-- <th>Country</th> -->
                                    <!-- <th>Instructor Name</th> -->
                                    <!-- <th>Instructor Last Name</th> -->


                                    <!-- <th>Action</th> -->
                                    <th  *ngFor="let h of dateattendance;let i=index" style="text-transform: capitalize;">{{h}}</th>


                                </tr>


                                <tr *ngFor="let l of attendancecontent;let j= index">
                                    <td>{{j+1}}</td>

                                    <td>{{l.name}}</td>
                                    <!-- <td>{{l.lname}}</td> -->

                                    <!-- <td>{{l.event_name}}</td> -->
                                    <!-- <td>{{l.nric}}</td> -->
                                    <td>{{l.phone_no}}</td>
                                    <td>{{l.email}}</td>
                                    <!-- <td>{{l.address}}</td> -->
                                    <!-- <td>{{l.city}}</td> -->
                                    <!-- <td>{{l.state}}</td> -->
                                    <!-- <td>{{l.pincode}}</td> -->
                                    <!-- <td>{{l.country}}</td> -->
                                    <!-- <td>{{l.ins_fname}} {{l.ins_lname}}</td> -->
                                    <!-- <td>{{l.ins_lname}}</td> -->
                    <!--           
                    <td  *ngFor="let m of dateattendance;let i=index">
                        <input type="text" [(ngModel)]="l[m]" name="l[m]" (click)="getvalue(m,l,l[m])" > -->

                        <!--  <td  *ngFor="let m of dateattendance;let i=index"><input type="checkbox"     name="l[m]"    [ngModel]="l[m] == '1' ? true : l[m] == '0' ? false : null"      (click)="getvalue(m,l,l[m])"></td> -->
                        <!-- <td  *ngFor="let m of dateattendance;let i=index"><input type="checkbox"       [checked]="l[m]"  (change)="l[m] = !l[m]"  (click)="getvalue(m,l,l[m])"></td> -->

                        <td  *ngFor="let m of dateattendance;let i=index">
                          <div>
                            <!-- <div *ngIf="cur_date == m"> -->
                               <!-- {{dateattendance}}{{m}} -->
                               <input #saveUserNameCheckBox 
                               id="saveUserNameCheckBox" 
                               type="checkbox" 
                               [ngModel]="l[m] == '1' ? true : l[m] == '0' ? false : null"
                               (change)="getvalue(m,l,saveUserNameCheckBox.checked)" />
                           </div>
                          <!--  <div *ngIf="cur_date != m">
                            <input #saveUserNameCheckBoxs  disabled=true
                            id="saveUserNameCheckBox" 
                            type="checkbox" 
                            [ngModel]="l[m] == '1' ? true : l[m] == '0' ? false : null"
                            (change)="getvalue(m,l,saveUserNameCheckBoxs.checked)" />
                        </div> -->


                    </td>


                    <!-- [checked]="saveUsername" -->

                    <!-- [(ngModel)]="l[m]" -->
                    <!-- </td> -->
                    
                    
                </tr> 


                            
                        </table>
                    </div>
                </div>
                
            </div>
        </div>
    </div>




























</div>









