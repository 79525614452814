import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';
declare var $:any;
declare var iziToast:any;
@Component({
  selector: 'app-exportsms',
  templateUrl: './exportsms.component.html',
  styleUrls: ['./exportsms.component.css']
})
export class ExportsmsComponent implements OnInit {
getRep: FormGroup;
 reports:any=[];
 meetinglist:any=[];
 reportview = false;
 generatedreport:any=[];

reportheadershow:any=[];
  constructor(private serverService: ServerService) { }

  ngOnInit(): void {
    this.meeting_id_list()
    this.view_report_list()
    this.getRep = new FormGroup({
      'event_name' :new FormControl(null,Validators.required),
    
      // 'add_inst' :new FormControl(null),
      // 'add_std' :new FormControl(null),
      'report_type':new FormControl(null),
      // 'course_name':new FormControl(null)

      });

  }

validateAllFields(formGroup: FormGroup) {         
        Object.keys(formGroup.controls).forEach(field => {  
            const control = formGroup.get(field);            
            if (control instanceof FormControl) {             
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {        
                this.validateAllFields(control);  
            }
        });
    }



getReports(){

console.log(this.getRep.value)
// console.log(this.eventData)


   if(this.getRep.valid){

      this.meetingreport()
   }

   
   else {
            this.validateAllFields(this.getRep); 
        }
  }

meetingreport(){
  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');


registerstudentdata.action="meeting_report";
registerstudentdata.meeting_id=this.getRep.value.event_name;
registerstudentdata.name='';
registerstudentdata.admin_id=admin_id;
registerstudentdata.report_type="csv";

 registeruser.access_token=access_token;
  registeruser.operation="student_report";
  registeruser.moduleType="student_report";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status == true && response.result.data == 1){
     

     this.view_report_list()

     this.meeting_attendee()
//      iziToast.success({
//     title: 'OK',
//     position: 'topRight',
//     message: 'Successfully event assigned!',
// });
   }else{
//    iziToast.error({
//     title: 'OK',
//     position: 'topRight',
//     message: 'Illegal operation!',
// });
   }
      
      }, 
      (error)=>{
          console.log(error);
      });


}

view_report_list(){


  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');


registerstudentdata.action="meeting_report_list";
registerstudentdata.admin_id=admin_id;





 registeruser.access_token=access_token;
  registeruser.operation="student_report";
  registeruser.moduleType="student_report";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){

     this.reports = response.result.data
//     iziToast.success({
//     title: 'OK',
//     position: 'topRight',
//     message: 'Report generated Successfully!',
// });

// this.reportview = true;

   }else{

   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}


meeting_attendee(){


  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');


registerstudentdata.action="meeting_attendee";
registerstudentdata.admin_id=admin_id;
registerstudentdata.meeting_id=this.getRep.value.event_name;





 registeruser.access_token=access_token;
  registeruser.operation="student_report";
  registeruser.moduleType="student_report";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){

this.generatedreport = response.result.data
    iziToast.success({
    title: 'OK',
    position: 'topRight',
    message: 'Report generated Successfully!',
});
    if(this.generatedreport.length != 0){
this.reportview = true;

    }else{
               iziToast.warning({
    // title: 'OK',
    position: 'topRight',
    message: 'No Data Found!',
});
    }


   }else{
    iziToast.error({
    // title: 'OK',
    position: 'topRight',
    message: 'No Data Found!',
});
   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}

meeting_id_list(){

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');
  
    let api_req:any = '{"operation":"meeting","moduleType":"meeting","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"meetings","admin_id":"'+admin_id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){
      // this.courselist= response

           this.meetinglist= response.result.data
    
      console.log(this.meetinglist);
      }
   

    }, 
    (error)=>{
        console.log(error);
    });
  }

}
