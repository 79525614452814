import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Router,ActivatedRoute } from '@angular/router';
declare var $:any;
declare var iziToast:any;
@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit {
eventData:any={};
page;
locations:any =[];
idupdate;

  constructor(private route:ActivatedRoute,private router:Router,private serverService: ServerService) { 
this.viewlocation()
this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {

  this.page=this.router.getCurrentNavigation().extras.state.page; 
this.assignpage()
      }
    });
if(this.page == undefined ){
    this.page=localStorage.getItem('locationpagetype');
    this.assignpage()
   }

  }

assignpage(){


  console.log(this.page)
  if(this.page == 'add'){

 localStorage.setItem('locationpagetype', 'add'); 


}else if(this.page == 'view'){

localStorage.setItem('locationpagetype', 'view'); 


}
}



  ngOnInit(): void {
  }
pageshow(name){
this.page = name
localStorage.setItem('locationpagetype',name); 
this.eventData.location='';
if(name == 'view'){
  
this.viewlocation()
}


}

editlocation(val){

this.idupdate = val.id;

  let updateevents:any =new Object();
 let updateeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
   
updateevents.action="edit_location";

updateevents.admin_id =localStorage.getItem('userId');

updateevents.id =val.id

 updateeventsuser.access_token=access_token;
  updateeventsuser.operation="lecture";
  updateeventsuser.moduleType="lecture";
  updateeventsuser.api_type="web";
  updateeventsuser.element_data = updateevents;
      this.serverService.sendServer(updateeventsuser).subscribe((response: any) => {
console.log(response)
        if(response.result.status==true){
  
this.eventData.location = response.result.data[0].location

   $('#addNewPopup').modal({
  show:true,
   backdrop:false

});
$('#addNewPopup').modal('show');
          }
        else{

        }
      
      }, 
      (error)=>{
          console.log(error);
      });


}

updatelocation(){



  let updateevents:any =new Object();
 let updateeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
   
updateevents.action="update_location";

updateevents.admin_id =localStorage.getItem('userId');

updateevents.id =this.idupdate;
updateevents.location =this.eventData.location

  

 updateeventsuser.access_token=access_token;
  updateeventsuser.operation="lecture";
  updateeventsuser.moduleType="lecture";
  updateeventsuser.api_type="web";
  updateeventsuser.element_data = updateevents;
      this.serverService.sendServer(updateeventsuser).subscribe((response: any) => {
console.log(response)
        if(response.result.status==true){
 
    iziToast.success({

          message: "location Updated successfully",
          position: 'topRight'
      });
   $('#addNewPopup').modal('hide');
this.viewlocation()

          }
        else{

  iziToast.error({

          message: "Error Occured",
          position: 'topRight'
      });
        }
      
      }, 
      (error)=>{
          console.log(error);
      });


}


addlocation(){

  let updateevents:any =new Object();
 let updateeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
   
updateevents.action="create_location";
updateevents.location=this.eventData.location;

updateevents.admin_id =localStorage.getItem('userId');


 updateeventsuser.access_token=access_token;
  updateeventsuser.operation="lecture";
  updateeventsuser.moduleType="lecture";
  updateeventsuser.api_type="web";
  updateeventsuser.element_data = updateevents;
      this.serverService.sendServer(updateeventsuser).subscribe((response: any) => {

        if(response.result.status==true){
     
      iziToast.success({

          message: "location Added successfully",
          position: 'topRight'
      });
this.eventData.location='';

          }
        else{

  iziToast.error({

          message: "Error Occured",
          position: 'topRight'
      });
        }
      
      }, 
      (error)=>{
          console.log(error);
      });

}


viewlocation(){

  let updateevents:any =new Object();
 let updateeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
   
updateevents.action="view_location";

updateevents.admin_id =localStorage.getItem('userId');


 updateeventsuser.access_token=access_token;
  updateeventsuser.operation="lecture";
  updateeventsuser.moduleType="lecture";
  updateeventsuser.api_type="web";
  updateeventsuser.element_data = updateevents;
      this.serverService.sendServer(updateeventsuser).subscribe((response: any) => {
console.log(response)
        if(response.result.status==true){
     this.locations = response.result.data
   

          }
        else{

 
        }
      
      }, 
      (error)=>{
          console.log(error);
      });

}


deletelocation(){



  let updateevents:any =new Object();
 let updateeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
   
updateevents.action="delete_location";

updateevents.admin_id =localStorage.getItem('userId');

updateevents.id =this.idupdate;


  

 updateeventsuser.access_token=access_token;
  updateeventsuser.operation="lecture";
  updateeventsuser.moduleType="lecture";
  updateeventsuser.api_type="web";
  updateeventsuser.element_data = updateevents;
      this.serverService.sendServer(updateeventsuser).subscribe((response: any) => {
console.log(response)
        if(response.result.status==true){

    iziToast.success({

          message: "Deleted successfully",
          position: 'topRight'
      });
      $('#addNewPopup').modal('hide');
this.viewlocation()
          }
        else{
 iziToast.error({

          message: "Error Occured",
          position: 'topRight'
      });
        }
      
      }, 
      (error)=>{
          console.log(error);
      });


}





}
