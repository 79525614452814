import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Router,ActivatedRoute } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
declare var $:any;
declare var iziToast:any;
import * as moment from 'moment';
const TODAY_STR = new Date(); 
@Component({
  selector: 'app-recordcourse',
  templateUrl: './recordcourse.component.html',
  styleUrls: ['./recordcourse.component.css']
})
export class RecordcourseComponent implements OnInit {
events:any=[];
addEvent : FormGroup;
IsmodelShow = false;
id;
recordNotFound = false;
 pageLimit = 10;
 paginationData:any ={"info":"hide"};
 offset_count = 0;
 instructorlist:any=[];
get_attendees:any=[];
instid;
event_id;

 studentslist:any=[];
recordNotFound2 = false;

 pageLimit2 = 10;

 paginationData2:any ={"info":"hide"};

  offset_count2 = 0;
 sendstudent;
 sendstudentarr=[];

selectedinst;


recordNotFound3 = false;

 pageLimit3 = 10;

 paginationData3:any ={"info":"hide"};

  offset_count3 = 0;

instructorselect;
instructselectname;
instructorselectco;
instructselectnameco;
titleshow = "Event"
courseditdetials:any=[];
eventeditdetials:any=[];
addCourse: FormGroup;
typecalled;
 editshow = false;
 typeinstructor;

instructselectshow;
instructselectshowname;

instructselectshowco;
instructselectshownameco;

 instructorfullname;
 instructorshowfullname;

  instructorfullnameco;
 instructorshowfullnameco;

eventslist:any=[];
details;

 days:any=[];
 dayspick;
 daysarray:any=[];
 locations:any=[];
 course_send_id;
 senddeletenames;
  deletearr:any=[];

  deleteval = false;
  evcorouter;

  instructorselectview;
instructorselectviewco;

 type;
enableduplicate;
   constructor(private route:ActivatedRoute,private router:Router,private serverService: ServerService) {

   }
 ngOnInit(): void {
// if(localStorage.getItem('evcorouterload') == '1' ){
//  localStorage.setItem('evcorouterload','0');
//  location.reload()

//     }
 this.type = localStorage.getItem('type');
console.log(this.type)

    if(localStorage.getItem('evcorouter') == undefined || localStorage.getItem('evcorouter') == null || localStorage.getItem('evcorouter') == ""  ){
 localStorage.setItem('evcorouter','event');
    }
    this.evcorouter=localStorage.getItem('evcorouter');
  	this.viewevents()
    this.viewlocation()
      this.vieweventsdetail({})
      this.dayslist()
     this.duplicatepermisiion()
    this.addEvent = new FormGroup({
      'event_name' : new FormControl(null,Validators.required),
      'instructors' : new FormControl(null,Validators.required),
      'start_time' : new FormControl(null,Validators.required),
      'end_time' : new FormControl(null,Validators.required),
      'count' : new FormControl(null,Validators.required),
      'location' : new FormControl(null,Validators.required),
      'editshowing' : new FormControl(null)

   
     });

this.addCourse = new FormGroup({
      'event_name' : new FormControl(null,Validators.required),
      'instructors' : new FormControl(null,Validators.required),
      'start_time' : new FormControl(null,Validators.required),
      'end_time' : new FormControl(null,Validators.required),
      'start_date':new FormControl(null,Validators.required),
      // 'count' : new FormControl(null,Validators.required),
      'location' : new FormControl(null,Validators.required),
      'editshowing' : new FormControl(null),
     'no_of_class' : new FormControl(null,Validators.required),
     'days' : new FormControl(null,Validators.required),


     });



  
  }

assignnew(){
   // this.router.navigate(['adminattendance'], { state: { page: 'course'} });
this.router.navigate(['assignnewevent'], { state: { pagetype: "course"} });

}

eventpage(name){
this.router.navigate(['addnewevent'], { state: { type: name} });

}
templatepage(){
this.router.navigate(['addlesson'], { state: { type: "course"} });

}
removealert(methodname,val){
var s = this;
  iziToast.question({
    timeout: 20000,
    close: false,
    overlay: true,
    displayMode: 'once',
    id: 'question',
    zindex: 999,
    title: 'Confirm',
    message: 'Are you sure you want to delete?',
    position: 'center',
    buttons: [
        ['<button><b>YES</b></button>', function (instance, toast) {
 
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
 console.log('yes')
 if(methodname == 'deleteeventorcourse'){
   s.deleteeventorcourse(val)
 }
else if(methodname == 'deleteassignstudent'){
  s.deleteassignstudent()
}
        }, true],
        ['<button>NO</button>', function (instance, toast) {
 
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
  console.log('no')

        }],
    ]

});

}

deleteeventorcourse(val){
  console.log(val)




 if(val.type == "Course"){

   let deleteuser:any = new Object();
  
 let deleteuserdata:any = new Object();
    let access_token: any=localStorage.getItem('access_token');


deleteuserdata.action="delete_course_event";
deleteuserdata.admin_id=localStorage.getItem('userId');;
deleteuserdata.id=val.id;
deleteuserdata.course_id=val.course_id;
deleteuserdata.event_id=0;

 deleteuser.access_token=access_token;
  deleteuser.operation="lecture";
  deleteuser.moduleType="lecture";
  deleteuser.api_type="web";
  deleteuser.element_data = deleteuserdata;
      this.serverService.sendServer(deleteuser).subscribe((response: any) => {

        console.log(response)
       
 

        if(response.result.status==true){
       iziToast.success({
    title: 'OK',
    position: 'topRight',
    message: 'Deleted Successfully!',
});
       this.vieweventsdetail({'search_text':'','offset':this.offset_count}) 
        }
        else{
  iziToast.error({
    position: 'topRight',
    message: 'Some Error Occured',
});
        }
      
      }, 
      (error)=>{
          console.log(error);
      });
    }
else{
  this.id = val.id;
  this.deleteevents()

}


// console.log(this.response)
//  



}
dayslist(){
  this.days=[{

    "id": 1,
    "name": "Mon",
    "checked": false
  },
  {
    "id": 2,
    "name": "Tue",
    "checked": false
  },
  {
    "id": 3,
    "name": "Wed",
    "checked": false
  },
  {
    "id": 4,
    "name": "Thurs",
    "checked": false
  },
  {
    "id": 5,
    "name": "Fri",
    "checked": false
  },
  {
    "id": 6,
    "name": "Sat",
    "checked": false
  },
    {
    "id": 7,
    "name": "Sun",
    "checked": false
  }
]
 
}
editeventdetails(l,type){

this.typecalled = type;
this.eventeditdetials =[];
  let editevents:any = new Object();
 let editeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');


editevents.action="edit_event_details";
editevents.id=l;

 editeventsuser.access_token=access_token;
  editeventsuser.operation="user";
  editeventsuser.moduleType="user";
  editeventsuser.api_type="web";
  editeventsuser.element_data = editevents;
      this.serverService.sendServer(editeventsuser).subscribe((response: any) => {

        if(response.result.status==true && response.result.data.length != 0){
this.eventeditdetials = response.result.data[0]
console.log(this.eventeditdetials)
this.id =response.result.data[0].id
         this.instructorselect = response.result.data[0].inst_id
         this.instructorselectco = response.result.data[0].co_inst_id
         this.instructselectnameco =response.result.data[0].co_inst;

         this.instructselectname =response.result.data[0].instructors;
         this.instructorfullname = response.result.data[0].instruct_name;
         this.instructorfullnameco= response.result.data[0].co_instruct_name;

      

         this.instructselectname =response.result.data[0].instructors;
         // this.instructselectname =response.result.data[0].instructors;

this.event_id =response.result.data[0].id;
console.log(moment(TODAY_STR).format('YYYY-MM-DDTHH:mm'))
console.log(moment(response.result.data[0].end_time).format('YYYY-MM-DDTHH:mm'))
if(moment(TODAY_STR).format('YYYY-MM-DDTHH:mm') >= moment(response.result.data[0].start_time).format('YYYY-MM-DDTHH:mm')){

  this.editshow = true;
}else{
  this.editshow = false;
}

// if(response.result.data[0].course_id != "0"){
// this.titleshow = "Course"
// }else{
this.titleshow = "Event"
// }


          this.addEvent.patchValue({
event_name: response.result.data[0].event_name,
 instructors :response.result.data[0].instructors,
 start_time:moment(response.result.data[0].start_time).format('YYYY-MM-DDTHH:mm'),
 end_time:moment(response.result.data[0].end_time).format('YYYY-MM-DDTHH:mm'),
 count:response.result.data[0].count,
 location:response.result.data[0].location,
editshowing :this.editshow

  });
// if(this.details == "duplicate"){
  this.viewstud('')
// }



       console.log(this.events);
  $('#addNewPopup').modal({
   show:true,
   backdrop:false
});
$('#addNewPopup').modal('show');
 
 
          }
        else{

        }
      
      }, 
      (error)=>{
          console.log(error);
      });
}


close() {
    this.IsmodelShow=true;// set false while you need open your model popup
   // do your more code
}

getsearch(searchValue: string): void {  
  console.log(searchValue);
  this.vieweventsdetail({'search_text':searchValue})
}


updateEventData(){
// this.IsmodelShow=true
  let updateevents:any =this.addEvent.value;
 let updateeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let superadmin: any=localStorage.getItem('superadmin');

console.log(this.addEvent.value.instructors)

updateevents.action="update_event_details";
updateevents.super_admin=superadmin;
updateevents.id=this.id;
updateevents.admin_id =localStorage.getItem('userId');
updateevents.instructors =this.instructselectname;
updateevents.co_inst =this.instructselectnameco;

 updateeventsuser.access_token=access_token;
  updateeventsuser.operation="user";
  updateeventsuser.moduleType="user";
  updateeventsuser.api_type="web";
  updateeventsuser.element_data = updateevents;
      this.serverService.sendServer(updateeventsuser).subscribe((response: any) => {

        if(response.result.status==true){
       // this.addevents()
      iziToast.success({

          message: "Event Updated successfully",
          position: 'topRight'
      });
      // $('#addNewPopup').modal('hide');
 // window.location.reload();
 this.vieweventsdetail({})
          }
        else{


        }
      
      }, 
      (error)=>{
          console.log(error);
      });

}



vieweventsdetail(data){
  var list_data= this.listDataInfo(data);

  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');
registerstudentdata.admin_id=admin_id;


registerstudentdata.action="view_course_details";
// limit":"'+list_data.limit+'","offset":"'+list_data.offset+'","search_text":"'+list_data.search_text+'"
registerstudentdata.limit=list_data.limit;
registerstudentdata.offset=list_data.offset;
registerstudentdata.search_text=list_data.search_text;



 registeruser.access_token=access_token;
  registeruser.operation="user";
  registeruser.moduleType="user";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
       
 

        if(response.result.status==true){
          this.events = response.result.data.list_data
         

         for (var i = 0; i < this.events.length; i++) {
           this.events[i].start =moment(this.events[i].start_time).format('DD-MM-YYYY & hh:mm a'),
           this.events[i].end =moment(this.events[i].end_time).format('DD-MM-YYYY & hh:mm a')
 this.events[i].durationhours =moment(this.events[i].end_time).diff(this.events[i].start_time, 'hours')
 this.events[i].durationminutes =moment(this.events[i].end_time).diff(this.events[i].start_time, 'minutes')% 60
            this.events[i].duration

this.events[i].durationshow = this.events[i].durationhours + " hours and " + this.events[i].durationminutes + " minutes";
  
        // this.dashboardevents[i].events = this.dashboardevents[i]
      if(this.events[i].colour == ""){
        this.events[i].colour = 'hotpink'
      }
    }


         
           this.offset_count = list_data.offset;
        this.paginationData = this.serverService.pagination({'offset':response.result.data.list_info.offset, 'total':response.result.data.list_info.total, 'page_limit' :this.pageLimit });
         console.log(this.paginationData);
                this.recordNotFound = this.events.length == 0 ? true : false;

        }
      
      }, 
      (error)=>{
          console.log(error);
      });
}


listDataInfo(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
} 


deleteevents(){
  
  let deleteuser:any = new Object();
 let deleteuserdata:any = new Object();
    let access_token: any=localStorage.getItem('access_token');


deleteuserdata.action="delete_event_details";
deleteuserdata.id=this.id;

 deleteuser.access_token=access_token;
  deleteuser.operation="user";
  deleteuser.moduleType="user";
  deleteuser.api_type="web";
  deleteuser.element_data = deleteuserdata;
      this.serverService.sendServer(deleteuser).subscribe((response: any) => {

      	console.log(response)
       
 

        if(response.result.status==true){
       iziToast.success({
    title: 'OK',
    position: 'topRight',
    message: 'Event deleted Successfully!',
});
       $('#addNewPopup').modal('hide');
// this.vieweventsdetail({})
this.vieweventsdetail({'search_text':'','offset':this.offset_count})
        }
        else{
  iziToast.error({
    position: 'topRight',
    message: 'Some Error Occured',
});
        }
      
      }, 
      (error)=>{
          console.log(error);
      });
}



getinstid(val){
  console.log(val)
}


viewstud(val){
console.log(this.eventeditdetials)
 let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

 
if(this.typecalled == "Event"){
var starts =moment(this.eventeditdetials.start_time).format('YYYY-MM-DD HH:mm:ss');
 var ends =moment(this.eventeditdetials.end_time).format('YYYY-MM-DD HH:mm:ss');

 console.log(starts)
 console.log(ends)

registerstudentdata.action="view_assign_students";
registerstudentdata.admin_id=admin_id;
registerstudentdata.event_name=this.addEvent.value.event_name;
registerstudentdata.start_time=starts;
registerstudentdata.end_time=ends;
registerstudentdata.inst_id=this.instructorselect;
// registerstudentdata.co_inst=this.instructorselectco;
registerstudentdata.course_id =0;
registerstudentdata.event_id  =this.event_id;
registerstudentdata.meeting_id =0;

}
else{
var starts =moment(this.courseditdetials.start_time).format('YYYY-MM-DD HH:mm:ss');
 var ends =moment(this.courseditdetials.end_time).format('YYYY-MM-DD HH:mm:ss');


registerstudentdata.action="view_assign_students";
registerstudentdata.admin_id=admin_id;
registerstudentdata.event_name=this.addCourse.value.event_name;
registerstudentdata.start_time=starts;
registerstudentdata.end_time=ends;
registerstudentdata.inst_id=this.instructorselect;

registerstudentdata.course_id =this.course_send_id;
registerstudentdata.event_id  =0;
registerstudentdata.meeting_id  =0;

// registerstudentdata.co_inst=this.instructorselectco;

}


 registeruser.access_token=access_token;
  registeruser.operation="lecture";
  registeruser.moduleType="lecture";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){
     this.get_attendees = response.result.data
this.sendstudentarr=[];
for (var i = 0; i < this.get_attendees.length; i++) {
   this.get_attendees[i].add = false;
  this.sendstudentarr.push(this.get_attendees[i].student_name)
}

 this.sendstudent = '';
   this.sendstudent=this.sendstudentarr.join(',');

if(val == 'call'){
    this.deletearr =[];
   $('#addNewPopupee').modal({
   show:true,
   backdrop:false
});
$('#addNewPopupee').modal('show');
 
}
     

   }else{

   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}


savealldata(){
   console.log(this.typecalled)
   this.sendstudent = ''
   console.log(this.sendstudentarr)

   this.sendstudent=this.sendstudentarr.join(',');
   console.log(this.sendstudent)

if(this.details == 'edit'){

if(this.typecalled == "Event"){
this.assignstudentstoevents('')
}else{
this.assignstudentstocourse('')

}
}
}

get_student_list(data){
  // if(this.details == 'edit'){
  // this.viewstud('') 
  // }
  var list_data2= this.listDataInfo2(data);
  console.log(list_data2)

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

 
    let api_req:any = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"view_student_rec","limit":"'+list_data2.limit+'","offset":'+list_data2.offset+',"search_text":"'+list_data2.search_text+'","admin_id":"'+admin_id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){
      this.studentslist=response.result.data.list_data;

console.log(this.sendstudentarr)
console.log(this.studentslist)


if(this.studentslist.length != 0){

if(this.sendstudentarr.length != 0){
for (var i = 0; i < this.studentslist.length; i++) {
   var ismatch = false;
  for (var j = 0; j < this.sendstudentarr.length; j++) {
   if(this.studentslist[i].username ==  this.sendstudentarr[j]){
     ismatch = true;
     this.studentslist[i].add = true;
     break;  

   }
 }
 if (!ismatch){
         this.studentslist[i].add = false//  checkbox status false
      
      } 

}
}
else{
  
  for (var i = 0; i < this.studentslist.length; i++) {
     this.studentslist[i].add = false  
  }
}

}

console.log(this.studentslist)




          this.offset_count2 = list_data2.offset;
        this.paginationData2 = this.serverService.pagination({'offset':response.result.data.list_info.offset, 'total':response.result.data.list_info.total, 'page_limit' :this.pageLimit2 });
         console.log(this.paginationData);
                this.recordNotFound2 = this.studentslist.length == 0 ? true : false;


      }
     

    }, 
    (error)=>{
        console.log(error);
    });
  }


getsearch2(searchValue: string): void {  
  console.log(searchValue);
  this.get_student_list({'search_text':searchValue})
}

listDataInfo2(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
} 
addstudent(){
  this.get_student_list({})
   $('#addNewPopupstud').modal({
  show:true,
   backdrop:false

});
$('#addNewPopupstud').modal('show');
}


savedata(val){

 var adding = false
if(val.add == false){

if(this.sendstudentarr.length != 0){

 for (var i = 0; i < this.sendstudentarr.length; i++) {


if(val.username !== this.sendstudentarr[i]){

 adding  = true
}

}
  if(adding)
      this.sendstudentarr.push(val.username)


}
else{
   this.sendstudentarr.push(val.username)
}

}
else{
 
   for (var j = 0; j < this.sendstudentarr.length; j++) {

if(val.username == this.sendstudentarr[j]){
 
    this.sendstudentarr.splice(this.sendstudentarr.indexOf(this.sendstudentarr[j]), 1 );

}
}


}
 
 console.log(this.sendstudentarr)
}



assignstudentstoevents(res){
// console.log(res)
  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

var starts =moment(this.eventeditdetials.start_time).format('YYYY-MM-DD HH:mm:ss');
 var ends =moment(this.eventeditdetials.end_time).format('YYYY-MM-DD HH:mm:ss');

registerstudentdata.action="assign_students";

registerstudentdata.student_name=this.sendstudent;
registerstudentdata.inst_id=this.instructorselect;
registerstudentdata.co_inst=this.instructorselectco;

registerstudentdata.admin_id=admin_id;

if(this.details == 'edit'){
  registerstudentdata.event_id=this.event_id ;
  registerstudentdata.event_name=this.addEvent.value.event_name;
registerstudentdata.start_time=starts;
registerstudentdata.end_time=ends;
registerstudentdata.type="update" ;


}else{
  registerstudentdata.event_name=res[0].event_name;
registerstudentdata.start_time=res[0].start_time;
registerstudentdata.end_time=res[0].end_time;
  registerstudentdata.event_id=res[0].id ;
}



 registeruser.access_token=access_token;
  registeruser.operation="lecture";
  registeruser.moduleType="lecture";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){

if(this.details == 'duplicate'){
    iziToast.success({
    title: 'OK',
    position: 'topRight',
    message: 'Successfully Event'+' '+res[0].id+' '+'assigned!',
}); 
     this.vieweventsdetail({}) 
}

     iziToast.success({
    title: 'OK',
    position: 'topRight',
    message: 'Successfully student assigned!',
});
   }else{
   iziToast.error({
    title: 'OK',
    position: 'topRight',
    message: 'Illegal operation!',
});
   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}


chnageinst(name){
 this.typeinstructor = name;
      this.get_instructor_list({})

  $('#addNewPopupinst').modal({
  show:true,
   backdrop:false

});
$('#addNewPopupinst').modal('show');



}

getsearchinstructor(searchValue: string): void {  
  console.log(searchValue);
  this.get_instructor_list({'search_text':searchValue})
}

get_instructor_list(data){
  var list_data= this.listDataInfo3(data);

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');
var type;
if(this.typecalled == 'Event'){
type = 'event';
}else if(this.typecalled == 'Course'){
type = 'course';
  }
    let api_req:any = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"view_instruct_rec","limit":"'+list_data.limit+'","offset":'+list_data.offset+',"search_text":"'+list_data.search_text+'","admin_id":"'+admin_id+'","type":"'+type+'"}}';
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      console.log(this.instructorselect);

      if(response.result.status == true){

      this.instructorlist = response.result.data.list_data

    for (var i = 0; i < this.instructorlist.length; i++) {
        if(this.typeinstructor == 'instructor'){
if(this.instructorlist[i].id == this.instructorselect){
this.instructorlist[i].add = true 

}
else{
this.instructorlist[i].add = false 

}

}
else{
   if(this.instructorlist[i].id == this.instructorselectco){
this.instructorlist[i].add = true 

}
else{
this.instructorlist[i].add = false 

}
}
}
//  for (var j = 0; j < this.sendstudentarr.length; j++) {
//    if(this.instructorlist[i].username ==  this.sendstudentarr[j]){
//   this.instructorlist[i].add = true 

//    }
//    else{
//   this.instructorlist[i].add = false 

//    }
// }
// }
 console.log(this.instructorlist)
      this.offset_count3= list_data.offset;
        this.paginationData3 = this.serverService.pagination({'offset':response.result.data.list_info.offset, 'total':response.result.data.list_info.total, 'page_limit' :this.pageLimit });
                this.recordNotFound3 = this.instructorlist.length == 0 ? true : false;


      }
     
    }, 
    (error)=>{
        console.log(error);
    });
  }

listDataInfo3(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
} 



savedatainstructor(val){
 console.log(val)
if(val.add == false){
  if(this.typeinstructor == "instructor"){
// this.instructorselect= val.id
// this.instructselectname = val.username

this.instructselectshow = val.id;
this.instructselectshowname = val.username;
this.instructorshowfullname = val.fname + val.lname

  }else{
this.instructselectshowco = val.id;
this.instructselectshownameco = val.username;
this.instructorshowfullnameco = val.fname + val.lname

    // this.instructorselectco= val.id
// this.instructselectnameco = val.username

  }


}

}

savealldata2(){
  if(this.typeinstructor == "instructor"){
this.instructorselect= this.instructselectshow;
this.instructselectname = this.instructselectshowname;
this.instructorfullname = this.instructorshowfullname;

  }else{
 
    this.instructorselectco= this.instructselectshowco
this.instructselectnameco =this.instructselectshownameco
this.instructorfullnameco = this.instructorshowfullnameco;

  }



}

// updateCourseData(){
  
// }

showedit(val,name){
  console.log(val)
 

  this.sendstudentarr =[];


  this.details = name
  // if(name == 'duplicate'){
    

  // }
  if(val.type == "Event"){

 if(this.details == 'duplicate'){
  
this.editeventdetails(val.id,val.type)
 
}
else{
this.editeventdetails(val.id,val.type)

}
  }
  else{
    if(this.details == 'duplicate'){

this.duplicate_course_details(val.id,val.type)

    }else{
this.edit_course_details(val.id,val.type)

    }
  }

}

duplicate_course_details(l,type){


this.typecalled = type;

this.courseditdetials =[];


 let editevents:any = new Object();
 let editeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');


editevents.action="get_course_details";
editevents.course_id=l;

 editeventsuser.access_token=access_token;
  editeventsuser.operation="lecture";
  editeventsuser.moduleType="lecture";
  editeventsuser.api_type="web";
  editeventsuser.element_data = editevents;
      this.serverService.sendServer(editeventsuser).subscribe((response: any) => {
console.log(response)
        if(response.result.status==true && response.result.data.length != 0){
this.courseditdetials = response.result.data[0]
this.id =response.result.data[0].id
        this.daysarray =response.result.data[0].days.split(',')
        console.log(this.daysarray)
this.instructorselect = response.result.data[0].inst_id
         this.instructorselectco = response.result.data[0].co_inst_id
         this.instructselectnameco =response.result.data[0].co_inst;

         this.instructselectname =response.result.data[0].instructors;

this.instructorfullname = response.result.data[0].inst_name;
   this.instructorfullnameco= response.result.data[0].co_inst_name;

this.event_id =response.result.data[0].id;
this.course_send_id =response.result.data[0].course_id;


console.log(moment(TODAY_STR).format('YYYY-MM-DDTHH:mm'))
console.log(moment(response.result.data[0].end_time).format('YYYY-MM-DDTHH:mm'))
if(moment(TODAY_STR).format('YYYY-MM-DDTHH:mm') >= moment(response.result.data[0].start_time).format('YYYY-MM-DDTHH:mm')){

  this.editshow = true;
}else{
  this.editshow = false;
}




          this.addCourse.patchValue({
event_name: response.result.data[0].event_name,
 instructors :response.result.data[0].instructors,
 start_time:moment(response.result.data[0].start_time).format('HH:mm:ss'),
 end_time:moment(response.result.data[0].end_time).format('HH:mm:ss'),
 start_date:moment(response.result.data[0].start_time).format('YYYY-MM-DD'),
 // count:response.result.data[0].count,
 location:response.result.data[0].location,
 no_of_class:response.result.data[0].no_of_class,
editshowing :this.editshow

  });

// for (var i = 0; i < this.days.length; i++) {
//   if(this.days[i] ==  this.daysarray)
// }

for (var i = 0; i < this.days.length; i++) {
   var ismatchs = false;
  for (var j = 0; j < this.daysarray.length; j++) {
   if(this.days[i].id ==  this.daysarray[j]){
     ismatchs = true;
     this.days[i].checked = true;
     break;  

   }
 }
 if (!ismatchs){
         this.days[i].checked = false//  checkbox status false
      
      } 

}




if(this.details == "duplicate"){
  this.viewstud('')
}


       console.log(this.events);
  $('#addNewPopupcourse').modal({
   show:true,
   backdrop:false
});
$('#addNewPopupcourse').modal('show');
 
 
          }
        else{

        }
      
      }, 
      (error)=>{
          console.log(error);
      });





}













edit_course_details(l,type){
this.typecalled = type;

this.courseditdetials =[];
  let editevents:any = new Object();
 let editeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');


editevents.action="edit_course_details";
editevents.id=l;

 editeventsuser.access_token=access_token;
  editeventsuser.operation="user";
  editeventsuser.moduleType="user";
  editeventsuser.api_type="web";
  editeventsuser.element_data = editevents;
      this.serverService.sendServer(editeventsuser).subscribe((response: any) => {

        if(response.result.status==true && response.result.data.length != 0){
this.courseditdetials = response.result.data[0]
this.id =response.result.data[0].id
        
this.instructorselect = response.result.data[0].inst_id
         this.instructorselectco = response.result.data[0].co_inst_id

 this.instructorselectview = response.result.data[0].inst_id;
         this.instructorselectviewco = response.result.data[0].co_inst_id;
         
         this.instructselectnameco =response.result.data[0].co_inst;

         this.instructselectname =response.result.data[0].instructors;

this.instructorfullname = response.result.data[0].instruct_name;
   this.instructorfullnameco= response.result.data[0].co_instruct_name;

this.event_id =response.result.data[0].id;
this.course_send_id =response.result.data[0].course_id;
console.log(moment(TODAY_STR).format('YYYY-MM-DDTHH:mm'))
console.log(moment(response.result.data[0].end_time).format('YYYY-MM-DDTHH:mm'))
if(moment(TODAY_STR).format('YYYY-MM-DDTHH:mm') >= moment(response.result.data[0].start_time).format('YYYY-MM-DDTHH:mm')){

  this.editshow = true;
}else{
  this.editshow = false;
}

// if(response.result.data[0].course_id != "0"){
// this.titleshow = "Course"
// }else{
// this.titleshow = "Course"
// }


          this.addCourse.patchValue({
event_name: response.result.data[0].event_name,
 instructors :response.result.data[0].instructors,
 start_time:moment(response.result.data[0].start_time).format('HH:mm:ss'),
 end_time:moment(response.result.data[0].end_time).format('HH:mm:ss'),
 start_date:moment(response.result.data[0].start_time).format('YYYY-MM-DD'),
 // count:response.result.data[0].count,
 location:response.result.data[0].location,
editshowing :this.editshow

  });
this.viewstud('')
          
       console.log(this.events);
  $('#addNewPopupcourse').modal({
   show:true,
   backdrop:false
});
$('#addNewPopupcourse').modal('show');
 
 
          }
        else{

        }
      
      }, 
      (error)=>{
          console.log(error);
      });
}



assignstudentstocourse(res){

  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');


  let updateevents:any =this.addCourse.value;


registerstudentdata.action="add_course_students";

if(this.details == 'edit'){

registerstudentdata.event_name=this.courseditdetials.event_name;
registerstudentdata.start_time= moment(this.courseditdetials.start_time).format('YYYY-MM-DD');
registerstudentdata.id=this.id;

registerstudentdata.from_time=this.courseditdetials.start_time+':'+'00'
registerstudentdata.to_time=this.courseditdetials.end_time+':'+'00'
registerstudentdata.course_id=this.courseditdetials.course_id;
registerstudentdata.no_of_class=this.courseditdetials.no_of_class;
registerstudentdata.days=this.courseditdetials.days;
registerstudentdata.type="update";
registerstudentdata.inst_id=this.instructorselectview;
registerstudentdata.co_inst=this.instructorselectviewco;
}else{


  registerstudentdata.event_name=this.addCourse.value.event_name;
registerstudentdata.start_time= moment(this.addCourse.value.start_date).format('YYYY-MM-DD');
registerstudentdata.from_time=this.addCourse.value.start_time
registerstudentdata.to_time=this.addCourse.value.end_time
registerstudentdata.course_id=res;
registerstudentdata.no_of_class=this.addCourse.value.no_of_class;
registerstudentdata.days=this.dayspick;
registerstudentdata.inst_id=this.instructorselect;
registerstudentdata.co_inst=this.instructorselectco;
}




registerstudentdata.student_name=this.sendstudent;

registerstudentdata.admin_id=admin_id;



 registeruser.access_token=access_token;
  registeruser.operation="lecture";
  registeruser.moduleType="lecture";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.data.status=="true" ){

if(this.details == 'edit'){
     iziToast.success({
    title: 'OK',
    position: 'topRight',
    message: 'Successfully Course Details Updated!',
});
 this.vieweventsdetail({'search_text':'','offset':this.offset_count})
     
}else{
  iziToast.success({
    title: 'OK',
    position: 'topRight',
    message: 'Successfully Course'+' '+res+''+'Assigned!',
  })
 this.vieweventsdetail({'search_text':'','offset':this.offset_count})
}
   }

   else{
   iziToast.error({
    title: 'OK',
    position: 'topRight',
    message: 'Error Occured!',
});
   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}


assignevent(){


console.log(this.addEvent.value)


  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');

    let admin_id: any=localStorage.getItem('userId');
    let superadmin: any=localStorage.getItem('superadmin');

registerstudentdata.action="add_event_details";
registerstudentdata.admin_id=admin_id;
registerstudentdata.location=this.addEvent.value.location;


registerstudentdata.super_admin=superadmin;

registerstudentdata.event_name=this.addEvent.value.event_name;
registerstudentdata.instructors=this.instructselectname;
registerstudentdata.co_inst=this.instructselectnameco;

registerstudentdata.notes='';
registerstudentdata.start_time=this.addEvent.value.start_time;
registerstudentdata.end_time=this.addEvent.value.end_time;
registerstudentdata.status=''
// ;

 registeruser.access_token=access_token;
  registeruser.operation="user";
  registeruser.moduleType="user";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
       
 

        if(response.result.status==true){
   
this.assignstudentstoevents(response.result.data)

        }
        else{
iziToast.error({
    position: 'topRight',
    message: 'Illegal operation!',
});
        }
      
      }, 
      (error)=>{
          console.log(error);
      });
}


viewevents(){

  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');


registerstudentdata.action="view_events";
registerstudentdata.admin_id=admin_id;

 registeruser.access_token=access_token;
  registeruser.operation="user";
  registeruser.moduleType="user";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){
     this.eventslist = response.result.data
   }else{

   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}


add_course(){

  console.log(this.addCourse.value)
  // console.log(this.eventData.cousreendtime)

//   console.log(this.days)
this.dayspick =''
var daysarray=[];
for (var i = 0; i<this.days.length; i++) {
  if(this.days[i].checked == true)
  {
   daysarray.push(this.days[i].id)
  }
}
this.dayspick = daysarray.toString();
  console.log(this.dayspick)

  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

registerstudentdata.location=this.addCourse.value.location;

registerstudentdata.action="add_new_course";
registerstudentdata.event_name=this.addCourse.value.event_name;
registerstudentdata.instructors=this.instructselectname;
registerstudentdata.co_inst=this.instructselectnameco;

registerstudentdata.start_time= moment(this.addCourse.value.start_date).format('YYYY-MM-DD');

registerstudentdata.no_of_class=this.addCourse.value.no_of_class;

registerstudentdata.notes='';
registerstudentdata.from_time=this.addCourse.value.start_time
registerstudentdata.to_time=this.addCourse.value.end_time

registerstudentdata.admin_id=admin_id;
registerstudentdata.days=this.dayspick;
 registeruser.access_token=access_token;
  registeruser.operation="lecture";
  registeruser.moduleType="lecture";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.data.status== "true"){
//    iziToast.success({
//     title: 'OK',
//     position: 'Successfully Course Id'+response.result.data.course_id+'Assigned !',
//     message: 'Illegal operation!',
// });


    this.assignstudentstocourse(response.result.data.course_id)
   }else{
   iziToast.error({
    title: 'OK',
    position: 'topRight',
    message: 'Illegal operation!',
});
   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}

viewlocation(){

  let updateevents:any =new Object();
 let updateeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
   
updateevents.action="view_location";

updateevents.admin_id =localStorage.getItem('userId');


 updateeventsuser.access_token=access_token;
  updateeventsuser.operation="lecture";
  updateeventsuser.moduleType="lecture";
  updateeventsuser.api_type="web";
  updateeventsuser.element_data = updateevents;
      this.serverService.sendServer(updateeventsuser).subscribe((response: any) => {
console.log(response)
        if(response.result.status==true){
     this.locations = response.result.data
   

          }
        else{

 
        }
      
      }, 
      (error)=>{
          console.log(error);
      });

}

adddeletestudent(val){
 



 var adding = false
if(val.add == false){

if(this.deletearr.length != 0){

 for (var i = 0; i < this.deletearr.length; i++) {


if(val.id !== this.deletearr[i]){

 adding  = true
}

}
  if(adding){
      this.deletearr.push(val.id)


  }
}
else{
  this.deletearr.push(val.id)

}

}
else{

   for (var j = 0; j < this.deletearr.length; j++) {

if(val.id == this.deletearr[j]){

    this.deletearr.splice(this.deletearr.indexOf(this.deletearr[j]), 1 );

}
}
 

}
 
 console.log(this.deletearr)
}
deleteassignstudent(){
 
this.senddeletenames = '';
this.senddeletenames = this.deletearr.join(',');
 let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');

registerstudentdata.action="remove_assign_students";
registerstudentdata.id=this.senddeletenames;
       
 registeruser.access_token=access_token;
  registeruser.operation="lecture";
  registeruser.moduleType="lecture";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){

   iziToast.success({
    title: 'OK',
    position: 'topRight',
    message: 'Deleted Successfully!',
});
$('#addNewPopupee').modal('hide');

   }else{

   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}

duplicatepermisiion(){
  
  let user:any = new Object();
 let userdata:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
  let admin_id: any=localStorage.getItem('superadmin');

userdata.action="get_duplicate";
// userdata.id=this.id;
userdata.user_id=admin_id;

 user.access_token=access_token;
  user.operation="user";
  user.moduleType="user";
  user.api_type="web";
  user.element_data = userdata;
      this.serverService.sendServer(user).subscribe((response: any) => {

        console.log(response)
       
 

        if(response.result.status==true){
   
this.enableduplicate = response.result.data.enable_duplicate
}
        else{
 

        }
      
      }, 
      (error)=>{
          console.log(error);
      });
}






updatecourseData(){

  let updateevents:any =this.addEvent.value;
 let updateeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
 

updateevents.action="update_course_details";
updateevents.admin_id =localStorage.getItem('userId');
updateevents.id=this.id;
updateevents.start_dt =this.addCourse.value.start_date;
updateevents.start_time =this.addCourse.value.start_time;
updateevents.end_time =this.addCourse.value.end_time;



 updateeventsuser.access_token=access_token;
  updateeventsuser.operation="user";
  updateeventsuser.moduleType="user";
  updateeventsuser.api_type="web";
  updateeventsuser.element_data = updateevents;
      this.serverService.sendServer(updateeventsuser).subscribe((response: any) => {

        if(response.result.status==true){
       // this.addevents()
      iziToast.success({

          message: "course Updated successfully",
          position: 'topRight'
      });
      // $('#addNewPopup').modal('hide');
 // window.location.reload();
 this.vieweventsdetail({'search_text':'','offset':this.offset_count})
          }
        else{


        }
      
      }, 
      (error)=>{
          console.log(error);
      });

}







}

