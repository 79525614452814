
import {Injectable} from '@angular/core';
import { Component, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
// import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
import { Router } from '@angular/router';
import * as _ from 'underscore';

declare var iziToast:any;
@Injectable({
  providedIn: 'root'
})
export class ServerService {
show:Subject<any> = new Subject();
  constructor(private http:HttpClient) { }

//   sendServer(postData:any[]) {
//         const httpOptions = {
//           headers: new HttpHeaders({
//             'Content-Type':  'application/json'
//           })
//         };
//         return this.http.post("https://booking.mconnectapps.com/api/v1.0/index.php", postData,httpOptions);

//     } 
    
  sendServer(postData:any[]) {
        // const httpOptions = {
        let headers = new HttpHeaders();
          headers.append('Content-Type', 'application/json');
        // };
        return this.http.post("https://booking.mconnectapps.com/api/v1.0/index.php",  postData, { headers: headers });

    } 
    
  
    // sendServerLogin(postData:any[]) {
    //     // const httpOptions = {
    //     let headers = new HttpHeaders();
    //       headers.append('Content-Type', 'application/json');
    //     // };
    //     return this.http.post("https://booking.mconnectapps.com/api/v1.0/index.php",  JSON.stringify(postData), { headers: headers });

    // } 
    // sendServer(postData: any[]) {

    //     return new Promise((resolve, reject) => {
    //       let headers = new HttpHeaders();
    //       headers.append('Content-Type', 'application/json');
    
    //       this.http.post('https://booking.mconnectapps.com/api/v1.0/index.php', JSON.stringify(postData), { headers: headers })
    //         .subscribe(res => {
    //           resolve(res);
    //         }, (err) => {
    //           reject(err);
    //         });
    //     });
    //   }
  pagination(list_info){
        var start,eu,next,back,limit,total_count,offset,last_val,last_final_val,current,pagination,btn_length;
        limit = list_info.page_limit;
        total_count = list_info.total;
        offset = list_info.offset;
        start = 0 + offset;
        eu = start-0;
        if(total_count<start+1 && total_count>1){

            eu = start-limit;
            start = eu;
        }
        current = eu + limit;
        back = eu - limit;
        next = eu + limit;
        last_val = Math.ceil(total_count/limit);
        last_final_val = (last_val-1)*limit;
        pagination = {"info":"hide"};
        if(total_count > limit){
            pagination.info = "show";
            pagination.start = 0;

            if(back >= 0){
                pagination.back = back;
                pagination.backtab = "show";
            }
            else{
                pagination.backtab = "hide";
            }
        
            btn_length = 1;
            pagination.data = []
            for(var offset_count = 0;offset_count < total_count;offset_count=offset_count+limit){

                if((offset_count<=eu+(2*limit)) && (offset_count>=eu-(2*limit))){

                    if(offset_count != eu){
                        pagination.data.push({"btn_length":btn_length,"offset_count":offset_count,"load":true});
                    }
                    else{
                        pagination.data.push({"btn_length":btn_length,"offset_count":offset_count,"load":false});
                    }
                
                }
                 btn_length=btn_length+1;

            }
            if(current < total_count){
                pagination.next = next;
                pagination.nexttab = "show";
            }
            else{
                pagination.nexttab = "hide";
            }
            pagination.end = last_final_val;

        }

        return pagination;
    }
    








 getPager(totalItems: number, currentPage: number = 1, pageSize: number = 5) {
        // calculate total pages
        let totalPages = Math.ceil(totalItems / pageSize);

        let startPage: number, endPage: number;
        // if (totalPages <= 10) {
        //     // less than 10 total pages so show all
        //     startPage = 1;
        //     endPage = totalPages;
        // } else {
        //     // more than 10 total pages so calculate start and end pages
        //     if (currentPage <= 6) {
        //         startPage = 1;
        //         endPage = 10;
        //     } else if (currentPage + 4 >= totalPages) {
        //         startPage = totalPages - 9;
        //         endPage = totalPages;
        //     } else {
        //         startPage = currentPage - 5;
        //         endPage = currentPage + 4;
        //     }
        // }
        
        if (totalPages <= 5) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= 3) {
                startPage = 1;
                endPage = 5;
            } else if (currentPage + 1 >= totalPages) {
                startPage = totalPages - 4;
                endPage = totalPages;
            } else {
                startPage = currentPage - 2;
                endPage = currentPage+2;
            }
        }

        // calculate start and end item indexes
        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        let pages = _.range(startPage, endPage + 1);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }















}
  