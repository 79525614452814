<div class="card">
    
    <input type="hidden" id="reconnect_socket"  (click)="initSocket()">
    <input type="hidden" id="reloaddashboard"  (click)="getLots()">
    <input type="hidden" id="reloadcounts"  (click)="getcounts()">

    <div class="card-header">
        <div class="card-header-title">
            <h4>Parking Lot Wall Board</h4>
        </div>
        <!-- <div class="card-header-btn-group">
            <a class="btn btn-success" ng-reflect-router-link="/recordevents" href="#/recordevents">
                <i aria-hidden="true" class="fa fa-eye"></i> Create New Lot</a>
            </div> -->
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-xl-5 col-lg-6">
                    <div class="card l-bg-green-dark">
                        <div class="card-statistic-3">
                            <div class="card-icon">
                            <img alt="image" src="assets/images/car-park/capark.png">
                        </div>
                            <div class="card-content">
                                <h4 class="card-title">Occupied Parking Lot</h4>
                                <div class="card-event-details">
                                    <h2>{{this.scanned}} / {{this.total_slots}}</h2>
                                    <p class="mb-0 text-sm">
                                        <span class="mr-2"><i class="far fa-calendar-alt"></i></span>
                                        <span class="text-nowrap">{{this.current_date}}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-5 col-lg-6">
                    <div class="card l-bg-purple-dark">
                        <div class="card-statistic-3">
                            <div class="card-icon">
                                <img alt="image" src="assets/images/car-park/visitors-count.png">
                            </div>
                            <div class="card-content">
                                    <h4 class="card-title">Visitor count</h4>
                                    <div class="card-event-details">
                                        <h2>XX</h2>
                                        <p class="mb-0 text-sm">
                                            <span class="mr-2"><i class="far fa-calendar-alt"></i></span>
                                            <span class="text-nowrap">{{this.current_date}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
</div>
</div>

<div class="card">
    <div class="card-header">
        <div class="card-header-title">
            <h4>Parking Lot Status</h4>
        </div>
        <div class="card-header-btn-group">
            <a class="btn btn-success mr-1">
                <i aria-hidden="true" class="fa fa-car"></i> Available</a>
                <a class="btn btn-danger ">
                    <i aria-hidden="true" class="fa fa-car"></i> Occupied</a>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
           
                <div class="col-xl-3" *ngFor="let l of carlots_list;let i=index">
                    <div   class="card">                 
                   <h2 style="display: block;text-align: center;" class="mt-5">{{l.lot_name}}</h2>
                  
                 <div   class="card-body">   
                        <img style="width: 50%;" *ngIf="l.status=='0'"  class="img-center"  src="assets/images/car-park/car-gr.png" alt="Available">
                        <img style="width: 50%;" *ngIf="l.status=='1'"  class="img-center"  src="assets/images/car-park/car-red.png" alt="Occupied">
                    <div style="margin: 13px auto;text-align: center;">
                        <a (click)="makeEntry(l.lot_name,l.id)" title="Make Entry" *ngIf="l.status=='0'"  class="btn btn-success">
                            <i aria-hidden="true" class="fas fa-sign-in-alt"></i>  Entry</a>
                            <a (click)="makeExit(l.lot_name,l.id)"  title="Make Exit"  *ngIf="l.status=='1'"  class="btn btn-danger">
                            <i aria-hidden="true" class="fas fa-sign-out-alt"></i>  Exit</a>
                    </div>
                </div>
            </div>
                </div>
                </div>
</div>
</div>

<!-- Make entry -->
<div class="modal fade bd-example-modal-lg" id="makeEntryPopup" aria-modal="true">
    <div class="modal-dialog modal-lg">

        <div class="modal-content">


            <div class="modal-header">
                <h5 class="modal-title" id="myLargeModalLabel">Make Entry to {{e_lotname}}</h5>

            </div>

            <div class="modal-body">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="card-body">
                         
                            <div class="col-md-12 col-12">
                                <div class="form-group form-float">
                                  <div class="form-line">
                                    <label class="form-label"><b>Visitor Name</b></label>
                                    <input type="text" id="visitor_name" class="form-control" >            
                                  </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-12">
                                <div class="form-group form-float">
                                  <div class="form-line">
                                    <label class="form-label"><b>Mobile Number</b></label>
                                    <input type="number" id="visitor_mobile" class="form-control" >            
                                  </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-12">
                                <div class="form-group form-float">
                                  <div class="form-line">
                                    <label class="form-label"><b>Vehicle No</b></label>
                                    <input (input)="code.value = code.value.toUpperCase()" type="text" id="visitor_vehicle" class="form-control" #code >            
                                  </div>
                                </div>
                            </div>                           


                            
                        </div>


                    </div>



                </div>
            </div>
            <div class="modal-footer bg-whitesmoke br">

                <button type="button" class="btn btn-success" (click)="AddEntry()">Create</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
            </div>



        </div>
    </div>
</div>
<!-- Make Entry End -->