  import { Component, OnInit } from '@angular/core';
  import { ServerService } from '../services/server.service';
  declare var $:any;
  declare var iziToast:any;
  import Swal from 'sweetalert2'
  @Component({
    selector: 'app-active-logs',
    templateUrl: './active-logs.component.html',
    styleUrls: ['./active-logs.component.css']
  })
  export class ActiveLogsComponent implements OnInit {
  
    constructor(private serverService:ServerService) { }
    carlots_log;
    websocket
    ngOnInit(): void {
      this.getLogs();
      this.initSocket();
    }
    initSocket(){
   
    
      this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4035"); 
   
  
  
      this.websocket.onopen = function(event) { 
        console.log('active Slots socket connected');
      }
  
      this.websocket.onmessage = function(event) {  
        var result_message= JSON.parse(event.data);
        console.log(result_message)
          if(result_message.message_type=="parking"){
          $('#reloadlogs').click();
          }
        
      }
  
      this.websocket.onerror = function(event){
        console.log('error');
      }
      this.websocket.onclose = function(event){
        $('#reconnect_socket').click();
  
  }
  
  
  
  
  }
    getLogs(){
      let access_token: any=localStorage.getItem('access_token');
      let admin_id: any=localStorage.getItem('supersuperadmin');
       let api_req:any = '{"operation":"carparking","moduleType":"carparking","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"slot_reports_active","admin_id":"'+admin_id+'"}}'
       Swal.fire({
        html:
          '<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
      showCloseButton: false,
        showCancelButton: false,
        showConfirmButton: false,
        focusConfirm: false,
        background: 'transparent'
      });
         this.serverService.sendServer(api_req).subscribe((response:any) => {
           Swal.close()
           console.log(response);
           if(response.result.status == true){
         this.carlots_log=response.result.data; 
         this.carlots_log.filter(it =>it.status === 1);     
      
           }
     
     
         }, 
         (error)=>{
             console.log(error);
         });
    }   
    
    makeExit(name,id){
      Swal.fire({
        title: 'Are you sure?',
        text: "You want to Exit this Slot manually",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.value) {
      let admin_id: any=localStorage.getItem('supersuperadmin');
      let api_data:any =  new Object();
      let api_req:any = new Object();
      api_data.action="parking_exit_web";
      api_data.status=0;   
      api_data.lot_no=id;
      api_data.exit_by='Web';
      api_data.id=id;
      api_data.admin_id=admin_id;
      api_req.operation="carparking";
      api_req.moduleType="carparking";
      api_req.api_type="web";
      api_req.element_data=api_data;
      //  let api_req:any = '{"operation":"carparking","moduleType":"carparking","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"parking_slot","id":""}}'
         this.serverService.sendServer(api_req).subscribe((response:any) => {
           
           console.log(response);   
           if(response.result.status==true){
            iziToast.success({
              message: "Slot "+name+" exited Successfully",
              position: 'topRight'
            });
            this.getLogs();
            var socket_message  =  '{"message_type":"parking_web","message_status":"qrscan_exit","message_info" : {"status":"0"}}';
            this.websocket.send(socket_message);
           }
           else{
            iziToast.warning({
              message: "Sorry, we having trouble to exit the slot "+name+".Please contact admin",
              position: 'topRight'
            });  
           }
         }, 
         (error)=>{
             console.log(error);
         });
        }
      });
    }
  }
  