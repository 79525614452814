import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
declare var $:any;
declare var iziToast:any;
import * as moment from 'moment';
const TODAY_STR = new Date(); 
@Component({
  selector: 'app-eventtemplate',
  templateUrl: './eventtemplate.component.html',
  styleUrls: ['./eventtemplate.component.css']
})
export class EventtemplateComponent implements OnInit {

  meetingtemplatelist:any=[];

addEvent: FormGroup;

instructorlist:any=[]
 
 studentslist:any=[];
recordNotFound = false;
recordNotFound2 = false;
recordNotFound3 = false;

 pageLimit = 5;
 pageLimit2 = 10;
 pageLimit3 = 10;

 paginationData:any ={"info":"hide"};
 paginationData2:any ={"info":"hide"};
 paginationData3:any ={"info":"hide"};

 offset_count = 0;
  offset_count2 = 0;
  offset_count3 = 0;


 participantarr=[];

 sendparticipant;
selectedinst;

 participantnames:any=[];


locations:any=[];
 typeinstructor;
instructorselect;
  instructorselectco;

 instructselectname;
instructselectnameco;


 instructorfullname;
 instructorfullnameco;

 instructorshowfullname;
 instructorshowfullnameco;

instructselectshow;
instructselectshowco;

instructselectshowname;
instructselectshownameco;

 showsendnames:any=[];
courseidsend;
meetingidsend;
get_attendees:any=[];
eventeditdetials:any =[];
eventslist:any=[];
 participantlist:any=[];

 instructorselectview;
 instructorselectviewco;
 event_id;
 editshow;
 titleshow;
  constructor(private serverService: ServerService,private route:ActivatedRoute,private router:Router) { 

  
this.event_template_list()
this.viewlocation()
this.viewevents()
 
}


showedit(val){
  console.log(val)
 

  this.participantarr =[];
 
this.editeventdetails(val.event_id,val.type,val)
 
}

assignevent(){


console.log(this.addEvent.value)


  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');

    let admin_id: any=localStorage.getItem('userId');
    let superadmin: any=localStorage.getItem('superadmin');

registerstudentdata.action="add_event_details";
registerstudentdata.admin_id=admin_id;
registerstudentdata.location=this.addEvent.value.location;


registerstudentdata.super_admin=superadmin;

registerstudentdata.event_name=this.addEvent.value.event_name;
registerstudentdata.instructors=this.instructorselect;
registerstudentdata.co_inst=this.instructorselectco;

registerstudentdata.notes="";
registerstudentdata.start_time=this.addEvent.value.start_time;
registerstudentdata.end_time=this.addEvent.value.end_time;
registerstudentdata.status=''
// ;

 registeruser.access_token=access_token;
  registeruser.operation="user";
  registeruser.moduleType="user";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
       
 

        if(response.result.status==true){
   
this.assignstudentstoevents(response.result.data)

        }
        else{
iziToast.error({
    position: 'topRight',
    message: 'Illegal operation!',
});
        }
      
      }, 
      (error)=>{
          console.log(error);
      });
}

editeventdetails(l,type,val){

this.eventeditdetials =[];
  let editevents:any = new Object();
 let editeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');

editevents.event_name=val.event_name;
editevents.admin_id =localStorage.getItem('userId');

editevents.action="edit_event_details";
editevents.id=l;

 editeventsuser.access_token=access_token;
  editeventsuser.operation="user";
  editeventsuser.moduleType="user";
  editeventsuser.api_type="web";
  editeventsuser.element_data = editevents;
      this.serverService.sendServer(editeventsuser).subscribe((response: any) => {

        if(response.result.status==true && response.result.data.length != 0){
this.eventeditdetials = response.result.data[0]
console.log(this.eventeditdetials)
// this.id =response.result.data[0].event_id
         this.instructorselect = response.result.data[0].inst_id

         this.instructorselectview = response.result.data[0].inst_id;
         this.instructorselectviewco = response.result.data[0].co_inst_id;

         this.instructorselectco = response.result.data[0].co_inst_id
       

         this.instructselectname =response.result.data[0].instructors;
           this.instructselectnameco =response.result.data[0].co_inst;

         this.instructorfullname = response.result.data[0].instruct_name;
         this.instructorfullnameco= response.result.data[0].co_instruct_name;

      

         this.instructselectname =response.result.data[0].instructors;
         // this.instructselectname =response.result.data[0].instructors;

this.event_id =response.result.data[0].event_id;
console.log(moment(TODAY_STR).format('YYYY-MM-DDTHH:mm'))
console.log(moment(response.result.data[0].end_time).format('YYYY-MM-DDTHH:mm'))
if(moment(TODAY_STR).format('YYYY-MM-DDTHH:mm') >= moment(response.result.data[0].start_time).format('YYYY-MM-DDTHH:mm')){

  this.editshow = true;
}else{
  this.editshow = false;
}

// if(response.result.data[0].course_id != "0"){
// this.titleshow = "Course"
// }else{
this.titleshow = "Event"
// }


          this.addEvent.patchValue({
event_name: response.result.data[0].event_name,
 instructors :response.result.data[0].instructors,
 start_time:moment(response.result.data[0].start_time).format('YYYY-MM-DDTHH:mm'),
 end_time:moment(response.result.data[0].end_time).format('YYYY-MM-DDTHH:mm'),
 count:response.result.data[0].count,
 location:response.result.data[0].location,
editshowing :this.editshow

  });

  this.viewstud('')


  $('#addNewPopup').modal({
   show:true,
   backdrop:false
});
$('#addNewPopup').modal('show');
 
 
          }
        else{

        }
      
      }, 
      (error)=>{
          console.log(error);
      });
}



viewstud(val){
console.log(this.eventeditdetials)
 let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

var starts =moment(this.eventeditdetials.start_time).format('YYYY-MM-DD HH:mm:ss');
 var ends =moment(this.eventeditdetials.end_time).format('YYYY-MM-DD HH:mm:ss');

 console.log(starts)
 console.log(ends)

registerstudentdata.action="view_assign_students";
registerstudentdata.admin_id=admin_id;
registerstudentdata.event_name=this.addEvent.value.event_name;
registerstudentdata.start_time=starts;
registerstudentdata.end_time=ends;
registerstudentdata.inst_id=this.instructorselectview;
registerstudentdata.course_id =0;
registerstudentdata.event_id  =this.event_id;
registerstudentdata.meeting_id  =0;

 registeruser.access_token=access_token;
  registeruser.operation="lecture";
  registeruser.moduleType="lecture";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){
     this.get_attendees = response.result.data
this.participantarr=[];
for (var i = 0; i < this.get_attendees.length; i++) {
   this.get_attendees[i].add = false;
  this.participantarr.push(this.get_attendees[i].student_name)
  
}
  this.sendparticipant = ''
   this.sendparticipant=this.participantarr.join(',');

// if(val == 'call'){
//     this.deletearr =[];
//    $('#addNewPopupee').modal({
//    show:true,
//    backdrop:false
// });
// $('#addNewPopupee').modal('show');
 
// }
     

   }else{

   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}

assignstudentstoevents(res){
// console.log(res)
  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

var starts =moment(this.eventeditdetials.start_time).format('YYYY-MM-DD HH:mm:ss');
 var ends =moment(this.eventeditdetials.end_time).format('YYYY-MM-DD HH:mm:ss');

registerstudentdata.action="assign_students";

registerstudentdata.student_name=this.sendparticipant;



registerstudentdata.admin_id=admin_id;
registerstudentdata.event_name=res[0].event_name;
registerstudentdata.start_time=res[0].start_time;
registerstudentdata.end_time=res[0].end_time;
registerstudentdata.event_id=res[0].id ;
registerstudentdata.inst_id=this.instructorselect;
registerstudentdata.co_inst=this.instructorselectco;




 registeruser.access_token=access_token;
  registeruser.operation="lecture";
  registeruser.moduleType="lecture";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){


    iziToast.success({
    title: 'OK',
    position: 'topRight',
    message: 'Successfully Event'+' '+res[0].id+' '+'added!',
});

   }else{
   iziToast.error({
    title: 'OK',
    position: 'topRight',
    message: 'Please Try Again Later!',
});
   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}


event_template_list(){

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

   
      let api_req:any  = '{"operation":"lecture","moduleType":"lecture","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"event_template","admin_id":"'+admin_id+'"}}'
   
  
  

  // 
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response){
      this.meetingtemplatelist= response.result.data

     
    
      console.log(this.meetingtemplatelist);
      }
   

    }, 
    (error)=>{
        console.log(error);
    });
  }


  ngOnInit(): void {
 

  this.addEvent = new FormGroup({
      'event_name' : new FormControl(null,Validators.required),
      'instructors' : new FormControl(null,Validators.required),
      'start_time' : new FormControl(null,Validators.required),
      'end_time' : new FormControl(null,Validators.required),
      'location' : new FormControl(null,Validators.required),
      'editshowing' : new FormControl(null)
     });  



  }

savealldata2(){
  if(this.typeinstructor == "instructor"){
this.instructorselect= this.instructselectshow;
this.instructselectname = this.instructselectshowname;
this.instructorfullname = this.instructorshowfullname;

  }else{
 
    this.instructorselectco= this.instructselectshowco
this.instructselectnameco =this.instructselectshownameco
this.instructorfullnameco = this.instructorshowfullnameco;

  }



}


savealldataparticip(){

   this.sendparticipant = ''
   console.log(this.participantarr)

   this.sendparticipant=this.participantarr.join(',');
   console.log(this.sendparticipant)


}



getsearchinstructor(searchValue: string): void {  
  console.log(searchValue);
  this.get_instructor_list({'search_text':searchValue})
}







addsubmit(){

}






addstudent(){
  this.get_participant_list({})
   $('#addNewPopupparticipant').modal({
  show:true,
   backdrop:false

});
$('#addNewPopupparticipant').modal('show');
}


get_participant_list(data){
    // if(this.details == 'edit'){
  // this.viewstud() 
  // }
  var list_data= this.listDataInfo2(data);
  console.log(list_data)

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

 
      let api_req:any = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"view_participant","limit":"'+list_data.limit+'","offset":'+list_data.offset+',"search_text":"'+list_data.search_text+'","admin_id":"'+admin_id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){
      this.participantlist=response.result.data.list_data;

console.log(this.participantarr)
console.log(this.participantlist)


if(this.participantlist.length != 0){

if(this.participantarr.length != 0){
for (var i = 0; i < this.participantlist.length; i++) {
   var ismatch = false;
  for (var j = 0; j < this.participantarr.length; j++) {
   if(this.participantlist[i].id ==  this.participantarr[j]){
     ismatch = true;
     this.participantlist[i].add = true;
     break;  

   }
 }
 if (!ismatch){
         this.participantlist[i].add = false//  checkbox status false
      
      } 

}
}
else{
  
  for (var i = 0; i < this.participantlist.length; i++) {
     this.participantlist[i].add = false  
  }
}

}

console.log(this.participantlist)




          this.offset_count2 = list_data.offset;
        this.paginationData2 = this.serverService.pagination({'offset':response.result.data.list_info.offset, 'total':response.result.data.list_info.total, 'page_limit' :this.pageLimit2 });
         console.log(this.paginationData);
                this.recordNotFound2 = this.participantlist.length == 0 ? true : false;


      }
     

    }, 
    (error)=>{
        console.log(error);
    });
  }



getsearchpaticipant(searchValue: string): void {  
  console.log(searchValue);
  this.get_participant_list({'search_text':searchValue})
}



listDataInfo3(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit3 : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
}  


listDataInfo(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
}  

chnageinst(name){
 this.typeinstructor = name;
      this.get_instructor_list({})

  $('#addNewPopupinst').modal({
  show:true,
   backdrop:false

});
$('#addNewPopupinst').modal('show');



}



listDataInfo2(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit2 : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
} 


savedatainstructor(val){
 console.log(val)
if(val.add == false){
  if(this.typeinstructor == "instructor"){


this.instructselectshow = val.id;
this.instructselectshowname = val.username;
this.instructorshowfullname = val.fname + val.lname

  }else{
this.instructselectshowco = val.id;
this.instructselectshownameco = val.username;
this.instructorshowfullnameco = val.fname + val.lname



  }


}

}

get_instructor_list(data){
  var list_data= this.listDataInfo3(data);

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');



  
    let api_req:any = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"view_instruct_rec","limit":"'+list_data.limit+'","offset":"'+list_data.offset+'","search_text":"'+list_data.search_text+'","admin_id":"'+admin_id+'","type":"event"}}';
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){
      this.instructorlist = response.result.data.list_data

    for (var i = 0; i < this.instructorlist.length; i++) {

      if(this.typeinstructor == 'instructor'){
        if(this.instructorlist[i].id == this.instructorselect){
this.instructorlist[i].add = true 

}
else{
this.instructorlist[i].add = false 

}
      }
      else{
                if(this.instructorlist[i].id == this.instructorselectco){
this.instructorlist[i].add = true 

}
else{
this.instructorlist[i].add = false 

}
      }


}

 console.log(this.instructorlist)
      this.offset_count3= list_data.offset;
        this.paginationData3 = this.serverService.pagination({'offset':response.result.data.list_info.offset, 'total':response.result.data.list_info.total, 'page_limit' :this.pageLimit3 });
                this.recordNotFound3 = this.instructorlist.length == 0 ? true : false;


      }
     
    }, 
    (error)=>{
        console.log(error);
    });
  }

viewlocation(){

  let updateevents:any =new Object();
 let updateeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
   
updateevents.action="view_location";

updateevents.admin_id =localStorage.getItem('userId');


 updateeventsuser.access_token=access_token;
  updateeventsuser.operation="lecture";
  updateeventsuser.moduleType="lecture";
  updateeventsuser.api_type="web";
  updateeventsuser.element_data = updateevents;
      this.serverService.sendServer(updateeventsuser).subscribe((response: any) => {
console.log(response)
        if(response.result.status==true){
     this.locations = response.result.data
   

          }
        else{

 
        }
      
      }, 
      (error)=>{
          console.log(error);
      });

}






viewevents(){

  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');


registerstudentdata.action="view_events";
registerstudentdata.admin_id=admin_id;

 registeruser.access_token=access_token;
  registeruser.operation="user";
  registeruser.moduleType="user";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){
     this.eventslist = response.result.data
   }else{

   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}



savealldataparticipant(){
   // console.log(this.typecalled)
   this.sendparticipant = ''
   console.log(this.participantarr)

   this.sendparticipant=this.participantarr.join(',');
   console.log(this.sendparticipant)




// this.assignstudentstomeeting('')


}



savedataparticipant(val){

 var adding = false
if(val.add == false){

if(this.participantarr.length != 0){

 for (var i = 0; i < this.participantarr.length; i++) {


if(val.id !== this.participantarr[i]){

 adding  = true
}

}
  if(adding){
      this.participantarr.push(val.id)
      this.participantnames.push(val.name)

  }
}
else{
   this.participantarr.push(val.id)

    this.participantnames.push(val.name)
}

}
else{

   for (var j = 0; j < this.participantarr.length; j++) {

if(val.id == this.participantarr[j]){

    this.participantarr.splice(this.participantarr.indexOf(this.participantarr[j]), 1 );

}
}
   for (var k = 0; k < this.participantnames.length; k++) {

if(val.name == this.participantnames[k]){

    this.participantnames.splice(this.participantnames.indexOf(this.participantnames[k]), 1 );

}
}

}
 

}














}