import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ServerService } from '../services/server.service';
import * as feather from 'feather-icons';
import { Router,ActivatedRoute } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
declare var $:any;
declare function forming():any;
declare var iziToast:any;
import '../../assets/js/scripts.js';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-addnewparticipantevent',
  templateUrl: './addnewparticipantevent.component.html',
  styleUrls: ['./addnewparticipantevent.component.css']
})
export class AddnewparticipanteventComponent implements OnInit {

   @ViewChild(WizardComponent)
    public wizard: WizardComponent;
information: FormGroup;
step2:FormGroup;
nextForm = false;
id;
page ='';
settingshow = false;
participanteditdetails:any=[];

eSave = true;
imagePath;
formData;
Logo;
 eventslist:any=[];
 eventbind;
  constructor(private route:ActivatedRoute,private router:Router,private serverService: ServerService) { 
 this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {

     this.id=this.router.getCurrentNavigation().extras.state.id; 
     this.page=this.router.getCurrentNavigation().extras.state.page; 

     console.log(this.id)
     console.log(this.page)

if(this.page == 'Edit'){
     this.get_edit_participant()

}
    
      }
    });

  }

 
 ngOnInit(): void {

    feather.replace();
    this.information = new FormGroup({
      'fname' : new FormControl(null,Validators.required),
      // 'lname' : new FormControl(null,Validators.required),

      'email' : new FormControl(null),
      'gender':new FormControl(null,Validators.required),
      'country_code' : new FormControl(null,Validators.required),
 'phone_no' : new FormControl(null,Validators.required),
      'home' : new FormControl(null),
  'country':new FormControl(null,Validators.required),
      // 'name' : new FormControl(null,Validators.required),
    
      'nric' : new FormControl(null),
   'age':new FormControl(null),

      'address':new FormControl(null),
  
  
     });
 this.step2 = new FormGroup({
      'username' : new FormControl(null),    
      'password' : new FormControl(null)
        // 'confirm':new FormControl(null,Validators.required)
        
        
     
     
     });
 // this.information.value.gender ="";
 // this.information.value.country_code ="65";

this.information.patchValue({

  gender: "", 
 country_code :"65",
 country: "Singapore"
  });

  }


 localUrl;
 file: File;
  showPreviewImage(event: any) {
    this.file = event.target.files[0];
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();
            reader.onload = (event: any) => {
                this.localUrl = event.target.result;
            }
            reader.readAsDataURL(event.target.files[0]);
        }
    }


 get password() {
  return this.step2.controls['password'];
}

get confirm_password(){
  return this.step2.controls['confirm'];
}

onPasswordChange() {
  if (this.confirm_password.value == this.password.value) {
    this.confirm_password.setErrors(null);
  } else {
    this.confirm_password.setErrors({ mismatch: true });
  }
}


  moveToNext() {
   
    console.log(this.information.value)
        if (this.information.valid) {
    
this.wizard.goToNextStep();
            
        } else {

            this.validateAllFields(this.information); 
        }
    }

moveToNext2(){
   if(this.step2.valid){
   this.registeruserdata()
   }
     
   
   else {
            this.validateAllFields(this.step2); 
        }
  }

validateAllFields(formGroup: FormGroup) {         
        Object.keys(formGroup.controls).forEach(field => {  
            const control = formGroup.get(field);            
            if (control instanceof FormControl) {             
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {        
                this.validateAllFields(control);  
            }
        });
    }
        



registeruserdata(){
 
  

    let access_token: any=localStorage.getItem('access_token');
  let superadmin: any=localStorage.getItem('superadmin');
    let admin_id: any=localStorage.getItem('userId');

    var formData = new FormData();
    formData.append('operation', 'user');
    formData.append('moduleType', 'user');
    formData.append('api_type', 'web');



    if(this.file != undefined){
    formData.append('image', this.file);

  }else{
    formData.append('image','');

  }


    formData.append('access_token', access_token);
    formData.append('admin_id', admin_id);
    formData.append('super_admin', superadmin);
  formData.append('name', this.information.value.fname);
  formData.append('email', this.information.value.email);
  formData.append('country_code', this.information.value.country_code);
  formData.append('phone_no',this.information.value.phone_no);
  formData.append('gender',this.information.value.gender);
  formData.append('home', this.information.value.home);
  formData.append('country', this.information.value.country);
  formData.append('nric',this.information.value.nric);
  formData.append('dob', this.information.value.age);
  formData.append('address', this.information.value.address);


  formData.append('username', this.step2.value.username);
  formData.append('password',this.step2.value.password);

 
if(this.page == 'Edit'){
  formData.append('id', this.id);
formData.append('action', 'update_participant');
}else{
formData.append('action', 'participant_rec');

}

    console.log(formData);
  var s = this;
  $.ajax({  
    url:"https://booking.mconnectapps.com/api/v1.0/index_new.php",  
    type : 'POST',
    data : formData,
    processData: false,  // tell jQuery not to process the data
    contentType: false, 
    success:function(data){ 
      var parsed_data = JSON.parse(data);
      console.log(this.parsed_data );

      if(parsed_data.status == 'true'){  
      if(s.page == 'Edit'){
          iziToast.success({
          message: "Participant Data Updated successfully",
          position: 'topRight'
      });
s.router.navigate(['/participantrecordevent'])

      }else{
  iziToast.success({
          message: "Participant added successfully",
          position: 'topRight'
      });
s.router.navigate(['/participantrecordevent'])
  
      }  
      
      } else {
        if(parsed_data.status){
          
        iziToast.error({
          message: parsed_data.status,
          position: 'topRight'
      });
      
}
else{
   iziToast.error({
          message: "Please try again later",
          position: 'topRight'
      });
}

      }
    }  
});  

  }






   
get_edit_participant(){
    let access_token: any=localStorage.getItem('access_token');
   let admin_id: any=localStorage.getItem('userId');
    let api_req:any = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"edit_participant","id":"'+this.id+'","admin_id":"'+admin_id+'"}}';
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){
    this.participanteditdetails = response.result.data
 this.localUrl= response.result.data[0].image

  this.information.patchValue({

      email:this.participanteditdetails[0].email,
      fname:this.participanteditdetails[0].name,
     
      phone_no:this.participanteditdetails[0].phone_no,
      country_code:this.participanteditdetails[0].country_code,
     gender:this.participanteditdetails[0].gender,
      home:this.participanteditdetails[0].home,
      country:this.participanteditdetails[0].country,
      nric:this.participanteditdetails[0].nric,
      age:this.participanteditdetails[0].dob,
      address:this.participanteditdetails[0].address,

      

  });

 this.step2.patchValue({
username: this.participanteditdetails[0].username,
 password :this.participanteditdetails[0].psw,
 // confirm:this.participanteditdetails[0].psw
  });

      }

    }, 
    (error)=>{
        console.log(error);
    });
  }











}
