import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Router,ActivatedRoute } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
declare var $:any;
declare var iziToast:any;
import * as moment from 'moment';
const TODAY_STR = new Date(); 
@Component({
  selector: 'app-meetingrecord',
  templateUrl: './meetingrecord.component.html',
  styleUrls: ['./meetingrecord.component.css']
})
export class MeetingrecordComponent implements OnInit {
meetings:any=[];
addEvent : FormGroup;
IsmodelShow = false;
id;
recordNotFound = false;
 pageLimit = 10;
 paginationData:any ={"info":"hide"};
 offset_count = 0;
 instructorlist:any=[];
get_attendees:any=[];
instid;
meeting_id;

 participantlist:any=[];
recordNotFound2 = false;

 pageLimit2 = 10;
// pageLimit3
 paginationData2:any ={"info":"hide"};

  offset_count2 = 0;

 sendparticipant;
 // sendstudentarr=[];
 participantarr=[];
 deletearr:any=[];
selectedinst;

 participantnames:any=[];
recordNotFound3 = false;

 pageLimit3 = 10;

 paginationData3:any ={"info":"hide"};

  offset_count3 = 0;

instructorselect;
instructselectname;
instructorselectco;
instructselectnameco;
titleshow = "Event"

eventeditdetials:any=[];

typecalled;
 editshow = false;
 typeinstructor;

instructselectshow;
instructselectshowname;

instructselectshowco;
instructselectshownameco;

 instructorfullname;
 instructorshowfullname;

  instructorfullnameco;
 instructorshowfullnameco;

eventslist:any=[];
details;

 days:any=[];
 dayspick;
 daysarray:any=[];
 locations:any=[];
 course_send_id;
 senddeletenames;
 instructorselectview;
instructorselectviewco;
type;
enableduplicate;
  constructor(private route:ActivatedRoute,private router:Router,private serverService: ServerService) { }

 
 ngOnInit(): void {
  	this.viewevents()
    this.viewlocation()
      this.vieweventsdetail({})
this.duplicatepermisiion()
 this.type = localStorage.getItem('type');
console.log(this.type)

    this.addEvent = new FormGroup({
      'event_name' : new FormControl(null,Validators.required),
      'instructors' : new FormControl(null,Validators.required),
      'start_time' : new FormControl(null,Validators.required),
      'end_time' : new FormControl(null,Validators.required),
      'count' : new FormControl(null,Validators.required),
      'location' : new FormControl(null,Validators.required),
      'reference' : new FormControl(null,Validators.required),
      'editshowing' : new FormControl(null),
      'mobile_display' : new FormControl(null)
     });  
  }


templatepage(){
this.router.navigate(['addlesson'], { state: { type: "meeting"} });

}


delete_meeting(val){

   let deleteuser:any = new Object();
  
 let deleteuserdata:any = new Object();
    let access_token: any=localStorage.getItem('access_token');


deleteuserdata.action="delete_meeting";
deleteuserdata.admin_id=localStorage.getItem('userId');;
deleteuserdata.id=val.id;


 deleteuser.access_token=access_token;
  deleteuser.operation="meeting";
  deleteuser.moduleType="meeting";
  deleteuser.api_type="web";
  deleteuser.element_data = deleteuserdata;
      this.serverService.sendServer(deleteuser).subscribe((response: any) => {

        console.log(response)
       
 

        if(response.result.status==true){
       iziToast.success({
    title: 'OK',
    position: 'topRight',
    message: 'Deleted Successfully!',
});
       this.vieweventsdetail({'search_text':'','offset':this.offset_count}) 
        }
        else{
  iziToast.error({
    position: 'topRight',
    message: 'Some Error Occured',
});
        }
      
      }, 
      (error)=>{
          console.log(error);
      });
    

}



eventpage(name){
this.router.navigate(['addnewevent'], { state: { type: name} });

}

assignnew(){
this.router.navigate(['assignnewevent'], { state: { pagetype: "meeting"} });

}

vieweventsdetail(data){
  var list_data= this.listDataInfo(data);

  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');
registerstudentdata.admin_id=admin_id;


registerstudentdata.action="meeting_list";

registerstudentdata.limit=list_data.limit;
registerstudentdata.offset=list_data.offset;
registerstudentdata.search_text=list_data.search_text;



 registeruser.access_token=access_token;
  registeruser.operation="meeting";
  registeruser.moduleType="meeting";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
       
 

        if(response.result.status==true){
          this.meetings = response.result.data.list_data
         

         for (var i = 0; i < this.meetings.length; i++) {
           this.meetings[i].start =moment(this.meetings[i].meeting_dt).format('DD-MM-YYYY & hh:mm a'),
           this.meetings[i].end =moment(this.meetings[i].meeting_end).format('DD-MM-YYYY & hh:mm a')

 this.meetings[i].durationhours =moment(this.meetings[i].end_time).diff(this.meetings[i].start_time, 'hours')
 this.meetings[i].durationminutes =moment(this.meetings[i].end_time).diff(this.meetings[i].start_time, 'minutes')% 60
            this.meetings[i].duration

this.meetings[i].durationshow = this.meetings[i].durationhours + " hours and " + this.meetings[i].durationminutes + " minutes";
 
    }


         
           this.offset_count = list_data.offset;
        this.paginationData = this.serverService.pagination({'offset':response.result.data.list_info.offset, 'total':response.result.data.list_info.total, 'page_limit' :this.pageLimit });
         console.log(this.paginationData);
                this.recordNotFound = this.meetings.length == 0 ? true : false;

        }
      
      }, 
      (error)=>{
          console.log(error);
      });
}

listDataInfo(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
} 



viewevents(){

  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');


registerstudentdata.action="view_events";
registerstudentdata.admin_id=admin_id;

 registeruser.access_token=access_token;
  registeruser.operation="user";
  registeruser.moduleType="user";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){
     this.eventslist = response.result.data
   }else{

   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}

viewlocation(){

  let updateevents:any =new Object();
 let updateeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
   
updateevents.action="view_location";

updateevents.admin_id =localStorage.getItem('userId');


 updateeventsuser.access_token=access_token;
  updateeventsuser.operation="lecture";
  updateeventsuser.moduleType="lecture";
  updateeventsuser.api_type="web";
  updateeventsuser.element_data = updateevents;
      this.serverService.sendServer(updateeventsuser).subscribe((response: any) => {
console.log(response)
        if(response.result.status==true){
     this.locations = response.result.data
   

          }
        else{

 
        }
      
      }, 
      (error)=>{
          console.log(error);
      });

}
getsearch(searchValue: string): void {  
  console.log(searchValue);
  this.vieweventsdetail({'search_text':searchValue})
}
getsearchpaticipant(searchValue: string): void {  
  console.log(searchValue);
  this.get_participant_list({'search_text':searchValue})
}

showedit(val,name){
  console.log(val)
 
  this.participantarr =[];
console.log(name)

  this.details = name


 // if(this.details == 'duplicate'){
  
this.editmeetingdetails(val.id,'')
 
// }
// else{
// this.editmeetingdetails(val.id,val.type)

// }
  
 

}
chnageinst(name){
 this.typeinstructor = name;
      this.get_instructor_list({})

  $('#addNewPopupinst').modal({
  show:true,
   backdrop:false

});
$('#addNewPopupinst').modal('show');

}


getsearchinstructor(searchValue: string): void {  
  console.log(searchValue);
  this.get_instructor_list({'search_text':searchValue})
}

get_instructor_list(data){
  var list_data= this.listDataInfo3(data);

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

  
    let api_req:any = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"view_instruct_rec","limit":"'+list_data.limit+'","offset":'+list_data.offset+',"search_text":"'+list_data.search_text+'","admin_id":"'+admin_id+'","type":"meeting"}}';
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      console.log(this.instructorselect);

      if(response.result.status == true){

      this.instructorlist = response.result.data.list_data

    for (var i = 0; i < this.instructorlist.length; i++) {
        if(this.typeinstructor == 'instructor'){
if(this.instructorlist[i].id == this.instructorselect){
this.instructorlist[i].add = true 

}
else{
this.instructorlist[i].add = false 

}

}
else{
   if(this.instructorlist[i].id == this.instructorselectco){
this.instructorlist[i].add = true 

}
else{
this.instructorlist[i].add = false 

}
}
}

 console.log(this.instructorlist)
      this.offset_count3= list_data.offset;
        this.paginationData3 = this.serverService.pagination({'offset':response.result.data.list_info.offset, 'total':response.result.data.list_info.total, 'page_limit' :this.pageLimit3 });
                this.recordNotFound3 = this.instructorlist.length == 0 ? true : false;


      }
     
    }, 
    (error)=>{
        console.log(error);
    });
  }

listDataInfo3(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
} 


editmeetingdetails(l,type){

this.typecalled = type;
this.eventeditdetials =[];
  let editevents:any = new Object();
 let editeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');

let admin_id: any=localStorage.getItem('userId');

editevents.action="edit_meeting";
editevents.id=l;
editevents.admin_id=admin_id;

 editeventsuser.access_token=access_token;
  editeventsuser.operation="meeting";
  editeventsuser.moduleType="meeting";
  editeventsuser.api_type="web";
  editeventsuser.element_data = editevents;
      this.serverService.sendServer(editeventsuser).subscribe((response: any) => {

        if(response.result.status==true && response.result.data.length != 0){
this.eventeditdetials = response.result.data[0]
console.log(this.eventeditdetials)
this.id =response.result.data[0].id
         this.instructorselect = response.result.data[0].inst_id;
         this.instructorselectco = response.result.data[0].co_inst_id;

            this.instructorselectview = response.result.data[0].inst_id;
         this.instructorselectviewco = response.result.data[0].co_inst_id;
         // this.instructselectnameco =response.result.data[0].co_inst;

         // this.instructselectname =response.result.data[0].instructors;
         this.instructorfullname = response.result.data[0].instruct_name;
         this.instructorfullnameco= response.result.data[0].co_instruct_name;

      

         // this.instructselectname =response.result.data[0].instructors;
         // this.instructselectname =response.result.data[0].instructors;

this.meeting_id =response.result.data[0].meeting_id;
console.log(moment(TODAY_STR).format('YYYY-MM-DDTHH:mm'))
console.log(moment(response.result.data[0].meeting_end).format('YYYY-MM-DDTHH:mm'))
if(moment(TODAY_STR).format('YYYY-MM-DDTHH:mm') >= moment(response.result.data[0].meeting_dt).format('YYYY-MM-DDTHH:mm')){

  this.editshow = true;
}else{
  this.editshow = false;
}

// if(response.result.data[0].course_id != "0"){
// this.titleshow = "Course"
// }else{
this.titleshow = "Meeting"
// }
var showcheck;
if(response.result.data[0].mobile_display == "1"){
showcheck = true
}else{
  showcheck = false
}





          this.addEvent.patchValue({
event_name: response.result.data[0].meeting_name,
 instructors :response.result.data[0].instructors,
 start_time:moment(response.result.data[0].meeting_dt).format('YYYY-MM-DDTHH:mm'),
 end_time:moment(response.result.data[0].meeting_end).format('YYYY-MM-DDTHH:mm'),
 count:response.result.data[0].count,
 reference:response.result.data[0].reference,
mobile_display :showcheck,
 location:response.result.data[0].location,
editshowing :this.editshow,


  });
// if(this.details == "duplicate"){
  this.viewstud('')
// }else{
   // this.viewstud('')
// }



       // console.log(this.events);
  $('#addNewPopup').modal({
   show:true,
   backdrop:false
});
$('#addNewPopup').modal('show');
 
 
          }
        else{

        }
      
      }, 
      (error)=>{
          console.log(error);
      });
}

savedatainstructor(val){
 console.log(val)
if(val.add == false){
  if(this.typeinstructor == "instructor"){
// this.instructorselect= val.id
// this.instructselectname = val.username

this.instructselectshow = val.id;
this.instructselectshowname = val.username;
this.instructorshowfullname = val.fname + val.lname

  }else{
this.instructselectshowco = val.id;
this.instructselectshownameco = val.username;
this.instructorshowfullnameco = val.fname + val.lname

    // this.instructorselectco= val.id
// this.instructselectnameco = val.username

  }


}

}

savealldata2(){
  if(this.typeinstructor == "instructor"){
this.instructorselect= this.instructselectshow;
this.instructselectname = this.instructselectshowname;
this.instructorfullname = this.instructorshowfullname;

  }else{
 
    this.instructorselectco= this.instructselectshowco
this.instructselectnameco =this.instructselectshownameco
this.instructorfullnameco = this.instructorshowfullnameco;

  }



}

deleteassignstudent(){
  // console.log(val)
this.senddeletenames = '';
this.senddeletenames = this.deletearr.join(',');
 let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');

registerstudentdata.action="remove_assign_students";
registerstudentdata.id=this.senddeletenames;
       
 registeruser.access_token=access_token;
  registeruser.operation="lecture";
  registeruser.moduleType="lecture";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){

   iziToast.success({
    title: 'OK',
    position: 'topRight',
    message: 'Deleted Successfully!',
});
$('#addNewPopupee').modal('hide');

   }else{

   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}



viewstud(val){
console.log(this.eventeditdetials)
 let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

var starts =moment(this.eventeditdetials.meeting_dt).format('YYYY-MM-DD HH:mm:ss');
 var ends =moment(this.eventeditdetials.meeting_end).format('YYYY-MM-DD HH:mm:ss');

 console.log(starts)
 console.log(ends)

registerstudentdata.action="view_assign_students";
registerstudentdata.admin_id=admin_id;
registerstudentdata.event_name=this.addEvent.value.event_name;
registerstudentdata.start_time=starts;
registerstudentdata.end_time=ends;
registerstudentdata.inst_id=this.instructorselectview;
// registerstudentdata.co_inst=this.instructorselectco;
registerstudentdata.course_id =0;
registerstudentdata.event_id  =0;

registerstudentdata.meeting_id  =this.meeting_id;




 registeruser.access_token=access_token;
  registeruser.operation="lecture";
  registeruser.moduleType="lecture";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){
     this.get_attendees = response.result.data
this.participantarr=[];
for (var i = 0; i < this.get_attendees.length; i++) {
  this.get_attendees[i].add = false;
  this.participantarr.push(this.get_attendees[i].student_name)
  
}
  this.sendparticipant=this.participantarr.join(',');

if(val == 'call'){
  this.deletearr =[];
   $('#addNewPopupee').modal({
   show:true,
   backdrop:false
});
$('#addNewPopupee').modal('show');
 
}
     

   }else{

   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}


savedataparticipant(val){

 var adding = false
if(val.add == false){

if(this.participantarr.length != 0){

 for (var i = 0; i < this.participantarr.length; i++) {


if(val.id !== this.participantarr[i]){

 adding  = true
}

}
  if(adding){
      this.participantarr.push(val.id)
      this.participantnames.push(val.name)

  }
}
else{
   this.participantarr.push(val.id)

    this.participantnames.push(val.name)
}

}
else{

   for (var j = 0; j < this.participantarr.length; j++) {

if(val.id == this.participantarr[j]){

    this.participantarr.splice(this.participantarr.indexOf(this.participantarr[j]), 1 );

}
}
   for (var k = 0; k < this.participantnames.length; k++) {

if(val.name == this.participantnames[k]){

    this.participantnames.splice(this.participantnames.indexOf(this.participantnames[k]), 1 );

}
}

}
 

}


addstudent(){
  this.get_participant_list({})
   $('#addNewPopupparticipant').modal({
  show:true,
   backdrop:false

});
$('#addNewPopupparticipant').modal('show');
}

listDataInfo2(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
} 
get_participant_list(data){
	 //  if(this.details == 'edit'){
  // this.viewstud('') 
  // }
  var list_data= this.listDataInfo2(data);
  console.log(list_data)

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

 
      let api_req:any = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"view_participant","limit":"'+list_data.limit+'","offset":'+list_data.offset+',"search_text":"'+list_data.search_text+'","admin_id":"'+admin_id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){
      this.participantlist=response.result.data.list_data;
//          for (var i = 0; i < this.studentslist.length; i++) {
 
//    this.studentslist[i].add = false
  
// }
console.log(this.participantarr)
console.log(this.participantlist)


if(this.participantlist.length != 0){

if(this.participantarr.length != 0){
for (var i = 0; i < this.participantlist.length; i++) {
   var ismatch = false;
  for (var j = 0; j < this.participantarr.length; j++) {
   if(this.participantlist[i].id ==  this.participantarr[j]){
     ismatch = true;
     this.participantlist[i].add = true;
     break;  

   }
 }
 if (!ismatch){
         this.participantlist[i].add = false//  checkbox status false
      
      } 

}
}
else{
  
  for (var i = 0; i < this.participantlist.length; i++) {
     this.participantlist[i].add = false  
  }
}

}

console.log(this.participantlist)




          this.offset_count2 = list_data.offset;
        this.paginationData2 = this.serverService.pagination({'offset':response.result.data.list_info.offset, 'total':response.result.data.list_info.total, 'page_limit' :this.pageLimit3 });
         console.log(this.paginationData);
                this.recordNotFound2 = this.participantlist.length == 0 ? true : false;


      }
     

    }, 
    (error)=>{
        console.log(error);
    });
  }





savealldata(){
   console.log(this.typecalled)
   this.sendparticipant = ''
   console.log(this.participantarr)

   this.sendparticipant=this.participantarr.join(',');
   console.log(this.sendparticipant)

if(this.details == 'edit'){


this.assignstudentstomeeting('')

}
}

assignstudentstomeeting(res){
// console.log(res)
  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

var starts =moment(this.eventeditdetials.start_time).format('YYYY-MM-DD HH:mm:ss');
 var ends =moment(this.eventeditdetials.end_time).format('YYYY-MM-DD HH:mm:ss');

registerstudentdata.action="assign_participant";
registerstudentdata.participant_name=this.sendparticipant;

registerstudentdata.inst_id=this.instructorselectview;
registerstudentdata.co_inst=this.instructorselectviewco;


// registerstudentdata.inst_id=this.instructorselect;
// registerstudentdata.co_inst=this.instructorselectco;

registerstudentdata.admin_id=admin_id;

if(this.details == 'edit'){
  registerstudentdata.meeting_id=this.meeting_id ;
  registerstudentdata.meeting_name=this.addEvent.value.event_name;
registerstudentdata.start_time=starts;
registerstudentdata.end_time=ends;
registerstudentdata.type="update" ;



}else{
	registerstudentdata.meeting_id=res[0].id;
	registerstudentdata.meeting_name=res[0].meeting_name;
	registerstudentdata.start_time=res[0].meeting_dt;
registerstudentdata.end_time=res[0].meeting_end;

}



 registeruser.access_token=access_token;
  registeruser.operation="meeting";
  registeruser.moduleType="meeting";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){

if(this.details == 'duplicate'){
    iziToast.success({
    title: 'OK',
    position: 'topRight',
    message: 'Successfully Meeting'+' '+res[0].id+' '+'assigned!',
}); 
     this.vieweventsdetail({}) 
}

     iziToast.success({
    title: 'OK',
    position: 'topRight',
    message: 'Successfully Participant assigned!',
});
   }else{
   iziToast.error({
    title: 'OK',
    position: 'topRight',
    message: 'Error Occured!',
});
   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}

updateEventData(){
// this.IsmodelShow=true

    var sends;
if(this.addEvent.value.mobile_display == true){
  sends = 1;
}else{
sends = 0;
}


  let updateevents:any =this.addEvent.value;
 let updateeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let superadmin: any=localStorage.getItem('superadmin');

// console.log(this.addEvent.value.instructors)

updateevents.action="update_meeting";
updateevents.meeting_name=this.addEvent.value.event_name;
updateevents.incharge_id=this.instructorselect;
updateevents.co_incharge_id=this.instructorselectco;
updateevents.meeting_dt=this.addEvent.value.start_time;
updateevents.meeting_end=this.addEvent.value.end_time;
updateevents.location=this.addEvent.value.location;
updateevents.reference=this.addEvent.value.reference;
updateevents.template=0;
updateevents.template_name='';
updateevents.meeting_id=this.meeting_id;
updateevents.notes='';
updateevents.mobile_display=sends;


updateevents.super_admin=superadmin;
// updateevents.id=this.id;
updateevents.admin_id =localStorage.getItem('userId');
// updateevents.instructors =this.instructselectname;
// updateevents.co_inst =this.instructselectnameco;

 updateeventsuser.access_token=access_token;
  updateeventsuser.operation="meeting";
  updateeventsuser.moduleType="meeting";
  updateeventsuser.api_type="web";
  updateeventsuser.element_data = updateevents;
      this.serverService.sendServer(updateeventsuser).subscribe((response: any) => {

        if(response.result.status==true){
       // this.addevents()
      iziToast.success({

          message: "Event Updated successfully",
          position: 'topRight'
      });
      // $('#addNewPopup').modal('hide');
 // window.location.reload();
 this.vieweventsdetail({})
          }
        else{


        }
      
      }, 
      (error)=>{
          console.log(error);
      });

}

assignmeeting(){

  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');

    let admin_id: any=localStorage.getItem('userId');
    let superadmin: any=localStorage.getItem('superadmin');
    var templatesend;
// if(this.eventData.template == true){
//   templatesend = 1;
// }else{
templatesend = 0;
// }

    var sends;
if(this.addEvent.value.mobile_display == true){
  sends = 1;
}else{
sends = 0;
}

registerstudentdata.action="create_meeting";
registerstudentdata.admin_id=admin_id;
registerstudentdata.location=this.addEvent.value.location;
registerstudentdata.reference=this.addEvent.value.reference;
registerstudentdata.mobile_display=sends;


registerstudentdata.super_admin=superadmin;

registerstudentdata.meeting_name=this.addEvent.value.event_name;
registerstudentdata.incharge_id=this.instructorselect;
registerstudentdata.co_incharge_id=this.instructorselectco;

registerstudentdata.template=templatesend;
registerstudentdata.template_name='';

registerstudentdata.notes='';
registerstudentdata.meeting_dt=this.addEvent.value.start_time;
registerstudentdata.meeting_end=this.addEvent.value.end_time;
// registerstudentdata.status=''
;

 registeruser.access_token=access_token;
  registeruser.operation="meeting";
  registeruser.moduleType="meeting";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
       
 

        if(response.result.status==true){
this.assignstudentstomeeting(response.result.data)

        }
        else{
iziToast.error({
    // title: 'OK',
    position: 'topRight',
    message: 'Illegal operation!',
});
        }
      
      }, 
      (error)=>{
          console.log(error);
      });
}

adddeletestudent(val){
 



 var adding = false
if(val.add == false){

if(this.deletearr.length != 0){

 for (var i = 0; i < this.deletearr.length; i++) {


if(val.id !== this.deletearr[i]){

 adding  = true
}

}
  if(adding){
      this.deletearr.push(val.id)


  }
}
else{
  this.deletearr.push(val.id)

}

}
else{

   for (var j = 0; j < this.deletearr.length; j++) {

if(val.id == this.deletearr[j]){

    this.deletearr.splice(this.deletearr.indexOf(this.deletearr[j]), 1 );

}
}
 

}
 
 console.log(this.deletearr)
}


  
removealert(methodname,val){
var s = this;
  iziToast.question({
    timeout: 20000,
    close: false,
    overlay: true,
    displayMode: 'once',
    id: 'question',
    zindex: 999,
    title: 'Confirm',
    message: 'Are you sure you want to delete?',
    position: 'center',
    buttons: [
        ['<button><b>YES</b></button>', function (instance, toast) {
 
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
 console.log('yes')
 if(methodname == 'delete_meeting'){
   s.delete_meeting(val)
 }
else if(methodname == 'deleteassignstudent'){
  s.deleteassignstudent()
}
        }, true],
        ['<button>NO</button>', function (instance, toast) {
 
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
  console.log('no')

        }],
    ]

});

}

duplicatepermisiion(){
  
  let user:any = new Object();
 let userdata:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
  let admin_id: any=localStorage.getItem('superadmin');

userdata.action="get_duplicate";
// userdata.id=this.id;
userdata.user_id=admin_id;

 user.access_token=access_token;
  user.operation="user";
  user.moduleType="user";
  user.api_type="web";
  user.element_data = userdata;
      this.serverService.sendServer(user).subscribe((response: any) => {

        console.log(response)
       
 

        if(response.result.status==true){
   
this.enableduplicate = response.result.data.enable_duplicate
}
        else{
 

        }
      
      }, 
      (error)=>{
          console.log(error);
      });
}
}