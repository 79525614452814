<div class="section-body">
    <div class="row">
        <div class="col-7 col-md-7 col-lg-7">
            <div class="card">
                <div class="card-header">
                    <h4>Event Report</h4>
                  
                </div>
                <div class="card-body p-30">
                <form [formGroup]="getRep" (ngSubmit)="getReports()">




       <div class="row" >
                       <!--  <div class="col-md-12">
                            <div class="form-group">
                                <label for="auxcode_name" style="margin-right: 10px !important">Choose Type</label>
        <input type="radio" id="Number" [(ngModel)]="eventData.types"  class="mrg-right" name="types"   value="events" [ngModelOptions]="{standalone: true}" >
  <label for="phone number"  class="mrg-right">Event</label>
    <input type="radio" id="Number" [(ngModel)]="eventData.types" class="mrg-right"  name="types"  value="course" [ngModelOptions]="{standalone: true}">
  <label for="phone number"  class="mrg-right">Course</label>
                 


                            </div>
                        </div> -->





              <!--   <div class="col-md-12" *ngIf="eventData.types == 'course'">
                            <div class="form-group">
                                <label for="auxcode_name">Select Course Name</label>
                                <select  id="auxcode_name" class="form-control " formControlName="course_name">
                                    <option value="" selected>Select</option>
                                    <option *ngFor="let l of courselist" value="{{l.course_id}}">{{l.course_name}}</option>
                                </select>
                     


                            </div>
                        </div> -->
             
                        


 


                <div class="col-md-12">
                            <div class="form-group">
                                <label for="auxcode_name">Select Event Name</label>
                                <select   id="auxcode_name" class="form-control " formControlName="event_name">
                                    <option value="" selected>Select</option>
                                    <option *ngFor="let l of eventslist" value="{{l.id}}">{{l.event_name}}</option>
                                </select>
                       <!--  <div class="error-message message-small" *ngIf="!getRep.get('event_name').valid && getRep.get('event_name').touched">
                      Please Select Event Name
                    </div> -->



                            </div>
                        </div>



                    </div>
  <div class="row">


                        <div class="col-md-6">
                            <div class="form-group">
                            
                                 <button type="button" class="btn btn-primary" (click)="addstudent()" style="margin-right: 10px !important">
                                 Add Participant</button>
                                 <!-- <p>Added Participants : {{sendparticipantname}}</p> -->


   




                            </div>
                        </div>

<div class="col-md-12">
 <div class="form-group">
<div>
  <p style="margin-bottom: : 0px !important" >Added Participants:</p>
 <div class="row">
  
    
 <div class="col-sm-2 col-md-2"*ngFor="let l of participantnames" style="background-color:lavender;border: 1px solid">{{l}}</div>
    
  </div>
</div>
</div>
</div>






                           <!--  <div class="col-md-6">
                            <div class="form-group">
                          
                                 <button type="button" class="btn btn-primary" (click)="addinstructor()" style="margin-right: 10px !important">Add Instructor</button>
                                 <p>Added Instructor : {{instructselectname}}</p>
                            </div>
                        </div> -->
 <div class="col-md-12">
                            <div class="form-group">
                                <label for="auxcode_name">Select Report Type</label>
                                <select name="auxcode_name"  id="auxcode_name" class="form-control " formControlName="report_type">
                                    <option value="" selected>Select</option>

                        <option value="html">HTML</option>
                        <option value="csv">CSV</option>
                        <!-- <option value="pdf">PDF</option> -->
                        

                                     
                                </select>
                                          <div class="error-message message-small" *ngIf="!getRep.get('report_type').valid && getRep.get('report_type').touched">
                      Please Select Report type
                    </div>
                            </div>
                        </div>

                    </div>
                   
                    <div class="row">
                         <div class="col-md-4"></div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <button type="button" class="btn btn-success" (click)="getReports()">Generate Report</button>
                            </div>
                        </div>
                          <div class="col-md-4"></div>
                        </div>
             


                </form>
                </div>
            </div>
            
        </div>
    </div> 



<div class="row" *ngIf="reportview">
        <div class="col-12 col-md-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>Generated Report</h4>
                </div>
                <div class="card-body p-0">
<h2 style="padding-left: 25px">Attendance : {{nameshowevent}} <span style="padding-left: 25px">     Incharge Name :{{nameshowinstructor}}</span></h2>


                    <div class="table-responsive">
                        <table class="table table-striped table-md">
                       
                            <tr >
                        <th>S.No</th>
                               <!-- <th>NRIC</th> -->
                                <th>Name</th>
                                <!-- <th>Last Name</th> -->
                                <!-- <th>Event Name</th> -->
                                <th>Mobile</th>
                                <th>Email</th>
                                <!-- <th>Address</th> -->
                                <!-- <th>City</th> -->
                                <!-- <th>State</th> -->
                                <!-- <th>Pincode</th> -->
                                <!-- <th>Country</th> -->
                                <!-- <th>Instructor First Name</th> -->

                                <!-- <th>Instructor Last Name</th> -->

            <th  *ngFor="let shows of datereport" style="text-transform: capitalize;">{{shows}}</th>

                               





                            
                            </tr>
                       
                            <tr *ngFor="let l of reportcontent;let j=index" >

                                <td>{{j+1}}</td>
                                <!-- <td>{{l.nric}}</td> -->
                                <td>{{l.name}}</td>
                                <!-- <td>{{l.lname}}</td> -->
                                <!-- <td>{{l.event_name}}</td> -->
                                <td>{{l.phone_no}}</td>
                                <td>{{l.email}}</td>
                                <!-- <td>{{l.address}}</td> -->
                                <!-- <td>{{l.city}}</td> -->
                                <!-- <td>{{l.state}}</td> -->
                                <!-- <td>{{l.pincode}}</td> -->
                                <!-- <td>{{l.country}}</td> -->
                                <!-- <td>{{l.ins_fname}} </td> -->
                                <!-- <td>{{l.ins_lname}}</td> -->
                                <td  *ngFor="let m of datereport;let i=index">{{l[m]}}</td>
                            
                            </tr>
                           
                        </table>
                    </div>
                </div>
            
            </div>
        </div>
    </div>












    <div class="row">
        <div class="col-12 col-md-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>All Reports</h4>
                </div>
                <div class="card-body p-0">
                    <div class="table-responsive">
                        <table class="table table-striped table-md">
                          <!--   <tr>
                                <td colspan="12" class="search-text-tbl">
                                    <div class="input-group"><input type="text" class="form-control" (keyup.enter)="callHistoryList({'search_text':call_history_search.value})" #call_history_search>
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" (click)="callHistoryList({'search_text':call_history_search.value})"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </td>
                            </tr> -->
                            <tr>
                                <th>S.no</th>
                                <th>Report Name</th>
                                <th>Report Date</th>
                                <th>Report Type Name</th>
                                <th>Action</th>
                            
                            </tr>
                            <tr *ngFor="let h of reports;let i=index" >
                                <td>{{i+1}}</td>
                                <td>{{h.report_name}}</td>
                                <!-- <td><span class="callType"> <img src="assets/images/incoming-call.svg" *ngIf="history.call_type == 'incoming'"><img src="assets/images/outgoing-call.svg" *ngIf="history.call_type == 'outgoing'"></span>{{history.call_type}}</td> -->
                                <td>{{h.report_dt}}</td>
                                <td>
                                    {{h.report_type_name}}
                                </td>
                                <td>
                                <a href="{{h.path}}" target="_blank" class="btn btn-action bg-purple mr-1" data-toggle="tooltip" title="Edit">
                                <i class="fas fa-pencil-alt"></i>
                              </a> 
                          </td>
                            </tr>
                            <!-- <tr *ngIf="recordNotFound == true">
                               <td colspan="12">Data not found</td>
                            </tr> -->
                      
                        </table>
                    </div>
                </div>
              <!--   <div class="card-footer text-right" *ngIf="recordNotFound == false">
                        <nav class="d-inline-block">
                        <ul class="pagination mb-0" *ngIf="paginationData.info == 'show'">

                                <li class="page-item">
                                    <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? callHistoryList({'search_text':call_history_search.value,'offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
                                </li>
                                <li class="page-item" *ngIf="paginationData.backtab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="callHistoryList({'search_text':call_history_search.value,'offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
                                </li>


                                <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
                                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? callHistoryList({'search_text':call_history_search.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                                </li>

                                <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="callHistoryList({'search_text':call_history_search.value,'offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
                                </li>

                                <li class="page-item">
                                    <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? callHistoryList({'search_text':call_history_search.value,'offset':paginationData.end}) : false"><i class="fas fa-angle-right"></i></a>
                                </li>
                        </ul>
                    </nav>
                </div>
 -->


            </div>
        </div>
    </div>































    
</div>




























<!-- modal -->



<!-- <div class="modal fade bd-example-modal-lg" id="addNewPopup" aria-modal="true" >
    <div class="modal-dialog modal-lg">

        <div class="modal-content">


            <div class="modal-header">
                <h5 class="modal-title" id="myLargeModalLabel">Add Student</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>

            <div class="modal-body">

                <div class="row">
                    <div class="col-sm-12">

                  <div class="card-body" >
                    <div class="table-responsive">
                      <table  class="table table-striped" id="table-2">




                        <thead>
                          <tr>
                <td colspan="20" class="search-text-tbl">
                            <div class="input-group"><input type="text" class="form-control" placeholder="Search Student" (input)="getsearch($event.target.value)"  (keyup.enter)="get_student_list({'search_text':agent_search.value})" #agent_search>
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" (click)="get_student_list({'search_text':agent_search.value})"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                          <tr>
                          
                            <th>S.no</th>
                            <th>Student Name</th>
                            <th>Photo</th>
                            <th>Email</th>
                            <th>Contact no.</th>
                            <th>NIRP</th>
                            <th>Add</th>
                          </tr>
                        </thead>
                      
                        <tbody >


                          <tr *ngFor="let l of studentslist;let i=index">
                          
                            <td>{{offset_count+i+1}}</td>
                            <td>{{l.fname}} {{l.lname}}</td>
                            <td class="student-img">
                              <img alt="image" *ngIf="l.image == ''" src="assets/images/avatar.jpg" width="35">
                               <img alt="image" [src]="l.image"  *ngIf="l.image != ''" width="35">


                            </td>

                            <td>{{l.email}}</td>
                            <td>{{l.phone_no}}</td>
                            <td>{{l.nirp}}</td>

    <td><input type="checkbox"    [(ngModel)]="l.add"  name="add"  (click)="savedata(l)"></td>
   
                            <td>
                     
                            </td>
                          </tr>

                        
                        </tbody>
                         <tr *ngIf="recordNotFound == true">
                               <td colspan="12">Data not found</td>
                            </tr>
                      
                      </table>
                    </div>

 <div class="card-footer text-right" *ngIf="recordNotFound == false">
                        <nav class="d-inline-block">
                        <ul class="pagination mb-0" *ngIf="paginationData.info == 'show'">

                                <li class="page-item">
                                    <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? get_student_list({'search_text':agent_search.value,'offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
                                </li>
                                <li class="page-item" *ngIf="paginationData.backtab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="get_student_list({'search_text':agent_search.value,'offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
                                </li>


                                <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
                                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? get_student_list({'search_text':agent_search.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                                </li>

                                <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="get_student_list({'search_text':agent_search.value,'offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
                                </li>

                                <li class="page-item">
                                    <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? get_student_list({'search_text':agent_search.value,'offset':paginationData.end}) : false"><i class="fas fa-angle-right"></i></a>
                                </li>
                        </ul>
                    </nav>
                </div>










                  </div>
             


            </div>

            

        </div>
    </div>
<div class="modal-footer bg-whitesmoke br">
 
    <button  type="button" class="btn btn-success" (click)="savealldata()" data-dismiss="modal" >Save changes</button>
    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
  </div>


    
</div>
</div>
</div> -->



<div class="modal fade bd-example-modal-lg" id="addNewPopup" aria-modal="true" >
    <div class="modal-dialog modal-lg">

        <div class="modal-content">


            <div class="modal-header">
                <h5 class="modal-title" id="myLargeModalLabel">Add Paticipant</h5>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>

            <div class="modal-body">

                <div class="row">
                    <div class="col-sm-12">

                  <div class="card-body" >
                    <div class="table-responsive">
                      <table  class="table table-striped" id="table-2">




                        <thead>
                          <tr>
                <td colspan="20" class="search-text-tbl">
                            <div class="input-group"><input type="text" class="form-control" placeholder="Search Participant" (input)="getsearchpaticipant($event.target.value)"  (keyup.enter)="get_participant_list({'search_text':participant_search.value})" #participant_search>
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" (click)="get_participant_list({'search_text':participant_search.value})"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                          <tr>
                          
                            <th>S.no</th>
                            <th>Participant Name</th>
                            <!-- <th>Photo</th> -->
                            <th>Email</th>
                            <th>Contact no.</th>
                            <!-- <th>NIRP</th> -->
                            <th>Add</th>
                          </tr>
                        </thead>
                      
                        <tbody >


                          <tr *ngFor="let l of participantlist;let i=index">
                          
                            <td>{{offset_count2+i+1}}</td>
                            <td>{{l.name}}</td>
                            <!-- <td class="student-img">
                              <img alt="image" *ngIf="l.image == ''" src="assets/images/avatar.jpg" width="35">
                               <img alt="image" [src]="l.image"  *ngIf="l.image != ''" width="35">


                            </td> -->

                            <td>{{l.email}}</td>
                            <td>{{l.phone_no}}</td>
                            <td>{{l.nirp}}</td>

    <td><input type="checkbox"    [(ngModel)]="l.add"  name="add"  (click)="savedataparticipant(l)"></td>
        <!-- checked="l.add" -->
                            <td>
                     
                            </td>
                          </tr>

                        
                        </tbody>
                         <tr *ngIf="recordNotFound2 == true">
                               <td colspan="12">Data not found</td>
                            </tr>
                      
                      </table>
                    </div>

 <div class="card-footer text-right" *ngIf="recordNotFound2 == false">
                        <nav class="d-inline-block">
                        <ul class="pagination mb-0" *ngIf="paginationData.info == 'show'">

                                <li class="page-item">
                                    <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? get_participant_list({'search_text':participant_search.value,'offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
                                </li>
                                <li class="page-item" *ngIf="paginationData.backtab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="get_participant_list({'search_text':participant_search.value,'offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
                                </li>


                                <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
                                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? get_participant_list({'search_text':participant_search.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                                </li>

                                <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="get_participant_list({'search_text':participant_search.value,'offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
                                </li>

                              <li class="page-item">
                                    <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? get_participant_list({'search_text':participant_search.value,'offset':paginationData
                                    .end}) : false"><i class="fas fa-angle-right"></i></a>
                                </li>
                        </ul>
                    </nav>
                </div>










                  </div>


            </div>

            

        </div>
    </div>
<div class="modal-footer bg-whitesmoke br">
 
    <button  type="button" class="btn btn-success" (click)="savealldata()" data-dismiss="modal" >Save changes</button>
    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
  </div>


    
</div>
</div>
</div>














<div class="modal fade bd-example-modal-lg" id="addNewPopups" aria-modal="true" >
    <div class="modal-dialog modal-lg">

        <div class="modal-content">


            <div class="modal-header">
                <h5 class="modal-title" id="myLargeModalLabel">Add Instructor</h5>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>

            <div class="modal-body">

                <div class="row">
                    <div class="col-sm-12">

                  <div class="card-body" >
                    <div class="table-responsive">
                      <table  class="table table-striped" id="table-2">




                        <thead>
                          <tr>
                <td colspan="20" class="search-text-tbl">
                            <div class="input-group"><input type="text" class="form-control" placeholder="Search Student" (input)="getsearchinstructor($event.target.value)"  (keyup.enter)="get_instructor_list({'search_text':agent_search2.value})" #agent_search2>
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" (click)="get_instructor_list({'search_text':agent_search2.value})"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                          <tr>
                          
                            <th>S.no</th>
                            <th>Instructor Name</th>
                            <th>Photo</th>
                            <th>Email</th>
                            <th>Contact no.</th>
                            <th>NIRP</th>
                            <th>Add</th>
                          </tr>
                        </thead>
                      
                        <tbody >


                          <tr *ngFor="let l of instructorlist;let i=index">
                          
                            <td>{{offset_count2+i+1}}</td>
                            <td>{{l.fname}} {{l.lname}}</td>
                            <td class="student-img">
                              <img alt="image" *ngIf="l.image == ''" src="assets/images/avatar.jpg" width="35">
                               <img alt="image" [src]="l.image"  *ngIf="l.image != ''" width="35">


                            </td>

                            <td>{{l.email}}</td>
                            <td>{{l.phone_no}}</td>
                            <td>{{l.nirp}}</td>

        <td><input type="radio"    [checked]="l.add" name="add"  (click)="savedatainstructor(l)"></td>
                            <td>
                     
                            </td>
                          </tr>

                        
                        </tbody>
                         <tr *ngIf="recordNotFound == true">
                               <td colspan="12">Data not found</td>
                            </tr>
                      
                      </table>
                    </div>

 <div class="card-footer text-right" *ngIf="recordNotFound2 == false">
                        <nav class="d-inline-block">
                        <ul class="pagination mb-0" *ngIf="paginationData2.info == 'show'">

                                <li class="page-item">
                                    <a [class]="paginationData2.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData2.backtab=='show' ? get_instructor_list({'search_text':agent_search2.value,'offset':paginationData2.start}) : false"><i class="fas fa-angle-left"></i></a>
                                </li>
                                <li class="page-item" *ngIf="paginationData2.backtab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="get_instructor_list({'search_text':agent_search2.value,'offset':paginationData2.back})"><i class="fas fa-angle-double-left"></i></a>
                                </li>


                                <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData2.data;let i=index">
                                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? get_instructor_list({'search_text':agent_search2.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                                </li>

                                <li class="page-item" *ngIf="paginationData2.nexttab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="get_instructor_list({'search_text':agent_search2.value,'offset':paginationData2.next})"><i class="fas fa-angle-double-right"></i></a>
                                </li>

                                <li class="page-item">
                                    <a [class]="paginationData2.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData2.nexttab =='show' ? get_instructor_list({'search_text':agent_search2.value,'offset':paginationData2.end}) : false"><i class="fas fa-angle-right"></i></a>
                                </li>
                        </ul>
                    </nav>
                </div>


                  </div>
                <!-- </div> -->


            </div>

            

        </div>
    </div>
<div class="modal-footer bg-whitesmoke br">
    <button  type="button" class="btn btn-success" (click)="savealldata2()" data-dismiss="modal" >Save changes</button>
    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
  </div>


    
</div>
</div>
</div>



