  <div class="section-body">
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-header">
                      <div class="card-header-title">
                        <h4>Event Template View</h4>
                      </div>
                      <div class="card-header-btn-group">

                        <a  [routerLink]="['/recordevents']"  class="btn btn-success"><i class="fa fa-eye" aria-hidden="true"></i>View event record </a>
                      </div>
                    </div>
                    <div class="card-body">

<!-- view start -->
                      <div>
                      <div class="table-responsive">
                        <table class="table table-striped" id="table-2">
                          <thead>
                            
                            <tr>
                         
                              <th>S.no</th>

                              <th>Template Name</th>
                             
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr  *ngFor="let l of meetingtemplatelist;let i=index">
                          
                              <td>{{i+1}}</td>

                              <td>{{l.template_name}}</td>

                              <td>
                          

                                 <button   type="button" class="btn btn-info btn-icon icon-left" (click)="showedit(l)">
                                <i class="fas fa-pencil-alt" ></i> Add Event</button>
                              </td>
                              
                            </tr>
                        
                          </tbody>
                        </table>

                      
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
       



<!-- add event start -->


  <div class="modal fade"   id="addNewPopup">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <form [formGroup]="addEvent" >

          <div class="modal-header">
           
          <h5 class="modal-title" id="exampleModalLabel">Add Event</h5>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">


<div class="row" >
  <div class="col-md-6 col-12">
    <div class="form-group">
      <label>Event Name</label>
      <select required class="form-control"  formControlName="event_name">
       <option value="{{l.event_name}}" *ngFor ="let l of eventslist">{{l.event_name}}</option>
     </select>

   </div>
 </div>



 <div class="col-md-6 col-12">
  <div class="form-group">
   <label>Incharge</label>  :  <strong>{{instructorfullname}}</strong>
   <button   type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="chnageinst('instructor')">Change Incharge</button>

 </div>
</div>
<div class="col-md-6 col-12">
  <div class="form-group">
   <label>Co-Incharge</label>  :  <strong>{{instructorfullnameco}}</strong>
   <button   type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="chnageinst('coinstructor')">Change Co-Incharge</button>

 </div>
</div>


<div class="col-md-6 col-12">
 <div class="form-group">
  <label>Start Time</label>

  <input  type="datetime-local" formControlName="start_time"  class="form-control datetimepicker" >





</div>
</div>

<div class="col-md-6 col-12">
 <div class="form-group">
  <label>End Time</label>
  <input  type="datetime-local" formControlName="end_time" class="form-control datetimepicker" >


</div>
</div>

<div class="col-md-6 col-12">
 <div class="form-group">
  <label>Location</label>
  <select  class="form-control"  formControlName="location">
   <option value="{{l.location}}" *ngFor ="let l of locations">{{l.location}}</option>
 </select>

</div>
</div>


      <div class="col-md-6 col-12">
       <div class="form-group">
        <label>Add Participants</label>
        <button type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="addstudent()">Add Participant</button>
      </div>
    </div>

  </div>


</div>

<div class="modal-footer bg-whitesmoke br">
 

    <button  style="margin-right: 5px;" type="button" class="btn btn-success" data-dismiss="modal" (click)="assignevent()">Add</button>
    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>

 

</div>


</form>
</div>
</div>
</div>




<div class="modal fade bd-example-modal-lg" id="addNewPopupparticipant" aria-modal="true" >
    <div class="modal-dialog modal-lg">

        <div class="modal-content">


            <div class="modal-header">
                <h5 class="modal-title" id="myLargeModalLabel">Add Paticipant</h5>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>

            <div class="modal-body">

                <div class="row">
                    <div class="col-sm-12">

                  <div class="card-body" >
                    <div class="table-responsive">
                      <table  class="table table-striped" id="table-2">
                      <thead>
                          <tr>
                <td colspan="20" class="search-text-tbl">
                            <div class="input-group"><input type="text" class="form-control" placeholder="Search Participant" (input)="getsearchpaticipant($event.target.value)"  (keyup.enter)="get_participant_list({'search_text':participant_search.value})" #participant_search>
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" (click)="get_participant_list({'search_text':participant_search.value})"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                          <tr>
                          
                            <th>S.no</th>
                            <th>Participant Name</th>
                            <th>Email</th>
                            <th>Contact no.</th>

                            <th>Add</th>
                          </tr>
                        </thead>
                      
                        <tbody >

                          <tr *ngFor="let l of participantlist;let i=index">
                          
                            <td>{{offset_count2+i+1}}</td>
                            <td>{{l.name}}</td>
                            <td>{{l.email}}</td>
                            <td>{{l.phone_no}}</td>
                            <td>{{l.nirp}}</td>

    <td><input type="checkbox"    [(ngModel)]="l.add"  name="add"  (click)="savedataparticipant(l)"></td>
 
                            <td>
                     
                            </td>
                          </tr>

                        
                        </tbody>
                         <tr *ngIf="recordNotFound2 == true">
                               <td colspan="12">Data not found</td>
                            </tr>
                      
                      </table>
                    </div>

 <div class="card-footer text-right" *ngIf="recordNotFound2 == false">
                        <nav class="d-inline-block">
                        <ul class="pagination mb-0" *ngIf="paginationData2.info == 'show'">

                                <li class="page-item">
                                    <a [class]="paginationData2.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData2.backtab=='show' ? get_participant_list({'search_text':participant_search.value,'offset':paginationData2.start}) : false"><i class="fas fa-angle-left"></i></a>
                                </li>
                                <li class="page-item" *ngIf="paginationData2.backtab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="get_participant_list({'search_text':participant_search.value,'offset':paginationData2.back})"><i class="fas fa-angle-double-left"></i></a>
                                </li>


                                <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData2.data;let i=index">
                                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? get_participant_list({'search_text':participant_search.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                                </li>

                                <li class="page-item" *ngIf="paginationData2.nexttab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="get_participant_list({'search_text':participant_search.value,'offset':paginationData2.next})"><i class="fas fa-angle-double-right"></i></a>
                                </li>

                              <li class="page-item">
                                    <a [class]="paginationData2.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData2.nexttab =='show' ? get_participant_list({'search_text':participant_search.value,'offset':paginationData2
                                    .end}) : false"><i class="fas fa-angle-right"></i></a>
                                </li>
                        </ul>
                    </nav>
                </div>
             </div>
            </div>
        </div>
    </div>
<div class="modal-footer bg-whitesmoke br">
 
    <button  type="button" class="btn btn-success" (click)="savealldataparticipant()" data-dismiss="modal" >Save changes</button>
    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
  </div> 
</div>
</div>
</div>








 <div class="modal fade bd-example-modal-lg" id="addNewPopupinst" aria-modal="true" >
    <div class="modal-dialog modal-lg">

      <div class="modal-content">


        <div class="modal-header">
          <h5 class="modal-title" id="myLargeModalLabel" *ngIf="typeinstructor == 'instructor'">Add Incharge To Event</h5>
          <h5 class="modal-title" id="myLargeModalLabel" *ngIf="typeinstructor == 'coinstructor'">Add Co-Incharge To Event</h5>

  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        </div>

        <div class="modal-body">

          <div class="row">
            <div class="col-sm-12">

              <div class="card-body" >
                <div class="table-responsive">
                  <table  class="table table-striped" id="table-2">




                    <thead>
                      <tr>
                        <td colspan="20" class="search-text-tbl">
                          <div class="input-group"><input type="text" class="form-control" placeholder="Search Student" (input)="getsearchinstructor($event.target.value)"  (keyup.enter)="get_instructor_list({'search_text':agent_search3.value})" #agent_search3>
                            <div class="input-group-append">
                              <button class="btn btn-primary" (click)="get_instructor_list({'search_text':agent_search3.value})"><i class="fas fa-search"></i></button>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>

                        <th>S.no</th>
                        <th>Instructor Name</th>
                        <th>Photo</th>
                        <th>Email</th>
                        <th>Contact no.</th>
                        <th>NRIC</th>
                        <th>Add</th>
                      </tr>
                    </thead>

                    <tbody >


                      <tr *ngFor="let l of instructorlist;let i=index">

                        <td>{{offset_count3+i+1}}</td>
                        <td>{{l.fname}} {{l.lname}}</td>
                        <td class="student-img">

  <img src="{{l.image}}"  onerror="this.src='assets/images/avatar.jpg'" alt="image"  width="35">
                        </td>

                        <td>{{l.email}}</td>
                        <td>{{l.phone_no}}</td>
                        <td>{{l.nirp}}</td>

                        <td><input type="radio"    [checked]="l.add" name="add"  (click)="savedatainstructor(l)"></td>
                        <td>

                        </td>
                      </tr>


                    </tbody>
                    <tr *ngIf="recordNotFound3 == true">
                     <td colspan="12">Data not found</td>
                   </tr>

                 </table>
               </div>

               <div class="card-footer text-right" *ngIf="recordNotFound3 == false">
                <nav class="d-inline-block">
                  <ul class="pagination mb-0" *ngIf="paginationData3.info == 'show'">

                    <li class="page-item">
                      <a [class]="paginationData3.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData3.backtab=='show' ? get_instructor_list({'search_text':agent_search3.value,'offset':paginationData3.start}) : false"><i class="fas fa-angle-left"></i></a>
                    </li>
                    <li class="page-item" *ngIf="paginationData3.backtab =='show'">
                      <a class="page-link" href="javascript:void(0)" (click)="get_instructor_list({'search_text':agent_search3.value,'offset':paginationData3.back})"><i class="fas fa-angle-double-left"></i></a>
                    </li>


                    <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData3.data;let i=index">
                      <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? get_instructor_list({'search_text':agent_search3.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                    </li>

                    <li class="page-item" *ngIf="paginationData3.nexttab =='show'">
                      <a class="page-link" href="javascript:void(0)" (click)="get_instructor_list({'search_text':agent_search3.value,'offset':paginationData3.next})"><i class="fas fa-angle-double-right"></i></a>
                    </li>

                    <li class="page-item">
                      <a [class]="paginationData3.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData3.nexttab =='show' ? get_instructor_list({'search_text':agent_search3.value,'offset':paginationData3.end}) : false"><i class="fas fa-angle-right"></i></a>
                    </li>
                  </ul>
                </nav>
              </div>

            </div>



          </div>



        </div>
      </div>
      <div class="modal-footer bg-whitesmoke br">

        <button  type="button" class="btn btn-success" (click)="savealldata2()" data-dismiss="modal" >Save changes</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
      </div>



    </div>
  </div>
</div>
