



  <!-- <div class="loader"></div>
  <div id="app"> -->
    <!-- <section class="section"> -->
      <div class="container mt-5">
        <div class="row">
          <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">



            <div class="card card-primary" *ngIf="page == 'forgotuser'">
              <div class="card-header">
                <h4>Forgot Username</h4>
              </div>
              <div class="card-body">
                <!-- <p class="text-muted">We will send a link to reset your password</p> -->
            <form  method="POST"  [formGroup]="forgotpwdsuser">

              
          <div class="form-group">
                     <input type="radio" id="Number"  class="mrg-right"formControlName="emailtype" value="numbertype">
  <label for="phone number"  class="mrg-right">Phone Number</label>
    <input type="radio" id="Number"  class="mrg-right" formControlName="emailtype" value="emailtype">
  <label for="phone number"  class="mrg-right">Email</label>
                  </div>

                  <div class="form-group" *ngIf="forgotpwdsuser.value.emailtype == 'numbertype'">
                    <label for="email">Phone Number</label>
                    <input id="email" type="number" class="form-control" formControlName="phoneno" tabindex="1" required autofocus>
                           <div class="error-message message-small" *ngIf="!forgotpwdsuser.get('phoneno').valid && forgotpwdsuser.get('phoneno').touched" >
                      Please enter Phone Number
                    </div>
                  </div>

<div class="form-group" *ngIf="forgotpwdsuser.value.emailtype == 'emailtype'">
                    <label for="email">Email</label>
                    <input id="email" type="email" class="form-control" formControlName="email" tabindex="1" required autofocus>
                           <div class="error-message message-small" *ngIf="!forgotpwdsuser.get('email').valid && forgotpwdsuser.get('email').touched" >
                      Please enter valid email
                    </div>
                  </div>
<div class="form-group"  *ngIf="forgotpwdsuser.value.emailtype">
                    <label for="company_name">Company Name</label>
                    <input id="company_name" type="text" class="form-control" formControlName="company_name" tabindex="1" required autofocus>
                           <div class="error-message message-small" *ngIf="!forgotpwdsuser.get('company_name').valid && forgotpwdsuser.get('company_name').touched" >
                      Please enter Company Number
                    </div>
                  </div>




                  <div class="form-group">
                    <button type="submit" (click)="submitforgotuser() " class="btn btn-primary btn-lg btn-block" tabindex="4">
                      Forgot Username
                    </button>
                  </div>
                </form>
              </div>
            </div>





   <div class="card card-primary" *ngIf="page == 'forgotpassword' && !verifyotps && !conformpassword">
              <div class="card-header">
                <h4>Forgot Password</h4>
              </div>
              <div class="card-body">
                <p class="text-muted">We will send a OTP to verify you</p>
            <form  method="POST"  [formGroup]="forgotpwdpassword">
                  <div class="form-group">
                    <label for="email">User Name</label>
                    <input id="email" type="text" class="form-control" formControlName="username" tabindex="1"  required autofocus>
                           <div class="error-message message-small" *ngIf="!forgotpwdpassword.get('username').valid && forgotpwdpassword.get('username').touched" >
                      Please enter User Name
                    </div>
                  </div>
                  <div class="form-group">
                    <button type="submit" (click)="submitforgotpassword() " class="btn btn-primary btn-lg btn-block" tabindex="4">
                      Forgot Password
                    </button>
                  </div>
                </form>
              </div>
            </div>


  <div class="card card-primary" *ngIf="verifyotps">
              <div class="card-header">
                <h4>Verify OTP to change your password</h4>
              </div>
              <div class="card-body">
                <p class="text-muted">Enter your OTP</p>
            <form  method="POST"  [formGroup]="verifyotp">
                  <div class="form-group">
                    <label for="email">OTP</label>
                    <input id="email" type="number" class="form-control" formControlName="otp" tabindex="1" required autofocus>
                           <div class="error-message message-small" *ngIf="!verifyotp.get('otp').valid && verifyotp.get('otp').touched" >
                      Please enter OTP
                    </div>
                  </div>
                  <div class="form-group">
                    <button type="submit" (click)="submitverifyotp() " class="btn btn-primary btn-lg btn-block" tabindex="4">
                      Verify
                    </button>
                  </div>
                </form>
              </div>
            </div>


 <div class="card card-primary" *ngIf="conformpassword">
              <div class="card-header">
                <h4>Change your password</h4>
              </div>
              <div class="card-body">
            <form  method="POST"  [formGroup]="verifypassword">
                  <div class="form-group">
                    <label for="email">New Password</label>
                    <input id="email" type="text" class="form-control" formControlName="newpassword" (change)="onPasswordChange()" tabindex="1" required autofocus>
                           <div class="error-message message-small" *ngIf="!verifypassword.get('newpassword').valid && verifypassword.get('newpassword').touched" >
                      Please enter New Password
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="email">Confirmation Password</label>
                    <input id="email" type="text" class="form-control" formControlName="confirmnewpassword" (change)="onPasswordChange()" tabindex="1" required autofocus>

<div *ngIf="confirm_password.hasError('mismatch') && verifypassword.get('confirmnewpassword').touched" class="error-message message-small">Password do not match.</div> 
                    <div class="error-message message-small" *ngIf="!confirm_password.hasError('mismatch') && !verifypassword.get('confirmnewpassword').valid && verifypassword.get('confirmnewpassword').touched">
                      Please enter confirmation password
                    </div>
                      
                  </div>
                  <div class="form-group">
                    <button type="submit" (click)="submitchangedpassword() " class="btn btn-primary btn-lg btn-block" tabindex="4">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>




          </div>
        </div>
      </div>
    <!-- </section> -->
  <!-- </div> -->
 


