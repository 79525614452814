import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
declare var $:any;
declare var iziToast:any;
import * as moment from 'moment';
@Component({
  selector: 'app-addlesson',
  templateUrl: './addlesson.component.html',
  styleUrls: ['./addlesson.component.css']
})
export class AddlessonComponent implements OnInit {
courselist:any=[];
addCourse: FormGroup;
addEvent: FormGroup;
days:any=[];
 dayspick;
 daysarray:any=[];

instructorlist:any=[]
 
 studentslist:any=[];
recordNotFound = false;
recordNotFound2 = false;
recordNotFound3 = false;

 pageLimit = 5;
 pageLimit2 = 10;
 pageLimit3 = 10;

 paginationData:any ={"info":"hide"};
 paginationData2:any ={"info":"hide"};
 paginationData3:any ={"info":"hide"};

 offset_count = 0;
  offset_count2 = 0;
  offset_count3 = 0;

 sendstudent;
sendstudentarr=[];
 participantarr=[];

 sendparticipant;
selectedinst;

 participantnames:any=[];


locations:any=[];
 typeinstructor;
instructorselect;
  instructorselectco;

 instructselectname;
instructselectnameco;


 instructorfullname;
 instructorfullnameco;

 instructorshowfullname;
 instructorshowfullnameco;

instructselectshow;
instructselectshowco;

instructselectshowname;
instructselectshownameco;
 studentnames:any=[];
 sendstudentname;
 showsendnames:any=[];
courseidsend;
meetingidsend;
get_attendees:any=[];
courseditdetials:any =[];
meetingdetails:any=[];
type;
eventslist:any=[];
 participantlist:any=[];
  constructor(private serverService: ServerService,private route:ActivatedRoute,private router:Router) { 

    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {

  this.type=this.router.getCurrentNavigation().extras.state.type; 
console.log(this.type)
    this.assignpage()




      }
    });

 if(this.type == undefined ){
    this.type=localStorage.getItem('addlessonpagetype');
    this.assignpage()
   }

this.course_list()
this.dayslist()
this.viewlocation()
this.viewevents()

  }

assignpage(){


  console.log(this.type)
  if(this.type == 'course'){

// this.addtitleinstructor = 'Add Incharge To Meeting';
// this.addtitleinstructorco = 'Add Co-Incharge To Meeting';
 localStorage.setItem('addlessonpagetype', 'course'); 


}else if(this.type == 'meeting'){
  // this.addtitleinstructor = 'Add Incharge To Event'
  // this.addtitleinstructorco = 'Add Co-Incharge To Event'
localStorage.setItem('addlessonpagetype', 'meeting'); 


}
}









  ngOnInit(): void {
  	this.addCourse = new FormGroup({
      'event_name' : new FormControl(null,Validators.required),
      'instructors' : new FormControl(null,Validators.required),
      'start_time' : new FormControl(null,Validators.required),
      'end_time' : new FormControl(null,Validators.required),
      'start_date':new FormControl(null,Validators.required),
      // 'count' : new FormControl(null,Validators.required),
      'location' : new FormControl(null,Validators.required),
      // 'editshowing' : new FormControl(null),
     'no_of_class' : new FormControl(null,Validators.required),
     'days' : new FormControl(null,Validators.required),
      'savetemplate' : new FormControl(null),
      'template_name' : new FormControl(null),
      'student_count' : new FormControl(null)




     });

  this.addEvent = new FormGroup({
      'event_name' : new FormControl(null,Validators.required),
      'instructors' : new FormControl(null,Validators.required),
      'start_time' : new FormControl(null,Validators.required),
      'end_time' : new FormControl(null,Validators.required),
      'count' : new FormControl(null,Validators.required),
      'location' : new FormControl(null,Validators.required),
      'reference' : new FormControl(null,Validators.required),
      'editshowing' : new FormControl(null)
     });  



  }

savealldata2(){
  if(this.typeinstructor == "instructor"){
this.instructorselect= this.instructselectshow;
this.instructselectname = this.instructselectshowname;
this.instructorfullname = this.instructorshowfullname;

  }else{
 
    this.instructorselectco= this.instructselectshowco
this.instructselectnameco =this.instructselectshownameco
this.instructorfullnameco = this.instructorshowfullnameco;

  }



}

savealldata(){
this.showsendnames =[];
   this.sendstudent = ''
   this.sendstudentname  =''
   console.log(this.sendstudentarr)
this.addCourse.patchValue({

  student_count:this.sendstudentarr.length
});

  
  // this.addCourse.value.student_count = 
   this.sendstudent=this.sendstudentarr.join(',');
   this.showsendnames =this.studentnames
   this.sendstudentname =this.studentnames.join(',');
   console.log(this.sendstudent)
    console.log(this.sendstudentname)
}

savealldataparticip(){

   this.sendparticipant = ''
   console.log(this.participantarr)

   this.sendparticipant=this.participantarr.join(',');
   console.log(this.sendparticipant)


}

getsearch(searchValue: string): void {  
  console.log(searchValue);
  this.get_student_list({'search_text':searchValue})
}

getsearchinstructor(searchValue: string): void {  
  console.log(searchValue);
  this.get_instructor_list({'search_text':searchValue})
}
course_list(){

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');
   var api_req:any
   if(this.type == 'course'){
  api_req = '{"operation":"lecture","moduleType":"lecture","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"view_template","admin_id":"'+admin_id+'"}}'
   }else if(this.type == 'meeting'){
      api_req = '{"operation":"meeting","moduleType":"meeting","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"view_template_meet","admin_id":"'+admin_id+'"}}'
   }
  
  

  // 
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response){
      this.courselist= response.result.data

     
    
      console.log(this.courselist);
      }
   

    }, 
    (error)=>{
        console.log(error);
    });
  }



addclass(l){

this.courseidsend = l.course_id;

  let editevents:any = new Object();
 let editeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');


editevents.action="edit_template";
editevents.course_id=l.course_id;
editevents.admin_id =localStorage.getItem('userId');
 editeventsuser.access_token=access_token;
  editeventsuser.operation="lecture";
  editeventsuser.moduleType="lecture";
  editeventsuser.api_type="web";
  editeventsuser.element_data = editevents;
      this.serverService.sendServer(editeventsuser).subscribe((response: any) => {
        if(response.result.data.length != 0){

this.courseditdetials = response.result.data[0]

this.instructorselect = response.result.data[0].inst_id
         this.instructorselectco = response.result.data[0].co_inst_id
         this.instructselectnameco =response.result.data[0].co_inst;

         this.instructselectname =response.result.data[0].instructors;
         this.instructorfullname = response.result.data[0].instructor_name;
         this.instructorfullnameco= response.result.data[0].co_instructor_name;
 this.daysarray =response.result.data[0].days.split(',')
        console.log(this.daysarray)
         this.addCourse.patchValue({
event_name: response.result.data[0].event_name,
 instructors :response.result.data[0].instructors,
 start_time:moment(response.result.data[0].start_time).format('HH:mm:ss'),
 end_time:moment(response.result.data[0].end_time).format('HH:mm:ss'),
 start_date:moment(response.result.data[0].start_time).format('YYYY-MM-DD'),
 // count:response.result.data[0].count,
 location:response.result.data[0].location,
 no_of_class:response.result.data[0].no_of_class,
// editshowing :this.editshow

  });

for (var i = 0; i < this.days.length; i++) {
   var ismatchs = false;
  for (var j = 0; j < this.daysarray.length; j++) {
   if(this.days[i].id ==  this.daysarray[j]){
     ismatchs = true;
     this.days[i].checked = true;
     break;  

   }
 }
 if (!ismatchs){
         this.days[i].checked = false//  checkbox status false
      
      } 

}
this.viewstud() 
 $('#addNewPopupcourse').modal({
   show:true,
   backdrop:false
});
$('#addNewPopupcourse').modal('show');
 
          }
        else{

        }
      
      }, 
      (error)=>{
          console.log(error);
      });
}




addsubmit(){

}

addclasstocourseold(){

console.log(this.addCourse.value)
if(this.addCourse.value.start_date == null){

      iziToast.error({

          message: "Please Add Start Date",
          position: 'topRight'
      });
      return false
}

if(this.addCourse.value.start_time == null){

      iziToast.error({

          message: "Please Add Start Time",
          position: 'topRight'
      });
      return false
}
if(this.addCourse.value.end_time == null){

      iziToast.error({

          message: "Please Add End Time",
          position: 'topRight'
      });
      return false
}
if(this.addCourse.value.location == null){

      iziToast.error({

          message: "Please Add Location",
          position: 'topRight'
      });
      return false
}

if(this.addCourse.value.no_of_class == null){

      iziToast.error({

          message: "Please Add No Of Class",
          position: 'topRight'
      });
      return false
}
	  console.log(this.days)
this.dayspick =''
var daysarray=[];
for (var i = 0; i<this.days.length; i++) {
  if(this.days[i].checked == true)
  {
   daysarray.push(this.days[i].id)
  }
}
this.dayspick = daysarray.toString();
  console.log(this.dayspick)
  console.log(this.addCourse.value)

	 let updateevents:any =new Object(); 
 let updateeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let superadmin: any=localStorage.getItem('superadmin');



updateevents.action="add_class";
updateevents.course_id=this.courseidsend;
updateevents.instructors =this.instructselectname;
updateevents.co_inst =this.instructselectnameco;
updateevents.start_time =this.addCourse.value.start_date;
updateevents.from_time =this.addCourse.value.start_time;
updateevents.to_time =this.addCourse.value.end_time;
updateevents.admin_id =localStorage.getItem('userId');
updateevents.location =this.addCourse.value.location;
updateevents.no_of_class =this.addCourse.value.no_of_class;
updateevents.event_name =this.addCourse.value.event_name;



updateevents.notes ='';

updateevents.days = this.dayspick;
 updateeventsuser.access_token=access_token;
  updateeventsuser.operation="lecture";
  updateeventsuser.moduleType="lecture";
  updateeventsuser.api_type="web";
  updateeventsuser.element_data = updateevents;
      this.serverService.sendServer(updateeventsuser).subscribe((response: any) => {

        if(response.result.data.status =="true"){
       // this.addevents()
      // iziToast.success({

      //     message: "Class Added successfully",
      //     position: 'topRight'
      // });
  
    this.assignstudentstocourse(response.result.data.course_id)

          }
        else{

    iziToast.error({

          message: "Error Occured",
          position: 'topRight'
      });
  
        }
      
      }, 
      (error)=>{
          console.log(error);
      });
}


addclasstocourse(){

console.log(this.addCourse.value)
var sendtemplate;

if(this.addCourse.value.savetemplate == true){
  sendtemplate = 1;
}else{
sendtemplate = 0;
}


if(this.addCourse.value.start_date == null){

      iziToast.error({

          message: "Please Add Start Date",
          position: 'topRight'
      });
      return false
}

if(this.addCourse.value.start_time == null){

      iziToast.error({

          message: "Please Add Start Time",
          position: 'topRight'
      });
      return false
}
if(this.addCourse.value.end_time == null){

      iziToast.error({

          message: "Please Add End Time",
          position: 'topRight'
      });
      return false
}
if(this.addCourse.value.location == null){

      iziToast.error({

          message: "Please Add Location",
          position: 'topRight'
      });
      return false
}

if(this.addCourse.value.no_of_class == null){

      iziToast.error({

          message: "Please Add No Of Class",
          position: 'topRight'
      });
      return false
}
    console.log(this.days)
this.dayspick =''
var daysarray=[];
for (var i = 0; i<this.days.length; i++) {
  if(this.days[i].checked == true)
  {
   daysarray.push(this.days[i].id)
  }
}
this.dayspick = daysarray.toString();
  console.log(this.dayspick)
  console.log(this.addCourse.value)

   let updateevents:any =new Object(); 
 let updateeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let superadmin: any=localStorage.getItem('superadmin');
updateevents.location =this.addCourse.value.location;
updateevents.action="add_new_course";
updateevents.event_name =this.addCourse.value.event_name;
updateevents.instructors =this.instructselectname;
updateevents.co_inst =this.instructselectnameco;
updateevents.template=sendtemplate;
updateevents.template_name=this.addCourse.value.template_name;

// updateevents.course_id=this.courseidsend;

updateevents.start_time =moment(this.addCourse.value.start_date).format('YYYY-MM-DD');;
updateevents.from_time =this.addCourse.value.start_time;
updateevents.to_time =this.addCourse.value.end_time;
updateevents.admin_id =localStorage.getItem('userId');
updateevents.no_of_class =this.addCourse.value.no_of_class;
updateevents.notes ='';




updateevents.days = this.dayspick;
 updateeventsuser.access_token=access_token;
  updateeventsuser.operation="lecture";
  updateeventsuser.moduleType="lecture";
  updateeventsuser.api_type="web";
  updateeventsuser.element_data = updateevents;
      this.serverService.sendServer(updateeventsuser).subscribe((response: any) => {

        if(response.result.data.status =="true"){
       // this.addevents()
      // iziToast.success({

      //     message: "Class Added successfully",
      //     position: 'topRight'
      // });
  
    this.assignstudentstocourse(response.result.data.course_id)

          }
        else{

    iziToast.error({

          message: "Error Occured",
          position: 'topRight'
      });
  
        }
      
      }, 
      (error)=>{
          console.log(error);
      });
}



savedata(val){

 var adding = false
if(val.add == false){

if(this.sendstudentarr.length != 0){

 for (var i = 0; i < this.sendstudentarr.length; i++) {


if(val.username !== this.sendstudentarr[i]){

 adding  = true
}

}
  if(adding){
      this.sendstudentarr.push(val.username)
      this.studentnames.push(val.fname+val.lname)

  }






}
else{
   this.sendstudentarr.push(val.username)
   // this.studentnames.push(this.sendstudentarr[i].fname+this.sendstudentarr[i].lname)
    this.studentnames.push(val.fname+val.lname)
}

}
else{

   for (var j = 0; j < this.sendstudentarr.length; j++) {

if(val.username == this.sendstudentarr[j]){

    this.sendstudentarr.splice(this.sendstudentarr.indexOf(this.sendstudentarr[j]), 1 );

}
}
   for (var k = 0; k < this.studentnames.length; k++) {

if(val.fname+val.lname == this.studentnames[k]){

    this.studentnames.splice(this.studentnames.indexOf(this.studentnames[k]), 1 );

}
}



}
 
 console.log(this.sendstudentarr)
}







dayslist(){
  this.days=[{

    "id": 1,
    "name": "Mon",
    "checked": false
  },
  {
    "id": 2,
    "name": "Tue",
    "checked": false
  },
  {
    "id": 3,
    "name": "Wed",
    "checked": false
  },
  {
    "id": 4,
    "name": "Thurs",
    "checked": false
  },
  {
    "id": 5,
    "name": "Fri",
    "checked": false
  },
  {
    "id": 6,
    "name": "Sat",
    "checked": false
  },
    {
    "id": 7,
    "name": "Sun",
    "checked": false
  }
]
 
}

 addstud(){

this.get_student_list({})
     $('#addNewPopupstud').modal({
  show:true,
   backdrop:false

});
$('#addNewPopupstud').modal('show');


  }

addinstruct(name){

  this.typeinstructor = name;

    this.get_instructor_list({})
  $('#addNewPopupinst').modal({
    show:true,
   backdrop:false

});
$('#addNewPopupinst').modal('show');
}


get_student_list(data){
  
  var list_data= this.listDataInfo(data);
  console.log(list_data)

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

 
    let api_req:any = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"view_student_rec","limit":"'+list_data.limit+'","offset":'+list_data.offset+',"search_text":"'+list_data.search_text+'","admin_id":"'+admin_id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){
      this.studentslist=response.result.data.list_data;
//          for (var i = 0; i < this.studentslist.length; i++) {
 
//    this.studentslist[i].add = false
  
// }
console.log(this.sendstudentarr)
console.log(this.studentslist)


if(this.studentslist.length != 0){

if(this.sendstudentarr.length != 0){
for (var i = 0; i < this.studentslist.length; i++) {
   var ismatch = false;
  for (var j = 0; j < this.sendstudentarr.length; j++) {
   if(this.studentslist[i].username ==  this.sendstudentarr[j]){
     ismatch = true;
     this.studentslist[i].add = true;
     break;  

   }
 }
 if (!ismatch){
         this.studentslist[i].add = false//  checkbox status false
      
      } 

}
}
else{
  
  for (var i = 0; i < this.studentslist.length; i++) {
     this.studentslist[i].add = false  
  }
}

}

console.log(this.studentslist)


// this.addCourse.value.student_count = this.sendstudentarr.length;
this.addCourse.patchValue({

  student_count:this.sendstudentarr.length
});
          this.offset_count = list_data.offset;
        this.paginationData = this.serverService.pagination({'offset':response.result.data.list_info.offset, 'total':response.result.data.list_info.total, 'page_limit' :this.pageLimit });
         console.log(this.paginationData);
                this.recordNotFound = this.studentslist.length == 0 ? true : false;


      }
     

    }, 
    (error)=>{
        console.log(error);
    });
  }

addstudent(){
  this.get_participant_list({})
   $('#addNewPopupparticipant').modal({
  show:true,
   backdrop:false

});
$('#addNewPopupparticipant').modal('show');
}


get_participant_list(data){
    // if(this.details == 'edit'){
  // this.viewstud() 
  // }
  var list_data= this.listDataInfo3(data);
  console.log(list_data)

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

 
      let api_req:any = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"view_participant","limit":"'+list_data.limit+'","offset":'+list_data.offset+',"search_text":"'+list_data.search_text+'","admin_id":"'+admin_id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){
      this.participantlist=response.result.data.list_data;

console.log(this.participantarr)
console.log(this.participantlist)


if(this.participantlist.length != 0){

if(this.participantarr.length != 0){
for (var i = 0; i < this.participantlist.length; i++) {
   var ismatch = false;
  for (var j = 0; j < this.participantarr.length; j++) {
   if(this.participantlist[i].id ==  this.participantarr[j]){
     ismatch = true;
     this.participantlist[i].add = true;
     break;  

   }
 }
 if (!ismatch){
         this.participantlist[i].add = false//  checkbox status false
      
      } 

}
}
else{
  
  for (var i = 0; i < this.participantlist.length; i++) {
     this.participantlist[i].add = false  
  }
}

}

console.log(this.participantlist)




          this.offset_count3 = list_data.offset;
        this.paginationData3 = this.serverService.pagination({'offset':response.result.data.list_info.offset, 'total':response.result.data.list_info.total, 'page_limit' :this.pageLimit3 });
         console.log(this.paginationData);
                this.recordNotFound3 = this.participantlist.length == 0 ? true : false;


      }
     

    }, 
    (error)=>{
        console.log(error);
    });
  }



getsearchpaticipant(searchValue: string): void {  
  console.log(searchValue);
  this.get_participant_list({'search_text':searchValue})
}



listDataInfo3(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit3 : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
}  







listDataInfo(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
}  

listDataInfo2(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
} 
savedatainstructor(val){
 console.log(val)
if(val.add == false){
  if(this.typeinstructor == "instructor"){


this.instructselectshow = val.id;
this.instructselectshowname = val.username;
this.instructorshowfullname = val.fname + val.lname

  }else{
this.instructselectshowco = val.id;
this.instructselectshownameco = val.username;
this.instructorshowfullnameco = val.fname + val.lname



  }


}

}

get_instructor_list(data){
  var list_data= this.listDataInfo(data);

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');
var type;

if(this.type == 'course'){
type = 'course';

}else if(this.type == 'meeting'){
type ='meeting'
}

  
    let api_req:any = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"view_instruct_rec","limit":"'+list_data.limit+'","offset":"'+list_data.offset+'","search_text":"'+list_data.search_text+'","admin_id":"'+admin_id+'","type":"'+type+'"}}';
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){
      this.instructorlist = response.result.data.list_data

    for (var i = 0; i < this.instructorlist.length; i++) {

      if(this.typeinstructor == 'instructor'){
        if(this.instructorlist[i].id == this.instructorselect){
this.instructorlist[i].add = true 

}
else{
this.instructorlist[i].add = false 

}
      }
      else{
                if(this.instructorlist[i].id == this.instructorselectco){
this.instructorlist[i].add = true 

}
else{
this.instructorlist[i].add = false 

}
      }


}

 console.log(this.instructorlist)
      this.offset_count2= list_data.offset;
        this.paginationData2 = this.serverService.pagination({'offset':response.result.data.list_info.offset, 'total':response.result.data.list_info.total, 'page_limit' :this.pageLimit2 });
                this.recordNotFound2 = this.instructorlist.length == 0 ? true : false;


      }
     
    }, 
    (error)=>{
        console.log(error);
    });
  }

viewlocation(){

  let updateevents:any =new Object();
 let updateeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
   
updateevents.action="view_location";

updateevents.admin_id =localStorage.getItem('userId');


 updateeventsuser.access_token=access_token;
  updateeventsuser.operation="lecture";
  updateeventsuser.moduleType="lecture";
  updateeventsuser.api_type="web";
  updateeventsuser.element_data = updateevents;
      this.serverService.sendServer(updateeventsuser).subscribe((response: any) => {
console.log(response)
        if(response.result.status==true){
     this.locations = response.result.data
   

          }
        else{

 
        }
      
      }, 
      (error)=>{
          console.log(error);
      });

}


assignstudentstocourse(res){

  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');


  let updateevents:any =this.addCourse.value;


registerstudentdata.action="add_course_students";

// if(this.details == 'edit'){

// registerstudentdata.event_name=this.courseditdetials.event_name;
// registerstudentdata.start_time= moment(this.courseditdetials.start_time).format('YYYY-MM-DD');
// registerstudentdata.from_time=this.courseditdetials.start_time+':'+'00'
// registerstudentdata.to_time=this.courseditdetials.end_time+':'+'00'
// registerstudentdata.course_id=this.courseditdetials.course_id;
// registerstudentdata.no_of_class=this.courseditdetials.no_of_class;
// registerstudentdata.days=this.courseditdetials.days;
// registerstudentdata.type="update";
// }else{


  registerstudentdata.event_name=this.addCourse.value.event_name;
registerstudentdata.start_time= moment(this.addCourse.value.start_date).format('YYYY-MM-DD');
registerstudentdata.from_time=this.addCourse.value.start_time
registerstudentdata.to_time=this.addCourse.value.end_time
registerstudentdata.course_id=res;
registerstudentdata.no_of_class=this.addCourse.value.no_of_class;
registerstudentdata.days=this.dayspick;
// }




registerstudentdata.student_name=this.sendstudent;
registerstudentdata.inst_id=this.instructorselect;
registerstudentdata.co_inst=this.instructorselectco;
registerstudentdata.admin_id=admin_id;



 registeruser.access_token=access_token;
  registeruser.operation="lecture";
  registeruser.moduleType="lecture";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.data.status=="true" ){

      iziToast.success({
    title: 'OK',
    position: 'topRight',
    message: 'Successfully Course Id'+' '+res+' '+'assigned!',
});
this.course_list()
     $('#addNewPopupcourse').modal('hide');

   }else{
   iziToast.error({
    title: 'OK',
    position: 'topRight',
    message: 'Error Occured!',
});
   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}

viewstud(){
// console.log(this.eventeditdetials)
 let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

 




registerstudentdata.action="view_assign_students";
registerstudentdata.admin_id=admin_id;

registerstudentdata.inst_id=this.instructorselect;


// registerstudentdata.event_id  =0;

 if(this.type == 'course'){
   var starts =moment(this.courseditdetials.start_time).format('YYYY-MM-DD HH:mm:ss');
 var ends =moment(this.courseditdetials.end_time).format('YYYY-MM-DD HH:mm:ss');
   registerstudentdata.start_time=starts;
registerstudentdata.end_time=ends;
registerstudentdata.event_name=this.addCourse.value.event_name;
   registerstudentdata.course_id =this.courseidsend;
   registerstudentdata.meeting_id =0;
registerstudentdata.event_id  =0;
 }else if(this.type == 'meeting'){
var startss =moment(this.meetingdetails.meeting_dt).format('YYYY-MM-DD HH:mm:ss');
 var endss =moment(this.meetingdetails.meeting_end).format('YYYY-MM-DD HH:mm:ss');
   registerstudentdata.start_time=startss;
registerstudentdata.end_time=endss;
registerstudentdata.event_name=this.addEvent.value.event_name;
   registerstudentdata.course_id =0;
registerstudentdata.event_id  =0;
registerstudentdata.meeting_id  =this.meetingidsend;

 }

 registeruser.access_token=access_token;
  registeruser.operation="lecture";
  registeruser.moduleType="lecture";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){
     this.get_attendees = response.result.data

 if(this.type == 'course'){
this.sendstudentarr=[];
for (var i = 0; i < this.get_attendees.length; i++) {
  this.sendstudentarr.push(this.get_attendees[i].student_name) 
}

this.addCourse.patchValue({

  student_count:this.sendstudentarr.length
});


this.sendstudent = '';
   this.sendstudent=this.sendstudentarr.join(',');
}
 else if(this.type == 'meeting'){
this.participantarr=[];
for (var i = 0; i < this.get_attendees.length; i++) {
  // this.get_attendees[i].add = false;
  this.participantarr.push(this.get_attendees[i].student_name) 
}

  this.sendparticipant = ''
   this.sendparticipant=this.participantarr.join(',');
     }

   }else{

   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}





addmeeting(val){
console.log(val)
// this.courseidsend = l.course_id;
this.meetingidsend = val.id;
  let editevents:any = new Object();
 let editeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');


editevents.action="edit_meeting";

editevents.id=val.id;

editevents.admin_id =localStorage.getItem('userId');
 editeventsuser.access_token=access_token;
  editeventsuser.operation="meeting";
  editeventsuser.moduleType="meeting";
  editeventsuser.api_type="web";
  editeventsuser.element_data = editevents;
      this.serverService.sendServer(editeventsuser).subscribe((response: any) => {
        if(response.result.data.length != 0){
console.log(response)
this.meetingdetails = response.result.data[0]

this.instructorselect = response.result.data[0].inst_id
         this.instructorselectco = response.result.data[0].co_inst_id
         // this.instructselectnameco =response.result.data[0].co_inst;

         // this.instructselectname =response.result.data[0].instructors;
         this.instructorfullname = response.result.data[0].instruct_name;
         this.instructorfullnameco= response.result.data[0].co_instruct_name;


         this.addEvent.patchValue({
event_name: response.result.data[0].meeting_name,
 instructors :response.result.data[0].instructors,
 start_time:moment(response.result.data[0].meeting_dt).format('YYYY-MM-DDTHH:mm'),

 end_time:moment(response.result.data[0].meeting_end).format('YYYY-MM-DDTHH:mm'),

 // count:response.result.data[0].count,
 location:response.result.data[0].location,
 reference:response.result.data[0].reference,


  });




this.viewstud() 
 $('#addNewPopupmeeting').modal({
   show:true,
   backdrop:false
});
$('#addNewPopupmeeting').modal('show');
 
          // }
        // else{

        }
      
      }, 
      (error)=>{
          console.log(error);
      });
}






viewevents(){

  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');


registerstudentdata.action="view_events";
registerstudentdata.admin_id=admin_id;

 registeruser.access_token=access_token;
  registeruser.operation="user";
  registeruser.moduleType="user";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){
     this.eventslist = response.result.data
   }else{

   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}



savealldataparticipant(){
   // console.log(this.typecalled)
   this.sendparticipant = ''
   console.log(this.participantarr)

   this.sendparticipant=this.participantarr.join(',');
   console.log(this.sendparticipant)




// this.assignstudentstomeeting('')


}

assignstudentstomeeting(res){
console.log(res)
  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

// var starts =moment(this.eventeditdetials.start_time).format('YYYY-MM-DD HH:mm:ss');
 // var ends =moment(this.eventeditdetials.end_time).format('YYYY-MM-DD HH:mm:ss');

registerstudentdata.action="assign_participant";
registerstudentdata.participant_name=this.sendparticipant;

registerstudentdata.inst_id=this.instructorselect;
registerstudentdata.co_inst=this.instructorselectco;

registerstudentdata.admin_id=admin_id;


  registerstudentdata.meeting_id=res[0].id;
  registerstudentdata.meeting_name=res[0].meeting_name;
  registerstudentdata.start_time=res[0].meeting_dt;
registerstudentdata.end_time=res[0].meeting_end;





 registeruser.access_token=access_token;
  registeruser.operation="meeting";
  registeruser.moduleType="meeting";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){


     iziToast.success({
    title: 'OK',
    position: 'topRight',
    message: 'Successfully Participant assigned!',
});
   }else{
   iziToast.error({
    title: 'OK',
    position: 'topRight',
    message: 'Error Occured!',
});
   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}

savedataparticipant(val){

 var adding = false
if(val.add == false){

if(this.participantarr.length != 0){

 for (var i = 0; i < this.participantarr.length; i++) {


if(val.id !== this.participantarr[i]){

 adding  = true
}

}
  if(adding){
      this.participantarr.push(val.id)
      this.participantnames.push(val.name)

  }
}
else{
   this.participantarr.push(val.id)

    this.participantnames.push(val.name)
}

}
else{

   for (var j = 0; j < this.participantarr.length; j++) {

if(val.id == this.participantarr[j]){

    this.participantarr.splice(this.participantarr.indexOf(this.participantarr[j]), 1 );

}
}
   for (var k = 0; k < this.participantnames.length; k++) {

if(val.name == this.participantnames[k]){

    this.participantnames.splice(this.participantnames.indexOf(this.participantnames[k]), 1 );

}
}

}
 

}


assignmeeting(){

  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');

    let admin_id: any=localStorage.getItem('userId');
    let superadmin: any=localStorage.getItem('superadmin');


registerstudentdata.action="create_meeting";
registerstudentdata.admin_id=admin_id;
registerstudentdata.location=this.addEvent.value.location;
registerstudentdata.reference=this.addEvent.value.reference;


registerstudentdata.super_admin=superadmin;

registerstudentdata.meeting_name=this.addEvent.value.event_name;
registerstudentdata.incharge_id=this.instructorselect;
registerstudentdata.co_incharge_id=this.instructorselectco;

registerstudentdata.template='0';
registerstudentdata.template_name='';

registerstudentdata.notes='';
registerstudentdata.meeting_dt=this.addEvent.value.start_time;
registerstudentdata.meeting_end=this.addEvent.value.end_time;

// event_name: response.result.data[0].meeting_name,
//  instructors :response.result.data[0].instructors,
//  start_time:moment(response.result.data[0].meeting_dt).format('YYYY-MM-DDTHH:mm'),

//  end_time:moment(response.result.data[0].meeting_end).format('YYYY-MM-DDTHH:mm'),

//  // count:response.result.data[0].count,
//  location:response.result.data[0].location,
//  reference:response.result.data[0].reference,



 registeruser.access_token=access_token;
  registeruser.operation="meeting";
  registeruser.moduleType="meeting";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
       
 

        if(response.result.status==true){
this.assignstudentstomeeting(response.result.data)

        }
        else{
iziToast.error({
    // title: 'OK',
    position: 'topRight',
    message: 'Illegal operation!',
});
        }
      
      }, 
      (error)=>{
          console.log(error);
      });
}












}
