<!-- <body> --> 
<!-- <div class="loader"></div> -->
<!-- <div id="app"> -->
<!-- <div class="main-wrapper main-wrapper-1"> -->
<!-- Main Content -->
<!-- <div class="main-content"> -->
<!-- <section class="section"> -->
<div class="card">
   <div class="card-header">
      <div class="card-header-title">
         <h4>Wallboard</h4>
      </div>
      <div class="card-header-btn-group">
         <a [routerLink]="['/recordevents']" class="btn btn-success"><i class="fa fa-eye" aria-hidden="true"></i> Manage Events</a>
      </div>
   </div>
   <div class="card-body">
      <div class="row" >
         <div class="col-xl-3 col-lg-6"  *ngFor="let l of dashboardevents">
         <!-- <div class="card" [ngStyle]="{'background':'l.colour !important'}"> -->
         <div    [style.background-color]="l.colour" class="card" >

            
            <!-- <div class="card l-bg-cyan-dark "> -->

               <div class="card-statistic-3">
                  <div class="card-icon">
                     <!-- <img [src]="l.dash_image"> -->
<img src="{{l.dash_image}}"  onerror="this.src='assets/images/nodashimage.png'" alt="image"  width="35">
                  </div>
                  <div class="card-content">
                     <h4 class="card-title">{{l.event_name}}</h4>
                     <div class="card-event-details">
                        <h2>{{l.dashboard_count}}</h2>
                        <p class="mb-0 text-sm">
                           <span class="mr-2"><i class="fa fa-users" aria-hidden="true"></i></span>
                           <span class="text-nowrap">Students</span>
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
       <!--   <div class="col-xl-3 col-lg-6">
            <div class="card l-bg-green-dark">
               <div class="card-statistic-3">
                  <div class="card-icon"><img src="assets/img/dashboard/hockey.svg"></div>
                  <div class="card-content">
                     <h4 class="card-title">Hockey</h4>
                     <div class="card-event-details">
                        <h2>19</h2>
                        <p class="mb-0 text-sm">
                           <span class="mr-2"><i class="fa fa-users" aria-hidden="true"></i></span>
                           <span class="text-nowrap">Students</span>
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-xl-3 col-lg-6">
            <div class="card l-bg-purple-dark">
               <div class="card-statistic-3">
                  <div class="card-icon"><img src="assets/img/dashboard/basketball.svg"></div>
                  <div class="card-content">
                     <h4 class="card-title">Basketball</h4>
                     <div class="card-event-details">
                        <h2>42</h2>
                        <p class="mb-0 text-sm">
                           <span class="mr-2"><i class="fa fa-users" aria-hidden="true"></i></span>
                           <span class="text-nowrap">Students</span>
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-xl-3 col-lg-6">
            <div class="card l-bg-orange-dark">
               <div class="card-statistic-3">
                  <div class="card-icon"><img src="assets/img/dashboard/football.svg"></div>
                  <div class="card-content">
                     <h4 class="card-title">Football</h4>
                     <div class="card-event-details">
                        <h2>87</h2>
                        <p class="mb-0 text-sm">
                           <span class="mr-2"><i class="fa fa-users" aria-hidden="true"></i></span>
                           <span class="text-nowrap">Students</span>
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-xl-3 col-lg-6">
            <div class="card l-bg-dark-red">
               <div class="card-statistic-3">
                  <div class="card-icon"><img src="assets/img/dashboard/tennis.svg"></div>
                  <div class="card-content">
                     <h4 class="card-title">Tennis</h4>
                     <div class="card-event-details">
                        <h2>12</h2>
                        <p class="mb-0 text-sm">
                           <span class="mr-2"><i class="fa fa-users" aria-hidden="true"></i></span>
                           <span class="text-nowrap">Students</span>
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-xl-3 col-lg-6">
            <div class="card l-bg-dark-orange">
               <div class="card-statistic-3">
                  <div class="card-icon"><img src="assets/img/dashboard/badminton.svg"></div>
                  <div class="card-content">
                     <h4 class="card-title">Badminton</h4>
                     <div class="card-event-details">
                        <h2>56</h2>
                        <p class="mb-0 text-sm">
                           <span class="mr-2"><i class="fa fa-users" aria-hidden="true"></i></span>
                           <span class="text-nowrap">Students</span>
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-xl-3 col-lg-6">
            <div class="card l-bg-dark-green">
               <div class="card-statistic-3">
                  <div class="card-icon"><img src="assets/img/dashboard/athletics.svg"></div>
                  <div class="card-content">
                     <h4 class="card-title">Athletics</h4>
                     <div class="card-event-details">
                        <h2>45</h2>
                        <p class="mb-0 text-sm">
                           <span class="mr-2"><i class="fa fa-users" aria-hidden="true"></i></span>
                           <span class="text-nowrap">Students</span>
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div> -->
         <!-- <div class="col-xl-3 col-lg-6">
            <div class="card l-bg-dark-yellow">
               <div class="card-statistic-3">
                  <div class="card-icon"><img src="assets/img/dashboard/skateboarding.svg"></div>
                  <div class="card-content">
                     <h4 class="card-title">Skateboarding</h4>
                     <div class="card-event-details">
                        <h2>23</h2>
                        <p class="mb-0 text-sm">
                           <span class="mr-2"><i class="fa fa-users" aria-hidden="true"></i></span>
                           <span class="text-nowrap">Students</span>
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div> -->
      </div>
   </div>
</div>
<div class="card">
   <div class="card-header">
      <div class="card-header-title">
         <h4>Event Time Chart</h4>
      </div>
      <div class="card-header-btn-group">
         <a [routerLink]="['/calendar']" class="btn btn-warning"><i class="fa fa-eye" aria-hidden="true"></i> View Details</a>
      </div>
   </div>
   <div class="card-body">
      <div class="recent-report__chart">
         <div id="chart1"></div>
      </div>
   </div>
</div>
