

  <!-- <div class="loader"></div> -->
  <div id="app">
    <section class="section">
      <div class="container mt-5">
        <div class="row">
          <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-8 offset-lg-2 col-xl-8 offset-xl-2">
            <div class="card card-primary">
              <div class="card-header">
                <h4>Register</h4>
              </div>
              <div class="card-body">
                <form method="POST" [formGroup]="register" (ngSubmit)="registerdata()">
<div class="form-group">
                    <label for="username">User Name</label>
                    <input id="username" type="text" class="form-control"   formControlName="username">

                      <div class="error-message message-small" *ngIf="!register.get('username').valid && register.get('username').touched">
                      Please enter user name
                    </div>
                   <!--  <div class="invalid-feedback">
                    </div> -->
                  </div>

                  <div class="row">
                    <div class="form-group col-6">
                      <label for="fname">First Name</label>
                      <input id="fname" type="text" class="form-control" formControlName="fname" autofocus>
                       <div class="error-message message-small" *ngIf="!register.get('fname').valid && register.get('fname').touched">
                      Please enter first name
                    </div>
                    </div>
                    <div class="form-group col-6">
                      <label for="lname">Last Name</label>
                      <input id="lname" type="text" class="form-control"  formControlName="lname" >
                      <div class="error-message message-small" *ngIf="!register.get('lname').valid && register.get('lname').touched">
                      Please enter last name
                    </div>
                    </div>
                  </div>
                  <div class="row">
                  <div class="form-group col-12">
                    <label for="email">Email</label>
                    <input id="email" type="email" class="form-control"   formControlName="email">
                    <div class="error-message message-small" *ngIf="!register.get('email').valid && register.get('email').touched">
                      Please enter email
                    </div>
                    <!-- div class="invalid-feedback">
                    </div> -->
                  </div>
                   <div class="form-group col-6">
                    <label for="phone_no">Country Code</label>
                    <input id="phone_no" type="Number" class="form-control"   formControlName="country_code">
                    <div class="error-message message-small" *ngIf="!register.get('country_code').valid && register.get('country_code').touched">
                      Please enter country code
                    </div>
                  </div>
              <div class="form-group col-6">
                    <label for="phone_no">Phone Number</label>
                    <input id="phone_no" type="Number" class="form-control"   formControlName="phone_no">
                    <div class="error-message message-small" *ngIf="!register.get('phone_no').valid && register.get('phone_no').touched">
                      Please enter phone number
                    </div>
                    <!-- div class="invalid-feedback">
                    </div> -->
                  </div>

                </div>
                  <div class="row">
                    <div class="form-group col-6">
                      <label for="password" class="d-block">Password</label>
                      <input id="password" type="password" class="form-control pwstrength" data-indicator="pwindicator"
                         formControlName="password" (change)="onPasswordChange()">
                          <div class="error-message message-small" *ngIf="!register.get('password').valid && register.get('password').touched">
                      Please enter password
                    </div>
                      <div id="pwindicator" class="pwindicator">
                        <div class="bar"></div>
                        <div class="label"></div>
                      </div>
                    </div>
                    <div class="form-group col-6">
                      <label for="passwordconfrm" class="d-block">Password Confirmation</label>
                      <input id="passwordconfrm"  type="password" class="form-control" formControlName="passwordconfrm" (change)="onPasswordChange()">
                       <!--   <div class="error-message message-small" *ngIf="!register.get('passwordconfrm').valid && register.get('passwordconfrm').touched">
                      Please enter confirmation password
                    </div> -->

<div *ngIf="confirm_password.hasError('mismatch') && register.get('passwordconfrm').touched" class="error-message message-small">Password do not match.</div> 
                    <div class="error-message message-small" *ngIf="!confirm_password.hasError('mismatch') && !register.get('passwordconfrm').valid && register.get('passwordconfrm').touched">
                      Please enter confirmation password
                    </div>


                    </div>
                  </div>
                  <div class="form-group">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox"  class="custom-control-input" id="agree" formControlName="agree" >
                      
                      <label class="custom-control-label" for="agree">I agree with the terms and conditions</label>
                      <div class="error-message message-small" *ngIf="!register.get('agree').valid && register.get('agree').touched">
                      Please agree with the terms and conditions
                    </div>
                    </div>
                  </div>
                  <!-- div class="form-group">
                    <button type="submit" class="btn btn-primary btn-lg btn-block">
                      Register
                    </button>
                  </div> -->
<div class="row">
        <div class="col-md-6">
            <div class="form-group">
                 <span class="login-message success-message message-small">{{registerSuccess}}</span> 
                       <span class="login-message error-message message-small">{{registerError}}</span> 
                <button type="submit" class="btn btn-primary  btn-lg btn-block">Register</button>
            </div>
        </div>  </div>

 <!-- <div class="form-group">
                  
                    <input type="submit" value="Login" class="btn btn-primary btn-lg btn-block" tabindex="4">
                    
                  </div> -->


                </form>
              </div>
              <div class="mb-4 text-muted text-center">
                Already Registered? <a [routerLink]="['/login']">Login</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

