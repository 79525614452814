import { Component } from '@angular/core';
import { Router } from '@angular/router';
import * as Feather from 'feather-icons';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
	 constructor(private router:Router) {
  }

  title = 'communitycenter';
   public file_path: string = "";
    templateAuthView = false;
  ngAfterViewInit() {
    Feather.replace();
  }
   onActivate(event) {
        this.file_path=this.router.url;
        console.log(this.router.url);
        if (localStorage.getItem('access_token') && localStorage.getItem('type') == '0') {
             // this.router.navigate(['/dashboard']);
            this.templateAuthView=false;
            }
             else if(this.router.url == '/register'){
                this.router.navigate(['/register']);
            this.templateAuthView=true;
                
            }
              else if(this.router.url == '/forgotpwd'){
                this.router.navigate(['/forgotpwd']);
            this.templateAuthView=true;
                
            }
            else if(localStorage.getItem('access_token') && localStorage.getItem('type') == '1'){
           //      this.router.navigate(['/adminlist']);
            this.templateAuthView=false;
                
            }
              else if(localStorage.getItem('access_token') && localStorage.getItem('type') == '2'){
            this.templateAuthView=false;
                
            }
           // else if(this.router.url == '/adminlist' && localStorage.getItem('type') == '1'){
           //      this.router.navigate(['/adminlist']);
           //  this.templateAuthView=false;
                
           //  }
            //  else if(this.router.url == '/addadmin'  && localStorage.getItem('type') == '1'){
            //     this.router.navigate(['/addadmin']);
            // this.templateAuthView=false;
                
            // }
            
        else{
           if(this.router.url == '/Cp-Login'){
            this.router.navigate(['/Cp-Login']);
        }else{
            this.router.navigate(['/login']);            
        }
        this.templateAuthView=true;
        }

    }
   }