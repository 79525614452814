import { Component, OnInit } from '@angular/core';
import * as feather from 'feather-icons';
import { ServerService } from '../services/server.service';
import { Router,ActivatedRoute } from '@angular/router';

declare var iziToast:any;
declare var $:any;
import Swal from 'sweetalert2'
@Component({
  selector: 'app-recordinstructor',
  templateUrl: './recordinstructor.component.html',
  styleUrls: ['./recordinstructor.component.css']
})
export class RecordinstructorComponent implements OnInit {
instructorlist:any=[];
 pageLimit = 10;
 paginationData:any ={"info":"hide"};
 offset_count = 0;
 recordNotFound = false;
dummyCsv;
title = 'app';
elementType = 'url';
value = 'https://play.google.com/store/apps/details?id=com.communityinstructor';
deletearr:any=[];
senddeletenames;
uncheck = 0;
allcheck= false;
  constructor(private serverService: ServerService,private router:Router) { }

  ngOnInit(): void {
  		feather.replace();
  		
  		this.get_instructor_list({})
  	 this.dummyCsv = 'http://booking.mconnectapps.com/api/v1.0/samples/instructor.csv';
  }


  showmodalcsv(){

 $('#addNewPopup').modal({
   show:true,
   backdrop:false
});
$('#addNewPopup').modal('show');
 let admin_id: any =  localStorage.getItem('admin_id'); 
    var formData = new FormData();
   }
 




 
uploadcsv(){

    let superadmin: any=localStorage.getItem('superadmin');
  
        if($('#file').val() == ''){
          iziToast.error({
            message: "Please select the CSV file to Upload",
            position: 'topRight'
        });
        return false;
        }
       var formData = new FormData();
    formData.append('super_admin', superadmin);

    formData.append('action', 'instructor_csv');
    formData.append('admin_id',localStorage.getItem('userId'));
      formData.append('file', $('#file')[0].files[0]);
      formData.append('type','1');

      var s = this;
 $.ajax({  
    url:"https://booking.mconnectapps.com/api/v1.0/index_new.php",  
    type : 'POST',
    data : formData,
    processData: false,  // tell jQuery not to process the data
    contentType: false, 
    success:function(data){ 
     var parsed_data = JSON.parse(data);
      console.log(parsed_data );
 


  if(parsed_data.result.status == true){  

 
       iziToast.success({
          message: "Imported "+parsed_data.result.imported+" records",
          position: 'topRight'
      });
if(parsed_data.result.not_impported != ''){
   iziToast.success({
          message: parsed_data.result.not_impported+" are not Imported",
          position: 'topRight'
      });
}



  s.get_instructor_list({})

$('#addNewPopup').modal('hide');
  
      }  
      
       else{    
  iziToast.success({
          message: "Error Occured .Please try again later",
          position: 'topRight'
      });
  $('#addNewPopup').modal('hide');
      }  
      
      

      
    }  
});  



}


multislt(val)
{
  this.uncheck = 0;
  console.log(val)

 var adding = false
if(val.add == false){

if(this.deletearr.length != 0){

 for (var i = 0; i < this.deletearr.length; i++) {


if(val.id !== this.deletearr[i]){

 adding  = true
}

}
  if(adding){
      this.deletearr.push(val.id)


  }
}
else{
  this.deletearr.push(val.id)

}

}
else{

   for (var j = 0; j < this.deletearr.length; j++) {

if(val.id == this.deletearr[j]){

    this.deletearr.splice(this.deletearr.indexOf(this.deletearr[j]), 1 );

}
}
 

}
 
 console.log(this.deletearr)
}


 removealert(methodname,val){
      if(val == ''){
if(this.deletearr.length == 0){

    iziToast.warning({
         // title: 'OK',
    position: 'topRight',
    message: 'Please select any Incharge!',
});
}else{
    this.calldelete(methodname,val)
}
}else{
 this.calldelete(methodname,val)
}
}
  
    calldelete(methodname,val){
var s = this;
  iziToast.question({
    timeout: 20000,
    close: false,
    overlay: true,
    displayMode: 'once',
    id: 'question',
    zindex: 999,
    title: 'Confirm',
    message: 'Are you sure you want to delete?',
    position: 'center',
    buttons: [
        ['<button><b>YES</b></button>', function (instance, toast) {
 
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
 console.log('yes')
 if(methodname == 'deleteinstructor'){
   s.deleteinstructor(val)
 }

        }, true],
        ['<button>NO</button>', function (instance, toast) {
 
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
  console.log('no')

        }],
    ]

});
}
sendWelcomeMail(val){
  Swal.fire('Please wait')
Swal.showLoading()


    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');
  
    let api_req:any = '{"operation":"lecture","moduleType":"lecture","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"instructor_email","user_id":"'+val.id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response){
      // this.eventss= response
 Swal.close();
     
        iziToast.success({
    title: 'OK',
    position: 'topRight',
    message: 'Welcome Email Successfully!',
});
      // console.log(this.eventss);
      }
   else{
      Swal.close();

            iziToast.error({
    title: 'OK',
    position: 'topRight',
    message: 'Error Occured!',
});
   }

    }, 
    (error)=>{
        console.log(error);
    });
  }

gonew(){
this.router.navigate(['addnew'], { state: { id:'',page:'',pagetype:'Incharge',type:'event'} });

}


goedit(user){
this.router.navigate(['addnew'], { state: { id:user.id,page:'Edit',pagetype:'Incharge',type:'event'} });

}
getsearch(searchValue: string): void {  
  console.log(searchValue);
  this.get_instructor_list({'search_text':searchValue})
}
get_instructor_list(data){
  var list_data= this.listDataInfo(data);

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

  
    let api_req:any = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"view_instruct_rec","limit":"'+list_data.limit+'","offset":'+list_data.offset+',"search_text":"'+list_data.search_text+'","admin_id":"'+admin_id+'","type":"1"}}';
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){
      this.instructorlist = response.result.data.list_data
if(this.allcheck == true){

this.checkallbox()

}else{

if(this.uncheck == 1) {
this.uncheckallbox()
}else{

if(this.instructorlist.length != 0){
if(this.deletearr.length != 0){
for (var i = 0; i < this.instructorlist.length; i++) {
   var ismatch = false;
  for (var j = 0; j < this.deletearr.length; j++) {
   if(this.instructorlist[i].id ==  this.deletearr[j]){
     ismatch = true;
     this.instructorlist[i].add = true;
     break;  

   }
 }
 if (!ismatch){
         this.instructorlist[i].add = false//  checkbox status false
      
      } 

}
}
else{
  
  for (var i = 0; i < this.instructorlist.length; i++) {
     this.instructorlist[i].add = false  
  }
}
}

}
}



 console.log(this.instructorlist)
      this.offset_count = list_data.offset;
      	this.paginationData = this.serverService.pagination({'offset':response.result.data.list_info.offset, 'total':response.result.data.list_info.total, 'page_limit' :this.pageLimit });
                this.recordNotFound = this.instructorlist.length == 0 ? true : false;


      }
     
    }, 
    (error)=>{
        console.log(error);
    });
  }


listDataInfo(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
}  

deleteinstructor(user){

  let deleteuser:any = new Object();
 let deleteuserdata:any = new Object();
    let access_token: any=localStorage.getItem('access_token');


deleteuserdata.action="delete_instruct_record";
// deleteuserdata.id=user.id;
if(user == ''){
this.senddeletenames = '';
this.senddeletenames = this.deletearr.join(',');

 deleteuserdata.id=this.senddeletenames; 
}else{
  deleteuserdata.id=user.id;
}
 deleteuser.access_token=access_token;
  deleteuser.operation="user";
  deleteuser.moduleType="user";
  deleteuser.api_type="web";
  deleteuser.element_data = deleteuserdata;
      this.serverService.sendServer(deleteuser).subscribe((response: any) => {

        console.log(response)
       
  if(response.result.status==true){
       iziToast.success({
    title: 'OK',
    position: 'topRight',
    message: 'Instructor deleted Successfully!',
});
this.get_instructor_list({})
        }
        else{
  iziToast.error({
    position: 'topRight',
    message: 'Some Error Occured',
});
        }
      
      }, 
      (error)=>{
          console.log(error);
      });
}

allchk(){

console.log(this.allcheck)
if(this.allcheck == false){
 
 this.checkallbox()
}else{

this.uncheck = 1;
this.uncheckallbox()
}

console.log(this.deletearr)
}
checkallbox(){
  console.log('enter all check')
   // this.deletearr =[];
 

 console.log(this.deletearr)
  for (var i = 0; i < this.instructorlist.length; i++) { 
 var ismatchs = false;
if(this.deletearr.length != 0){

  for (var j = i+1; j < this.deletearr.length; j++) {
   if(this.instructorlist[i].id !==  this.deletearr[j]){
    
console.log('exists')
// this.deletearr.splice(this.deletearr.indexOf(this.deletearr[j]), 1 );
ismatchs = true;
     this.instructorlist[i].add = true;
    this.deletearr.push(this.instructorlist[i].id)

     break;  

   }
   // else{
     
   // }
 }
 if(!ismatchs){
// this.studentslist[i].add = false;
    // this.deletearr.push(this.studentslist[i].id)

      } 
}else{
  this.instructorlist[i].add = true;
    this.deletearr.push(this.instructorlist[i].id)
}

}
 console.log(this.deletearr)
}
uncheckallbox(){
 for (var i = 0; i < this.instructorlist.length; i++) { 
  this.instructorlist[i].add = false;
   this.deletearr=[];
    // this.deletearr.push(this.studentslist[i].id)
 }
 console.log(this.deletearr)
}
}
