import { EventInput } from '@fullcalendar/angular';

let eventGuid = 0;
const TODAY_STR = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today

export const INITIAL_EVENTS: EventInput[] = [
  {
    id: createEventId(),
    title: 'All-day event',
    start: TODAY_STR
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: TODAY_STR + 'T12:00:00'
  }
];


export function editevents(){

  let editevents:any = new Object();
 let editeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');


editevents.action="view_event_details";

 editeventsuser.access_token=access_token;
  editeventsuser.operation="user";
  editeventsuser.moduleType="user";
  editeventsuser.api_type="web";
  editeventsuser.element_data = editevents;
      this.serverService.sendServer(editeventsuser).subscribe((response: any) => {

        console.log(response)
       
 

        if(response.result.status==true){
          this.events = response.result.data

        }
        else{

        }
      
      }, 
      (error)=>{
          console.log(error);
      });
}




export function createEventId() {
  return String(eventGuid++);
}
