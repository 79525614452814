

<div class="light theme-black dark-sidebar">

  <!-- <div class="loader"></div> -->
  <div id="app">

    <div class="section-body">
      <div class="row">
        <div class="col-12">
          <div class="card">
         
            <div class="card-header">
              <div class="card-header-title">
                <h4>Incharge Record</h4>
              </div>

<!-- <ngx-qrcode [qrc-element-type]="elementType" [(qrc-value)] = "value">
</ngx-qrcode> -->



              <div class="card-header-btn-group" style="width: 100%">
<!--  <a   (click)="getandroid()" style="margin: 0px 10px 0px 10px !important;" class="btn btn-success">Get Android App</a>

 <a  (click)="getios()" style="margin: 0px 10px 0px 10px !important;" class="btn btn-success">Get IOS App</a> -->

                <a  (click)="gonew()"  style="margin: 0px 10px 0px 10px !important;" class="btn btn-info"><i class="fa fa-plus-circle" aria-hidden="true"></i> Add New</a>

 <a (click)="showmodalcsv()" style="margin: 0px 10px 0px 10px !important;" class="btn btn-warning"><i class="fa fa-plus-upload" aria-hidden="true"></i>Upload CSV</a>
                        <a href="{{this.dummyCsv}}"  style="margin: 0px 10px 0px 10px !important;"  class="btn btn-success"><i class="fa fa-plus-download" aria-hidden="true"></i>Download Sample CSV</a>
 <a (click)="removealert('deleteinstructor','')" style="margin: 0px 10px 0px 10px !important;"  class="btn btn-danger"><i class="fa fa-plus-download" aria-hidden="true" ></i>Delete</a>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-striped" id="table-2">
                  <thead>
                                <tr>
                                <td colspan="20" class="search-text-tbl">
                                    <div class="input-group"><input type="text" placeholder="Search Incharge" class="form-control" (input)="getsearch($event.target.value)" (keyup.enter)="get_instructor_list({'search_text':instructorsearch.value})" #instructorsearch>
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" (click)="get_instructor_list({'search_text':instructorsearch.value})"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </td>
                            </tr>


                    <tr class="l-bg-light-blue">
                 <th class="text-center pt-2">
                            <!--   <div class="custom-checkbox custom-checkbox-table custom-control">
                                 
                                <input type="checkbox" data-checkboxes="mygroup" data-checkbox-role="dad" [(ngModel)]="allcheck"
                                class="custom-control-input" id="checkbox-all" (click)="allchk()">
                                <label for="checkbox-all" class="custom-control-label">&nbsp;</label>
                              </div> -->
                            </th>
                      <th>S.no</th>
                      <th>Incharge Name</th>
                      <th>Photo</th>
                      <th>Email</th>
                      <th>Contact no.</th>
                      <th>NRIC</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr  *ngFor="let l of instructorlist;let i=index">
                       <td class="text-center pt-2">
                             
                                <input class="" type="checkbox"  [(ngModel)]="l.add" name="add" (click)="multislt(l)">
                                
                            </td>
                      <td>{{offset_count+i+1}}</td>
                      <td>{{l.fname}} {{l.lname}}</td>
                      <!-- <td>
                        <img alt="image" src="assets/img/users/user-1.png" width="35">
                      </td> -->
                      <!-- image -->
                     <td >
                              <!-- <img alt="image" *ngIf="l.image == ''" src="assets/images/avatar.jpg" width="35"> -->
                               <!-- <img alt="image" [src]="l.image"  *ngIf="l.image != ''" width="35"> -->

    <img src="{{l.image}}"  onerror="this.src='assets/images/avatar.jpg'" alt="image"  width="35">
                            </td>


                      <td>{{l.email}}</td>
                      <td>{{l.phone_no}}</td>
                      <td>{{l.nirp}}</td>

                      <td>
                        <a  (click)="goedit(l)"  class="btn btn-action bg-purple mr-1" data-toggle="tooltip" title="Edit">
                          <i class="fas fa-pencil-alt"></i>
                        </a> 
                        <a href="javascript:;" (click)="removealert('deleteinstructor',l)" class="btn btn-danger btn-action" data-toggle="tooltip" title="Delete">
                          <i class="fas fa-trash"></i>
                        </a>

   <button style="margin-left: 5px;margin-top: 5px" type="button" class="btn btn-success" (click)="sendWelcomeMail(l)"><i class="fas fa-envelope"></i>&nbsp; Send Welcome Mail</button>


                      </td>
                    </tr>


                  </tbody>
                  <tr *ngIf="recordNotFound == true">
                               <td colspan="12">Data not found</td>
                            </tr>
                </table>
              </div>


 <div class="card-footer text-right" *ngIf="recordNotFound == false">
                        <nav class="d-inline-block">
                        <ul class="pagination mb-0" *ngIf="paginationData.info == 'show'">

                                <li class="page-item">
                                    <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? get_instructor_list({'search_text':instructorsearch.value,'offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
                                </li>
                                <li class="page-item" *ngIf="paginationData.backtab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="get_instructor_list({'search_text':instructorsearch.value,'offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
                                </li>


                                <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
                                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? get_instructor_list({'search_text':instructorsearch.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                                </li>

                                <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="get_instructor_list({'search_text':instructorsearch.value,'offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
                                </li>

                              <!--   <li class="page-item">
                            <a [class]="paginationData.nexttab (click)="paginationData.nexttab =='show' ? get_student_list() : false"><i class="fas fa-angle-right"></i></a=='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (>
                                </li> -->
                                 <li class="page-item">
                                    <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? get_instructor_list({'search_text':instructorsearch.value,'offset':paginationData.end}) : false"><i class="fas fa-angle-right"></i></a>
                                </li>
                        </ul>
                    </nav>
                </div>

















            </div>
          </div>
        </div>
      </div>
    <!-- </div> -->
  <!-- </section> -->
</div>
<!-- <footer class="main-footer">
  <div class="footer-left">
    Copyright &copy; 2019 <div class="bullet"></div> Design By <a href="#">mconnect</a>
  </div>
  <div class="footer-right">
  </div>
</footer> -->
<!-- </div> -->
</div>

</div>

<div id="addNewPopup" class="modal">
        <div class="modal-dialog">
            <div class="modal-content">
              <div class="card-header">
                        <h4>Upload CSV</h4>
                    </div>
                <!-- <div class="modal-header"> -->

                   <!--  <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                    <h4 class="modal-title">Confirmation</h4> -->
                <!-- </div> -->
                <div class="modal-body">
                  <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="contact_owner">CSV file</label>
                                        <input type="file" class="form-control" name="file" id="file" size="150">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <a  href="{{this.dummyCsv}}"  target="_blank">Click Here To Download Sample CSV Format</a>
                                    </div>
                                </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                    <button type="button" (click)="uploadcsv()" data-dismiss="modal" class="btn btn-primary">Upload</button>
                </div>
            </div>
        </div>
    </div>    


</div>





<!--  <div class="modal fade"   id="iosapp">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
         

        <div class="modal-header">
         
        <h5 class="modal-title" id="exampleModalLabel">Get IOS App</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="text-align: center;">
        
     <ngx-qrcode
      [elementType]="elementType" 
      [value] = "value"
      cssClass = "aclass"
      errorCorrectionLevel = "L">
</ngx-qrcode>

    </div>

  </div>


</div>
</div>
 -->













   <!--     <div class="aligningqr">
            <div   style="padding-right: 4%">
             
              <p class="ptag">Get Android App</p>

</div>
    <div  style="padding-right: 2%">
       <p class="ptag">Get IOS
            <ngx-qrcode style="padding-right: 15%"
      [elementType]="elementType" 
      [value] = "value"
      cssClass = "aclass"
      errorCorrectionLevel = "L">
</ngx-qrcode>
</div>
</div> -->