import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Router,ActivatedRoute } from '@angular/router';
declare var iziToast:any;
declare var $:any;
import Swal from 'sweetalert2'
@Component({
  selector: 'app-adminlist',
  templateUrl: './adminlist.component.html',
  styleUrls: ['./adminlist.component.css']
})
export class AdminlistComponent implements OnInit {
adminlist:any=[];
helpurl
  constructor(private serverService: ServerService,private router:Router) {
  	this.get_admin_list()
this.helpurl = "http://booking.mconnectapps.com/api/v1.0/samples/Help.pdf"
   }

  ngOnInit(){
if(localStorage.getItem('type') == '0'){

localStorage.removeItem("access_token");
          localStorage.removeItem("user_name");
          localStorage.removeItem("user_type");
          localStorage.clear();
          this.router.navigate(['/login']);
          return false
}
    
  }

get_admin_list(){
  


    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('superadmin');

  let api_req:any = '{"operation":"lecture","moduleType":"lecture","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"view_admin","admin_id":"'+admin_id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){

      this.adminlist=response.result.data;


      }


    }, 
    (error)=>{
        console.log(error);
    });
  }
goedit(val){

this.router.navigate(['addadmin'], { state: { id:val.user_id,page:'Edit'} });


}

welcome_email(val){
  
  Swal.fire('Please wait')
Swal.showLoading()

    let access_token: any=localStorage.getItem('access_token');

  
    let api_req:any = '{"operation":"lecture","moduleType":"lecture","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"welcome_email","user_id":"'+val.user_id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.status == true){
 Swal.close();
      iziToast.success({
    // title: 'OK',
    position: 'topRight',
    message: 'Welcome Email Sent Successfully!',
});

      }
else{
   Swal.close();
	   iziToast.error({
    // title: 'OK',
    position: 'topRight',
    message: 'Operation Failed',
});
}

    }, 
    (error)=>{
        console.log(error);
    });
  }

}
