<div class="section-body">

    <input type="hidden" id="reconnect_socket"  (click)="initSocket()">
    <input type="hidden" id="reloadslots"  (click)="getLotclose()">

    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div class="card-header-title">
                        <h4>Manage Parking Lot</h4>
                    </div>
                    <div class="card-header-btn-group">
                        <a style="margin: 0px 10px 0px 10px !important;" (click)="popupadd()"
                            class="btn btn-info"><i class="fa fa-plus-circle" aria-hidden="true"></i> Add New</a>                      
                        <!-- <a style="margin: 0px 10px 0px 10px !important;" (click)="download()"
                                class="btn btn-info"><i class="fa fa-plus-circle" aria-hidden="true"></i> Print All</a>  -->
                    </div>

                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table  table-bordered table-striped table-md " id="table-2">

                            <thead>
                                <!-- <tr>
                                    <td colspan="20" class="search-text-tbl">
                                        <div class="input-group"><input type="text" class="form-control"
                                                placeholder="Search Participant"
                                                (input)="getsearch($event.target.value)"
                                                (keyup.enter)="get_participant_list({'search_text':agent_search.value})"
                                                #agent_search>
                                            <div class="input-group-append">
                                                <button class="btn btn-primary"
                                                    (click)="get_participant_list({'search_text':agent_search.value})"><i
                                                        class="fas fa-search"></i></button>
                                            </div>
                                        </div>
                                    </td>
                                </tr> -->
                                <tr class="l-bg-light-blue">
                                   
                                    <th>S.no</th>
                                    <th>Slot Name</th>
                                    <!-- <th>Slot ID</th> -->
                                    <th>Status</th>
                                    <!-- <th>Contact no.</th> -->
                                    <!-- <th>NRIC</th> -->
                                    <th>Action</th>
                                </tr>
                            </thead>

                            <tbody>


                                <tr *ngFor="let l of carlots_list;let i=index">
                                    
                                    <td>{{i+1}}</td>
                                    <td>{{l.lot_name}}</td>
                                    <!-- <td>{{l.lot_no}}</td> -->
                                    <td style="border-radius: 23px;" *ngIf="l.status==0" class="btn btn-success mb-2 ml-4 mt-2">Available</td>
                                    <td style="border-radius: 23px;" *ngIf="l.status==1" class="btn btn-danger mb-2 ml-4 mt-2">Occupied</td>


                                    <td>
                                        <a *ngIf="l.status==0" href="javascript:void(0)" (click)="editSlot(l.id)"
                                            class="btn btn-action bg-purple mr-1" data-toggle="tooltip" title="Edit">
                                            <i class="fas fa-pencil-alt"></i>
                                        </a>
                                        <a *ngIf="l.status==0" href="javascript:void(0)" (click)="deleteSlot(l.id)"
                                            class="btn btn-danger btn-action mr-1" data-toggle="tooltip" title="Delete">
                                            <i class="fas fa-trash"></i>
                                        </a> 
                                        &nbsp;&nbsp;
                                        &nbsp;&nbsp;
                                        &nbsp;&nbsp;
                                        &nbsp;&nbsp;
                                        <a href="javascript:void(0)" (click)="GenerateQR(l.id)"
                                        class="btn btn-info btn-action" data-toggle="tooltip" title="Show QR">
                                        <i class="fas fa-eye"></i>
                                    </a>
                                    </td>
                                </tr>

                            </tbody>
                            <!-- <tr *ngIf="recordNotFound == true">
                                <td colspan="12">Data not found</td>
                            </tr> -->

                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="modal fade bd-example-modal-lg" id="AddNewSlot" aria-modal="true">
    <div class="modal-dialog modal-lg">

        <div class="modal-content">


            <div class="modal-header">
                <h5 class="modal-title" id="myLargeModalLabel">Create New Slot</h5>

            </div>

            <div class="modal-body">

                <div class="row">
                    <div class="col-sm-12">

                        <div class="card-body">
                         
                            <div class="col-md-12 col-12">
                                <div class="form-group form-float">
                                  <div class="form-line">
                                    <label class="form-label"><b>Slot Name</b></label>
                                    <input (keyup.enter)="addSlot()" type="text" id="add_slot_name" class="form-control" >            
                                  </div>
                                </div>
                            </div>






                            
                        </div>


                    </div>



                </div>
            </div>
            <div class="modal-footer bg-whitesmoke br">

                <button type="button" class="btn btn-success" (click)="addSlot()" data-dismiss="modal">Create</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
            </div>



        </div>
    </div>
</div>

<!-- <div class="modal fade bd-example-modal-lg" id="ShowQrCode" aria-modal="true">
    <div class="modal-dialog modal-lg">

        <div class="modal-content">

            <div class="modal-header">
                <h5 class="modal-title" id="myLargeModalLabel">QR Code</h5>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card-header">
                            <h5 class="modal-title">{{this.slot_name}}</h5>
                        </div>
                      <div id="printThis" class="card-body">

                         <div  class="qr-code pt-3">
                            <qr-code size="200" [value]='this.QR_code_data'></qr-code>
                         </div>                            
                      </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer bg-whitesmoke br">

                <button type="button" class="btn btn-success" id="btnPrint"  data-dismiss="modal">OK</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
            </div>



        </div>
    </div>
</div> -->

<div id="printThis">
<div class="modal fade" id="ShowQrCode" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <div class="card-header">
                    <h5 class="modal-title">{{this.slot_name}}</h5>
                </div>
                <div  class="qr-code pt-3">
                    <qr-code size="400" [value]='this.QR_code_data'></qr-code>
                 </div>  
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" (click)="closemanual()" class="btn btn-primary">Close</button>
                <button type="button" id="Print" data-dismiss="modal" class="btn btn-primary"  >Print</button>
            </div>
        </div>
    </div>
</div>
</div>

<!-- EDIT Slot -->
<div class="modal fade bd-example-modal-lg" id="EditSlotpop" aria-modal="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myLargeModalLabel">Update Slot</h5>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-12">
                        <!-- <div class="card-header">
                            <h5 class="modal-title" id="myLargeModalLabel">{{this.Slot_name}}</h5>
                        </div>   -->
                        <div class="card-body">     
                                             
                            <div class="col-md-12 col-12">
                                <div class="form-group form-float">
                                  <div class="form-line">
                                    <label class="form-label"><b>Slot Name</b></label>
                                    <input (keyup.enter)="updateSlot()" type="text" id="edit_slot_name" class="form-control" >            
                                  </div>
                                </div>
                            </div>
                         </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer bg-whitesmoke br">
                <button type="button" class="btn btn-success" (click)="updateSlot()" data-dismiss="modal">Update</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
            </div>



        </div>
    </div>
</div>