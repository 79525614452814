

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ServerService } from '../../services/server.service';
declare var iziToast: any;
declare var $: any;
// import '../../../assets/js/scripts.js';
@Component({
  selector: 'app-cp-login',
  templateUrl: './cp-login.component.html',
  styleUrls: ['./cp-login.component.css']
})

export class CpLogin implements OnInit {
  loginForm: FormGroup;
  otpform: FormGroup;
  loginFormTemp = true;
  user_id;
  sendotps = false;
  page;
  username = '';
  constructor(private route: ActivatedRoute, private router: Router, private serverService: ServerService) {
    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        if (this.router.getCurrentNavigation().extras != null) {
          this.sendotps = this.router.getCurrentNavigation().extras.state.sendotp;
          this.user_id = this.router.getCurrentNavigation().extras.state.user_id;
          this.page = this.router.getCurrentNavigation().extras.state.page;
          this.username = this.router.getCurrentNavigation().extras.state.username;

        }
      }
    });


  }
  loginError = "";
  loginSuccess = "";
  loginotpSuccess = "";
  loginotpError = "";

  ngOnInit(): void {
    // if(localStorage.getItem('type') == '0'){

    // localStorage.removeItem("access_token");
    //           localStorage.removeItem("user_name");
    //           localStorage.removeItem("user_type");
    //           localStorage.clear();
    // this.router.navigate(['/login']);
    // return false
    // }else{

    // }


    this.loginForm = new FormGroup({
      'username': new FormControl(null, Validators.required),
      'pwd': new FormControl(null, Validators.required),
      'company_name': new FormControl(null, Validators.required)
    });
    this.otpform = new FormGroup({
      'otp': new FormControl(null, Validators.required),

    });
    this.loginForm.setValue({
      'username': this.username,
      'pwd': '',
      'company_name': ''
    })
    //  const months = ["slide1.jpeg","slide2.jpeg","slide3.jpeg","slide4.jpeg","slide5.jpeg","slide6.jpeg","car-park.jpg"];
    //  const random = Math.floor(Math.random() * months.length);
    //  console.log(random, months[random]);

    //  $(".login2-page").css('background-image','url("../../../assets/images/car-park/'+months[random]+'")!important');
  }



  validateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }
  userLogin() {
    if (this.loginForm.valid) {

      this.userLogins()
    }
    else {
      this.validateAllFields(this.loginForm);
    }

  }
  otpsubmit() {
    if (this.otpform.valid) {

      this.otpsubmits()
    }
    else {
      this.validateAllFields(this.otpform);
    }

  }


  sendotp() {

    let api_reqotp: any = new Object();
    let api_reqotpdata: any = new Object();
    api_reqotpdata.action = "resend_otp";
    api_reqotpdata.user_id = this.user_id;
    api_reqotp.operation = "register";
    api_reqotp.moduleType = "register";
    api_reqotp.api_type = "web";
    api_reqotp.element_data = api_reqotpdata;
    this.serverService.sendServer(api_reqotp).subscribe((response: any) => {

      console.log(response)
      if (response.result.status == true) {
        // this.loginotpError ="";
        // this.loginotpSuccess="OTP Send Sucessfully";
        iziToast.success({
          message: "OTP Send Sucessfully to your Phone Number",
          position: 'bottomLeft'
        });


      }
      else {
        iziToast.error({
          message: "Some error Occured",
          position: 'bottomLeft'
        });
      }

    },
      (error) => {
        console.log(error);
      });





  }


  backToLogin() {

    this.sendotps = false;
  }
  loginVia(gh) {

  }


  userLogins() {
    console.log('dsa')
    // let loginForm: any = this.loginForm.value;

    let loginForm:any =  new Object();
    let api_req: any = new Object();
    loginForm.action = "lot_login";
    api_req.operation = "carparking";
    api_req.moduleType = "carparking";
    api_req.api_type = "web";
    loginForm.login_by = "web";
    loginForm.company_name=this.loginForm.value.company_name;
    loginForm.username=this.loginForm.value.username;
    loginForm.pwd=this.loginForm.value.pwd;
    api_req.element_data = loginForm;

    // let new_req :any = JSON.stringify(api_req);
    
    this.serverService.sendServer(api_req).subscribe((response: any) => {

      if (response.status == 'true') {

        localStorage.setItem('company_name', response.data.company_name);
        localStorage.setItem('enable', '1');
        localStorage.setItem('admin_type', 'ParkingSlot');
        //  if(response.result.data.type  == '1'){
        localStorage.setItem('access_token', 'adyjavdkjakjdnkajdakjdnkad');
        localStorage.setItem('userId', '');
        localStorage.setItem('user_name', response.data.username);
        localStorage.setItem('type', '1');
        // localStorage.setItem('superadmin', response.result.data.user_id);
        localStorage.setItem('supersuperadmin', response.data.admin_id);


        // }

        iziToast.success({
          message: "You have logged in successfully",
          position: 'topLeft'
        });
        this.loginForm.reset();
        this.router.navigate(['/Carslot-dashboard']);


      }
      else {

        iziToast.error({
          message: "Invalid Login credentials",
          position: 'bottomLeft'
        });
      }

    },
      (error) => {
        console.log(error);
      });


  }


  otpsubmits() {

    let Otpform: any = this.otpform.value;
    let api_submitotpdata: any = new Object();

    Otpform.action = "check_otp";
    Otpform.user_id = this.user_id;
    api_submitotpdata.operation = "register";
    api_submitotpdata.moduleType = "register";
    api_submitotpdata.api_type = "web";
    api_submitotpdata.element_data = Otpform;
    this.serverService.sendServer(api_submitotpdata).subscribe((response: any) => {

      console.log(response)
      if (response.result.status == true && response.result.data == '1') {
        iziToast.success({
          message: "OTP Validated Sucessfully",
          position: 'bottomLeft'
        });

        if (this.page == 'register') {
          this.sendotps = false;
        }
        else {
          this.userLogin()

        }
      }
      else {
        iziToast.error({
          message: "Please Enter the Valid OTP",
          position: 'bottomLeft'
        });
      }

    },
      (error) => {
        console.log(error);
      });

  }

  forgotusername() {
    this.router.navigate(['forgotpwd'], { state: { page: 'forgotuser' } });

  }

  forgotpassword() {
    this.router.navigate(['forgotpwd'], { state: { page: 'forgotpassword' } });
  }

}





