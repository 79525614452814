

<div class="section-body">
   <div class="row">
      <div class="col-12">
         <div class="card">
            <div class="card-header">
               <div class="card-header-title" style="width: 22% !important">
                  <h4>Assign New </h4>
               </div>

               <div class="card-header-btn-group" style="width: 100%">
  <a (click)="golocationpage('add')"  class="btn btn-success"><i class="fa fa-plus-circle" aria-hidden="true"></i>Add Location</a>&nbsp;

<a (click)="golocationpage('view')"  class="btn btn-success"><i class="fa fa-eye" aria-hidden="true"></i>View Locations</a>&nbsp;



 <a (click)="eventpage('add')" *ngIf="pagetype == 'meeting'" class="btn btn-success"><i class="fa fa-plus-circle" aria-hidden="true"></i>Add Meeting</a>&nbsp;

 <a (click)="eventpage('add')" *ngIf="pagetype == 'event'"  class="btn btn-success"><i class="fa fa-plus-circle" aria-hidden="true"></i>Add Event</a>&nbsp;

 <a (click)="eventpage('add')"  *ngIf="pagetype == 'course'" class="btn btn-success"><i class="fa fa-plus-circle" aria-hidden="true"></i>Add Course</a>&nbsp;


                  <a (click)="eventpage('add')" *ngIf="pagetype == 'meeting'"   class="btn btn-success"><i class="fa fa-eye" aria-hidden="true"></i> View Meetings</a>&nbsp;

                  <a (click)="eventpage('add')" *ngIf="pagetype == 'event'" class="btn btn-success"><i class="fa fa-eye" aria-hidden="true"></i> View Events</a>&nbsp;
                  <a (click)="eventpage('add')" *ngIf="pagetype == 'course'" class="btn btn-success"><i class="fa fa-eye" aria-hidden="true"></i> View Courses</a>&nbsp;


 <a [routerLink]="['/calendar']" class="btn btn-success"><i class="fa fa-eye" aria-hidden="true"></i>Calendar View</a>

               </div>
            </div>
            <div class="card-body">

 <!--  <div class="form-group row mb-4" *ngIf="pagetype == 'evcourse'">
                  <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Choose Type</label>
                  <div class="col-sm-12 col-md-7">   


   <input type="radio" id="Number" [(ngModel)]="eventData.types"  class="mrg-right" name="types"   value="events">
  <label for="phone number"  class="mrg-right">Events</label>
    <input type="radio" id="Number" [(ngModel)]="eventData.types" class="mrg-right"  value="course">
  <label for="phone number"  class="mrg-right">Course</label> -->
 <!-- <input type="radio" id="Number" [(ngModel)]="eventData.types" class="mrg-right"  value="meeting">
  <label for="phone number"  class="mrg-right">Meeting</label> -->
                  <!-- /div>
               </div>
 -->



               <div class="form-group row mb-4">
                  <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3" *ngIf="eventData.types == 'events'">Event Name</label>

 <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3" *ngIf="eventData.types == 'course'">Course Name</label>
 <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3" *ngIf="eventData.types == 'meeting'">Meeting Name</label>
                  <div class="col-sm-12 col-md-7">               
 <select required class="form-control "    [(ngModel)]="eventData.event_name">
    <option value="undefined">Select</option>
           <option *ngFor ="let l of eventslist" value="{{l.event_name}}">{{l.event_name}}</option>
                     </select>
                  </div>
               </div>
 <div class="form-group row mb-4" *ngIf="eventData.types == 'meeting'">
                  <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Reference</label>
                  <div class="col-sm-12 col-md-7">
                     <input  type="text" [(ngModel)]="eventData.reference" name="reference" class="form-control" >
                  </div>
               </div>


 <div class="form-group row mb-4">
                  <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3" *ngIf="eventData.types != 'course'">Add Incharge</label>
                   <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3" *ngIf="eventData.types == 'course'">Add Instructor</label>


                  <div class="col-sm-12 col-md-7" *ngIf="eventData.types == 'course'">
                  <button type="submit" style="margin-right: 10px !important" class="btn btn-success" (click)="addinstruct('instructor')">Add Instructor</button> Added Instructor : {{instructorfullname}}
                  </div>
                    <div class="col-sm-12 col-md-7" *ngIf="eventData.types != 'course'">
                  <button type="submit" style="margin-right: 10px !important" class="btn btn-success" (click)="addinstruct('instructor')">Add Incharge</button> Added Incharge : {{instructorfullname}}
                  </div>
               </div>

 <div class="form-group row mb-4">
                  <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3" *ngIf="eventData.types == 'course'">Add Co-Instructor</label>

                <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3" *ngIf="eventData.types != 'course'">Add Co-Incharge</label>

                   <div class="col-sm-12 col-md-7"  *ngIf="eventData.types == 'course'">
                  <button type="submit" style="margin-right: 10px !important" class="btn btn-success" (click)="addinstruct('coinstructor')">Add Co-Instructor</button> Added Co-Instructor : {{instructorfullnameco}}
                  </div>
                  <div class="col-sm-12 col-md-7" *ngIf="eventData.types != 'course'">
                  <button type="submit" style="margin-right: 10px !important" class="btn btn-success" (click)="addinstruct('coinstructor')">Add Co-Incharge</button> Added Co-Incharge : {{instructorfullnameco}}
                  </div>
               </div>


               <div class="form-group row mb-4" *ngIf="eventData.types == 'course'">
                  <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Add Student</label>
                  <div class="col-sm-12 col-md-7">
                  <button type="submit" style="margin-right: 10px !important" class="btn btn-success" (click)="addstud()">Add Student</button> 
               
                  <!-- Added Students : {{sendstudentname}} -->
<div>
  <p style="margin-bottom: : 0px !important" >Added Students:</p>
 <div class="row">
  
    
 <div class="col-sm-2 col-md-2"*ngFor="let l of showsendnames" style="background-color:lavender;border: 1px solid">{{l}}</div>
    
  </div>
</div>
   
   
                      
                  
                  
                  </div>
               </div>

    <div class="form-group row mb-4" *ngIf="eventData.types != 'course'">
                  <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Add Participant</label>
                  <div class="col-sm-12 col-md-7">
                  <button type="submit" style="margin-right: 10px !important" class="btn btn-success" (click)="addparticipant()">Add Participant</button> 
               
                  <!-- Added Students : {{sendstudentname}} -->
<div>
  <p style="margin-bottom: : 0px !important" >Added Participants:</p>
 <div class="row">
  
    
 <div class="col-sm-2 col-md-2"*ngFor="let l of showsendnamesparticipant" style="background-color:lavender;border: 1px solid">{{l}}</div>
    
  </div>
</div>
   
   
                      
                  
                  
                  </div>
               </div>








 <div class="form-group row mb-4">
                  <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Save As Template</label>
                  <div class="col-sm-12 col-md-7"  style="display: flex;">   

   <input type="checkbox" [(ngModel)]="eventData.template"  class="mrg-right" name="daysselect"  style="margin-top: 10px;">

                  </div>
               </div>






 <div class="form-group row mb-4" *ngIf="eventData.template">
                  <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Template Name</label>
                  <div class="col-sm-12 col-md-7">
                     <input  type="text" [(ngModel)]="eventData.template_name" name="count" class="form-control" >
                  </div>
               </div>


 <div class="form-group row mb-4" *ngIf="eventData.types != 'course'">
                  <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Display Mobile</label>
                  <div class="col-sm-12 col-md-7"  style="display: flex;">   

   <input type="checkbox" [(ngModel)]="eventData.mobile_display"  class="mrg-right" name="mobile_display"  style="margin-top: 10px;">

                  </div>
               </div>










                  <div class="form-group row mb-4" >
                  <label *ngIf="eventData.types == 'course'" class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Student Count</label>
                  <label *ngIf="eventData.types != 'course'" class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Participant Count</label>

                  <div class="col-sm-12 col-md-7" *ngIf="eventData.types == 'course'">
                     <input  type="number" [(ngModel)]="eventData.count" name="count" class="form-control" readonly>
                  </div>
                  <div class="col-sm-12 col-md-7" *ngIf="eventData.types != 'course'">
                     <input  type="number" [(ngModel)]="eventData.count2" name="count2" class="form-control" readonly>
                  </div>
               </div>

 <!-- <div class="form-group row mb-4" *ngIf="eventData.types == 'meeting'">
                  <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Meeting Start Date & Time</label>
                  <div class="col-sm-12 col-md-7">
                     <input  type="datetime-local" [(ngModel)]="eventData.meeting_start_time" name="meeting_start_time" class="form-control datetimepicker">
                  </div>
               </div>
               <div class="form-group row mb-4" *ngIf="eventData.types == 'meeting'">
                  <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Meeting End Date & Time</label>
                  <div class="col-sm-12 col-md-7">
                     <input type="datetime-local" [(ngModel)]="eventData.meeting_end_time" name="end_time" class="form-control datetimepicker">
                  </div>
               </div> -->

               <div class="form-group row mb-4" *ngIf="eventData.types != 'course'">
                  <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3" *ngIf="eventData.types != 'meeting'">Event Start Date & Time</label>
                  <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3" *ngIf="eventData.types == 'meeting'">Meeting Start Date & Time</label>

                  <div class="col-sm-12 col-md-7">
                     <input  type="datetime-local" [(ngModel)]="eventData.start_time" name="start_time" class="form-control datetimepicker">
                  </div>
               </div>
               <div class="form-group row mb-4" *ngIf="eventData.types != 'course'">
                  <label *ngIf="eventData.types != 'meeting'" class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Event End Date & Time</label>
                  <label *ngIf="eventData.types == 'meeting'" class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Meeting End Date & Time</label>
                  <div class="col-sm-12 col-md-7">
                     <input type="datetime-local" [(ngModel)]="eventData.end_time" name="end_time" class="form-control datetimepicker">
                  </div>
               </div>
<!-- course detail html -->
 <div class="form-group row mb-4" *ngIf="eventData.types == 'course'">
                  <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Course Start Date</label>
                  <div class="col-sm-12 col-md-7">
                     <input type="date" [(ngModel)]="eventData.coursestartdate" name="coursestartdate" class="form-control datetimepicker">
                  </div>
               </div>
 <div class="form-group row mb-4" *ngIf="eventData.types == 'course'">
                  <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Start Time</label>
                  <div class="col-sm-12 col-md-7">
                     <input type="time" [(ngModel)]="eventData.coursestarttime" name="coursestarttime" class="form-control datetimepicker">
                  </div>
               </div>

<div class="form-group row mb-4" *ngIf="eventData.types == 'course'">
                  <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">End Time</label>
                  <div class="col-sm-12 col-md-7">
                     <input type="time" [(ngModel)]="eventData.cousreendtime" name="cousreendtime" class="form-control datetimepicker">
                  </div>
               </div>

<!--  -->














 <div class="form-group row mb-4" *ngIf="eventData.types == 'course'">
                  <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Choose Days</label>
                  <div class="col-sm-12 col-md-7"  style="display: flex;">   
<div *ngFor="let c of days; let i = index;">

   <input type="checkbox" [(ngModel)]="c.checked"  class="mrg-right" name="daysselect"  >
  <label for="phone number"  class="mrg-right">{{c.name}}</label>

</div>

 




                  </div>
               </div>


<div class="form-group row mb-4" *ngIf="eventData.types == 'course'">
                  <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">No Of Class</label>
                  <div class="col-sm-12 col-md-7">
                     <input  type="text" [(ngModel)]="eventData.no_of_class" name="no_of_class" class="form-control ">
                  </div>
               </div>



 <div class="form-group row mb-4">
                  <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Location</label>


                 
  <div class="col-sm-12 col-md-7">               
 <select required class="form-control"    [(ngModel)]="eventData.location">
    <option value="undefined" >Select Location</option>
           <option *ngFor ="let l of locations"  value="{{l.location}}" >{{l.location}}</option>
                     </select>
                  </div>







               </div>






               <div class="form-group row mb-4">
                  <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3">Notes</label>
                  <div class="col-sm-12 col-md-7">
                    <kendo-editor [value]="value" [(ngModel)]="eventData.note" name="note"  style="height: 370px;"></kendo-editor> 

                  </div>
               </div>

               <div class="form-group row mb-4">
                  <label class="col-form-label text-md-right col-12 col-md-3 col-lg-3"></label>
                  <div class="col-sm-12 col-md-7">
                     <button type="submit" class="btn btn-success" (click)="assigneventscourse()">Assign </button>
                    <button type="submit" class="btn btn-danger" style="margin-left: 5px !important" (click)="reseting()">Reset </button>

                  </div>


               </div>
            </div>


         </div>
      </div>
   </div>
</div>




<div class="modal fade bd-example-modal-lg" id="addNewPopup" aria-modal="true" >
    <div class="modal-dialog modal-lg">

        <div class="modal-content">


            <div class="modal-header">
                <h5 class="modal-title" id="myLargeModalLabel">Add Student To Event</h5>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>

            <div class="modal-body">

                <div class="row">
                    <div class="col-sm-12">

                  <div class="card-body" >
                    <div class="table-responsive">
                      <table  class="table table-striped" id="table-2">




                        <thead>
                          <tr>
                <td colspan="20" class="search-text-tbl">
                            <div class="input-group"><input type="text" class="form-control" placeholder="Search Student" (input)="getsearch($event.target.value)"  (keyup.enter)="get_student_list({'search_text':agent_search.value})" #agent_search>
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" (click)="get_student_list({'search_text':agent_search.value})"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                          <tr>
                          
                            <th>S.no</th>
                            <th>Student Name</th>
                            <th>Photo</th>
                            <th>Email</th>
                            <th>Contact no.</th>
                            <th>NRIC</th>
                            <th>Add</th>
                          </tr>
                        </thead>
                      
                        <tbody >


                          <tr *ngFor="let l of studentslist;let i=index">
                          
                            <td>{{offset_count+i+1}}</td>
                            <td>{{l.fname}} {{l.lname}}</td>
                            <td class="student-img">
                              <img alt="image" *ngIf="l.image == ''" src="assets/images/avatar.jpg" width="35">
                               <img alt="image" [src]="l.image"  *ngIf="l.image != ''" width="35">


                            </td>

                            <td>{{l.email}}</td>
                            <td>{{l.phone_no}}</td>
                            <td>{{l.nirp}}</td>

    <td><input type="checkbox"    [(ngModel)]="l.add"  name="add"  (click)="savedata(l)"></td>
        <!-- checked="l.add" -->
                            <td>
                     
                            </td>
                          </tr>

                        
                        </tbody>
                         <tr *ngIf="recordNotFound == true">
                               <td colspan="12">Data not found</td>
                            </tr>
                      
                      </table>
                    </div>

 <div class="card-footer text-right" *ngIf="recordNotFound == false">
                        <nav class="d-inline-block">
                        <ul class="pagination mb-0" *ngIf="paginationData.info == 'show'">

                                <li class="page-item">
                                    <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? get_student_list({'search_text':agent_search.value,'offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
                                </li>
                                <li class="page-item" *ngIf="paginationData.backtab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="get_student_list({'search_text':agent_search.value,'offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
                                </li>


                                <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
                                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? get_student_list({'search_text':agent_search.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                                </li>

                                <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="get_student_list({'search_text':agent_search.value,'offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
                                </li>

                                <li class="page-item">
                                    <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? get_student_list({'search_text':agent_search.value,'offset':paginationData.end}) : false"><i class="fas fa-angle-right"></i></a>
                                </li>
                        </ul>
                    </nav>
                </div>










                  </div>
                <!-- </div> -->


            </div>

            

        </div>
    </div>
<div class="modal-footer bg-whitesmoke br">
 
    <button  type="button" class="btn btn-success" (click)="savealldata()" data-dismiss="modal" >Save changes</button>
    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
  </div>


    
</div>
</div>
</div>



<div class="modal fade bd-example-modal-lg" id="addNewPopups" aria-modal="true" >
    <div class="modal-dialog modal-lg">

        <div class="modal-content">


            <div class="modal-header">



                <h5 class="modal-title" id="myLargeModalLabel"  *ngIf="typeinstructor == 'instructor'">{{addtitleinstructor}}</h5>

                <h5 class="modal-title" id="myLargeModalLabel" *ngIf="typeinstructor != 'instructor'">{{addtitleinstructorco}}</h5>

               <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>

            <div class="modal-body">

                <div class="row">
                    <div class="col-sm-12">

                  <div class="card-body" >
                    <div class="table-responsive">
                      <table  class="table table-striped" id="table-2">




                        <thead>
                          <tr>
                <td colspan="20" class="search-text-tbl">
                            <div class="input-group"><input type="text" class="form-control" placeholder="Search" (input)="getsearchinstructor($event.target.value)"  (keyup.enter)="get_instructor_list({'search_text':agent_search2.value})" #agent_search2>
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" (click)="get_instructor_list({'search_text':agent_search2.value})"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                          <tr>
                          
                            <th>S.no</th>
                            <th>Instructor Name</th>
                            <th>Photo</th>
                            <th>Email</th>
                            <th>Contact no.</th>
                            <th>NRIC</th>
                            <th>Add</th>
                          </tr>
                        </thead>
                      
                        <tbody >


                          <tr *ngFor="let l of instructorlist;let i=index">
                          
                            <td>{{offset_count2+i+1}}</td>
                            <td>{{l.fname}} {{l.lname}}</td>
                            <td class="student-img">
                              <!-- <img alt="image" *ngIf="l.image == ''" src="assets/images/avatar.jpg" width="35">
                               <img alt="image" [src]="l.image"  *ngIf="l.image != ''" width="35"> -->
<img src="{{l.image}}"  onerror="this.src='assets/images/avatar.jpg'" alt="image"  width="35">

                            </td>

                            <td>{{l.email}}</td>
                            <td>{{l.phone_no}}</td>
                            <td>{{l.nirp}}</td>

        <td><input type="radio"    [checked]="l.add" name="add"  (click)="savedatainstructor(l)"></td>
                            <td>
                     
                            </td>
                          </tr>

                        
                        </tbody>
                         <tr *ngIf="recordNotFound2 == true">
                               <td colspan="12">Data not found</td>
                            </tr>
                      
                      </table>
                    </div>

 <div class="card-footer text-right" *ngIf="recordNotFound2 == false">
                        <nav class="d-inline-block">
                        <ul class="pagination mb-0" *ngIf="paginationData2.info == 'show'">

                                <li class="page-item">
                                    <a [class]="paginationData2.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData2.backtab=='show' ? get_instructor_list({'search_text':agent_search2.value,'offset':paginationData2.start}) : false"><i class="fas fa-angle-left"></i></a>
                                </li>
                                <li class="page-item" *ngIf="paginationData2.backtab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="get_instructor_list({'search_text':agent_search2.value,'offset':paginationData2.back})"><i class="fas fa-angle-double-left"></i></a>
                                </li>


                                <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData2.data;let i=index">
                                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? get_instructor_list({'search_text':agent_search2.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                                </li>

                                <li class="page-item" *ngIf="paginationData2.nexttab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="get_instructor_list({'search_text':agent_search2.value,'offset':paginationData2.next})"><i class="fas fa-angle-double-right"></i></a>
                                </li>

                                <li class="page-item">
                                    <a [class]="paginationData2.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData2.nexttab =='show' ? get_instructor_list({'search_text':agent_search2.value,'offset':paginationData2.end}) : false"><i class="fas fa-angle-right"></i></a>
                                </li>
                        </ul>
                    </nav>
                </div>










                  </div>
                <!-- </div> -->


            </div>

            

        </div>
    </div>
<div class="modal-footer bg-whitesmoke br">
 
    <button  type="button" class="btn btn-success" (click)="savealldata2()" data-dismiss="modal" >Save changes</button>
    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
  </div>


    
</div>
</div>
</div>





<div class="modal fade bd-example-modal-lg" id="addNewPopupparticipant" aria-modal="true" >
    <div class="modal-dialog modal-lg">

        <div class="modal-content">


            <div class="modal-header">
                <h5 class="modal-title" id="myLargeModalLabel">Add Paticipant</h5>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>

            <div class="modal-body">

                <div class="row">
                    <div class="col-sm-12">

                  <div class="card-body" >
                    <div class="table-responsive">
                      <table  class="table table-striped" id="table-2">




                        <thead>
                          <tr>
                <td colspan="20" class="search-text-tbl">
                            <div class="input-group"><input type="text" class="form-control" placeholder="Search Participant" (input)="getsearchpaticipant($event.target.value)"  (keyup.enter)="get_participant_list({'search_text':participant_search.value})" #participant_search>
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" (click)="get_participant_list({'search_text':participant_search.value})"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                          <tr>
                          
                            <th>S.no</th>
                            <th>Participant Name</th>
                            <!-- <th>Photo</th> -->
                            <th>Email</th>
                            <th>Contact no.</th>
                            <!-- <th>NIRP</th> -->
                            <th>Add</th>
                          </tr>
                        </thead>
                      
                        <tbody >


                          <tr *ngFor="let l of participantlist;let i=index">
                          
                            <td>{{offset_count3+i+1}}</td>
                            <td>{{l.name}}</td>
                            <!-- <td class="student-img">
                              <img alt="image" *ngIf="l.image == ''" src="assets/images/avatar.jpg" width="35">
                               <img alt="image" [src]="l.image"  *ngIf="l.image != ''" width="35">


                            </td> -->

                            <td>{{l.email}}</td>
                            <td>{{l.phone_no}}</td>
                            <td>{{l.nirp}}</td>

    <td><input type="checkbox"    [(ngModel)]="l.add"  name="add"  (click)="savedataparticipant(l)"></td>
        <!-- checked="l.add" -->
                            <td>
                     
                            </td>
                          </tr>

                        
                        </tbody>
                         <tr *ngIf="recordNotFound3 == true">
                               <td colspan="12">Data not found</td>
                            </tr>
                      
                      </table>
                    </div>

 <div class="card-footer text-right" *ngIf="recordNotFound3 == false">
                        <nav class="d-inline-block">
                        <ul class="pagination mb-0" *ngIf="paginationData3.info == 'show'">

                                <li class="page-item">
                                    <a [class]="paginationData3.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData3.backtab=='show' ? get_participant_list({'search_text':participant_search.value,'offset':paginationData3.start}) : false"><i class="fas fa-angle-left"></i></a>
                                </li>
                                <li class="page-item" *ngIf="paginationData3.backtab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="get_participant_list({'search_text':participant_search.value,'offset':paginationData3.back})"><i class="fas fa-angle-double-left"></i></a>
                                </li>


                                <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData3.data;let i=index">
                                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? get_participant_list({'search_text':participant_search.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                                </li>

                                <li class="page-item" *ngIf="paginationData3.nexttab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="get_participant_list({'search_text':participant_search.value,'offset':paginationData3.next})"><i class="fas fa-angle-double-right"></i></a>
                                </li>

                              <li class="page-item">
                                    <a [class]="paginationData3.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData3.nexttab =='show' ? get_participant_list({'search_text':participant_search.value,'offset':paginationData3
                                    .end}) : false"><i class="fas fa-angle-right"></i></a>
                                </li>
                        </ul>
                    </nav>
                </div>










                  </div>
                <!-- </div> -->


            </div>

            

        </div>
    </div>
<div class="modal-footer bg-whitesmoke br">
 
    <button  type="button" class="btn btn-success" (click)="savealldata()" data-dismiss="modal" >Save changes</button>
    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
  </div>


    
</div>
</div>
</div>