import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
declare var iziToast:any;
import Swal from 'sweetalert2'
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
eventData:any={};
  constructor(private serverService: ServerService) { }

  ngOnInit(): void {
  }

sendfeedback(){
  
 if(this.eventData.feed == ''){

      iziToast.warning({

          message: "Please enter feedback",
          position: 'topRight'
      });
      return false
}

 if(this.eventData.feed == undefined){

      iziToast.warning({

          message: "Please enter feedback",
          position: 'topRight'
      });
      return false
}

 Swal.fire('Please wait')
Swal.showLoading()
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');
  
    let api_req:any = '{"operation":"lecture","moduleType":"lecture","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"help","user_id":"'+admin_id+'","mode":"admin","feedback":"'+this.eventData.feed+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
       Swal.close();
      if(response.status == true){
  this.eventData.feed ='';
  iziToast.success({

          message: "Feedback Submitted Succesffully",
          position: 'topRight'
      });
      }
   else{
   	 iziToast.error({

          message: "Try Again Later",
          position: 'topRight'
      });
   }

    }, 
    (error)=>{
        console.log(error);
    });
  }












}
