
    <div class="section-body">

      <div class="row clearfix">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="card">
           <div class="card-header">
            <div class="card-header-title" *ngIf="page == 'Edit'">
              <h4>Edit CC Details</h4>
            </div>
            <div class="card-header-title" *ngIf="page != 'Edit'">
              <h4>Add New CC</h4>
            </div>
            <div class="card-header-btn-group">
              <a  [routerLink]="['/cclist']"  class="btn btn-success"><i class="fa fa-eye" aria-hidden="true"></i> CC List</a>
            </div>
          </div>
          <div class="card-body">



   <form [formGroup]="addadmindata">
              <div class="row" >
 <div class="col-md-6 col-12">
                    <div class="form-group form-float">
                      <div class="form-line">
                        <label class="form-label">First Name*</label>
                        <input type="text" formControlName="fname" class="form-control" required>
                         <div class="error-message message-small" *ngIf="!addadmindata.get('fname').valid && addadmindata.get('fname').touched">
                      Please enter your First Name
                    </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-12">
                    <div class="form-group form-float">
                      <div class="form-line">
                        <label class="form-label">Last Name*</label>
                        <input type="text" formControlName="lname" class="form-control" required>
          <div class="error-message message-small" *ngIf="!addadmindata.get('lname').valid && addadmindata.get('lname').touched">
                      Please enter your Last Name
                    </div>

                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-12">
                    <div class="form-group form-float">
                      <div class="form-line">
                        <label class="form-label">Company Name*</label>
                        <input type="text" formControlName="company_name" class="form-control" required>
          <div class="error-message message-small" *ngIf="!addadmindata.get('company_name').valid && addadmindata.get('company_name').touched">
                      Please enter your Company Name
                    </div>

                      </div>
                    </div>
                  </div>


<div class="col-md-6 col-12">
                    <div class="form-group form-float">
                      <div class="form-line">
                        <label class="form-label">Email*</label>
                        <input type="email" formControlName="email" class="form-control" required>
                        <div class="error-message message-small" *ngIf="!addadmindata.get('email').valid && addadmindata.get('email').touched" >
                       
                      Please enter valid email
                        </div>

                      </div>
                    </div>
                  </div>

               
 <div class="col-md-6 col-12">
                    <div class="form-group form-float">
                      <div class="form-line">
                        <label class="form-label">Country Code*</label>
                        <input type="number" formControlName="country_code" class="form-control" required>
                        <div class="error-message message-small" *ngIf="!addadmindata.get('country_code').valid && addadmindata.get('country_code').touched">
                      Please enter Country Code 
                    </div>
                      </div>
                    </div>
                  </div>

 <div class="col-md-6 col-12">
                    <div class="form-group form-float">
                      <div class="form-line">
                        <label class="form-label">Admin Phone*</label>
                        <input type="number" formControlName="phone_no" class="form-control" required>
                        <div class="error-message message-small" *ngIf="!addadmindata.get('phone_no').valid && addadmindata.get('phone_no').touched">
                      Please enter phone number
                    </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-12">
                    <div class="form-group form-float">
                      <div class="form-line">
                        <label class="form-label">Send OTP</label>

<div class="form-control">

<input type="checkbox"  class="mrg-right" formControlName="enableotp" >
<label class="form-label" style="padding-right: 10px !important">OTP</label>            
              </div>
                      </div>
                    </div>
                  </div>

 <div class="col-md-6 col-12">
                    <div class="form-group form-float">
                      <div class="form-line">
                        <label class="form-label">Duplicate</label>

<div class="form-control">

<input type="checkbox"  class="mrg-right" formControlName="enableduplicate" >
<label class="form-label" style="padding-right: 10px !important">Enable Duplicate</label>            
              </div>
                      </div>
                    </div>
                  </div>

               <div class="col-md-6 col-12">
                <div class="form-group form-float">
                  <div class="form-line">
                    <label class="form-label">Username*</label>

   <input type="text" *ngIf="page != 'Edit'" class="form-control" formControlName="username"  required>
                    <input type="text" *ngIf="page == 'Edit'" class="form-control" formControlName="username" readonly required>
                     <div class="error-message message-small" *ngIf="!addadmindata.get('username').valid && addadmindata.get('username').touched">
                      Please enter username
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-12">
                <div class="form-group form-float">
                  <div class="form-line">
                    <label class="form-label">Password*</label>
                    <input type="password" class="form-control" formControlName="password" id="password"  required>
                     <div class="error-message message-small" *ngIf="!addadmindata.get('password').valid && addadmindata.get('password').touched">
                      Please enter password
                    </div>
                  </div>
                </div>
              </div>
             
  
                
           <div class="col-md-6 col-12"  *ngIf="page == 'Edit'">
                <div class="form-group form-float">
                  <div class="form-line">    
                    <label class="form-label">Status*</label>
                   
<select required class="form-control select2"  formControlName="status">

                     <option value="0">OTP Verification In Progress</option>
                     <option value="1">Active</option>
                     <option value="2">Inactive</option>
                   
                     </select>
                      <div class="error-message message-small" *ngIf="!addadmindata.get('status').valid && addadmindata.get('status').touched">
                      Please select status
                    </div>
             </div>
           </div>
         </div>
        









            
            </div>
<button type="button" class="btn btn-primary  btn-lg" style="float: right !important;margin-left: 10px !important;" (click)="submit()">Submit</button>
<!-- <button type="button" class="btn btn-primary  btn-lg" style="float: right !important;margin-left: 10px !important;"  awPreviousStep>Go to previous</button> -->


</form>



  
    
      </div>
    </div>
  </div>
</div>
</div>

