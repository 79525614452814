import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ServerService } from '../services/server.service';
declare var iziToast:any;
import '../../assets/js/scripts.js';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
 register: FormGroup;
  constructor(private router:Router,private serverService: ServerService) { }
registerSuccess =''
registerError =''
  ngOnInit(): void {

  	 this.register = new FormGroup({
      'username' : new FormControl(null,Validators.required),
      'fname' : new FormControl(null,Validators.required),
      'lname' : new FormControl(null,Validators.required),
      'password' : new FormControl(null,Validators.required),
      'phone_no' : new FormControl(null,Validators.required),
      'country_code' : new FormControl(null,Validators.required),
      'passwordconfrm':new FormControl(null,Validators.required),
      // 'agree': new FormControl(0),
    'agree': new FormControl(false,Validators.requiredTrue),

'email' : new FormControl(null,[
    Validators.required,
    Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")])
      // 'action' : new FormControl(0),
     });

 // this.register.value.agree = false;
  }

  get password() {
  return this.register.controls['password'];
}

get confirm_password(){
  return this.register.controls['passwordconfrm'];
}

onPasswordChange() {
  if (this.confirm_password.value == this.password.value) {
    this.confirm_password.setErrors(null);
  } else {
    this.confirm_password.setErrors({ mismatch: true });
  }
}


registerdata() {
        if (this.register.valid) {
            this.registerdatas()
        } else {
            this.validateAllFields(this.register); 
        }
    }

validateAllFields(formGroup: FormGroup) {         
        Object.keys(formGroup.controls).forEach(field => {  
            const control = formGroup.get(field);            
            if (control instanceof FormControl) {             
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {        
                this.validateAllFields(control);  
            }
        });
    }
  gologin(){
            this.router.navigate(['/login']);

  }


registerdatas(){


   let registeruserdata:any =  this.register.value;
  registeruserdata.action ="register_user";
  // registeruserdata.country_code ="+91";
  if(registeruserdata.agree == true)
    registeruserdata.agree = 1
  else
    registeruserdata.agree = 0


  let registeruser:any = new Object();


  registeruser.operation="register";
  registeruser.moduleType="register";
  registeruser.api_type="web";
  registeruser.element_data = registeruserdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

      	console.log(response)
        
        if(response.result.status==true){
       // this.registerSuccess ='Registered Sucessfully'
       // this.registerError = ''
       if(response.result.data == "Already exists"){
          iziToast.warning({
          message: "Data"+" "+response.result.data,
          position: 'bottomLeft'
      });
      }
      else{
                 iziToast.success({
         message: "OTP Send Sucessfully to your Phone Number",
         position: 'bottomLeft'
       });
this.router.navigate(['login'], { state: { sendotp: true ,user_id:response.result.data,page:'register'} });
            
      }
      



      }else{
this.registerSuccess =''
this.registerError = 'Please Check entered data'
     iziToast.error({
          message: "Please Check entered data",
          position: 'bottomLeft'
      });

      }
      }, 
      (error)=>{
          console.log(error);
      });
}



}
