<div class="section-body">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4>Information technology foundation Singapore (ITFS)</h4>
        </div>
        <div class="card-body p-30">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group contact-details">
                <img alt="image" src="assets/images/itfs-logo.svg" class="about-logo" />
               <!--   <button type="button" class="btn btn-primary" (click)="addinstructor()" style="margin-right: 10px !important">Select Instructor</button> -->
               <p><strong class="contact-label">Email Us</strong>  <a href="mailto:info@itfs.org.sg">info@itfs.org.sg</a></p>
               <p><strong class="contact-label">phone</strong> <a href="tel:+6567157008">+65 67157008</a></p>
               <p><strong class="contact-label">Fax</strong> <a href="fax:+6563401007">+65 63401007</a></p>
               <p><strong class="contact-label">Address</strong> 27 New Industrial Rd, #03-06 Novelty Techpoint, Singapore 536212</p>
               <p><strong class="contact-label">Website</strong> <a href="https://www.itfs.org.sg/" target="_blank">https://www.itfs.org.sg/</a></p>
             </div>
           </div>
         </div>
              <!--   <div class="row">
                   <div class="col-md-4"></div>
                   <div class="col-md-4">
                    <div class="form-group">
                        <button type="button" class="btn btn-success" (click)="deskattendance()">Take Attendance</button>
                    </div>
                </div>
                <div class="col-md-4"></div>
              </div> -->
            </div>
          </div>
        </div>
      </div> 
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>About US</h4>
            </div>
            <div class="card-body p-30">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group contact-details">
                    ITFS strives to set a path out of poverty to the deserving youth belonging to the economically downtrodden community. The Foundation provides education to eligible youth in the field of Information Technology and aids them to find new jobs. ITFS believes in working together in reshaping the lives of the less fortunate and so we join forces with Grassroots, various Religious Groups and the local NGOs.
               
                 </div>
               </div>
             </div>
                  <!--   <div class="row">
                       <div class="col-md-4"></div>
                       <div class="col-md-4">
                        <div class="form-group">
                            <button type="button" class="btn btn-success" (click)="deskattendance()">Take Attendance</button>
                        </div>
                    </div>
                    <div class="col-md-4"></div>
                  </div> -->
                </div>
              </div>
            </div>
          </div> 

      <div *ngIf="this.admin_type !='ParkingSlot'" class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>Help Video and Mobile App</h4>
            </div>
            <div class="card-body p-30">
              <div class="row">
                <div class="col-md-12">
                 
                 <ul class="download-app">
                  <li class="alignfooter mr-3">
                    <a href="javascript:;"  (click)="gethelpvideo()"><span class="footer-icon">
                      <img alt="image" class="" src="assets/img/footer/video-icon.svg">
                      <!-- <i class="fab fa-android"></i> -->
                    </span>
                  </a>
                </li>
                <li class="alignfooter mr-2">
                  <a href="javascript:;" (click)="getandroid()"><span class="footer-icon">
                    <img alt="image" class="" src="assets/img/play-store.png">
                    <!-- <i class="fab fa-android"></i> -->
                  </span>
                </a>
              </li>
              <li class="alignfooter">
                <a href="javascript:;" (click)="getios()"><span class="footer-icon">
                 <img alt="image"   class="" src="assets/img/app-store.png">
                 <!-- <i class="fab fa-apple"></i> -->
               </span>
             </a>
           </li>
         </ul>
       </div>
     </div>
   </div>
 </div>
</div>
</div> 



<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h4>{{ title2 }}</h4>
      </div>
      <div class="card-body p-30">
       <iframe width="100%" height="300" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d997.1898448301008!2d103.88715812004655!3d1.320099184070484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19b432e6bdc5%3A0xa2aad5a10b7bb0ff!2sCal4care+Pte+Ltd!5e0!3m2!1sen!2ssg!4v1551924573694" frameborder="0"  allowfullscreen></iframe>
            <!--  <agm-map [latitude]="lat" [longitude]="lng">
              <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
            </agm-map> -->
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="modal fade bd-example-modal-lg" id="androidapp" aria-modal="true" >
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="myLargeModalLabel">Scan To Get Andriod App</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="text-align: center;">
        <div class="row">
          <div class="col-sm-12">
            <div class="card-body" >
              <ngx-qrcode
              [elementType]="elementType" 
              [value] = "value"
              cssClass = "aclass"
              errorCorrectionLevel = "L">
            </ngx-qrcode>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<div class="modal fade bd-example-modal-lg" id="iosapp" aria-modal="true" >
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="myLargeModalLabel">Scan To Get IOS App</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="text-align: center;">
        <div class="row">
          <div class="col-sm-12">
            <div class="card-body" >
              <ngx-qrcode
              [elementType]="elementType" 
              [value] = "iosvalue"
              cssClass = "aclass"
              errorCorrectionLevel = "L">
            </ngx-qrcode>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>



<div class="modal fade bd-example-modal-lg" id="help" aria-modal="true" >
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="myLargeModalLabel">Help Videos</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-12">
            <div class="card-body" >
              <div class="table-responsive">
                <table  class="table table-striped" id="table-2">
                  <thead>
                    <tr>
                      <th>S.no</th>
                      <th>Help Video Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr>
                      <td>1</td>
                      <td>Add Student</td>
                      <td>
                        <button type="button" class="btn btn-primary  btn-lg"  (click)="helpvideoplay('stud')">Play</button>
                      </td>
                    </tr>
                    <tr>
                     <td>2</td>
                     <td>Add Instructor</td>
                     <td><button type="button" class="btn btn-primary  btn-lg"  (click)="helpvideoplay('inst')">Play</button>
                     </td>
                   </tr>
                   <tr>
                    <td>3</td>
                    <td>Add and Assign Event</td>
                    <td>
                      <button type="button" class="btn btn-primary  btn-lg"  (click)="helpvideoplay('event')">Play</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer bg-whitesmoke br">
      <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
    </div>  
  </div>
</div>
</div>
<div class="modal fade bd-example-modal-lg" id="showvideo" aria-modal="true" >
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="myLargeModalLabel">Scan To Get IOS App</h5> -->
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="text-align: center;">
        <div class="row">
          <div class="col-sm-12">
            <div class="card-body" >
              <!-- <iframe src="videourl" width="540" height="310"></iframe> -->
              <!-- <video width="320" height="240" controls> -->
                <div  *ngIf="videourl =='stud'">
                 <video controls width="auto" height="400" (click)="toggleVideo()" #videoPlayer>
                  <source   src="https://booking.mconnectapps.com/api/storage/help/add%20student.mp4" type="video/mp4">
                    <!-- < <source src="movie.ogg" type="video/ogg"> -->
                    </video> 
                  </div>
                  <div  *ngIf="videourl =='inst'">
                    <video controls  width="auto" height="400" (click)="toggleVideo()" #videoPlayer>
                     <source  src="https://booking.mconnectapps.com/api/storage/help/add-instructor.mp4" type="video/mp4">
                     </video>
                   </div>
                   <div *ngIf="this.videourl =='event'" >
                    <video controls width="auto" height="400" (click)="toggleVideo()" #videoPlayer>
                      <source  src="https://booking.mconnectapps.com/api/storage/help/add-and-assign-Event.mp4" type="video/mp4">
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>