import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { ServerService } from '../services/server.service';
declare var $:any;
declare var iziToast:any;
@Component({
  selector: 'app-meetingreport',
  templateUrl: './meetingreport.component.html',
  styleUrls: ['./meetingreport.component.css']
})
export class MeetingreportComponent implements OnInit {
 eventData:any={};
   call_history_list;

   recordNotFound3 = false;
   pageLimit3 = 10;
   paginationData3:any ={"info":"hide"};
   offset_count3 = 0;
    reports:any=[];
   getRep: FormGroup;
  


 studentslist:any=[];
recordNotFound = false;
recordNotFound2 = false;

 pageLimit = 10;
 pageLimit2 = 10;

 paginationData:any ={"info":"hide"};
 paginationData2:any ={"info":"hide"};

 offset_count = 0;
  offset_count2 = 0;
 sendstudent;
 sendstudentarr=[];



reportview = false;

reportheader:any=[];

reportcontent:any=[];

reportheadershow:any=[];

datereport:any=[];
courselist:any=[];
page;


 participantlist:any=[];
  participantnames:any=[];
  shownameparticip:any=[];
 sendparticipant;
 participantarr=[];
nameshowevent;
nameshowinstructor;
sendparticipantname;

meetinglist:any=[];
  constructor(private serverService: ServerService,private route:ActivatedRoute,private router:Router) {
    this.eventData.types = 'events'
 this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {


     this.page=this.router.getCurrentNavigation().extras.state.page; 

    
      }
    });


 }

  

  ngOnInit() {
    this.meeting_id_list()
       this.view_report_list()
this.get_participant_list({})
      this.getRep = new FormGroup({
      'event_name' :new FormControl(null),

      'report_type':new FormControl(null,Validators.required),


      });
    
    this.eventData.types = 'events'

    }



validateAllFields(formGroup: FormGroup) {         
        Object.keys(formGroup.controls).forEach(field => {  
            const control = formGroup.get(field);            
            if (control instanceof FormControl) {             
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {        
                this.validateAllFields(control);  
            }
        });
    }
 addstud(){

    // $('#add_queue_form').modal('show');

     $('#addNewPopup').modal({
  show:true,
   backdrop:false

});
$('#addNewPopup').modal('show');
  }

addinstruct(){
  $('#addNewPopups').modal({
  show:true,
   backdrop:false

});
$('#addNewPopups').modal('show');
}

goaction(){
  

}





  listDataInfo3(list_data){

    list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
    list_data.order_by_name = list_data.order_by_name == undefined ? "history.callid" : list_data.order_by_name;
    list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
    list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
    list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
    return list_data;
  }



     callHistoryList(data){

    
    }
    





savealldata(){
   this.sendparticipant = ''
   this.sendparticipantname =''
   console.log(this.participantarr)

   this.sendparticipant=this.participantarr.join(',');
this.shownameparticip = this.participantnames
   this.sendparticipantname =this.participantnames.join(',');  
   console.log(this.sendparticipant)
 console.log(this.participantnames)
   
}



addinstructor(){
    $('#addNewPopups').modal({
  show:true,
   backdrop:false

});
$('#addNewPopups').modal('show');

}
addstudent(){
   $('#addNewPopup').modal({
  show:true,
   backdrop:false

});
$('#addNewPopup').modal('show');
}

savedataparticipant(val){

 var adding = false
if(val.add == false){

if(this.participantarr.length != 0){

 for (var i = 0; i < this.participantarr.length; i++) {


if(val.id !== this.participantarr[i]){

 adding  = true
}

}
  if(adding){
      this.participantarr.push(val.id)
      this.participantnames.push(val.name)

  }
}
else{
   this.participantarr.push(val.id)

    this.participantnames.push(val.name)
}

}
else{

   for (var j = 0; j < this.participantarr.length; j++) {

if(val.id == this.participantarr[j]){

    this.participantarr.splice(this.participantarr.indexOf(this.participantarr[j]), 1 );

}
}
   for (var k = 0; k < this.participantnames.length; k++) {

if(val.name == this.participantnames[k]){

    this.participantnames.splice(this.participantnames.indexOf(this.participantnames[k]), 1 );

}
}

}
 console.log(this.participantnames)

}





get_participant_list(data){
  
  var list_data= this.listDataInfo(data);
  console.log(list_data)

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

 
      let api_req:any = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"view_participant","limit":"'+list_data.limit+'","offset":'+list_data.offset+',"search_text":"'+list_data.search_text+'","admin_id":"'+admin_id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){
      this.participantlist=response.result.data.list_data;
//          for (var i = 0; i < this.studentslist.length; i++) {
 
//    this.studentslist[i].add = false
  
// }
console.log(this.participantarr)
console.log(this.participantlist)


if(this.participantlist.length != 0){

if(this.participantarr.length != 0){
for (var i = 0; i < this.participantlist.length; i++) {
   var ismatch = false;
  for (var j = 0; j < this.participantarr.length; j++) {
   if(this.participantlist[i].id ==  this.participantarr[j]){
     ismatch = true;
     this.participantlist[i].add = true;
     break;  

   }
 }
 if (!ismatch){
         this.participantlist[i].add = false//  checkbox status false
      
      } 

}
}
else{
  
  for (var i = 0; i < this.participantlist.length; i++) {
     this.participantlist[i].add = false  
  }
}

}

console.log(this.participantlist)




          this.offset_count = list_data.offset;
        this.paginationData = this.serverService.pagination({'offset':response.result.data.list_info.offset, 'total':response.result.data.list_info.total, 'page_limit' :this.pageLimit });
         console.log(this.paginationData);
                this.recordNotFound = this.participantlist.length == 0 ? true : false;


      }
     

    }, 
    (error)=>{
        console.log(error);
    });
  }



getsearchpaticipant(searchValue: string): void {  
  console.log(searchValue);
  this.get_participant_list({'search_text':searchValue})
}


getReports(){

console.log(this.getRep.value)
console.log(this.eventData)


   if(this.getRep.valid){

      this.getReportss()
   }

   
   else {
            this.validateAllFields(this.getRep); 
        }
  }


    getReportss(){



// if(this.getRep.value.event_name == null){

//       iziToast.error({

//           message: "Please Select Meeting Name",
//           position: 'topRight'
//       });
//       return false
// }


  this.meetingreportinsert()

}


meetingreportinsert(){
  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');


registerstudentdata.action="meeting_participant";
registerstudentdata.meeting_id=this.getRep.value.event_name;

registerstudentdata.name=this.sendparticipant;
registerstudentdata.admin_id=admin_id;

registerstudentdata.report_type=this.getRep.value.report_type;
registerstudentdata.inst_id="";




 registeruser.access_token=access_token;
  registeruser.operation="student_report";
  registeruser.moduleType="student_report";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status == true && response.result.data == 1){
     

     this.view_report_list()

     this.view_asssign_report()
//      iziToast.success({
//     title: 'OK',
//     position: 'topRight',
//     message: 'Successfully event assigned!',
// });
   }else{
//    iziToast.error({
//     title: 'OK',
//     position: 'topRight',
//     message: 'Illegal operation!',
// });
   }
      
      }, 
      (error)=>{
          console.log(error);
      });





}









listDataInfo(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
}  

listDataInfo2(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
} 





view_report_list(){


  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');


registerstudentdata.action="meeting_list";
registerstudentdata.admin_id=admin_id;





 registeruser.access_token=access_token;
  registeruser.operation="student_report";
  registeruser.moduleType="student_report";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){

     this.reports = response.result.data
//     iziToast.success({
//     title: 'OK',
//     position: 'topRight',
//     message: 'Report generated Successfully!',
// });

// this.reportview = true;

   }else{

   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}


viewreport(){
if(this.getRep.value.report_type == 'html')
{

}
else if(this.getRep.value.report_type == 'csv'){

}
else if(this.getRep.value.report_type == 'pdf'){

}
}


view_asssign_report(){


  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');


registerstudentdata.action="meeting_attendee_view";
registerstudentdata.name=this.sendparticipant;
registerstudentdata.meeting_id=this.getRep.value.event_name;
registerstudentdata.admin_id=admin_id;
// registerstudentdata.report_type=this.getRep.value.report_type;
registerstudentdata.inst_id="";
registerstudentdata.type="report";

 registeruser.access_token=access_token;
  registeruser.operation="student_report";
  registeruser.moduleType="student_report";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){

if(response.result.data.header != null &&  response.result.data.values != null
){


this.reportheadershow = response.result.data.header;

//  if(response.result.data.values.length != 0){
//         if(response.result.data.values[0].course_id == "0"){
// this.nameshowevent = "Event : "+response.result.data.values[0].event_name+"Ev"+response.result.data.values[0].event_id;
//         }else{
// this.nameshowevent = "Course : "+response.result.data.values[0].event_name+"C"+response.result.data.values[0].course_id;

//         }
//         this.nameshowinstructor = response.result.data.values[0].ins_fname+" "+response.result.data.values[0].ins_lname
//       }

 if(response.result.data.values.length != 0){
      
this.nameshowevent = "Meeting : "+"M"+response.result.data.values[0].meeting_id+" "+response.result.data.values[0].meeting_name;
        
        this.nameshowinstructor = response.result.data.values[0].inst_name
      }



      this.datereport   =[];
for (var i = 0; i < this.reportheadershow.length; i++) {
var parsedDate = Date.parse(this.reportheadershow[i]);

if (isNaN(this.reportheadershow[i]) && !isNaN(parsedDate)) {
  console.log(this.reportheadershow[i])
  this.datereport.push(this.reportheadershow[i])
    /* do your work */
}

}

this.reportheader = response.result.data.header
      this.reportcontent =  response.result.data.values






      console.log(this.reportheader)
console.log(this.reportcontent)
    iziToast.success({
    title: 'OK',
    position: 'topRight',
    message: 'Report generated Successfully!',
});

this.reportview = true;
}
    else{
        iziToast.error({
    title: 'OK',
    position: 'topRight',
    message: 'No Data Found!',
});
    }  

   }else{
      iziToast.error({
    // title: 'OK',
    position: 'topRight',
    message: 'No Data Found!',
});
   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}




meeting_id_list(){

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');
  
    let api_req:any = '{"operation":"meeting","moduleType":"meeting","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"meetings","admin_id":"'+admin_id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){
      // this.courselist= response

           this.meetinglist= response.result.data
    
      console.log(this.meetinglist);
      }
   

    }, 
    (error)=>{
        console.log(error);
    });
  }










}