  <div class="section-body">
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-header">
                      <div class="card-header-title">
                        <h4 *ngIf="type == 'course'">Course Template View</h4>
                        <h4 *ngIf="type == 'meeting'">Meeting Template View</h4>
                        
                      </div>
                      <div class="card-header-btn-group">

                        <a  [routerLink]="['/recordevents']"  class="btn btn-success"><i class="fa fa-eye" aria-hidden="true"></i>View event record </a>
                      </div>
                    </div>
                    <div class="card-body">

<!-- view start -->
                      <div>
                      <div class="table-responsive">
                        <table class="table table-striped" id="table-2">
                          <thead>
                            
                            <tr>
                         
                              <th>S.no</th>

                              <th>Template Name</th>
                             
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr  *ngFor="let l of courselist;let i=index">
                          
                              <td>{{i+1}}</td>

                              <td>{{l.template_name}}</td>

                              <td>
                                <button *ngIf="type == 'course'" type="button" class="btn btn-info btn-icon icon-left" (click)="addclass(l)">
                                <i class="fas fa-pencil-alt" ></i> Add Class</button>

                                 <button  *ngIf="type == 'meeting'" type="button" class="btn btn-info btn-icon icon-left" (click)="addmeeting(l)">
                                <i class="fas fa-pencil-alt" ></i> Add Meeting</button>
                              </td>
                              
                            </tr>
                        
                          </tbody>
                        </table>

                      
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
       

<!-- class template start -->
<div class="modal fade"   id="addNewPopupcourse">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
            <form [formGroup]="addCourse">
        <div class="modal-header">
         <!-- <div class="event-box l-bg-purple-dark">
          <img data-toggle="tooltip" title="" src="assets/img/dashboard/Basketball.svg" data-original-title="Swimming">
        </div>  -->
         <h5 class="modal-title" id="exampleModalLabel">Add  Class  </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

    <div class="row" >
        
 <div class="col-md-6 col-12">
            <div class="form-group">
              <label>Add Class</label>

               <input type="text"  class="form-control" formControlName="event_name" readonly>
            </div>
          </div>
  <div class="col-md-6 col-12">
            <div class="form-group">
 <label>Instructor</label>  :  <strong>{{instructorfullname}}</strong>
              <button   type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="addinstruct('instructor')">Add Instructor</button>
      
            </div>
          </div>
<div class="col-md-6 col-12">
            <div class="form-group">
 <label>Co-Instructor</label>  :  <strong>{{instructorfullnameco}}</strong>
              <button   type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="addinstruct('coinstructor')">Add Co-Instructor</button>
      
            </div>
          </div>

<div class="col-md-12 col-12">
            <div class="form-group" style="display: flex">
 <label>Choose Days</label>

<div *ngFor="let c of days; let i = index;">

   <input type="checkbox" [(ngModel)]="c.checked" style="margin-right: 8px !important;"  class="" name="daysselect"  [ngModelOptions]="{standalone: true}">
  <label for="phone number"  class="mrg-right">{{c.name}}</label>

</div>
                  </div>
               </div>
 <div class="col-md-6 col-12">
           <div class="form-group">
            <label>Start Date</label>        
            <input  type="date" formControlName="start_date"  class="form-control datetimepicker" >
          </div>
        </div>
          <div class="col-md-6 col-12">
           <div class="form-group">
            <label>Start Time</label>
            <input  type="time" formControlName="start_time"  class="form-control datetimepicker" >
          </div>
        </div>
        <div class="col-md-6 col-12">
         <div class="form-group">
          <label>End Time</label>
          <input  type="time" formControlName="end_time" class="form-control datetimepicker" >
        </div>
      </div>

 <div class="col-md-6 col-12">
         <div class="form-group">
          <label>Location</label>
<select  class="form-control"  formControlName="location">
               <option value="{{l.location}}" *ngFor ="let l of locations">{{l.location}}</option>
             </select>
        </div>
      </div>
<div class="col-md-6 col-12">
            <div class="form-group">
              <label>No Of Class</label>
               <input type="text"  class="form-control" formControlName="no_of_class" >
            </div>
          </div>


<!-- <div class="col-md-6 col-12">
 <div class="form-group">
                 
                  <label>Save As Template</label>
               

   <input type="checkbox" formControlName="savetemplate" class="form-control"  >

                 
               </div>
</div> -->



<div class="col-md-6 col-12">
            <div class="form-group" style="float: left;">
 <label>Save As Template</label>

<!-- <div *ngFor="let c of days; let i = index;"> -->

   <input type="checkbox"  formControlName="savetemplate"  style="height:14px !important;"  class="form-control" name="daysselect" >
  <!-- <label for="phone number"  class="mrg-right">{{c.name}}</label> -->

<!-- </div> -->
                  </div>
               </div>









<div class="col-md-6 col-12" *ngIf="this.addCourse.value.savetemplate">
 <div class="form-group" >
                
                   <label>Template Name</label>
                  <!-- <div class="col-sm-12 col-md-7"> -->
                     <input  type="text" formControlName="template_name" class="form-control" >
                  <!-- </div> -->
               </div>
</div>

<div class="col-md-6 col-12">
      <div class="form-group" >
     <label>Student Count</label>

      <!-- <div class="col-sm-12 col-md-7" > -->
         <input  type="number"  class="form-control" formControlName="student_count" readonly>
      <!-- </div> -->
      
   </div>

</div>









 <div class="col-md-6 col-12">
         <div class="form-group">
          <label>Add Students</label>
           <button type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="addstud()">Add Student</button>
        </div>
      </div>
    </div>
  </div>
 <div class="modal-footer bg-whitesmoke br">
  <div >
    <button style="margin-right: 5px;" type="button" class="btn btn-success"     (click)="addclasstocourse()">Add</button>
     
    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
</div>
  </div>
</form>
</div>
</div>
</div>


<!-- class template end -->

<!-- student add start -->

<div class="modal fade bd-example-modal-lg" id="addNewPopupstud" aria-modal="true" >
    <div class="modal-dialog modal-lg">
       <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myLargeModalLabel">Add Student To Event</h5>
            </div>
            <div class="modal-body">

                <div class="row">
                    <div class="col-sm-12">

                  <div class="card-body" >
                    <div class="table-responsive">
                      <table  class="table table-striped" id="table-2">
                        <thead>
                          <tr>
                <td colspan="20" class="search-text-tbl">
                            <div class="input-group"><input type="text" class="form-control" placeholder="Search Student" (input)="getsearch($event.target.value)"  (keyup.enter)="get_student_list({'search_text':agent_search.value})" #agent_search>
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" (click)="get_student_list({'search_text':agent_search.value})"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                          <tr>
                            <th>S.no</th>
                            <th>Student Name</th>
                            <th>Photo</th>
                            <th>Email</th>
                            <th>Contact no.</th>
                            <th>NRIC</th>
                            <th>Add</th>
                          </tr>
                        </thead>
                      
                        <tbody >

                          <tr *ngFor="let l of studentslist;let i=index">
                          
                            <td>{{offset_count+i+1}}</td>
                            <td>{{l.fname}} {{l.lname}}</td>
                            <td class="student-img">
                              <img alt="image" *ngIf="l.image == ''" src="assets/images/avatar.jpg" width="35">
                               <img alt="image" [src]="l.image"  *ngIf="l.image != ''" width="35">


                            </td>

                            <td>{{l.email}}</td>
                            <td>{{l.phone_no}}</td>
                            <td>{{l.nirp}}</td>

    <td><input type="checkbox"    [(ngModel)]="l.add"  name="add"  (click)="savedata(l)"></td>
      
                            <td>
                     
                            </td>
                          </tr>

                        
                        </tbody>
                         <tr *ngIf="recordNotFound == true">
                               <td colspan="12">Data not found</td>
                            </tr>
                      
                      </table>
                    </div>

 <div class="card-footer text-right" *ngIf="recordNotFound == false">
                        <nav class="d-inline-block">
                        <ul class="pagination mb-0" *ngIf="paginationData.info == 'show'">

                                <li class="page-item">
                                    <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? get_student_list({'search_text':agent_search.value,'offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
                                </li>
                                <li class="page-item" *ngIf="paginationData.backtab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="get_student_list({'search_text':agent_search.value,'offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
                                </li>


                                <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
                                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? get_student_list({'search_text':agent_search.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                                </li>

                                <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="get_student_list({'search_text':agent_search.value,'offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
                                </li>

                                <li class="page-item">
                                    <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? get_student_list({'search_text':agent_search.value,'offset':paginationData.end}) : false"><i class="fas fa-angle-right"></i></a>
                                </li>
                        </ul>
                    </nav>
                </div>

                  </div>
                <!-- </div> -->
            </div>
        </div>
    </div>
<div class="modal-footer bg-whitesmoke br">
 
    <button  type="button" class="btn btn-success" (click)="savealldata()" data-dismiss="modal" >Save changes</button>
    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
  </div>   
</div>
</div>
</div>

<!-- student add end -->


<!-- instructor start -->
<!-- <div class="modal fade bd-example-modal-lg" id="addNewPopupinst" aria-modal="true" >
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myLargeModalLabel">Add Instructor To Event</h5>     
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>

                        </button> 
            </div>
            <div class="modal-body">

                <div class="row">
                    <div class="col-sm-12">

                  <div class="card-body" >
                    <div class="table-responsive">
                      <table  class="table table-striped" id="table-2">
                        <thead>
                          <tr>
                <td colspan="20" class="search-text-tbl">
                            <div class="input-group"><input type="text" class="form-control" placeholder="Search Student" (input)="getsearchinstructor($event.target.value)"  (keyup.enter)="get_instructor_list({'search_text':agent_search2.value})" #agent_search2>
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" (click)="get_instructor_list({'search_text':agent_search2.value})"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                          <tr>                          
                            <th>S.no</th>
                            <th>Instructor Name</th>
                            <th>Photo</th>
                            <th>Email</th>
                            <th>Contact no.</th>
                            <th>NRIC</th>
                            <th>Add</th>
                          </tr>
                        </thead>                      
                        <tbody >
                          <tr *ngFor="let l of instructorlist;let i=index">                          
                           <td>{{offset_count2+i+1}}</td>
                            <td>{{l.fname}} {{l.lname}}</td>
                            <td class="student-img">
                              <img alt="image" *ngIf="l.image == ''" src="assets/images/avatar.jpg" width="35">
                               <img alt="image" [src]="l.image"  *ngIf="l.image != ''" width="35">


                            </td>

                            <td>{{l.email}}</td>
                            <td>{{l.phone_no}}</td>
                            <td>{{l.nirp}}</td>

        <td><input type="radio"    [checked]="l.add" name="add"  (click)="savedatainstructor(l)"></td>
                            <td>
                     
                            </td>
                          </tr>

                        
                        </tbody>
                         <tr *ngIf="recordNotFound2 == true">
                               <td colspan="12">Data not found</td>
                            </tr>
                      
                      </table>
                    </div>

 <div class="card-footer text-right" *ngIf="recordNotFound2 == false">
                        <nav class="d-inline-block">
                        <ul class="pagination mb-0" *ngIf="paginationData2.info == 'show'">

                                <li class="page-item">
                                    <a [class]="paginationData2.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData2.backtab=='show' ? get_instructor_list({'search_text':agent_search2.value,'offset':paginationData2.start}) : false"><i class="fas fa-angle-left"></i></a>
                                </li>
                                <li class="page-item" *ngIf="paginationData2.backtab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="get_instructor_list({'search_text':agent_search2.value,'offset':paginationData2.back})"><i class="fas fa-angle-double-left"></i></a>
                                </li>


                                <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData2.data;let i=index">
                                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? get_instructor_list({'search_text':agent_search2.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                                </li>

                                <li class="page-item" *ngIf="paginationData2.nexttab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="get_instructor_list({'search_text':agent_search2.value,'offset':paginationData2.next})"><i class="fas fa-angle-double-right"></i></a>
                                </li>

                                <li class="page-item">
                                    <a [class]="paginationData2.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData2.nexttab =='show' ? get_instructor_list({'search_text':agent_search2.value,'offset':paginationData2.end}) : false"><i class="fas fa-angle-right"></i></a>
                                </li>
                        </ul>
                    </nav>
                </div>
                </div>
          </div>
    </div>
    </div>
<div class="modal-footer bg-whitesmoke br">
    <button  type="button" class="btn btn-success" (click)="savealldata2()" data-dismiss="modal" >Save changes</button>
    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
  </div>   
</div>
</div>
</div> -->

<!-- instructor end -->

<!-- add meeting start -->
<div class="modal fade"   id="addNewPopupmeeting">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <form [formGroup]="addEvent" >

          <div class="modal-header">
          <!--  <div class="event-box l-bg-purple-dark">
            <img data-toggle="tooltip" title="" src="assets/img/dashboard/Basketball.svg" data-original-title="Swimming">
          </div> -->
      
          <h5 class="modal-title" id="exampleModalLabel" >Add Meeting</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          <div class="row" >
           
<div class="col-md-6 col-12">
    <div class="form-group">
      <label>Meeting Name</label>
      <select required class="form-control"  formControlName="event_name">
       <option value="{{l.event_name}}" *ngFor ="let l of eventslist">{{l.event_name}}</option>
     </select>

   </div>
 </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
               <label>Incharge</label>  :  <strong>{{instructorfullname}}</strong>
               <button  type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="addinstruct('instructor')">Add Incharge</button>

             </div>
           </div>
           <div class="col-md-6 col-12">
            <div class="form-group">
             <label>Co-Incharge</label>  :  <strong>{{instructorfullnameco}}</strong>
             <button   type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="addinstruct('coinstructor')">Add Incharge</button>
           </div>
         </div>


         <div class="col-md-6 col-12">
           <div class="form-group">
            <label>Start Time</label>
            <input  type="datetime-local" formControlName="start_time"  class="form-control datetimepicker">
          </div>
        </div>


   <div class="col-md-6 col-12">
           <div class="form-group">
            <label>Reference</label>
            <input  type="text" formControlName="reference"  class="form-control datetimepicker">
          </div>
        </div>



        <div class="col-md-6 col-12">
         <div class="form-group">
          <label>End Time</label>
          <input  type="datetime-local" formControlName="end_time" class="form-control datetimepicker">
        </div>
      </div>

      <div class="col-md-6 col-12">
       <div class="form-group">
        <label>Location</label>
        <select  class="form-control"  formControlName="location" >
         <option value="{{l.location}}" *ngFor ="let l of locations">{{l.location}}</option>
       </select>

     </div>
   </div>

<!--    <div class="col-md-6 col-12">
     <div class="form-group">
      <label>View Participants</label>
      <button type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="viewstud()">View Participant</button>
    </div>
  </div> -->

  <div class="col-md-6 col-12" >
   <div class="form-group">
    <label>Add Participant</label>
    <button type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="addstudent()">Add Participant</button>
  </div>
</div>

</div>
</div>

<div class="modal-footer bg-whitesmoke br">
  <div>
    <button style="margin-right: 5px;"  type="button" class="btn btn-success" data-dismiss="modal" (click)="assignmeeting()">Save changes</button>
    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
  </div>
</div>
</form>
</div>
</div>
</div>

<!-- add meeting end -->






<div class="modal fade bd-example-modal-lg" id="addNewPopupparticipant" aria-modal="true" >
    <div class="modal-dialog modal-lg">

        <div class="modal-content">


            <div class="modal-header">
                <h5 class="modal-title" id="myLargeModalLabel">Add Paticipant</h5>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>

            <div class="modal-body">

                <div class="row">
                    <div class="col-sm-12">

                  <div class="card-body" >
                    <div class="table-responsive">
                      <table  class="table table-striped" id="table-2">
                      <thead>
                          <tr>
                <td colspan="20" class="search-text-tbl">
                            <div class="input-group"><input type="text" class="form-control" placeholder="Search Participant" (input)="getsearchpaticipant($event.target.value)"  (keyup.enter)="get_participant_list({'search_text':participant_search.value})" #participant_search>
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" (click)="get_participant_list({'search_text':participant_search.value})"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                          <tr>
                          
                            <th>S.no</th>
                            <th>Participant Name</th>
                            <th>Email</th>
                            <th>Contact no.</th>

                            <th>Add</th>
                          </tr>
                        </thead>
                      
                        <tbody >

                          <tr *ngFor="let l of participantlist;let i=index">
                          
                            <td>{{offset_count3+i+1}}</td>
                            <td>{{l.name}}</td>
                            <td>{{l.email}}</td>
                            <td>{{l.phone_no}}</td>
                            <td>{{l.nirp}}</td>

    <td><input type="checkbox"    [(ngModel)]="l.add"  name="add"  (click)="savedataparticipant(l)"></td>
 
                            <td>
                     
                            </td>
                          </tr>

                        
                        </tbody>
                         <tr *ngIf="recordNotFound3 == true">
                               <td colspan="12">Data not found</td>
                            </tr>
                      
                      </table>
                    </div>

 <div class="card-footer text-right" *ngIf="recordNotFound3 == false">
                        <nav class="d-inline-block">
                        <ul class="pagination mb-0" *ngIf="paginationData3.info == 'show'">

                                <li class="page-item">
                                    <a [class]="paginationData3.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData3.backtab=='show' ? get_participant_list({'search_text':participant_search.value,'offset':paginationData3.start}) : false"><i class="fas fa-angle-left"></i></a>
                                </li>
                                <li class="page-item" *ngIf="paginationData3.backtab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="get_participant_list({'search_text':participant_search.value,'offset':paginationData3.back})"><i class="fas fa-angle-double-left"></i></a>
                                </li>


                                <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData3.data;let i=index">
                                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? get_participant_list({'search_text':participant_search.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                                </li>

                                <li class="page-item" *ngIf="paginationData3.nexttab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="get_participant_list({'search_text':participant_search.value,'offset':paginationData3.next})"><i class="fas fa-angle-double-right"></i></a>
                                </li>

                              <li class="page-item">
                                    <a [class]="paginationData3.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData3.nexttab =='show' ? get_participant_list({'search_text':participant_search.value,'offset':paginationData3
                                    .end}) : false"><i class="fas fa-angle-right"></i></a>
                                </li>
                        </ul>
                    </nav>
                </div>
             </div>
            </div>
        </div>
    </div>
<div class="modal-footer bg-whitesmoke br">
 
    <button  type="button" class="btn btn-success" (click)="savealldataparticipant()" data-dismiss="modal" >Save changes</button>
    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
  </div> 
</div>
</div>
</div>




<div class="modal fade bd-example-modal-lg" id="addNewPopupinst" aria-modal="true" >
    <div class="modal-dialog modal-lg">

      <div class="modal-content">


        <div class="modal-header">
          <h5 class="modal-title" id="myLargeModalLabel">Add Instructor To Event</h5>

        </div>

        <div class="modal-body">

          <div class="row">
            <div class="col-sm-12">

              <div class="card-body" >
                <div class="table-responsive">
                  <table  class="table table-striped" id="table-2">




                    <thead>
                      <tr>
                        <td colspan="20" class="search-text-tbl">
                          <div class="input-group"><input type="text" class="form-control" placeholder="Search Student" (input)="getsearchinstructor($event.target.value)"  (keyup.enter)="get_instructor_list({'search_text':agent_search3.value})" #agent_search3>
                            <div class="input-group-append">
                              <button class="btn btn-primary" (click)="get_instructor_list({'search_text':agent_search3.value})"><i class="fas fa-search"></i></button>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>

                        <th>S.no</th>
                        <th>Instructor Name</th>
                        <th>Photo</th>
                        <th>Email</th>
                        <th>Contact no.</th>
                        <th>NRIC</th>
                        <th>Add</th>
                      </tr>
                    </thead>

                    <tbody >


                      <tr *ngFor="let l of instructorlist;let i=index">

                        <td>{{offset_count2+i+1}}</td>
                        <td>{{l.fname}} {{l.lname}}</td>
                        <td class="student-img">
                          <img alt="image" *ngIf="l.image == ''" src="assets/images/avatar.jpg" width="35">
                          <img alt="image" [src]="l.image"  *ngIf="l.image != ''" width="35">


                        </td>

                        <td>{{l.email}}</td>
                        <td>{{l.phone_no}}</td>
                        <td>{{l.nirp}}</td>

                        <td><input type="radio"    [checked]="l.add" name="add"  (click)="savedatainstructor(l)"></td>
                        <td>

                        </td>
                      </tr>


                    </tbody>
                    <tr *ngIf="recordNotFound2 == true">
                     <td colspan="12">Data not found</td>
                   </tr>

                 </table>
               </div>

               <div class="card-footer text-right" *ngIf="recordNotFound2 == false">
                <nav class="d-inline-block">
                  <ul class="pagination mb-0" *ngIf="paginationData2.info == 'show'">

                    <li class="page-item">
                      <a [class]="paginationData2.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData2.backtab=='show' ? get_instructor_list({'search_text':agent_search3.value,'offset':paginationData2.start}) : false"><i class="fas fa-angle-left"></i></a>
                    </li>
                    <li class="page-item" *ngIf="paginationData2.backtab =='show'">
                      <a class="page-link" href="javascript:void(0)" (click)="get_instructor_list({'search_text':agent_search3.value,'offset':paginationData2.back})"><i class="fas fa-angle-double-left"></i></a>
                    </li>


                    <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData2.data;let i=index">
                      <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? get_instructor_list({'search_text':agent_search3.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                    </li>

                    <li class="page-item" *ngIf="paginationData2.nexttab =='show'">
                      <a class="page-link" href="javascript:void(0)" (click)="get_instructor_list({'search_text':agent_search3.value,'offset':paginationData2.next})"><i class="fas fa-angle-double-right"></i></a>
                    </li>

                    <li class="page-item">
                      <a [class]="paginationData2.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData3.nexttab =='show' ? get_instructor_list({'search_text':agent_search3.value,'offset':paginationData2.end}) : false"><i class="fas fa-angle-right"></i></a>
                    </li>
                  </ul>
                </nav>
              </div>










            </div>
            <!-- </div> -->


          </div>



        </div>
      </div>
      <div class="modal-footer bg-whitesmoke br">

        <button  type="button" class="btn btn-success" (click)="savealldata2()" data-dismiss="modal" >Save changes</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
      </div>



    </div>
  </div>
</div>


