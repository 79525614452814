import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Router,ActivatedRoute } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
declare var $:any;
declare var iziToast:any;
import * as moment from 'moment';
const TODAY_STR = new Date(); 
@Component({
  selector: 'app-meetingattendance',
  templateUrl: './meetingattendance.component.html',
  styleUrls: ['./meetingattendance.component.css']
})
export class MeetingattendanceComponent implements OnInit {

 meetinglist:any=[];
attendanceheader:any=[];
attendanceheadershow:any=[];
attendancecontent:any=[];
showing = false;
nameshowinstructor;
nameshowevent
instructorlist:any=[]
 studentslist:any=[];
recordNotFound = false;
recordNotFound2 = false;

 pageLimit = 10;
 pageLimit2 = 10;

 paginationData:any ={"info":"hide"};
 paginationData2:any ={"info":"hide"};

 offset_count = 0;
  offset_count2 = 0;
 // sendstudent;

 // sendstudentarr=[];
studentselect;
studentselectname ='';


 instructorselect;
 instructselectname = '';
 eventss:any=[];
dateattendance:any=[];

 getRep:FormGroup;
 markedvalue;
 markeddate;
 markedstudentname;
 markeddetail;

eventslist:any=[];

courselist:any=[];

eventData:any={};
cur_date;
page ;
  constructor(private route:ActivatedRoute,private router:Router,private serverService: ServerService) {
      this.eventData.types = 'events'
 this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {


     this.page=this.router.getCurrentNavigation().extras.state.page; 

 console.log(this.page)
     // if(this.page == 'event'){
   // this.eventData.types = 'events'
   // }else{
      // this.eventData.types = 'course'
   // }
      }
    });
 
   }

  ngOnInit(): void {
    this.meeting_id_list()
this.cur_date = moment(TODAY_STR).format('YYYY-MM-DD'); 
console.log(this.cur_date);
 
	  this.getRep = new FormGroup({
			'event_name' :new FormControl(null,Validators.required),
    
		  });

    this.eventData.types = 'events'
  }




meeting_id_list(){

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');
  
    let api_req:any = '{"operation":"meeting","moduleType":"meeting","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"meetings","admin_id":"'+admin_id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){
      // this.courselist= response

           this.meetinglist= response.result.data
    
      console.log(this.meetinglist);
      }
   

    }, 
    (error)=>{
        console.log(error);
    });
  }



deskattendance(){
  if(this.eventData.types == 'events'){
this.meeting_attendee_view()
  }


}

addstudent(){
   $('#addNewPopup').modal({
  show:true,
   backdrop:false

});
$('#addNewPopup').modal('show');
}

addinstructor(){
    $('#addNewPopups').modal({
  show:true,
   backdrop:false

});
$('#addNewPopups').modal('show');

}


savealldata(){

}

savealldata2(){

}



getvalue(valdate,detail,value){
console.log(valdate)
console.log(detail)
console.log(value)

if(value == true){
this.markedvalue = 1;
}else{
this.markedvalue = 0;
}
this.markeddate = valdate;
this.markeddetail = detail;


this.addattendance()
 
}





addattendance(){
var courseid;
var eventid;
if(this.markeddetail.course_id == "0"){
  courseid = ""
}
else{
  courseid = this.markeddetail.course_id 
}


if(this.markeddetail.event_id == "0"){
  eventid = ""
}
else{
  eventid = this.markeddetail.event_id 
}



   var statussend;
   console.log(this.markedvalue)
   

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');
  
   let userData:any = '{"access_token":"'+access_token+'","operation": "lecture","moduleType":"lecture","api_type":"web","element_data":{"action": "add_desk_attendance","event_id":"0","course_id":"0","meeting_id":"'+this.markeddetail.meeting_id+'","date":"'+this.markeddate+'","student_name":"'+this.markeddetail.part_id+'","status":"'+this.markedvalue+'","inst_id":"'+this.markeddetail.inst_id+'"}}';
  
    this.serverService.sendServer(userData).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){
     

  if(this.eventData.types == 'events'){
this.meeting_attendee_view()
  }
    
      console.log(this.eventss);
      }else{

      }
   

    }, 
    (error)=>{
        console.log(error);
    });
  }













meeting_attendee_view(){
console.log(this.getRep.value.event_name)
console.log(this.instructselectname)
console.log(this.instructselectname)

if(this.getRep.value.event_name == null){

      iziToast.error({

          message: "Please Select Meeting Name",
          position: 'topRight'
      });
      return false
}


 this.attendanceheader =[];

 this.attendancecontent =[];

this.attendanceheadershow=[];

  let updateevents:any = new Object();
 let updateeventsuser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');


updateevents.action="meeting_attendee_view";
updateevents.meeting_id=this.getRep.value.event_name;
updateevents.type="attendance";
updateevents.name='';
updateevents.inst_id='';
updateevents.admin_id =localStorage.getItem('userId');



 updateeventsuser.access_token=access_token;
  updateeventsuser.operation="student_report";
  updateeventsuser.moduleType="student_report";
  updateeventsuser.api_type="web";
  updateeventsuser.element_data = updateevents;
      this.serverService.sendServer(updateeventsuser).subscribe((response: any) => {

        if(response.result.status == true && response.result.data.header != null && response.result.data.values != null){
      
      this.showing = true;
      


       this.attendanceheadershow = response.result.data.header
 if(response.result.data.values.length != 0){
      
this.nameshowevent = "Meeting : "+"M"+response.result.data.values[0].meeting_id+" "+response.result.data.values[0].meeting_name;
        
        this.nameshowinstructor = response.result.data.values[0].inst_name
      }
 

this.dateattendance =[];
for (var i = 0; i < this.attendanceheadershow.length; i++) {
var parsedDate = Date.parse(this.attendanceheadershow[i]);

if (isNaN(this.attendanceheadershow[i]) && !isNaN(parsedDate)) {
  console.log(this.attendanceheadershow[i])
  this.dateattendance.push(this.attendanceheadershow[i])

}

}
console.log(this.dateattendance)

       this.attendanceheader = response.result.data.header

 this.attendancecontent =  response.result.data.values

 
   
          }
        else{
          console.log("data")
 iziToast.error({

          message: "No Data Found",
          position: 'topRight'
      });

        }
      
      }, 
      (error)=>{
          console.log(error);
      });

}






}
