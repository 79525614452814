import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ServerService } from '../services/server.service';
declare var iziToast:any;
import '../../assets/js/scripts.js';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
 loginForm:FormGroup;
 otpform:FormGroup;
    loginFormTemp = true;
    user_id;
    sendotps = false;
    page;
    username ='';
  constructor(private route:ActivatedRoute,private router:Router,private serverService: ServerService) { 
 this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
if(this.router.getCurrentNavigation().extras != null){
     this.sendotps=this.router.getCurrentNavigation().extras.state.sendotp; 
  this.user_id=this.router.getCurrentNavigation().extras.state.user_id; 
  this.page=this.router.getCurrentNavigation().extras.state.page; 
  this.username=this.router.getCurrentNavigation().extras.state.username; 
  
}
      }
    });


  }
loginError = "";
    loginSuccess = "";
    loginotpSuccess ="";
    loginotpError = "";

  ngOnInit(): void {
// if(localStorage.getItem('type') == '0'){

// localStorage.removeItem("access_token");
//           localStorage.removeItem("user_name");
//           localStorage.removeItem("user_type");
//           localStorage.clear();
          // this.router.navigate(['/login']);
          // return false
// }else{
  
// }

    
  	 this.loginForm = new FormGroup({
                'username' : new FormControl(null,Validators.required),
                'password' : new FormControl(null,Validators.required),
                'company_name' : new FormControl(null,Validators.required)           
            });
        this.otpform = new FormGroup({
                'otp' : new FormControl(null,Validators.required),
                
            });
        this.loginForm.setValue({
      'username' : this.username,
        'password' :'' ,
        'company_name' :'' 


   })
  }



validateAllFields(formGroup: FormGroup) {         
        Object.keys(formGroup.controls).forEach(field => {  
            const control = formGroup.get(field);            
            if (control instanceof FormControl) {             
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {        
                this.validateAllFields(control);  
            }
        });
    }
userLogin(){
   if(this.loginForm.valid){
     
     this.userLogins()
   }
   else {
            this.validateAllFields(this.loginForm); 
        }
  
}
otpsubmit(){
   if(this.otpform.valid){
     
     this.otpsubmits()
   }
   else {
            this.validateAllFields(this.otpform); 
        }
  
}


  sendotp(){

let api_reqotp:any = new Object();
let api_reqotpdata:any = new Object();
api_reqotpdata.action="resend_otp";
api_reqotpdata.user_id=this.user_id;
api_reqotp.operation="register";
api_reqotp.moduleType="register";
api_reqotp.api_type="web";
api_reqotp.element_data=api_reqotpdata;                     
                this.serverService.sendServer(api_reqotp).subscribe((response:any) => {
                    
        console.log(response)
                    if(response.result.status == true ){
                       // this.loginotpError ="";
        // this.loginotpSuccess="OTP Send Sucessfully";
                         iziToast.success({
          message: "OTP Send Sucessfully to your Phone Number",
          position: 'bottomLeft'
      });
             

                        } 
                     else{
                  iziToast.error({
          message: "Some error Occured",
          position: 'bottomLeft'
      });
                     }   
                   
                    }, 
                    (error)=>{
                        console.log(error);
                    });





}


backToLogin(){

this.sendotps = false;
}
loginVia(gh){

}


   userLogins(){
console.log('dsa')
        let loginForm:any = this.loginForm.value;

          let api_req:any = new Object();
          loginForm.action="login_validation";
          api_req.operation="login";
          api_req.moduleType="login";
          api_req.api_type="web";
          api_req.element_data=loginForm;
                this.serverService.sendServer(api_req).subscribe((response:any) => {

                    if(response.result.status == true ){

                      if(response.result.data.status == "1"){

  localStorage.setItem('access_token', response.access_token); 
  localStorage.setItem('session_login', response.result.data.session_login);
  localStorage.setItem('phone_no', response.result.data.phone_no); 
  localStorage.setItem('country_code', response.result.data.country_code);
  localStorage.setItem('email', response.result.data.email);  
  localStorage.setItem('company_name', response.result.data.company_name); 
  localStorage.setItem('enable','1'); 
  if(response.result.data.type  == '0'){

    localStorage.setItem('userId', response.result.data.user_id); 
    localStorage.setItem('user_name', response.result.data.username);  
    localStorage.setItem('type', response.result.data.type);
    localStorage.setItem('superadmin', response.result.data.admin_id);
     localStorage.setItem('supersuperadmin','');
  
}else if(response.result.data.type  == '1'){

   localStorage.setItem('userId',''); 
    localStorage.setItem('user_name', response.result.data.username);  
    localStorage.setItem('type', response.result.data.type);
    localStorage.setItem('superadmin', response.result.data.user_id);
    localStorage.setItem('supersuperadmin','');


}else if(response.result.data.type  == '2'){
  localStorage.setItem('userId',''); 
    localStorage.setItem('user_name', response.result.data.username);  
    localStorage.setItem('type', response.result.data.type);
    localStorage.setItem('superadmin','');
    localStorage.setItem('supersuperadmin', response.result.data.user_id);

}

       iziToast.success({
          message: "You have logged in successfully",
          position: 'topLeft'
      });
      this.loginForm.reset();

        // if(localStorage.getItem('type') == '0'){
        this.router.navigate(['/dashboard']);
        // }
        // else if(localStorage.getItem('type') == '1'){
        // this.router.navigate(['/dashboard']);
        // }
        if(response.result.data.admin_id == '90')
        this.router.navigate(['/Carslot-dashboard']);
          }
       else{
          this.sendotps = true;
          this.user_id =  response.result.data.user_id;
          this.sendotp()
        }


        }
       else{

       iziToast.error({
          message: "Invalid Login credentials",
          position: 'bottomLeft'
      });
                        }

                    }, 
                    (error)=>{
                        console.log(error);
                    });
    

}


otpsubmits(){
  
   let Otpform:any = this.otpform.value;
let api_submitotpdata:any = new Object();

    Otpform.action="check_otp";
    Otpform.user_id=this.user_id;
   api_submitotpdata.operation="register";
   api_submitotpdata.moduleType="register";
   api_submitotpdata.api_type="web";
   api_submitotpdata.element_data=Otpform;                     
                this.serverService.sendServer(api_submitotpdata).subscribe((response:any) => {
                    
        console.log(response)
        if(response.result.status == true && response.result.data == '1'){
        iziToast.success({
          message: "OTP Validated Sucessfully",
          position: 'bottomLeft'
      });

        if(this.page == 'register'){
          this.sendotps = false;
        }  
        else{
        this.userLogin()

        }
        } 
       else{
         iziToast.error({
          message: "Please Enter the Valid OTP",
          position: 'bottomLeft'
      });
}

    }, 
    (error)=>{
        console.log(error);
    });

}

forgotusername(){
this.router.navigate(['forgotpwd'], { state: { page: 'forgotuser'} });

}

forgotpassword(){
this.router.navigate(['forgotpwd'], { state: { page: 'forgotpassword'} });  
}

  }





