import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import jsPDF from 'jspdf';

declare var $:any;
declare var iziToast:any;
import Swal from 'sweetalert2'

@Component({
  selector: 'app-addusers',
  templateUrl: './addusers.component.html',
  styleUrls: ['./addusers.component.css']
})
export class AddusersComponent implements OnInit {

  constructor(private serverService:ServerService) { }
  carlots_list;
  user_list;
  QR_code_data;
  slot_name;
  lot_name;
  last_id:number;
  slot_id;
  user_id;
  websocket;
  company_name;
  username;
  password;
  admin_id;
  login_by;
  app;
  ngOnInit(): void {
    this.getLots();

    document.getElementById("Print").onclick = function () {
      printElement(document.getElementById("printThis"));
  }
  
  function printElement(elem) {
      var domClone = elem.cloneNode(true);
      
      var $printSection = document.getElementById("printSection");
      
      if (!$printSection) {
          $printSection = document.createElement("div");
          $printSection.id = "printSection";
          document.body.appendChild($printSection);
      }
      
      $printSection.innerHTML = "";
      $printSection.appendChild(domClone);
      window.print();
      $('#printThis').addClass('modal-close')
//       // $('#ShowQrCode').modal('hide');
//       $('#ShowQrCode').addClass('modal-close')
//       setTimeout(() => {
//         // $('#ShowQrCode').removeStyle('display');
//         // $('#ShowQrCode').css('display','none');    
//         const elem = $("#ShowQrCode");
// elem[0].style.removeAttribute('display');
// elem[0].style.setProperty('display', 'none', 'important');          
//       }, 2000);
setTimeout(() => {
  window.location.reload();
}, 2000);
  }
this.initSocket();
  }


  initSocket(){
   
    
        this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4035"); 
     


        this.websocket.onopen = function(event) { 
          console.log('Manage Slots socket connected');
        }
    
        this.websocket.onmessage = function(event) {      
          var result_message= JSON.parse(event.data);
          console.log(result_message)
          if(result_message.message_type=="parking"){
          $('#reloadslots').click();
          }
        }
    
        this.websocket.onerror = function(event){
          console.log('error');
        }
        this.websocket.onclose = function(event){
          $('#reconnect_socket').click();

    }




  }
  getLots(){
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('supersuperadmin');
     let api_req:any = '{"operation":"carparking","moduleType":"carparking","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"lot_userlist"}}'
     Swal.fire({
			html:
				'<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
		showCloseButton: false,
			showCancelButton: false,
			showConfirmButton: false,
			focusConfirm: false,
			background: 'transparent'
		});
       this.serverService.sendServer(api_req).subscribe((response:any) => {
         Swal.close()
         console.log(response);
         if(response.result.status == true){
       this.user_list=response.result.data;
       this.last_id=this.user_list[this.user_list.length-1].id;
       let c:number =1
       let last = +this.last_id + +c;      
       this.last_id=last;
    
         }
   
   
       }, 
       (error)=>{
           console.log(error);
       });
  }
  getLotclose(){
    $('#ShowQrCode').modal('hide');
    this.getLots();
  }
  popupuser(){
    $('#AddNewuser').modal({
    show:true,
     backdrop:false  
  });
  $('#AddNewuser').modal('show');
    }
    GenerateQR(id){

      let access_token: any=localStorage.getItem('access_token');
      let admin_id: any=localStorage.getItem('supersuperadmin');
       let api_req:any = '{"operation":"carparking","moduleType":"carparking","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"parking_edit","id":"'+id+'"}}'
       Swal.fire({
        html:
          '<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
      showCloseButton: false,
        showCancelButton: false,
        showConfirmButton: false,
        focusConfirm: false,
        background: 'transparent'
      });
         this.serverService.sendServer(api_req).subscribe((response:any) => {
          Swal.close()
           console.log(response);
           if(response.result.status == true){     
            var QR_data=response.result.data;
            // this.QR_code_data=response.result.data;
            console.log(QR_data)
            this.QR_code_data = JSON.stringify({
              en_id: QR_data.id,
              slotname: QR_data.lot_name,
              lot_no: QR_data.lot_no,
            });
            this.QR_code_data=btoa(btoa(btoa(this.QR_code_data)))
            this.slot_name=QR_data.lot_name;
           }
     
     
         }, 
         (error)=>{
             console.log(error);
         });        
    $('#ShowQrCode').modal({
    show:true,
     backdrop:false
  });
  $('#ShowQrCode').modal('show');

    }
 
  adduser(){

      let access_token: any=localStorage.getItem('access_token');
      // let admin_id: any=localStorage.getItem('supersuperadmin');

      let api_data:any =  new Object();
     let  company_name=$('#add_company_name').val();
     let username=$('#add_username').val();
     let password=$('#add_password').val();
 if(company_name == '' || username == '' || password == '' ){
  iziToast.warning({
    message: "Please enter User Name",
    position: 'topLeft'
});
return false;
 }
      let api_req:any = new Object();
      api_data.action="parking_user";
      api_data.company_name=company_name;
      api_data.username=username;
      api_data.password=password;
      api_req.operation="carparking";
      api_req.moduleType="carparking";
      api_req.api_type="web";
      api_req.element_data=api_data;
      Swal.fire({
        html:
          '<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
      showCloseButton: false,
        showCancelButton: false,
        showConfirmButton: false,
        focusConfirm: false,
        background: 'transparent'
      });
      //  let api_req:any = '{"operation":"carparking","moduleType":"carparking","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"parking_slot","id":""}}'
         this.serverService.sendServer(api_req).subscribe((response:any) => {
          Swal.close()
           console.log(response);
           $('#AddNewuser').modal('hide');
           if(response.result.data == 1){  
            //  alert('123');
            iziToast.success({
              message: "User added Successfully",
              position: 'topRight'
          });
          $('#add_company_name').val('');
          $('#add_username').val('');
          $('#add_password').val('');
          this.getLots();
           }
          else if(response.result.data == 2){   
            iziToast.warning({
              message: "User name already exist",
              position: 'topRight'
          });
          $('#add_company_name').val('');
          $('#add_username').val('');
          $('#add_password').val('');
           }
           else{
            iziToast.warning({
              message: "Sorry Some error occurred",
              position: 'topLeft'
          });  
           }
     
     
         }, 
         (error)=>{
             console.log(error);
         });

    }
    edituser(user_id){

      let access_token: any=localStorage.getItem('access_token');
      let admin_id: any=localStorage.getItem('supersuperadmin');

      let api_data:any =  new Object();
 
      let api_req:any = new Object();
      api_data.action="lot_edit";
      api_data.user_id=user_id;     
      api_req.operation="carparking";
      api_req.moduleType="carparking";
      api_req.api_type="web";
      api_req.element_data=api_data;
      Swal.fire({
        html:
          '<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
      showCloseButton: false,
        showCancelButton: false,
        showConfirmButton: false,
        focusConfirm: false,
        background: 'transparent'
      });
      //  let api_req:any = '{"operation":"carparking","moduleType":"carparking","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"parking_slot","id":""}}'
         this.serverService.sendServer(api_req).subscribe((response:any) => {
          Swal.close()
           console.log(response);
           if(response.result.status == true){   
           let data=response.result.data;
           this.user_id=data.user_id;
          // this.getLots();
            $('#edit_company_name').val(data.company_name);
            $('#edit_username').val(data.username);
            $('#edit_password').val(data.password);
            // alert(data.company_name)
            $('#EditUserpop').modal({
    show:true,
     backdrop:false  
  });
  $('#EditUserpop').modal('show');
           }
           else{
            iziToast.warning({
              message: "Sorry Some error occurred",
              position: 'topLeft'
          });  
           }
     
     
         }, 
         (error)=>{
             console.log(error);
         });

    } 
    updateUser(){

      let access_token: any=localStorage.getItem('access_token');
      let admin_id: any=localStorage.getItem('supersuperadmin');

      let api_data:any =  new Object();
 let company_name=$('#edit_company_name').val();
 let username=$('#edit_username').val();
 let password=$('#edit_password').val();
 if(company_name=='' || username=='' || password==''){
  iziToast.warning({
    message: "Please enter User Name",
    position: 'topLeft'
});
return false;
 }
      let api_req:any = new Object();
      api_data.action="lot_update";
      api_data.company_name=company_name; 
      api_data.username=username; 
      api_data.password=password; 
      api_data.admin_id=admin_id; 
      api_data.user_id=this.user_id;       
      api_req.operation="carparking";
      api_req.moduleType="carparking";
      api_req.api_type="web";
      api_req.element_data=api_data;
      //  let api_req:any = '{"operation":"carparking","moduleType":"carparking","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"parking_slot","id":""}}'
         this.serverService.sendServer(api_req).subscribe((response:any) => {
           console.log(response);
           $('#EditUserpop').modal('hide');
           if(response.result.data == true){  
              iziToast.success({
                message: "User Updated Successfully",
                position: 'topLeft'
            });
            this.getLots();                
          }
           else if(response.result.data == 2){   
                iziToast.warning({
                  message: "User name already exist",
                  position: 'topRight'
              });           
             
            }
           else{
            iziToast.warning({
              message: "Sorry Some error occurred",
              position: 'topLeft'
          });  
           }
     
     
         }, 
         (error)=>{
             console.log(error);
         });

    }

    deleteuser(user_id){
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't able to revert this",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then((result) => {
        if (result.value) {
              let api_data:any =  new Object();
               let api_req:any = new Object();
               api_data.action="lot_deleteusers";
               api_data.user_id=user_id;     
               api_req.operation="carparking";
               api_req.moduleType="carparking";
               api_req.api_type="web";
               api_req.element_data=api_data;
               //  let api_req:any = '{"operation":"carparking","moduleType":"carparking","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"parking_slot","id":""}}'
                  this.serverService.sendServer(api_req).subscribe((response:any) => {
                    console.log(response);
                    if(response.result.data == 1){               
                       iziToast.success({
                         message: "User Delete Successfully",
                         position: 'topLeft'
                     });
                     this.getLots();                
                       } 
                    else{
                     iziToast.warning({
                       message: "Sorry Some error occurred",
                       position: 'topLeft'
                   });  
                    }
              
              
                  }, 
                  (error)=>{
                      console.log(error);
                  });

        }
      })
    }
    closemanual(){
      this.getLots();
      // $('#ShowQrCode').removeAtt("style"); 
    }     
  
    
}
