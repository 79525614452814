
import { Component,OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-mappage',
  templateUrl: './mappage.component.html',
  styleUrls: ['./mappage.component.css']
})
export class MappageComponent implements OnInit {
	 @ViewChild('videoPlayer') videoplayer: ElementRef;
title = 'app';
  title2 = 'Reach Us';
  lat = 1.343990;
  lng = 103.885620;
  admin_type;
  elementType = 'url';
  videourl;
   value = 'https://play.google.com/store/apps/details?id=com.communityinstructor';
iosvalue ='https://apps.apple.com/us/app/id1530028946';
  constructor() { }

  ngOnInit(): void {
    this.admin_type=localStorage.getItem('admin_type');
  }
getandroid(){

 $('#androidapp').modal({
   show:true,
   backdrop:false
});
$('#androidapp').modal('show');

}

getios(){
  
   $('#iosapp').modal({
   show:true,
   backdrop:false
});
$('#iosapp').modal('show');

}

gethelpvideo(){
    $('#help').modal({
   show:true,
   backdrop:false
});
$('#help').modal('show');
}


helpvideoplay(val){
  console.log(val)
if(val == 'inst')
  this.videourl ='inst'
else if(val == 'stud')
  this.videourl ='stud'
else if(val == 'event')
  this.videourl ='event'

  console.log(this.videourl)
    $('#showvideo').modal({
   show:true,
   backdrop:false
});
$('#showvideo').modal('show');


}

toggleVideo() {
    this.videoplayer.nativeElement.play();
}


}
