<div class="light theme-black dark-sidebar">
    <!-- <div class="loader"></div> -->
    <div id="app">
      <section class="section login2-page">
       
              <div class="card card-primary login-card" *ngIf="!sendotps">
                <div class="card-header">
                  <h4>Login</h4>
                </div>
                <div class="card-body">
                  <form method="POST" [formGroup]="loginForm" (ngSubmit)="userLogin()" class="needs-validation" novalidate="">
                    <div class="login-logo fl">
                      <img alt="image" src="assets/images/itfs-logo.svg"  />
                    </div>
   <div class="form-group">
                      <div class="d-block">
                      <label for="username">Company Name</label>
  <!-- <div class="float-right">
                          <a (click)="forgotusername()" class="pointer text-small">
                            Forgot Username?
                          </a>
                        </div> -->
   </div>
                      <input id="company_name" type="text" class="form-control" formControlName="company_name" tabindex="1" required autofocus>
  
                      <div class="error-message message-small" *ngIf="!loginForm.get('company_name').valid && loginForm.get('company_name').touched">
                        Please enter your Company Name
                      </div>
                      <!-- <div class="invalid-feedback">
                        Please fill in your email
                      </div> -->
                    </div>
  
  
  
  
  
  
  
  
  
                    <div class="form-group">
                      <div class="d-block">
                      <label for="username">User Name</label>
  <div class="float-right">
                          <a (click)="forgotusername()" class="pointer text-small">
                            Forgot Username?
                          </a>
                        </div>
   </div>
                      <input id="username" type="text" class="form-control" formControlName="username" tabindex="1" required autofocus>
  
                      <div class="error-message message-small" *ngIf="!loginForm.get('username').valid && loginForm.get('username').touched">
                        Please enter your User Name
                      </div>
                      <!-- <div class="invalid-feedback">
                        Please fill in your email
                      </div> -->
                    </div>
                    <div class="form-group">
                      <div class="d-block">
                        <label for="password" class="control-label">Password</label>
                        <div class="float-right">
                          <a (click)="forgotpassword()" class="pointer text-small">
                            Forgot Password?
                          </a>
                        </div>
                      </div>
                      <input id="password" type="password" class="form-control"  formControlName="pwd" tabindex="2" required>
                      <!-- <div class="invalid-feedback">
                        please fill in your password
                      </div> -->
  
                      <div class="error-message message-small" *ngIf="!loginForm.get('pwd').valid && loginForm.get('pwd').touched">
                        please enter your password
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" name="remember" class="custom-control-input" tabindex="3" id="remember-me">
                        <label class="custom-control-label" for="remember-me">Remember Me</label>
                      </div>
                    </div>
                    <!-- <div class="form-group">
                      <button type="submit" class="btn btn-info btn-lg btn-block" tabindex="4">
                        Login
                      </button>
                    </div> -->
  
   <div class="form-group">
                       <span class="login-message success-message message-small">{{loginSuccess}}</span> 
                         <span class="login-message error-message message-small">{{loginError}}</span> 
                      <input type="submit" value="Login" class="btn btn-primary btn-lg btn-block" tabindex="4">
                      
                    </div>
  
                   <!--   <div class="text-muted text-center">
                Don't have an account? <a [routerLink]="['/register']">Create One</a>
              </div> -->
                  </form>
                </div>
              </div>
     
  
   
  
   <div class="card card-primary login-card" *ngIf="sendotps">
                <div class="card-header">
                  <h4>OTP</h4>
                </div>
                <div class="card-body">
                <form method="POST" [formGroup]="otpform" (ngSubmit)="otpsubmit()" class="needs-validation" novalidate="">
                    <div class="login-logo fl">
                      <img alt="image" src="assets/img/logo.png"  />
                    </div>
                   <!--  <div class="form-group">
                      <label for="username">User Name</label>
                      <input id="username" type="text" class="form-control" formControlName="username" tabindex="1" required autofocus>
  
                      <div class="error-message message-small" *ngIf="!loginForm.get('username').valid && loginForm.get('username').touched">
                        Please enter your User Name
                      </div>
                      <div class="invalid-feedback">
                        Please fill in your email
                      </div> -->
                    <!-- </div> -->
                    <div class="form-group">
                      <div class="d-block">
                        <label for="otp" class="control-label">OTP</label>
                        <div class="float-right">
                          <a (click)="sendotp()" class="pointer text-small">
                            Resend OTP?
                          </a>
                        </div>
                      </div>
                      <input id="otp" type="text" class="form-control"  formControlName="otp" required>
                      <!-- <div class="invalid-feedback">
                        please fill in your password
                      </div> -->
  
              <div class="error-message message-small" *ngIf="!otpform.get('otp').valid && otpform.get('otp').touched">
                        please enter OTP
                      </div>
                    </div>
                    <!-- <div class="form-group">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" name="remember" class="custom-control-input" tabindex="3" id="remember-me">
                        <label class="custom-control-label" for="remember-me">Remember Me</label>
                      </div>
                    </div> -->
                    <!-- <div class="form-group">
                      <button type="submit" class="btn btn-info btn-lg btn-block" tabindex="4">
                        Login
                      </button>
                    </div> -->
  
   <div class="form-group">
                       <span class="login-message success-message message-small">{{loginotpSuccess}}</span> 
                         <span class="login-message error-message message-small">{{loginotpError}}</span> 
                      <input type="submit" value="Login" class="btn btn-primary btn-lg btn-block" tabindex="4">
                      
                    </div>
  
                     <div class="text-muted text-center pointer" (click)="backToLogin()">
                Back to Login?
              </div>
  
    <!-- <button type="button" class="btn btn-primary btn-lg btn-block" (click)="backToLogin()">Submit</button> -->
  
                  </form>
                </div>
              </div>
     
  
   
  
  
  
  
  
  
  
  
  
  
      </section>
    </div>
   
  
  </div>
  
  
  