import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ServerService } from '../services/server.service';
import { Router,ActivatedRoute } from '@angular/router';
declare var $:any;
declare var iziToast:any;
@Component({
  selector: 'app-addadmincc',
  templateUrl: './addadmincc.component.html',
  styleUrls: ['./addadmincc.component.css']
})
export class AddadminccComponent implements OnInit {
id;
page;
addadmindata : FormGroup;
admineditdetails:any=[];
enableotps;
enableduplicates;
  constructor(private route:ActivatedRoute,private router:Router,private serverService: ServerService) {

  this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {

     this.id=this.router.getCurrentNavigation().extras.state.id; 
     this.page=this.router.getCurrentNavigation().extras.state.page; 

     console.log(this.id)
     console.log(this.page)

     this.get_edit_admin()
    
      }
    });
   }

  ngOnInit(){

if(localStorage.getItem('type') == '2'){

console.log("sa")
}else{
    // localStorage.removeItem("access_token");
    // localStorage.removeItem("user_name");
    // localStorage.removeItem("user_type");
    // localStorage.clear();
    this.router.navigate(['/dashboard']);
    // return false
}

this.addadmindata = new FormGroup({
      'fname' : new FormControl(null,Validators.required),
      'lname' : new FormControl(null,Validators.required),
      'company_name' : new FormControl(null,Validators.required),
 'email' : new FormControl(null,[
    Validators.required,
    Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
      'country_code' : new FormControl(null,Validators.required),
      'phone_no' : new FormControl(null,Validators.required),
        'username':new FormControl(null,Validators.required),
      'password' : new FormControl(null,Validators.required),
      'status' : new FormControl(null),
      'enableotp' : new FormControl(null),
      'enableduplicate' : new FormControl(null)
        
     });
     this.addadmindata.patchValue({

      enableotp:true,
  country_code: "65"

    });
  }


get_edit_admin(){
  



  let access_token: any=localStorage.getItem('access_token');
  let api_req:any = '{"operation":"lecture","moduleType":"lecture","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"edit_admin","id":"'+this.id+'"}}';
  this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){
      this.admineditdetails = response.result.data
      if(this.admineditdetails[0].enable_otp == '1'){
        this.enableotps = true;
    
      }else{
       this.enableotps = false;
    
      }
    
 if(this.admineditdetails[0].enable_duplicate == '1'){
        this.enableduplicates = true;
    
      }else{
       this.enableduplicates = false;
    
      }


  this.addadmindata.patchValue({
      email:this.admineditdetails[0].email,
      fname:this.admineditdetails[0].fname,
      lname:this.admineditdetails[0].lname,
      company_name:this.admineditdetails[0].company_name ,
      phone_no:this.admineditdetails[0].phone_no,
      country_code:this.admineditdetails[0].country_code,
      username:this.admineditdetails[0].username,
      password:this.admineditdetails[0].psw,
      status:this.admineditdetails[0].status,
      enableotp:this.enableotps,
      enableduplicate:this.enableduplicates
    
  });



      }

    }, 
    (error)=>{
        console.log(error);
    });
  }


submit(){


   if(this.addadmindata.valid){
     
   	if(this.page == 'Edit'){
this.submitupdate()
	}
	else{
		this.submitadd()
	}
   }
   else {
            this.validateAllFields(this.addadmindata); 
        }
  

}






submitadd(){
  if(this.addadmindata.value.enableotp == true){
    this.enableotps = 1;

  }else{
   this.enableotps = 0;

  }
  if(this.addadmindata.value.enableduplicate == true){
    this.enableduplicates= 1;

  }else{
   this.enableduplicates= 0;

  }
  let registerstudentdata:any =this.addadmindata.value;
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');

    let admin_id: any=localStorage.getItem('supersuperadmin');

registerstudentdata.action="add_admin";

registerstudentdata.type="1";

registerstudentdata.enable_otp=this.enableotps;

registerstudentdata.enable_duplicate=this.enableduplicates;
 

 registerstudentdata.admin_id=admin_id;

 registeruser.access_token=access_token;
  registeruser.operation="lecture";
  registeruser.moduleType="lecture";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

      	console.log(response)
       
 

        if(response.status=="true" ){
     
iziToast.success({
    // title: 'OK',
    position: 'topRight',
    message: 'CC added Successfully!',
});

this.router.navigate(['/cclist'])

        }

        else{
iziToast.error({
    // title: 'OK',
    position: 'topRight',
    message: 'Error Occured,Please Try Again Later!',
});
        }
      
      }, 
      (error)=>{
          console.log(error);
      });
}

submitupdate(){
  console.log(this.addadmindata.value.enableduplicate)
  if(this.addadmindata.value.enableotp == true){
    this.enableotps = 1;

  }else{
   this.enableotps = 0;

  }
 if(this.addadmindata.value.enableduplicate == true){
    this.enableduplicates= 1;

  }else{
   this.enableduplicates= 0;

  }

  let registerstudentdata:any =this.addadmindata.value;
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');

 
    let admin_id: any=localStorage.getItem('supersuperadmin');
registerstudentdata.action="update_admin";
registerstudentdata.id=this.id;
registerstudentdata.enable_otp=this.enableotps;
 registerstudentdata.admin_id=admin_id;

registerstudentdata.enable_duplicate=this.enableduplicates;


 registeruser.access_token=access_token;
  registeruser.operation="lecture";
  registeruser.moduleType="lecture";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

      	console.log(response)
       
 

        if(response.result.status==true && response.result.data == 1){
     
iziToast.success({
    // title: 'OK',
    position: 'topRight',
    message: 'CC Details Updated Successfully!',
});
      
        }
        else{
iziToast.error({
    // title: 'OK',
    position: 'topRight',
    message: 'Error Occured,Please Try Again Later!',
});
        }
      
      }, 
      (error)=>{
          console.log(error);
      });
}





validateAllFields(formGroup: FormGroup) {         
        Object.keys(formGroup.controls).forEach(field => {  
            const control = formGroup.get(field);            
            if (control instanceof FormControl) {             
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {        
                this.validateAllFields(control);  
            }
        });
    }










}
