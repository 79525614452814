    <div class="section-body">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <div class="card-header-title">
                    <h4>Calendar</h4>
                  </div>
                </div>
                <div class="card-body">
                  <div class="fc-overflow">
         

                    <div class='demo-app'>

                      <div class='demo-app-sidebar'>
                        <div class='demo-app-sidebar-section'>
    </div>
    <div class='demo-app-sidebar-section'>
      <h6 class="mb-3">All ({{currentEvents.length}})</h6>
    </div>
  </div>
  <div class='demo-app-main'>
    <full-calendar 
    *ngIf='calendarVisible'
    [options]='calendarOptions'
    ></full-calendar>
  </div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>


<!-- course modal box start -->

<div class="modal fade"   id="addNewPopupcourse">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <form [formGroup]="addCourse" >

        <div class="modal-header">

        <h5 class="modal-title" id="exampleModalLabel" >Course Details </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
       
          <div class="col-md-6 col-12">
            <div class="form-group">
              <label>Instructor</label>
              <br /> <div class="badge l-bg-purple-dark">{{instructselectname}}</div>
            </div>
          </div>

              <div class="col-md-6 col-12">
            <div class="form-group">
              <label>Co-Instructor</label>
              <br /> <div class="badge l-bg-dark-orange">{{instructselectnameco}}</div>
            </div>
          </div>


             <div class="col-md-6 col-12">
            <div class="form-group">
              <label>Course Name</label>
              <input type="text" readonly class="form-control" formControlName="event_name" >
            </div>
          </div> 

          <div class="col-md-6 col-12">
           <div class="form-group">
            <label>Start Date</label>

            <input  type="date" formControlName="start_date"  class="form-control datetimepicker" readonly>
          </div>
        </div>

        <div class="col-md-6 col-12">
         <div class="form-group">
          <label>Start Time</label>

          <input  type="time" formControlName="start_time"  class="form-control datetimepicker" readonly>
        </div>
      </div>

      <div class="col-md-6 col-12">
       <div class="form-group">
        <label>End Time</label>
        <input  type="time" formControlName="end_time" class="form-control datetimepicker" readonly>
      </div>
    </div>

    <div class="col-md-6 col-12">
     <div class="form-group">
      <label>Location</label>
      <input type="text" formControlName="location" class="form-control " readonly>
    </div>
  </div>

  <div class="col-md-6 col-12">
   <div class="form-group">
    <label>View Students</label><br />
    <button type="submit" style="margin-left: 10px !important" class="btn btn-info" (click)="viewstud('call')"><i aria-hidden="true" class="fa fa-eye"></i> View Student</button>
  </div>
</div>

<!-- <div class="col-md-6 col-12" *ngIf="!addCourse.value.editshowing">
 <div class="form-group">
  <label> Add Students</label><br />
  <button type="submit" style="margin-left: 10px !important" class="btn btn-danger" (click)="addstudent()"><i aria-hidden="true" class="fa fa-plus"></i> Add Student</button>
</div>
</div> -->


</div>
</div>

<div class="modal-footer bg-whitesmoke br">
  <button  *ngIf="!addCourse.value.editshowing" type="button" class="btn btn-success" data-dismiss="modal" (click)="updateCourseData()">Save changes</button>
  <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
</div>
</form>
</div>
</div>
</div>

<!-- course modal box end -->


<!-- meeeting  event modal box start -->
<div class="modal fade"   id="addNewPopup">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <form [formGroup]="addEvent" >

          <div class="modal-header">
 
          <h5 class="modal-title" id="exampleModalLabel">{{titleshow}} Details </h5>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">


          <div class="row">
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label>{{titleshow}} Name</label>
                <input type="text" readonly class="form-control" formControlName="event_name" >

              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
               <label>Incharge</label>  :  <strong>{{instructorfullname}}</strong>
               <button *ngIf="!addEvent.value.editshowing"  type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="chnageinst('instructor')">Change Incharge</button>

             </div>
           </div>
           <div class="col-md-6 col-12">
            <div class="form-group">
             <label>Co-Incharge</label>  :  <strong>{{instructorfullnameco}}</strong>
             <button *ngIf="!addEvent.value.editshowing"  type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="chnageinst('coinstructor')">Change Co-Incharge</button>

           </div>
         </div>


         <div class="col-md-6 col-12">
           <div class="form-group">
            <label>Start Time</label>

            <input *ngIf="addEvent.value.editshowing" type="datetime-local" formControlName="start_time"  class="form-control datetimepicker" readonly>

            <input *ngIf="!addEvent.value.editshowing" type="datetime-local" formControlName="start_time"  class="form-control datetimepicker">



          </div>
        </div>

   <div class="col-md-6 col-12" *ngIf="typecalled == 'Meeting'">
           <div class="form-group">
            <label>Reference</label>

            <input *ngIf="addEvent.value.editshowing" type="text" formControlName="reference"  class="form-control datetimepicker" readonly>

            <input *ngIf="!addEvent.value.editshowing" type="text" formControlName="reference"  class="form-control datetimepicker">



          </div>
        </div>



        <div class="col-md-6 col-12">
         <div class="form-group">
          <label>End Time</label>
          <input *ngIf="addEvent.value.editshowing" type="datetime-local" formControlName="end_time" class="form-control datetimepicker" readonly>

          <input *ngIf="!addEvent.value.editshowing" type="datetime-local" formControlName="end_time" class="form-control datetimepicker">

        </div>
      </div>
<!-- 
      <div class="col-md-6 col-12">
       <div class="form-group">
        <label>Location</label>

        <input *ngIf="addEvent.value.editshowing" type="text" formControlName="location" class="form-control " readonly>

        <select *ngIf="!addEvent.value.editshowing" class="form-control select2"  formControlName="location" >
         <option value="{{l.location}}" *ngFor="let l of locations">{{l.location}}</option>
       </select>

     </div>
   </div> -->

      <div class="col-md-6 col-12">
       <div class="form-group">
        <label>Location</label>

        <input *ngIf="addEvent.value.editshowing" type="text" formControlName="location" class="form-control" readonly>

        <select *ngIf="!addEvent.value.editshowing" class="form-control"  formControlName="location" >
         <option value="{{l.location}}" *ngFor ="let l of locations">{{l.location}}</option>
       </select>

     </div>
   </div>
   <div class="col-md-6 col-12">
     <div class="form-group">
      <label>View Participants</label>
      <button type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="viewstud('call')">View Participant</button>
    </div>
  </div>

  <div class="col-md-6 col-12" *ngIf="!addEvent.value.editshowing">
   <div class="form-group">
    <label>Add Participant</label>
    <button type="submit" style="margin-left: 10px !important" class="btn btn-success" (click)="addstudents()">Add Participant</button>
  </div>
</div>

</div>


</div>

<div class="modal-footer bg-whitesmoke br">

  <div>
    <button style="margin-right: 5px;" *ngIf="!addEvent.value.editshowing" type="button" class="btn btn-success" data-dismiss="modal" (click)="update()">Save changes</button>
    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
  </div>
</div>


</form>
</div>
</div>
</div>

<!-- meeeting modal box end -->


<!-- Add students to Course -->

<div class="modal fade bd-example-modal-lg" id="addNewPopups" aria-modal="true" >
  <div class="modal-dialog modal-lg">

    <div class="modal-content">


      <div class="modal-header">
        <h5 class="modal-title" id="myLargeModalLabel">Add Students</h5>
        <button type="button" data-dismiss="modal" aria-label="Close" class="close"><span aria-hidden="true">×</span></button>
      </div>

      <div class="modal-body">

        <div class="row">
          <div class="col-sm-12">
            <div class="card-body" >
              <div class="table-responsive">
                <table  class="table table-striped" id="table-2">

                  <thead>
                    <tr>
                      <td colspan="20" class="search-text-tbl">
                        <div class="input-group"><input type="text" class="form-control" placeholder="Search Student" (input)="getsearch($event.target.value)"  (keyup.enter)="get_student_list({'search_text':agent_search.value})" #agent_search>
                          <div class="input-group-append">
                            <button class="btn btn-primary" (click)="get_student_list({'search_text':agent_search.value})"><i class="fas fa-search"></i></button>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                    <!-- <tr class="l-bg-light-blue"> -->
                      <th>S.no</th>
                      <th>Student Name</th>
                      <th>Photo</th>
                      <th>Email</th>
                      <th>Contact no.</th>
                      <th>NRIC</th>
                      <th>Add</th>
                    </tr>
                  </thead>

                  <tbody >


                    <tr *ngFor="let l of studentslist;let i=index">

                      <td>{{offset_count+i+1}}</td>
                      <td>{{l.fname}} {{l.lname}}</td>
                      <td class="student-img">
                        <img alt="image" *ngIf="l.image == ''" src="assets/images/avatar.jpg" width="35">
                        <img alt="image" [src]="l.image"  *ngIf="l.image != ''" width="35">


                      </td>

                      <td>{{l.email}}</td>
                      <td>{{l.phone_no}}</td>
                      <td>{{l.nirp}}</td>

                      <td><input type="checkbox"    [(ngModel)]="l.add"  name="add"  (click)="savedata(l)"></td>
                      <!-- checked="l.add" -->
                      <td>

                      </td>
                    </tr>


                  </tbody>
                  <tr *ngIf="recordNotFound == true">
                   <td colspan="12">Data not found</td>
                 </tr>

               </table>
             </div>

             <div class="card-footer text-right" *ngIf="recordNotFound == false">
              <nav class="d-inline-block">
                <ul class="pagination mb-0" *ngIf="paginationData.info == 'show'">

                  <li class="page-item">
                    <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? get_student_list({'search_text':agent_search.value,'offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
                  </li>
                  <li class="page-item" *ngIf="paginationData.backtab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="get_student_list({'search_text':agent_search.value,'offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
                  </li>


                  <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? get_student_list({'search_text':agent_search.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                  </li>

                  <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="get_student_list({'search_text':agent_search.value,'offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
                  </li>

                  <li class="page-item">
                    <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? get_student_list({'search_text':agent_search.value,'offset':paginationData.end}) : false"><i class="fas fa-angle-right"></i></a>
                  </li>
                </ul>
              </nav>
            </div>

          </div>
          <!-- </div> -->
        </div>
      </div>
    </div>
    <div class="modal-footer bg-whitesmoke br">

      <button  type="button" class="btn btn-success" (click)="savealldata()" data-dismiss="modal" >Save changes</button>
      <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
    </div>  
  </div>
</div>
</div>

<!-- Add students to Course end-->

<!-- Add Participant to meeting and event start-->
<div class="modal fade bd-example-modal-lg" id="addNewPopupparticipant" aria-modal="true" >
    <div class="modal-dialog modal-lg">

        <div class="modal-content">


            <div class="modal-header">
                <h5 class="modal-title" id="myLargeModalLabel">Add Paticipant</h5>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>

            <div class="modal-body">

                <div class="row">
                    <div class="col-sm-12">

                  <div class="card-body" >
                    <div class="table-responsive">
                      <table  class="table table-striped" id="table-2">




                        <thead>
                          <tr>
                <td colspan="20" class="search-text-tbl">
                            <div class="input-group"><input type="text" class="form-control" placeholder="Search Participant" (input)="getsearchpaticipant($event.target.value)"  (keyup.enter)="get_participant_list({'search_text':participant_search.value})" #participant_search>
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" (click)="get_participant_list({'search_text':participant_search.value})"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                          <tr>
                          
                            <th>S.no</th>
                            <th>Participant Name</th>
                            <!-- <th>Photo</th> -->
                            <th>Email</th>
                            <th>Contact no.</th>
                            <!-- <th>NIRP</th> -->
                            <th>Add</th>
                          </tr>
                        </thead>
                      
                        <tbody >


                          <tr *ngFor="let l of participantlist;let i=index">
                          
                            <td>{{offset_count2+i+1}}</td>
                            <td>{{l.name}}</td>
                            <!-- <td class="student-img">
                              <img alt="image" *ngIf="l.image == ''" src="assets/images/avatar.jpg" width="35">
                               <img alt="image" [src]="l.image"  *ngIf="l.image != ''" width="35">


                            </td> -->

                            <td>{{l.email}}</td>
                            <td>{{l.phone_no}}</td>
                            <td>{{l.nirp}}</td>

    <td><input type="checkbox"    [(ngModel)]="l.add"  name="add"  (click)="savedataparticipant(l)"></td>
                            <td>
                     
                            </td>
                          </tr>

                        
                        </tbody>
                         <tr *ngIf="recordNotFound2 == true">
                               <td colspan="12">Data not found</td>
                            </tr>
                      
                      </table>
                    </div>

 <div class="card-footer text-right" *ngIf="recordNotFound2 == false">
                        <nav class="d-inline-block">
                        <ul class="pagination mb-0" *ngIf="paginationData2.info == 'show'">

                                <li class="page-item">
                                    <a [class]="paginationData2.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData2.backtab=='show' ? get_participant_list({'search_text':participant_search.value,'offset':paginationData2.start}) : false"><i class="fas fa-angle-left"></i></a>
                                </li>
                                <li class="page-item" *ngIf="paginationData2.backtab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="get_participant_list({'search_text':participant_search.value,'offset':paginationData2.back})"><i class="fas fa-angle-double-left"></i></a>
                                </li>


                                <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData2.data;let i=index">
                                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? get_participant_list({'search_text':participant_search.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                                </li>

                                <li class="page-item" *ngIf="paginationData2.nexttab =='show'">
                                    <a class="page-link" href="javascript:void(0)" (click)="get_participant_list({'search_text':participant_search.value,'offset':paginationData2.next})"><i class="fas fa-angle-double-right"></i></a>
                                </li>

                              <li class="page-item">
                                    <a [class]="paginationData2.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData2.nexttab =='show' ? get_participant_list({'search_text':participant_search.value,'offset':paginationData2
                                    .end}) : false"><i class="fas fa-angle-right"></i></a>
                                </li>
                        </ul>
                    </nav>
                </div>


                  </div>
                <!-- </div> -->


            </div>

            

        </div>
    </div>
<div class="modal-footer bg-whitesmoke br">
 
    <button  type="button" class="btn btn-success" (click)="savealldata()" data-dismiss="modal" >Save changes</button>
    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
  </div>
 
</div>
</div>
</div>

<!-- Add Participant to meeting and event end -->



<!-- Enrolled students to course -->
<div class="modal fade bd-example-modal-lg" id="addNewPopupee" aria-modal="true" >
  <div class="modal-dialog modal-lg">

    <div class="modal-content">


      <div class="modal-header">
        <h5 class="modal-title" id="myLargeModalLabel">Enrolled Students</h5>

      </div>

      <div class="modal-body">

        <div class="row">
          <div class="col-sm-12">

            <div class="card-body" >
              <div class="table-responsive">
                <table  class="table table-striped" id="table-2">

                  <thead>

                    <tr>

                      <th>S.no</th>
                      <th>Student Name</th>
                      <th>Email</th>
                      <th>Contact no.</th>


                      <th>NRIC</th>

                      <th  *ngIf="!addEvent.value.editshowing">Action</th>
                    </tr>
                  </thead>

                  <tbody >


                    <tr *ngFor="let l of get_attendees;let i=index">

                      <td>{{i+1}}</td>
                      <td>{{l.name}}</td>
                      <td>{{l.email}}</td>
                      <td>{{l.phone_no}}</td>

                      <td>{{l.nric}}</td>
     <td  *ngIf="!addEvent.value.editshowing" ><input type="checkbox"    [(ngModel)]="l.add"  name="add"  (click)="adddeletestudent(l)"></td>
                      <!-- <td class="student-img"> -->
                         <!--      <img alt="image" *ngIf="l.image == ''" src="assets/images/avatar.jpg" width="35">
                           <img alt="image" [src]="l.image"  *ngIf="l.image != ''" width="35"> -->
                           <!-- <td *ngIf="!addEvent.value.editshowing"> -->
                            <!-- <a href="javascript:void(0)" (click)="deleteassignstudent(l)"  class="btn btn-danger btn-action" data-toggle="tooltip" title="Delete"> -->
                              <!-- <i class="fas fa-trash"></i> -->
                            <!-- </a> -->
                          <!-- </td> -->

                          <!-- <td>{{l.email}}</td> -->
                          <!-- <td>{{l.phone_no}}</td> -->
                          <!-- <td>{{l.nirp}}</td> -->

                          <!-- <td><input type="checkbox"    [(ngModel)]="l.add"  name="add"  (click)="savedata(l)"></td> -->

                          <td>

                          </td>
                        </tr>

                        
                      </tbody>

                    </table>
                  </div>


                </div>



              </div>



            </div>
          </div>
          <div class="modal-footer bg-whitesmoke br">
  <button style="margin-right: 5px;" *ngIf="!addEvent.value.editshowing" type="button" class="btn btn-danger" data-dismiss="modal" (click)="removealert('deleteassignstudent')">Delete</button>

            <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
          </div>



        </div>
      </div>
    </div>



<!-- Enrolled students to course end-->



<!-- Enrolled participant to event and meeting start -->

 <div class="modal fade bd-example-modal-lg" id="addNewPopupeeparti" aria-modal="true" >
  <div class="modal-dialog modal-lg">

    <div class="modal-content">


      <div class="modal-header">
        <h5 class="modal-title" id="myLargeModalLabel">Enrolled Participants</h5>
<button type="button" data-dismiss="modal" aria-label="Close" class="close">
          <span aria-hidden="true">×</span></button>
      </div>

      <div class="modal-body">

        <div class="row">
          <div class="col-sm-12">

            <div class="card-body" >
              <div class="table-responsive">
                <table  class="table table-striped" id="table-2">

                  <thead>

                    <tr>

                      <th>S.no</th>
                      <th>Participant Name</th>
                      <th>Email</th>
                      <th>Contact no.</th>


                      <!-- <th>NRIC</th> -->

                      <th  *ngIf="!addEvent.value.editshowing">Action</th>
                    </tr>
                  </thead>

                  <tbody >


                    <tr *ngFor="let l of get_attendees;let i=index">

                      <td>{{i+1}}</td>
                      <td>{{l.name}}</td>
                      <td>{{l.email}}</td>
                      <td>{{l.phone_no}}</td>

                      <!-- <td>{{l.nric}}</td> -->

                      <!-- <td class="student-img"> -->
                         <!--      <img alt="image" *ngIf="l.image == ''" src="assets/images/avatar.jpg" width="35">
                           <img alt="image" [src]="l.image"  *ngIf="l.image != ''" width="35"> -->
                          <!--  <td *ngIf="!addEvent.value.editshowing">
                            <a href="javascript:void(0)" (click)="deleteassignstudent(l)"  class="btn btn-danger btn-action" data-toggle="tooltip" title="Delete">
                              <i class="fas fa-trash"></i>
                            </a>
                          </td> -->

                          <!-- <td>{{l.email}}</td> -->
                          <!-- <td>{{l.phone_no}}</td> -->
                          <!-- <td>{{l.nirp}}</td> -->

                          <td  *ngIf="!addEvent.value.editshowing" ><input type="checkbox"    [(ngModel)]="l.add"  name="add"  (click)="adddeletestudent(l)"></td>

                          <td>

                          </td>
                        </tr>

                        
                      </tbody>

                    </table>
                  </div>


                </div>



              </div>



            </div>
          </div>
          <div class="modal-footer bg-whitesmoke br">
           <button style="margin-right: 5px;" *ngIf="!addEvent.value.editshowing" type="button" class="btn btn-danger" data-dismiss="modal" (click)="removealert('deleteassignstudent','')">Delete</button>
            <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
          </div>



        </div>
      </div>
    </div>


<!--  Enrolled participant to event and meeting end -->


<!-- instructor and incharge start -->
    <div class="modal fade bd-example-modal-lg" id="addNewPopupinst" aria-modal="true" >
      <div class="modal-dialog modal-lg">

        <div class="modal-content">


          <div class="modal-header"  *ngIf="typecalled == 'Course'">

            <h5 class="modal-title" id="myLargeModalLabel" *ngIf="typeinstructor == 'instructor'">Add Instructor</h5>
            <h5 class="modal-title" id="myLargeModalLabel" *ngIf="typeinstructor == 'coinstructor'">Add Co-Instructor</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
          </div>

 <div class="modal-header"  *ngIf="typecalled != 'Course'">
            
            <h5 class="modal-title" id="myLargeModalLabel" *ngIf="typeinstructor == 'instructor'">Add Incharge</h5>
            <h5 class="modal-title" id="myLargeModalLabel" *ngIf="typeinstructor == 'coinstructor'">Add Co-Incharge</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
          </div>


          <div class="modal-body">

            <div class="row">
              <div class="col-sm-12">

                <div class="card-body" >
                  <div class="table-responsive">
                    <table  class="table table-striped" id="table-2">




                      <thead>
                        <tr>
                          <td colspan="20" class="search-text-tbl">
                            <div class="input-group"><input type="text" class="form-control" placeholder="Search Student" (input)="getsearchinstructor($event.target.value)"  (keyup.enter)="get_instructor_list({'search_text':agent_search3.value})" #agent_search3>
                              <div class="input-group-append">
                                <button class="btn btn-primary" (click)="get_instructor_list({'search_text':agent_search3.value})"><i class="fas fa-search"></i></button>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>

                          <th>S.no</th>
                          <th *ngIf="typecalled == 'Course'">Instructor Name</th>
                          <th *ngIf="typecalled != 'Course'">Incharge Name</th>

                          <th>Photo</th>
                          <th>Email</th>
                          <th>Contact no.</th>
                          <th>NRIC</th>
                          <th>Add</th>
                        </tr>
                      </thead>
                      
                      <tbody >


                        <tr *ngFor="let l of instructorlist;let i=index">

                          <td>{{offset_count3+i+1}}</td>
                          <td>{{l.fname}} {{l.lname}}</td>
                          <td class="student-img">
                            <img alt="image" *ngIf="l.image == ''" src="assets/images/avatar.jpg" width="35">
                            <img alt="image" [src]="l.image"  *ngIf="l.image != ''" width="35">


                          </td>

                          <td>{{l.email}}</td>
                          <td>{{l.phone_no}}</td>
                          <td>{{l.nirp}}</td>

                          <td><input type="radio"    [checked]="l.add" name="add"  (click)="savedatainstructor(l)"></td>
                          <td>

                          </td>
                        </tr>                        
                      </tbody>
                      <tr *ngIf="recordNotFound == true">
                       <td colspan="12">Data not found</td>
                     </tr>

                   </table>
                 </div>

                 <div class="card-footer text-right" *ngIf="recordNotFound3 == false">
                  <nav class="d-inline-block">
                    <ul class="pagination mb-0" *ngIf="paginationData3.info == 'show'">

                      <li class="page-item">
                        <a [class]="paginationData3.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData3.backtab=='show' ? get_instructor_list({'search_text':agent_search3.value,'offset':paginationData3.start}) : false"><i class="fas fa-angle-left"></i></a>
                      </li>
                      <li class="page-item" *ngIf="paginationData3.backtab =='show'">
                        <a class="page-link" href="javascript:void(0)" (click)="get_instructor_list({'search_text':agent_search3.value,'offset':paginationData3.back})"><i class="fas fa-angle-double-left"></i></a>
                      </li>


                      <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData3.data;let i=index">
                        <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? get_instructor_list({'search_text':agent_search3.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                      </li>

                      <li class="page-item" *ngIf="paginationData3.nexttab =='show'">
                        <a class="page-link" href="javascript:void(0)" (click)="get_instructor_list({'search_text':agent_search3.value,'offset':paginationData3.next})"><i class="fas fa-angle-double-right"></i></a>
                      </li>

                      <li class="page-item">
                        <a [class]="paginationData3.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData3.nexttab =='show' ? get_instructor_list({'search_text':agent_search3.value,'offset':paginationData3.end}) : false"><i class="fas fa-angle-right"></i></a>
                      </li>
                    </ul>
                  </nav>
                </div>


              </div>



            </div>

            

          </div>
        </div>
        <div class="modal-footer bg-whitesmoke br">

          <button  type="button" class="btn btn-success" (click)="savealldata2()" data-dismiss="modal" >Save changes</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
        </div>



      </div>
    </div>
  </div>

<!-- instructor and incharge end -->