<div class="section-body">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div class="card-header-title">
                        <h4>Parking Logs</h4>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table  table-bordered table-striped table-md " id="table-2">

                            <thead>
                                <tr class="l-bg-light-blue">
                                   
                                    <th>S.no</th>
                                    <th>Visitor Name</th>
                                    <th>Mobile Number</th>
                                    <th>Vehicle No</th>
                                    <th>Lot Name</th>
                                    <!-- <th>Lot Number</th> -->
                                    <th>Check In Time</th>
                                    <th>Check Out Time</th>
                                    <th>Status</th>
                                    <th>Exit By</th>
                                </tr>
                            </thead>

                            <tbody>


                                <tr *ngFor="let l of carlots_log;let i=index">
                                    
                                    <td>{{i+1}}</td>
                                    <td>{{l.visitor_name}}</td>
                                    <td>{{l.visitor_num}}</td>
                                    <td>{{l.visitor_carnum}}</td>
                                    <td>{{l.lot_name}}</td>
                                    <!-- <td>{{l.lot_no}}</td> -->
                                    <td>{{l.checkin_time | date:'MM/dd/yy h:mm a'}}</td>
                                    <td>{{l.checkout_time | date:'MM/dd/yy h:mm a'}}</td>                                   
                                    <td style="border-radius: 23px;" *ngIf="l.status==1" class="btn btn-success mb-2 ml-4 mt-2">Entry</td>
                                    <td style="border-radius: 23px;" *ngIf="l.status==0" class="btn btn-danger mb-2 ml-4 mt-2">Exit</td>
                                    <td>{{l.exit_by}}</td>
                                    
                                </tr>

                            </tbody>
                            <!-- <tr *ngIf="recordNotFound == true">
                                <td colspan="12">Data not found</td>
                            </tr> -->

                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
