import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { ServerService } from '../services/server.service';
declare var $:any;
declare var iziToast:any;
@Component({
  selector: 'app-reportscourse',
  templateUrl: './reportscourse.component.html',
  styleUrls: ['./reportscourse.component.css']
})
export class ReportscourseComponent implements OnInit {
eventData:any={};
	 call_history_list;

	 recordNotFound3 = false;
	 pageLimit3 = 10;
	 paginationData3:any ={"info":"hide"};
	 offset_count3 = 0;
    reports:any=[];
	 getRep: FormGroup;
	
	 eventslist:any=[];
sendstudentname;


instructorlist:any=[]
 studentslist:any=[];
recordNotFound = false;
recordNotFound2 = false;

 pageLimit = 10;
 pageLimit2 = 10;

 paginationData:any ={"info":"hide"};
 paginationData2:any ={"info":"hide"};

 offset_count = 0;
  offset_count2 = 0;
 sendstudent;
 sendstudentarr=[];



 instructorselect;
 instructselectname;
reportview = false;

reportheader:any=[];

reportcontent:any=[];

reportheadershow:any=[];

datereport:any=[];
courselist:any=[];
page;
studentnames=[];
nameshowinstructor;
nameshowevent;
  constructor(private serverService: ServerService,private route:ActivatedRoute,private router:Router) {
    this.eventData.types = "course"
 this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {


     this.page=this.router.getCurrentNavigation().extras.state.page; 
// if(this.page == 'event'){
//    this.eventData.types = 'events'
//    }else if(this.page == 'course'){
//       this.eventData.types = 'course'
//    }
   
    
      }
    });


 }

  

  ngOnInit() {
	  
	  	// this.getevents()
      // this.event_id_list()
      this.course_list()
      this.view_report_list()
	  		// this.get_instructor_list({})
    this.get_student_list({})

		  this.getRep = new FormGroup({
			'event_name' :new FormControl(null),
			// 'from_dt' :new FormControl(null,Validators.required),
			// 'to_dt' :new FormControl(null,Validators.required),
			'add_inst' :new FormControl(null),
			'add_std' :new FormControl(null),
			'report_type':new FormControl(null,Validators.required),
      'course_name':new FormControl(null)

		  });
		
    this.eventData.types = 'course'

	  }


// this.information.patchValue({

//   gender: "", 
// });


validateAllFields(formGroup: FormGroup) {         
        Object.keys(formGroup.controls).forEach(field => {  
            const control = formGroup.get(field);            
            if (control instanceof FormControl) {             
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {        
                this.validateAllFields(control);  
            }
        });
    }
 addstud(){

    // $('#add_queue_form').modal('show');

     $('#addNewPopup').modal({
  show:true,
   backdrop:false

});
$('#addNewPopup').modal('show');
  }

addinstruct(){
  $('#addNewPopups').modal({
  show:true,
   backdrop:false

});
$('#addNewPopups').modal('show');
}

goaction(){
  

}





	listDataInfo3(list_data){

		list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
		list_data.order_by_name = list_data.order_by_name == undefined ? "history.callid" : list_data.order_by_name;
		list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
		list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
		list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
		return list_data;
	}



     callHistoryList(data){

		
	  }
	  


getevents(){
  
 

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');
  
    let api_req:any = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"dashboard_det","admin_id":"'+admin_id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response){
      // this.eventss= response

     
    
      // console.log(this.eventss);
      }
   

    }, 
    (error)=>{
        console.log(error);
    });
  }


addinstructor(){
    $('#addNewPopups').modal({
  show:true,
   backdrop:false

});
$('#addNewPopups').modal('show');

}
addstudent(){
   $('#addNewPopup').modal({
  show:true,
   backdrop:false

});
$('#addNewPopup').modal('show');
}

getReports(){

console.log(this.getRep.value)
console.log(this.eventData)


   if(this.getRep.valid){

      this.getReportss()
   }

   
   else {
            this.validateAllFields(this.getRep); 
        }
  }


	  getReportss(){

console.log(this.eventData.types)
if(this.eventData.types == 'events'){

// if(this.getRep.value.event_name == null){

//       iziToast.error({

//           message: "Please Select Event Name",
//           position: 'topRight'
//       });
//       return false
// }


  
this.eventinsert()

}else{
if(this.getRep.value.course_name == null){

      iziToast.error({

          message: "Please Select Course Name",
          position: 'topRight'
      });
      return false
}
  this.courseinsert()

}
}
eventinsert(){
  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');


registerstudentdata.action="student_attendee";
registerstudentdata.event_id=this.getRep.value.event_name;


// registerstudentdata.from_dt=this.getRep.value.from_dt;
// registerstudentdata.to_dt=this.getRep.value.to_dt;
registerstudentdata.name=this.sendstudent;
// registerstudentdata.event_name=this.getRep.value.event_name;
registerstudentdata.admin_id=admin_id;
registerstudentdata.report_type=this.getRep.value.report_type;
registerstudentdata.inst_id=this.instructorselect;




 registeruser.access_token=access_token;
  registeruser.operation="student_report";
  registeruser.moduleType="student_report";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status == true && response.result.data == 1){
     

     this.view_report_list()

     this.view_asssign_report()
//      iziToast.success({
//     title: 'OK',
//     position: 'topRight',
//     message: 'Successfully event assigned!',
// });
   }else{
//    iziToast.error({
//     title: 'OK',
//     position: 'topRight',
//     message: 'Illegal operation!',
// });
   }
      
      }, 
      (error)=>{
          console.log(error);
      });





}





get_student_list(data){
  var list_data= this.listDataInfo(data);
  console.log(list_data)

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

 
    let api_req:any = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"view_student_rec","limit":"'+list_data.limit+'","offset":'+list_data.offset+',"search_text":"'+list_data.search_text+'","admin_id":"'+admin_id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){
      this.studentslist=response.result.data.list_data;

console.log(this.sendstudentarr)
console.log(this.studentslist)


if(this.studentslist.length != 0){

if(this.sendstudentarr.length != 0){
for (var i = 0; i < this.studentslist.length; i++) {
   var ismatch = false;
  for (var j = 0; j < this.sendstudentarr.length; j++) {
   if(this.studentslist[i].username ==  this.sendstudentarr[j]){
     ismatch = true;
     this.studentslist[i].add = true;
     break;  

   }
 }
 if (!ismatch){
         this.studentslist[i].add = false//  checkbox status false
      
      } 

}
}
else{
  
  for (var i = 0; i < this.studentslist.length; i++) {
     this.studentslist[i].add = false  
  }
}

}

console.log(this.studentslist)




          this.offset_count = list_data.offset;
        this.paginationData = this.serverService.pagination({'offset':response.result.data.list_info.offset, 'total':response.result.data.list_info.total, 'page_limit' :this.pageLimit });
         console.log(this.paginationData);
                this.recordNotFound = this.studentslist.length == 0 ? true : false;


      }
     

    }, 
    (error)=>{
        console.log(error);
    });
  }


getsearch(searchValue: string): void {  
  console.log(searchValue);
  this.get_student_list({'search_text':searchValue})
}

getsearchinstructor(searchValue: string): void {  
  console.log(searchValue);
  this.get_instructor_list({'search_text':searchValue})
}

listDataInfo(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
}  

listDataInfo2(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
} 



get_instructor_list(data){
  var list_data= this.listDataInfo(data);

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');

  
    let api_req:any = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"view_instruct_rec","limit":"'+list_data.limit+'","offset":'+list_data.offset+',"search_text":"'+list_data.search_text+'","admin_id":"'+admin_id+'"}}';
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){
      this.instructorlist = response.result.data.list_data

    for (var i = 0; i < this.instructorlist.length; i++) {
if(this.instructorlist[i].id == this.instructorselect){
this.instructorlist[i].add = true 

}
else{
this.instructorlist[i].add = false 

}

}

 console.log(this.instructorlist)
      this.offset_count2= list_data.offset;
     this.paginationData2 = this.serverService.pagination({'offset':response.result.data.list_info.offset, 'total':response.result.data.list_info.total, 'page_limit' :this.pageLimit });
     this.recordNotFound2 = this.instructorlist.length == 0 ? true : false;


      }
     
    }, 
    (error)=>{
        console.log(error);
    });
  }



savedatainstructor(val){
 console.log(val)
if(val.add == false){
this.instructorselect= val.id
this.instructselectname = val.username

}

}
savealldata2(){

}
savealldata(){
   this.sendstudent = ''
    this.sendstudentname =''
   // console.log(this.sendstudentarr)

   this.sendstudent=this.sendstudentarr.join(',');
   // console.log(this.sendstudent)

   this.sendstudentname =this.studentnames.join(',');  
}


savedata(val){

 var adding = false
if(val.add == false){

if(this.sendstudentarr.length != 0){

 for (var i = 0; i < this.sendstudentarr.length; i++) {


if(val.username !== this.sendstudentarr[i]){

 adding  = true
}

}
  if(adding){
      this.sendstudentarr.push(val.username)
      this.studentnames.push(val.fname)

  }
}
else{
   this.sendstudentarr.push(val.username)

    this.studentnames.push(val.fname)
}

}
else{

   for (var j = 0; j < this.sendstudentarr.length; j++) {

if(val.username == this.sendstudentarr[j]){

    this.sendstudentarr.splice(this.sendstudentarr.indexOf(this.sendstudentarr[j]), 1 );

}
}
   for (var k = 0; k < this.studentnames.length; k++) {

if(val.fname+val.lname == this.studentnames[k]){

    this.studentnames.splice(this.studentnames.indexOf(this.studentnames[k]), 1 );

}
}

}
 
 console.log(this.sendstudentarr)
}

view_report_list(){


  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');


registerstudentdata.action="view_report_list";
registerstudentdata.admin_id=admin_id;





 registeruser.access_token=access_token;
  registeruser.operation="student_report";
  registeruser.moduleType="student_report";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){

     this.reports = response.result.data
//     iziToast.success({
//     title: 'OK',
//     position: 'topRight',
//     message: 'Report generated Successfully!',
// });

// this.reportview = true;

   }else{

   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}


viewreport(){
if(this.getRep.value.report_type == 'html')
{

}
else if(this.getRep.value.report_type == 'csv'){

}
else if(this.getRep.value.report_type == 'pdf'){

}
}


view_asssign_report(){


  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');


registerstudentdata.action="student_report_view";
// registerstudentdata.from_dt=this.getRep.value.from_dt;
// registerstudentdata.to_dt=this.getRep.value.to_dt;
registerstudentdata.name=this.sendstudent;
registerstudentdata.event_id=this.getRep.value.event_name;
registerstudentdata.admin_id=admin_id;
registerstudentdata.report_type=this.getRep.value.report_type;
registerstudentdata.inst_id=this.instructorselect;


 registeruser.access_token=access_token;
  registeruser.operation="student_report";
  registeruser.moduleType="student_report";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){

if(response.result.data.header != null &&  response.result.data.values != null
){


this.reportheadershow = response.result.data.header
      this.datereport   =[];
for (var i = 0; i < this.reportheadershow.length; i++) {
var parsedDate = Date.parse(this.reportheadershow[i]);

if (isNaN(this.reportheadershow[i]) && !isNaN(parsedDate)) {
  console.log(this.reportheadershow[i])
  this.datereport.push(this.reportheadershow[i])
    /* do your work */
}

}

this.reportheader = response.result.data.header
      this.reportcontent =  response.result.data.values






      console.log(this.reportheader)
console.log(this.reportcontent)
    iziToast.success({
    title: 'OK',
    position: 'topRight',
    message: 'Report generated Successfully!',
});

this.reportview = true;
}
    else{
        iziToast.error({
    title: 'OK',
    position: 'topRight',
    message: 'No Data Found!',
});
    }  

   }else{
      iziToast.error({
    // title: 'OK',
    position: 'topRight',
    message: 'No Data Found!',
});
   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}


course_list(){

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');
  
    let api_req:any = '{"operation":"lecture","moduleType":"lecture","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"course_list","admin_id":"'+admin_id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response){
      this.courselist= response.result.data

     
    
      console.log(this.courselist);
      }
   

    }, 
    (error)=>{
        console.log(error);
    });
  }

event_id_list(){

    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');
  
    let api_req:any = '{"operation":"lecture","moduleType":"lecture","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"event_id_list","admin_id":"'+admin_id+'"}}'
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response){
      // this.courselist= response

           this.eventslist= response.result.data
    
      console.log(this.eventslist);
      }
   

    }, 
    (error)=>{
        console.log(error);
    });
  }


    courseinsert(){

if(this.getRep.value.course_name == undefined){

      iziToast.error({

          message: "Please Select Course Name",
          position: 'topRight'
      });
      return false
}

// console.log(this.eventData.types)
  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');


registerstudentdata.action="course_report";
registerstudentdata.course_id=this.getRep.value.course_name;

registerstudentdata.name=this.sendstudent;
// registerstudentdata.event_name=this.getRep.value.event_name;
registerstudentdata.admin_id=admin_id;
registerstudentdata.report_type=this.getRep.value.report_type;
registerstudentdata.inst_id="";




 registeruser.access_token=access_token;
  registeruser.operation="student_report";
  registeruser.moduleType="student_report";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status == true && response.result.data == 1){
     
this.course_attendee_view()
     this.view_report_list()

     // this.view_asssign_report()
//      iziToast.success({
//     title: 'OK',
//     position: 'topRight',
//     message: 'Successfully event assigned!',
// });
   }else{
   iziToast.error({
    // title: 'OK',
    position: 'topRight',
    message: 'Error operation!',
});
   }
      
      }, 
      (error)=>{
          console.log(error);
      });





}

course_attendee_view(){


  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');


registerstudentdata.action="course_report_view";
// registerstudentdata.from_dt=this.getRep.value.from_dt;
// registerstudentdata.to_dt=this.getRep.value.to_dt;
registerstudentdata.name=this.sendstudent;
registerstudentdata.course_id=this.getRep.value.course_name;
registerstudentdata.admin_id=admin_id;
registerstudentdata.report_type=this.getRep.value.report_type;
registerstudentdata.inst_id='';


 registeruser.access_token=access_token;
  registeruser.operation="student_report";
  registeruser.moduleType="student_report";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){

if(response.result.data.header != null &&  response.result.data.values != null
){

    if(response.result.data.values.length != 0){
        // if(response.result.data.values[0].course_id == "0"){
this.nameshowevent = "Course : "+"C"+response.result.data.values[0].course_id+" "+response.result.data.values[0].event_name;

//         }else{
// this.nameshowevent = "Course : "+response.result.data.values[0].event_name+"C"+response.result.data.values[0].course_id;

//         }
        this.nameshowinstructor = response.result.data.values[0].ins_fname
      }




this.reportheadershow = response.result.data.header





      this.datereport   =[];
for (var i = 0; i < this.reportheadershow.length; i++) {
var parsedDate = Date.parse(this.reportheadershow[i]);

if (isNaN(this.reportheadershow[i]) && !isNaN(parsedDate)) {
  console.log(this.reportheadershow[i])
  this.datereport.push(this.reportheadershow[i])
    /* do your work */
}

}

this.reportheader = response.result.data.header
      this.reportcontent =  response.result.data.values




// for (var g = 0; g < this.reportcontent.length; g++) {
//   if(this.reportcontent[g] == "nirp")
//   {
//     this.reportcontent[g] = "nric"
//   }
// }
  
      console.log(this.reportheader)
console.log(this.reportcontent)
    iziToast.success({
    title: 'OK',
    position: 'topRight',
    message: 'Report generated Successfully!',
});

this.reportview = true;
}
else{
  iziToast.error({
    // title: 'OK',
    position: 'topRight',
    message: 'No Data Found!',
});      
}
}
else{
  iziToast.success({
    // title: 'OK',
    position: 'topRight',
    message: 'No Data Found!',
});

   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}



}
