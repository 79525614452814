import { Component, OnInit ,ViewChild, ɵbypassSanitizationTrustResourceUrl} from '@angular/core';
import { WizardComponent } from 'angular-archwizard';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ServerService } from '../services/server.service';
import * as feather from 'feather-icons';
import { Router,ActivatedRoute } from '@angular/router';
declare var $:any;
declare var iziToast:any;
@Component({
  selector: 'app-addnewinstructor',
  templateUrl: './addnewinstructor.component.html',
  styleUrls: ['./addnewinstructor.component.css']
})
export class AddnewinstructorComponent implements OnInit {
  @ViewChild(WizardComponent)
    public wizard: WizardComponent;
information: FormGroup;
step2 : FormGroup;
step3 : FormGroup;
 studentseditdetails:any=[]; 
 id;
page =''; 
enter = true;
 eventslist:any=[];
 eventbind;
pagetype = '';
eventperm;
courseperm;
meetingperm;
enableotps;
enablemobiles;
perm1;
perm2;
perm3;
type;
  constructor(private route:ActivatedRoute,private router:Router,private serverService: ServerService) {
this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {

     this.id=this.router.getCurrentNavigation().extras.state.id; 
     this.page=this.router.getCurrentNavigation().extras.state.page; 
     this.pagetype=this.router.getCurrentNavigation().extras.state.pagetype; 
     this.type=this.router.getCurrentNavigation().extras.state.pagetype; 

     console.log(this.id)
     console.log(this.page)
     console.log(this.pagetype)

     this.get_edit_instructor()
    
      }
    });

   }
enterStep(r){

}
  ngOnInit(): void {
 this.viewevents()
    feather.replace();
    this.information = new FormGroup({
      // 'username' : new FormControl(null,Validators.required),
      'email' : new FormControl(null,[
    Validators.required,
    Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
      // 'alt_email' : new FormControl(null,[
    // Validators.required,
    // Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
      // 'typestaff' : new FormControl(null,Validators.required),
      
      'fname' : new FormControl(null,Validators.required),
      'lname' : new FormControl(null),
      // 'password' : new FormControl(null,Validators.required),
        // 'confirm':new FormControl(null,Validators.required),
      'phone_no' : new FormControl(null,Validators.required),
      // 'phone_no2' : new FormControl(null,Validators.required),
      'nirp' : new FormControl(null),
      'home' : new FormControl(null),

      'country_code' : new FormControl(null,Validators.required),
      // 'acceptTerms' : new FormControl(0),
        // 'parent_name':new FormControl(null,Validators.required),
        // 'parent_phone':new FormControl(null,Validators.required),
        'address':new FormControl(null),
        'city':new FormControl(null),
        'state':new FormControl(null),
        'country':new FormControl(null,Validators.required),
        'pincode':new FormControl(null),
        // 'events':new FormControl(null,Validators.required),
        'age':new FormControl(null),
        'gender':new FormControl(null,Validators.required),
        'enableotp':new FormControl(null),
        'enablemobile':new FormControl(null)
       
     });

  this.step2 = new FormGroup({
      'username' : new FormControl(null,Validators.required),
    
      'password' : new FormControl(null,Validators.required),
        'confirm':new FormControl(null,Validators.required),
        
        'eventperms':new FormControl(null),
        
        'courseperms':new FormControl(null),
        'meetingperms':new FormControl(null)
     
     
     });

    // this.step3 = new FormGroup({
    //  'agree': new FormControl(false,Validators.requiredTrue)
    
     
     
    //  });
this.information.patchValue({

  gender: "", 
  enableotp:true,
  enablemobile:true,
 country_code :"65",
 country: "Singapore"
  // typestaff:""
});

  }

 get password() {
  return this.step2.controls['password'];
}

get confirm_password(){
  return this.step2.controls['confirm'];
}

onPasswordChange() {
  if (this.confirm_password.value == this.password.value) {
    this.confirm_password.setErrors(null);
  } else {
    this.confirm_password.setErrors({ mismatch: true });
  }
}

  moveToNext() {
   
   console.log(this.information.value)
        if (this.information.valid) {
           
              this.wizard.goToNextStep();
            
   
        } else {
            this.validateAllFields(this.information); 
        }
    }
moveToNext2(){

console.log(this.step2.value.courseperms)
console.log(this.step2.value.eventperms)

   if(this.step2.valid){
     this.registedatass()
   //   // this.wizard.goToNextStep();
   }
   else {
            this.validateAllFields(this.step2); 
        }
  }

finishFunction(){
   if(this.step3.valid){
     
     this.registedatass()
   }
   else {
            this.validateAllFields(this.step3); 
        }
  
}


validateAllFields(formGroup: FormGroup) {         
        Object.keys(formGroup.controls).forEach(field => {  
            const control = formGroup.get(field);            
            if (control instanceof FormControl) {             
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {        
                this.validateAllFields(control);  
            }
        });
    }



// registerdatas(){
 
//   let registerstudentdata:any =  this.information.value;
//   let registeruser:any = new Object();

//     let access_token: any=localStorage.getItem('access_token');

// if(this.page == 'Edit'){
// registerstudentdata.id=this.id;
// registerstudentdata.status="1";
// registerstudentdata.action="update_student_rec";
// }else{
// registerstudentdata.action="instruct_rec";

// }

// registerstudentdata.username =  this.step2.value.username
// registerstudentdata.password = this.step2.value.password


//     registerstudentdata.phone_code="+91",
//      registeruser.access_token=access_token;
//   registeruser.operation="user";
//   registeruser.moduleType="user";
//   registeruser.api_type="web";
//   registeruser.element_data = registerstudentdata;
//       this.serverService.sendServer(registeruser).subscribe((response: any) => {

//         console.log(response)
       


//        //  if(response.result.status==true){
      
//       }, 
//       (error)=>{
//           console.log(error);
//       });
// }

localUrl: any[];
 file: File;
  showPreviewImage(event: any) {
    this.file = event.target.files[0];
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();
            reader.onload = (event: any) => {
                this.localUrl = event.target.result;
            }
            reader.readAsDataURL(event.target.files[0]);
        }
    }


registedatass(){
    let access_token: any=localStorage.getItem('access_token');

    let superadmin: any=localStorage.getItem('superadmin');
    
    var formData = new FormData();


    if(this.file != undefined){
    formData.append('image', this.file);

  }else{
    formData.append('image','');

  }

 if(this.step2.value.courseperms == true){
    this.courseperm = 1;

  }else{
   this.courseperm = 0;

  }


 if(this.step2.value.eventperms == true){
    this.eventperm = 1;

  }else{
   this.eventperm = 0;

  }
 if(this.step2.value.meetingperms == true){
    this.meetingperm = 1;

  }else{
   this.meetingperm = 0;

  }

  if(this.information.value.enableotp == true){
    this.enableotps = 1;

  }else{
   this.enableotps = 0;

  }
if(this.information.value.enablemobile == true){
    this.enablemobiles = 1;

  }else{
   this.enablemobiles = 0;

  }

  formData.append('operation', 'user');
  formData.append('moduleType', 'user');
  formData.append('api_type', 'web');
  formData.append('access_token', access_token);
  formData.append('fname', this.information.value.fname);
  formData.append('email', this.information.value.email);
  formData.append('gender', this.information.value.gender);
  formData.append('country_code', this.information.value.country_code);
  formData.append('phone_no',this.information.value.phone_no);
  formData.append('home',this.information.value.home);
  formData.append('country', this.information.value.country);
  formData.append('nirp',this.information.value.nirp);
  formData.append('age', this.information.value.age);
  formData.append('address', this.information.value.address);
  formData.append('pincode', this.information.value.pincode);
  formData.append('username', this.step2.value.username);
  formData.append('password',this.step2.value.password);
  formData.append('super_admin ',superadmin);
  formData.append('admin_id',localStorage.getItem('userId'));
  formData.append('event',this.eventperm);
  formData.append('course',this.courseperm);
  formData.append('meeting',this.meetingperm);
  formData.append('enable_otp',this.enableotps);
  formData.append('show_mob',this.enablemobiles);

  

  // formData.append('alt_email', this.information.value.alt_email);


  // formData.append('lname', this.information.value.lname);
  


  if(this.pagetype == 'Instructor'){
  formData.append('type','0');

}else{
  formData.append('type','1');

}
  
  formData.append('city', this.information.value.city);
  formData.append('state', this.information.value.state);
  formData.append('events', this.information.value.events);

  
  // formData.append('phone_code', this.information.value.country_code);



if(this.page == 'Edit'){
  formData.append('id', this.id);
  formData.append('status', "1");
formData.append('action', 'update_instruct_rec');

}else{

formData.append('action', 'instruct_rec');

}

    console.log(formData);
  var s = this;
  $.ajax({  
    url:"https://booking.mconnectapps.com/api/v1.0/index_new.php",  
    type : 'POST',
    data : formData,
    processData: false,  // tell jQuery not to process the data
    contentType: false, 
    success:function(data){ 
      var parsed_data = JSON.parse(data);
      // console.log(this.parsed_data );
        if(parsed_data.status == 'true'){  
      if(s.page == 'Edit'){

      if(s.pagetype == 'Instructor'){  
          iziToast.success({
          message: "Instructor Details Updated successfully",
          position: 'topRight'
      });


s.router.navigate(['/recordinstructor'])

}else{
     iziToast.success({
          message: "Incharge Details Updated successfully",
          position: 'topRight'
      });
s.router.navigate(['/incharge'])

}

      }else{

if(s.pagetype == 'Instructor'){
  iziToast.success({
          message: "New Instructor added successfully",
          position: 'topRight'
      });
s.router.navigate(['/recordinstructor'])
      }else{

iziToast.success({
          message: "New Incharge added successfully",
          position: 'topRight'
      });
s.router.navigate(['/incharge'])
      

      } 
    }
  }else {
        iziToast.error({
          message: "Sorry, Some Error Occured",
          position: 'topRight'
      });
      
      }
    }  
});  

  }







get_edit_instructor(){
    let access_token: any=localStorage.getItem('access_token');
  
    let api_req:any = '{"operation":"user","moduleType":"user","api_type": "web","access_token":"'+access_token+'", "element_data":{"action":"edit_instruct_rec","id":"'+this.id+'"}}';
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status == true){
      this.studentseditdetails = response.result.data
 this.localUrl= response.result.data[0].image
  var array = this.studentseditdetails[0].events.split(','); 
  
this.eventbind = array

if(this.studentseditdetails[0].enable_otp == '1'){
  this.enableotps = true;

}else{
 this.enableotps = false;
}

if(this.studentseditdetails[0].show_mob == '1'){
  this.enablemobiles = true;

}else{
 this.enablemobiles = false;
}



  this.information.patchValue({
      email:this.studentseditdetails[0].email,
      fname:this.studentseditdetails[0].fname,
      lname:this.studentseditdetails[0].lname,
      phone_no:this.studentseditdetails[0].phone_no,
      country_code:this.studentseditdetails[0].country_code,
        parent_name:this.studentseditdetails[0].parent_name,
        parent_phone:this.studentseditdetails[0].parent_phone,
        address:this.studentseditdetails[0].address,
        city:this.studentseditdetails[0].city,
        state:this.studentseditdetails[0].state,
        country:this.studentseditdetails[0].country,
        pincode:this.studentseditdetails[0].pincode,
        events:this.eventbind,
        age:this.studentseditdetails[0].age,
        phone_no2:this.studentseditdetails[0].phone_no2,
        alt_email:this.studentseditdetails[0].alt_email,
        gender:this.studentseditdetails[0].gender,
        nirp:this.studentseditdetails[0].nirp,
        home:this.studentseditdetails[0].home,
        enableotp:this.enableotps,
        enablemobile:this.enablemobiles


  });


  

if(this.studentseditdetails[0].event == '1'){
  this.perm1=  true;
}else{
   this.perm1=  false;
}

if(this.studentseditdetails[0].course == '1'){
  this.perm2=  true;
}else{
   this.perm2=  false;
}

if(this.studentseditdetails[0].meeting == '1'){
  this.perm3=  true;
}else
   this.perm3=  false;
}

 this.step2.patchValue({
username: this.studentseditdetails[0].username,
 password :this.studentseditdetails[0].psw,
 confirm:this.studentseditdetails[0].psw,

 eventperms:this.perm1,
 courseperms:this.perm2,
 meetingperms:this.perm3


  });

      // }

    }, 
    (error)=>{
        console.log(error);
    });
  }

viewevents(){

  let registerstudentdata:any = new Object();
 let registeruser:any = new Object();
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('userId');


registerstudentdata.action="view_events";
registerstudentdata.admin_id=admin_id;

 registeruser.access_token=access_token;
  registeruser.operation="user";
  registeruser.moduleType="user";
  registeruser.api_type="web";
  registeruser.element_data = registerstudentdata;
      this.serverService.sendServer(registeruser).subscribe((response: any) => {

        console.log(response)
   if(response.result.status==true){
     this.eventslist = response.result.data

   }else{

   }
      
      }, 
      (error)=>{
          console.log(error);
      });
}
}
