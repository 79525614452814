import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ServerService } from '../services/server.service';
declare var iziToast:any;
import '../../assets/js/scripts.js';
@Component({
  selector: 'app-forgotpwd',
  templateUrl: './forgotpwd.component.html',
  styleUrls: ['./forgotpwd.component.css']
})
export class ForgotpwdComponent implements OnInit {
 forgotpwdsuser:FormGroup;
 forgotpwdpassword:FormGroup;
  verifyotp:FormGroup;
   verifypassword:FormGroup;
 page;
 verifyotps = false;
 conformpassword = false;
 forgotuser_id;
  constructor(private route:ActivatedRoute,private router:Router,private serverService: ServerService) {
this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {

  this.page=this.router.getCurrentNavigation().extras.state.page; 

      }
    });

   }

  ngOnInit(): void {
 this.forgotpwdsuser = new FormGroup({
       'email' : new FormControl(null,[
    Validators.required,
    Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
                'emailtype' : new FormControl(null,Validators.required),
            'phoneno' : new FormControl(null,Validators.required),
                'company_name' : new FormControl(null,Validators.required)

            });

 this.forgotpwdpassword = new FormGroup({
    
            'username' : new FormControl(null,Validators.required),   
            });
 this.verifyotp = new FormGroup({
    
            'otp' : new FormControl(null,Validators.required),   
            });
 this.verifypassword = new FormGroup({
    
            'newpassword' : new FormControl(null,Validators.required),
            'confirmnewpassword' : new FormControl(null,Validators.required), 

              
            });


  }
submitforgotuser(){
  
	let forgot:any = new Object();
let forgotdata:any = new Object();

    forgotdata.action="forget_username";
     forgotdata.company_name="forget_username";
    if(this.forgotpwdsuser.value.emailtype == 'emailtype'){
    forgotdata.value=this.forgotpwdsuser.value.email;
    }
    else{
    forgotdata.value=this.forgotpwdsuser.value.phoneno;

    }

          forgot.operation="user";
          forgot.moduleType="user";
          forgot.api_type="web";
          forgot.element_data=forgotdata;                     
                this.serverService.sendServer(forgot).subscribe((response:any) => {
                    
        console.log(response)
         if(response.result.status == true && response.result.data != false ){
        	iziToast.success({
          message:"Your Username is"+' '+ response.result.data,
          position: 'topLeft'
      });
        	this.forgotpwdsuser.reset();
this.router.navigate(['login'], { state: { username: response.result.data} });
                    
                        } 
                      else{
                      	 iziToast.error({
          message: "Invalid Data",
          position: 'bottomLeft'
      });
                      }
                    }, 
                    (error)=>{
                        console.log(error);
                    });











}



submitforgotpassword(){
if (this.forgotpwdpassword.valid) {


	let forgot:any = new Object();
let forgotdata:any = new Object();

    forgotdata.action="forget_password";
    
    forgotdata.value=this.forgotpwdpassword.value.username;
    

          forgot.operation="user";
          forgot.moduleType="user";
          forgot.api_type="web";
          forgot.element_data=forgotdata;                     
         this.serverService.sendServer(forgot).subscribe((response:any) => {
                    
        console.log(response)
                    if(response.result.status == true ){
        //                this.loginotpError ="";
        // this.loginotpSuccess="OTP Send Sucessfully";
                           if(response.result.data.sms_sent == "1")
                           {
                     this.forgotuser_id =response.result.data.user_id
					this.verifyotps = true;
  			iziToast.success({
          message: "OTP send Successfully to your Phone Number",
          position: 'bottomLeft'
      });
this.forgotpwdpassword.reset();

                           }else{
  
          iziToast.error({
          message: "Check your Username",
          position: 'bottomLeft'
      });
                           }
                        } 
                     else{
                        iziToast.error({
          message: "Check your Username",
          position: 'bottomLeft'
      });	
                     } 

                    }, 
                    (error)=>{
                        console.log(error);
                    });

}else{
	 this.validateAllFields(this.forgotpwdpassword);
}


}


submitverifyotp(){
if (this.verifyotp.valid) {

	let verifyotp:any = new Object();
let verifyotpdata:any = new Object();

    verifyotpdata.action="check_otp";
    verifyotpdata.user_id=this.forgotuser_id;
    
    verifyotpdata.otp=this.verifyotp.value.otp;
    

          verifyotp.operation="user";
          verifyotp.moduleType="user";
          verifyotp.api_type="web";
          verifyotp.element_data=verifyotpdata;                     
         this.serverService.sendServer(verifyotp).subscribe((response:any) => {
                    
        console.log(response)
                    if(response.result.status == true ){
       
                           if(response.result.data == 1)
                           {

this.conformpassword = true;
this.verifyotps = false;
	iziToast.success({
          message: "OTP verified Successfully",
          position: 'bottomLeft'
      });
                           }else{


             	 iziToast.error({
          message: "Invalid OTP",
          position: 'bottomLeft'
      });


                           }
                        } 
                        else{
                        	 iziToast.error({
          message: "Invalid OTP",
          position: 'bottomLeft'
      });
                        }
                      

                    }, 
                    (error)=>{
                        console.log(error);
                    });

}
else{
	 this.validateAllFields(this.verifyotp);
}


}



submitchangedpassword(){


if (this.verifypassword.valid) {
	let changedpassword:any = new Object();
let changedpasswordpdata:any = new Object();

    changedpasswordpdata.action="update_password";
    changedpasswordpdata.user_id=  this.forgotuser_id;
    
    changedpasswordpdata.password=this.verifypassword.value.newpassword;
    

          changedpassword.operation="user";
          changedpassword.moduleType="user";
          changedpassword.api_type="web";
          changedpassword.element_data=changedpasswordpdata;                     
         this.serverService.sendServer(changedpassword).subscribe((response:any) => {
                    
        console.log(response)
                    if(response.result.status == true ){
                    	if(response.result.data == 1){
                    		iziToast.success({
          message: "Password Changed Successfully",
          position: 'bottomLeft'
      });	
this.router.navigate(['/login'])


                    	}
       else{
       	iziToast.error({
          message: "Some Error Occured",
          position: 'bottomLeft'
      });
                
       }}
                        else{
           iziToast.error({
          message: "Some Error Occured",
          position: 'bottomLeft'
      });
                        }
                      

                    }, 
                    (error)=>{
                        console.log(error);
                    });

}else{
	 this.validateAllFields(this.verifypassword);

}


}


  get password() {
  return this.verifypassword.controls['newpassword'];
}

get confirm_password(){
  return this.verifypassword.controls['confirmnewpassword'];
}

onPasswordChange() {
  if (this.confirm_password.value == this.password.value) {
    this.confirm_password.setErrors(null);
  } else {
    this.confirm_password.setErrors({ mismatch: true });
  }
}


validateAllFields(formGroup: FormGroup) {         
        Object.keys(formGroup.controls).forEach(field => {  
            const control = formGroup.get(field);            
            if (control instanceof FormControl) {             
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {        
                this.validateAllFields(control);  
            }
        });
    }




}
